import { CallFlowClient } from "./../../../../../System-api";

import { Component, Input, OnInit } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { DataService } from "../../../../../@core/utils/data.service";

@Component({
  selector: "setting-incoming-call",
  templateUrl: "./setting-incoming-call.component.html",
  styleUrls: ["./setting-incoming-call.component.scss"],
})
export class SettingIncomingCallComponent implements OnInit {
  @Input() parentForm: UntypedFormGroup;
  @Input() callSetting;
  @Input() languageDefault;
  @Input() botContactList;
  @Input() phoneNumberList;

  options = [
    { id: "0", value: "Ringing" },
    { id: "1", value: "Answer" },
    { id: "2", value: "Ringing & Answer" },
  ];
  selectedPhoneNumbers: number[];
  selectedBotContact: number[];

  constructor(
    public dataService: DataService,
    public callflowClient: CallFlowClient
  ) {
    this.getData();
  }
  ngOnInit(): void {
    if (!this.dataService.CallFlow.id) {
      if (this.callSetting.botContact) {
        const botContact = JSON.parse(this.callSetting.botContact);
        this.selectedBotContact = [];
        botContact.map((contact) => this.selectedBotContact.push(contact.id));
      }
      if (this.callSetting.selectedPhoneNumbers) {
        const numberPhone = this.callSetting.selectedPhoneNumbers.split(",");
        this.selectedPhoneNumbers = [];
        numberPhone.map((number) =>
          this.selectedPhoneNumbers.push(Number(number))
        );
      }
    }
  }
  getData() {
    if (this.dataService.CallFlow.id) {
      this.callflowClient
        .contactFlowUsing(this.dataService.CallFlow.id)
        .subscribe((rs) => {
          this.selectedPhoneNumbers = [];
          this.selectedBotContact = [];

          rs.phoneNumbers.map((number) => {
            if (number.active) {
              this.selectedPhoneNumbers.push(Number(number.id));
            }
          });
          rs.botContact?.map((contact) =>
            this.selectedBotContact.push(Number(contact.id))
          );

          this.callSetting.selectedPhoneNumbers =
            this.selectedPhoneNumbers.toString();
          this.callSetting.botContact = JSON.stringify(rs.botContact);
        });
    }
  }
  handleEnableRecording(checked: boolean) {
    this.callSetting.enableRecording = checked;
  }

  phoneChangeHandle(data) {
    this.callSetting.selectedPhoneNumbers = data.toString();
  }

  botContactChangeHandle(data) {
    const result = this.botContactList.filter((item) => data.includes(item.id));
    this.callSetting.botContact = JSON.stringify(result);
  }
}
