import { IApplicationData } from "./common";
export class Dial implements IApplicationData {
  constructor() {
    this.audioId = "";
    this.audioPath = "";
    this.text = "";
    this.option = 1;
    this.name = "Dial";
    this.trunkNumber = "";
    this.trunkNumberId = "";
    this.callerNumber = "";
    this.dialDestination = "";
    this.headers = "";
    this.timeout = "";
    this.multipleCase = true;
    this.multipleOptionCase = true;
    this.attribute = "";
    this.sentiment = "";
    this.multipleTransition = true;
    this.isRequired = true;
    this.checkFields = false;
    this.pathAttribute = "";
    this.transcriptionAttribute = "";
    this.record = false;
  }
  name: string;
  audioPath: string;
  audioId: string;
  text: string;
  option: number;
  trunkNumber: string;
  trunkNumberId: string;
  dialDestination: string;
  callerNumber: string;
  headers: string;
  timeout: string;
  multipleCase: boolean;
  record: boolean;
  pathAttribute: string;
  transcriptionAttribute: string;
  multipleOptionCase: boolean;
  multipleTransition: boolean;
  attribute: string;
  sentiment: string;
  isRequired: boolean;
  checkFields: boolean;
}
