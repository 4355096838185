<div>
  <div class="flex-col">
    <div>
      <div class="flex-title">
        <div class="label-title-wrapper">
          <span class="title">Videobot Setting</span>
          <nb-icon
            class="hint-icon"
            nbTooltip="Define videobot for your flow."
            nbTooltipTrigger="hover"
            nbTooltipPlacement="right"
            nbTooltipStatus="basic"
            icon="question-mark-circle-outline"
          ></nb-icon>
        </div>
        <span class="line"></span>
      </div>

      <div class="box-setting add-sentiment" style="flex-wrap: nowrap">
        <div style="width: 22rem">
          <label class="form-label label required" for="input-name-var"
            >Name</label
          >
          <input
            id="input-name-var"
            [(ngModel)]="name"
            type="text"
            nbInput
            [disabled]="currentDefault"
            fullWidth
            [status]="invalidName ? 'danger' : 'basic'"
            fieldSize="small"
            placeholder="Name"
            (ngModelChange)="handleWhiteSpace('name', $event)"
          />

          <p
            class="caption status-danger"
            style="margin-bottom: -15px"
            *ngIf="invalidName"
          >
            Name already exists.
          </p>
        </div>
        <div style="width: 16rem; display: flex; flex-direction: column">
          <label class="form-label label required" for="input-language"
            >Type</label
          >
          <nb-select
            size="small"
            (ngModelChange)="changeType($event)"
            placeholder="Select Type"
            [(ngModel)]="type"
            fullWidth
          >
            <nb-option
              *ngFor="let typeItem of types"
              [value]="typeItem.value"
              >{{ typeItem.name }}</nb-option
            >
          </nb-select>
        </div>
        <div style="width: 28rem">
          <label class="form-label label required" for="input-url-var"
            >URL</label
          >
          <input
            id="input-url-var"
            fieldSize="small"
            [(ngModel)]="url"
            type="text"
            nbInput
            [status]="invalidUrl ? 'danger' : 'basic'"
            fullWidth
            placeholder="URL"
            (ngModelChange)="handleWhiteSpace('url', $event)"
          />
          <p
            class="caption status-danger"
            style="margin-bottom: -15px"
            *ngIf="invalidUrl"
          >
            {{ invalidUrl }}
          </p>
        </div>
        <div>
          <button
            type="button"
            [disabled]="
              !name || !url || !type || invalidName || invalidUrl.trim() != ''
            "
            nbButton
            status="primary"
            size="small"
            (click)="addSentiment()"
          >
            {{ actionType }}
          </button>
        </div>
        <div>
          <button
            class="secondary"
            *ngIf="currentName"
            nbButton
            type="button"
            (click)="cancelUpdate()"
            size="small"
            status="basic"
          >
            <nb-icon icon="close-outline"></nb-icon>
          </button>
        </div>
      </div>
      <nb-list>
        <nb-list-item *ngFor="let sentiment of sentiments; let i = index">
          <div
            class="nation-item"
            style="width: 100%; word-break: break-word"
            (click)="handlePlayVideo(sentiment)"
          >
            <div class="nation-item-name" style="width: 20%">
              <nb-icon
                status="primary"
                [icon]="getIconType(sentiment.mediaType)"
              ></nb-icon>
              <span style="width: 80%">{{ sentiment.name }}</span>
            </div>
            <div
              class="nation-item-name"
              style="width: 66%; word-break: break-word"
            >
              <span>{{ sentiment.mediaUrl }}</span>
            </div>
            <div
              class="tag-action"
              style="width: 10%"
              [ngClass]="{ 'hidden-action': currentName == sentiment.name }"
            >
              <a
                data-fancybox="gallery"
                [attr.data-caption]="sentiment.name"
                [href]="sentiment.mediaUrl"
              >
                <nb-icon
                  icon="eye-outline"
                  style="font-size: 18px; color: #3367d6; cursor: pointer"
                ></nb-icon>
              </a>
              <nb-icon
                icon="edit-outline"
                (click)="handleEdit(sentiment, $event)"
                style="font-size: 18px; color: #3367d6; cursor: pointer"
              ></nb-icon>
              <nb-icon
                *ngIf="!sentiment.isDefault"
                icon="trash-2-outline"
                (click)="remove(sentiment, i)"
                style="font-size: 18px; color: #ff4332; cursor: pointer"
              ></nb-icon>
            </div>
          </div>
        </nb-list-item>
      </nb-list>
    </div>
  </div>
</div>
