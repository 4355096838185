<div class="row">
  <div class="flex-title" *ngIf="showTitle">
    <span class="title">Grammar</span>
    <span class="line"></span>
  </div>
  <div class="mb-2">
    <nb-card style="height: auto" *ngIf="isLoadingGrammar">
      <nb-list class="example-list">
        <nb-list-item class="example-box">
          <div class="grammar-name-wrapper">
            <span class="grammar-name">Loading...</span>
          </div>
          <div class="action-wrapper">
            <button
              ghost
              class="tbl-action"
              nbButton
              size="small"
              style="opacity: 0"
            >
              <nb-icon icon="edit-outline"></nb-icon>
            </button>
          </div>
        </nb-list-item> </nb-list
    ></nb-card>

    <nb-card
      style="height: auto"
      status="basic"
      *ngIf="grammarArray.length > 0 && !isLoadingGrammar"
    >
      <nb-list
        cdkDropList
        class="example-list"
        (cdkDropListDropped)="drop($event)"
      >
        <nb-list-item
          *ngFor="let grammar of grammarArray; let i = index"
          class="example-box"
          cdkDrag
        >
          <div class="grammar-name-wrapper">
            <span class="grammar-type">{{ grammar.type }}:</span>
            <span class="grammar-name">{{ grammar.name }}</span>
          </div>
          <div class="action-wrapper">
            <button
              *ngIf="grammar.type != 'BuiltIn'"
              ghost
              class="tbl-action"
              nbButton
              size="small"
              (click)="updateCurrentData($event, grammar)"
            >
              <nb-icon icon="edit-outline"></nb-icon>
            </button>
            <button
              ghost
              class="tbl-action"
              nbButton
              size="small"
              (click)="deleteGrammarInList(i)"
            >
              <nb-icon icon="trash-2-outline"></nb-icon>
            </button>

            <div class="move-icon" style="display: flex; align-items: center">
              <nb-icon
                style="margin-right: -14px"
                status="basic"
                icon="more-vertical-outline"
              ></nb-icon>
              <nb-icon status="basic" icon="more-vertical-outline"></nb-icon>
            </div>
          </div>
        </nb-list-item>
      </nb-list>
    </nb-card>
  </div>
  <div class="add-grammar mb-2">
    <button
      [nbPopover]="templateRef"
      shape="round"
      type="button"
      [size]="sizeButton"
      nbPopoverTrigger="noop"
      nbPopoverPlacement="left"
      (click)="showLog()"
      status="primary"
      nbButton
    >
      <nb-icon [icon]="icon"></nb-icon>
    </button>
    <div style="display: flex; align-items: center">
      <span style="font-size: 13.5px">Add a grammar</span>
      <nb-icon
        class="ms-1"
        style="color: #929bb3; width: 15px"
        nbTooltip="Choose the grammar to validate the user response."
        nbTooltipPlacement="bottom"
        nbTooltipStatus="basic"
        nbTooltipTrigger="hover"
        icon="question-mark-circle-outline"
      >
      </nb-icon>
    </div>
  </div>
</div>
<ng-template #templateRef2>
  <nb-card style="max-width: 500px; margin-bottom: 0">
    <nb-card-body style="font-size: 13px">
      <div style="margin-bottom: 0.5rem">
        Validate the user response by using grammar.
      </div>
      <div style="margin-bottom: 0.5rem">
        <b>Built-in:</b> the system's built-in grammar (phone number
        recognition, yes or no answer recognition, date recognition,...).
      </div>
      <div style="margin-bottom: 0.5rem">
        <b>Function:</b> validate user response using custom function in php or
        javascript.
      </div>
      <div style="margin-bottom: 0.5rem">
        <b>Custom:</b> detect intent or entity of the user response (using
        intent service or GPT).
      </div>
      <div style="margin-bottom: 0.5rem">
        <b>Digit Patten:</b> using the pattern to validate the user response as
        digits.
      </div>
      <div style="margin-bottom: 0.5rem">
        <b>Phrase List:</b> define the list of intents you want to return from
        the user responses.
      </div>
    </nb-card-body>
  </nb-card>
</ng-template>
<ng-template #templateRef>
  <nb-card class="example-box-2"
    ><nb-card-header
      style="
        padding: 15px;
        display: flex;
        align-items: center;
        gap: 0.5rem;
        justify-content: space-between;
        padding-left: 1.5rem;
      "
    >
      <nb-form-field style="width: 100%">
        <input
          type="text"
          nbInput
          fullWidth
          placeholder="Search Grammar"
          fieldSize="small"
          [(ngModel)]="searchText"
          (keyup)="filterData($event)"
        />
        <button
          nbSuffix
          nbButton
          ghost
          size="tiny"
          (click)="clear()"
          *ngIf="searchText != ''"
        >
          <nb-icon icon="close-circle"></nb-icon>
        </button>
      </nb-form-field>
      <button nbButton size="small" ghost type="button" (click)="closeModal()">
        <nb-icon icon="close"></nb-icon>
      </button>
    </nb-card-header>
    <nb-card-body class="grammar-add">
      <div class="mb-2">
        <label class="form-label label d-flex" style="align-items: center">
          <span>Type Grammar</span>
          <nb-icon
            class="ms-1"
            style="color: #929bb3; width: 15px"
            [nbPopover]="templateRef2"
            nbPopoverPlacement="bottom"
            nbPopoverTrigger="hover"
            icon="question-mark-circle-outline"
          >
          </nb-icon
        ></label>
        <div class="d-flex custom-gap">
          <nb-select
            size="small"
            [(ngModel)]="type"
            fullWidth
            placeholder="Select Type Grammar"
            [nbSpinner]="isLoading"
            nbSpinnerSize="medium"
            nbSpinnerStatus="info"
            (ngModelChange)="selectTypeChange()"
          >
            <nb-option value="BuiltIn">Built-in</nb-option>
            <nb-option value="Function">Function</nb-option>
            <nb-option value="Custom" *ngIf="customGrammar">Custom</nb-option>
            <nb-option value="Digit">Digit Pattern</nb-option>
            <nb-option value="PhraseList" *ngIf="phraseListGrammar"
              >Phrase List</nb-option
            >
          </nb-select>
          <button
            *ngIf="!(type == 'BuiltIn')"
            type="button"
            (click)="addGrammar()"
            size="small"
            status="primary"
            nbButton
          >
            <nb-icon icon="plus-outline"></nb-icon>
          </button>
        </div>
      </div>
      <div class="mb-2">
        <nb-list class="custom-list" style="max-height: 25rem">
          <nb-list-item
            class="custom-list-item"
            *ngIf="grammarsData.length <= 0 && isLoading == false"
          >
            <div class="grammar-name-wrapper">
              <span class="grammar-name">Empty</span>
            </div>
            <div class="action-wrapper">
              <button
                style="opacity: 0"
                ghost
                class="tbl-action"
                nbButton
                size="small"
              >
                <nb-icon icon="edit-outline"></nb-icon>
              </button>
            </div>
          </nb-list-item>
          <nb-list-item
            class="custom-list-item"
            *ngFor="let item of grammarsData; let x = index"
            (click)="addToObject(item)"
          >
            <div class="grammar-name-wrapper">
              <span class="grammar-name">{{ item.name }}</span>
            </div>

            <div class="action-wrapper">
              <div *ngIf="type == 'Custom'" style="margin-right: 5px">
                <span
                  class="btn-action failed"
                  *ngIf="item?.status == 'FAILED' || item?.status == 'False'"
                  >Failed</span
                >
                <span
                  class="btn-action not-train"
                  *ngIf="item?.status == 'NOT-TRAINED'"
                  >Not Trained</span
                >
                <span
                  class="btn-action success"
                  *ngIf="item?.status == 'TRAINED' || item?.status == 'True'"
                  >Trained</span
                >
                <span
                  class="btn-action training"
                  *ngIf="
                    item?.status == 'TRAINING' ||
                    item?.status == null ||
                    item?.status == ''
                  "
                  >Training</span
                >
                <label
                  class="btn-action not-train"
                  *ngIf="item?.status == 'WAITING'"
                  >Waiting</label
                >
              </div>
              <button
                *ngIf="type == 'BuiltIn'"
                style="opacity: 0"
                ghost
                class="tbl-action"
                nbButton
                size="small"
              >
                <nb-icon icon="edit-outline"></nb-icon>
              </button>
              <button
                (click)="updateGrammar($event, item)"
                ghost
                class="tbl-action"
                nbButton
                size="small"
                *ngIf="type !== 'BuiltIn'"
              >
                <nb-icon icon="edit-outline"></nb-icon>
              </button>
              <button
                ghost
                (click)="removeGrammar($event, item)"
                class="tbl-action"
                nbButton
                size="small"
                *ngIf="type !== 'BuiltIn'"
              >
                <nb-icon icon="trash-2-outline"></nb-icon>
              </button>
            </div>
          </nb-list-item>
        </nb-list>
      </div>
    </nb-card-body>
  </nb-card>
</ng-template>
