import { Component, EventEmitter, Input, OnInit, Output, Directive} from '@angular/core';
import {
  DataService
} from '../../../@core/utils/data.service';
import {
  CallStatus
} from '../soft-phone.component';

@Component({
  selector: 'app-incomming-dialog',
  templateUrl: './incomming-dialog.component.html',
  styleUrls: ['./incomming-dialog.component.scss']
})
export class IncommingDialogComponent implements OnInit {

  @Input() session: any;
  @Output() audioEmit = new EventEmitter<string>();

  constructor(private dataService: DataService) {}

  ngOnInit() {
  }

  acceptCall() {
    this.session.accept();
    this.dataService.callStatus = CallStatus.InCall;
    this.dataService.stateCall = "incall";
  }

  rejectCall() {
    this.session.reject();
  }
}