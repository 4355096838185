<nb-card>
  <nb-card-header>
    <div class="d-flex justify-content-between">
      Log
      <button
        (click)="dismiss()"
        type="button"
        class="btn-close"
        aria-label="Close"
      ></button></div
  ></nb-card-header>
  <nb-card-body style="padding-bottom: 0">
    <div class="row mb-2 mt-2">
      <div class="d-flex flex-row justify-content-end">
        <input
          class="custom-input"
          nbInput
          (keyup)="searchDatatable($event)"
          placeholder="Search"
          fieldSize="tiny"
          name="search"
          autocomplete="off"
        />
        <ng-select
          style="max-width: 12rem"
          [items]="callIdList"
          bindLabel="callId"
          bindValue="callId"
          [(ngModel)]="currentCallId"
          (change)="getLog()"
          placeholder="Select Call ID"
          class="custom-input"
          status="primary"
          (clear)="clearLog()"
          [editableSearchTerm]="true"
        >
          <ng-template
            ng-option-tmp
            let-item="item"
            let-index="index"
            let-search="searchTerm"
          >
            <div class="function-item">
              <span>{{ item.callId }}</span>
            </div>
          </ng-template>
        </ng-select>

        <button
          nbButton
          status="basic"
          class="me-2 button-basic"
          (click)="export()"
          size="small"
          [nbSpinner]="isLoading"
          nbSpinnerSize="small"
          nbSpinnerStatus="primary"
          [disabled]="disabled || isLoading"
        >
          <nb-icon icon="log-out-outline"></nb-icon>Export
        </button>
        <button
          title="Clear all log"
          status="primary"
          type="button"
          nbButton
          size="small"
          (click)="clear()"
          [disabled]="isReadOnly == true"
        >
          Clear
        </button>
      </div>
    </div>
    <log-list
      [data]="logList"
      (disabled)="setDisabledExport($event)"
      (updateLoading)="updateLoading($event)"
      (dataLogExport)="dataLogExport($event)"
    >
    </log-list>
  </nb-card-body>
</nb-card>
