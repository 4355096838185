import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { NbDialogService } from "@nebular/theme";
import { ConfirmDialogComponent } from "../../../../shared/confirm-dialog/confirm-dialog.component";

@Component({
  selector: "footer-action",
  templateUrl: "action-footer.component.html",
})
export class ActionFooterComponent implements OnInit {
  @Output() handleCancelAction = new EventEmitter<any>();
  @Output() handleSaveAction = new EventEmitter<any>();
  constructor(private dialogService: NbDialogService) {}

  ngOnInit() {}
  handleCancelActions() {
    this.dialogService
      .open(ConfirmDialogComponent, {
        autoFocus: false,
        context: {
          question: "Are you sure you want to cancel?",
          textYes: "Yes",
          textNo: "Cancel",
          statusYes: "primary",
          statusNo: "basic",
        },
      })
      .onClose.subscribe((isConfirm) => {
        if (isConfirm) {
          this.handleCancelAction.emit(true);
        }
      });
  }
  handleSaveActions() {
    this.handleSaveAction.emit(true);
  }
}
