import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import {
  NbDialogRef,
  NbDialogService,
  NbGlobalPhysicalPosition,
  NbPopoverDirective,
  NbToastrService,
} from "@nebular/theme";
import { DatatableComponent } from "@swimlane/ngx-datatable";
import { EventBusService } from "../../../../@core/utils/eventbus.service";
import { ConfirmDialogComponent } from "../../../../shared/confirm-dialog/confirm-dialog.component";
import { EventData } from "../../../../shared/model/eventdata";
import {
  CallFlowClient,
  CallFlowDto,
  DraftClient,
  UpdateReversionCommand,
} from "../../../../System-api";
import { DataService } from "../../../../@core/utils/data.service";
import { Fancybox } from "@fancyapps/ui";

@Component({
  selector: "app-revision-history",
  templateUrl: "./revision-history.component.html",
  styleUrls: ["./revision-history.component.scss"],
})
export class RevisionHistoryComponent implements OnInit, OnDestroy {
  @ViewChild(NbPopoverDirective) popover: NbPopoverDirective;
  @ViewChild(DatatableComponent) table: DatatableComponent;
  callflow: CallFlowDto;
  dataParams: any = {
    page_num: "",
    page_size: "",
  };
  currentRow: any;
  selected = [];
  dataDrafts: any[] = [];
  isLoading = false;
  isLoadingButton = false;
  isReadOnly: any = false;
  constructor(
    private draftClient: DraftClient,
    private toastrService: NbToastrService,
    private callFlowClient: CallFlowClient,
    private eventBusService: EventBusService,
    private sanitizer: DomSanitizer,
    private dialogService: NbDialogService,
    private dataService: DataService,
    protected ref: NbDialogRef<RevisionHistoryComponent>,
    private elRef: ElementRef
  ) {
    this.eventBusService.on("AutoSave", (data) => {
      if (data) {
        this.setDraftsData();
      }
    });
  }
  moreInfo() {
    window.open("https://docs.primas.net/display/CAL/Revision+History");
  }
  dismiss() {
    this.ref.close(false);
  }
  handleHeight() {
    const textArea = document.getElementById("text-input");
    if (textArea) {
      textArea.style.height = `${textArea.scrollHeight}px`;
    }
  }
  ngOnInit() {
    this.setDraftsData();
    this.dataParams.page_num = 1;
    this.dataParams.page_size = Math.floor(
      (window.innerHeight * 0.55) / Number(32) - 1
    );
    Fancybox.bind(this.elRef.nativeElement, "[data-fancybox='gallery']", {
      Thumbs: {
        type: "modern",
      },
    });
  }
  setBackground() {
    setTimeout(() => {
      const image = document.querySelector(".fancybox__content") as HTMLElement;
      image.style.backgroundColor = "#edf1f7";
    }, 100);
  }
  ngOnDestroy() {
    Fancybox.unbind(this.elRef.nativeElement);
    Fancybox.close();
  }
  setDraftsData() {
    if (this.callflow.id) {
      this.isLoading = true;
      this.draftClient.getByCallflow(this.callflow.id).subscribe(
        (item) => {
          this.dataDrafts = [...item.drafts];
          this.currentRow = this.dataDrafts[0];
          this.selected = [this.dataDrafts[0]];
          this.isLoading = false;
        },
        (error) => {
          this.isLoading = false;
        }
      );
    }
  }

  updateReversion() {
    if (this.currentRow) {
      let data = new UpdateReversionCommand();
      data.id = this.currentRow.id;
      data.tag = this.currentRow.tag?.trim();
      data.note = this.currentRow.note?.trim();
      this.isLoadingButton = true;
      this.draftClient.updateReversion(data).subscribe(
        (rs) => {
          if (rs) {
            this.popover?.hide();
            this.toastrService.show(
              "Update Revision successfully",
              `Notification`,
              {
                position: NbGlobalPhysicalPosition.BOTTOM_LEFT,
                status: "success",
              }
            );
            this.isLoadingButton = false;
            this.currentRow = "";
            this.setDraftsData();
          } else {
            this.toastrService.show(
              "Update Revision unsuccessfully",
              `Notification`,
              {
                position: NbGlobalPhysicalPosition.BOTTOM_LEFT,
                status: "danger",
              }
            );
            this.isLoadingButton = false;
          }
        },
        (error) => {
          this.toastrService.show(
            "Somethings went wrong, try to revert again",
            `Notification`,
            {
              position: NbGlobalPhysicalPosition.BOTTOM_LEFT,
              status: "danger",
            }
          );
          this.isLoadingButton = false;
        }
      );
    }
  }
  showPopup() {
    this.popover?.show();
    setTimeout(() => {
      this.handleHeight();
    });
  }
  hiddenPopup() {
    this.popover?.hide();
  }
  photoURL() {
    return this.sanitizer.bypassSecurityTrustResourceUrl(
      this.currentRow?.image
    );
  }

  onActivate(event) {
    if (event.type == "click") {
      this.currentRow = { ...event.row };
    }
  }

  revert() {
    if (this.isReadOnly != true) {
      this.dialogService
        .open(ConfirmDialogComponent, {
          autoFocus: false,
          context: {
            question: "Do you want to revert this version. Sure?",
            textYes: "Yes",
            textNo: "Cancel",
            statusYes: "info",
            statusNo: "basic",
          },
        })
        .onClose.subscribe((isConfirm) => {
          if (isConfirm) {
            if (this.currentRow.id !== undefined) {
              this.isLoading = true;
              this.draftClient.get(this.currentRow.id).subscribe(
                (rs) => {
                  this.isLoading = false;
                  this.eventBusService.emit(
                    new EventData("clickRevertRevision", rs)
                  );
                  this.dismiss();
                },
                (error) => {
                  this.isLoading = false;
                  this.toastrService.show(
                    "Somethings went wrong, try to revert again",
                    `Notification`,
                    {
                      position: NbGlobalPhysicalPosition.BOTTOM_LEFT,
                      status: "danger",
                    }
                  );
                }
              );
            } else {
              this.toastrService.show(
                "Somethings went wrong, try to revert again",
                `Notification`,
                {
                  position: NbGlobalPhysicalPosition.BOTTOM_LEFT,
                  status: "danger",
                }
              );
            }
          }
        });
    }
  }
  showToast(result, successText, failText) {
    if (result) {
      this.toastrService.show(successText, `Notification`, {
        position: NbGlobalPhysicalPosition.BOTTOM_LEFT,
        status: "success",
      });
    } else {
      this.toastrService.show(failText, `Notification`, {
        position: NbGlobalPhysicalPosition.BOTTOM_LEFT,
        status: "danger",
      });
    }
  }
}
