<div class="action-config-wrapper" style="min-width: 570px; max-width: 570px">
  <div class="config-action">
    <nb-stepper [linear]="false" orientation="horizontal" selectedIndex="0">
      <nb-step [label]="labelOne">
        <ng-template #labelOne>Say Somethings</ng-template>
        <nb-radio-group
          [(ngModel)]="data.option"
          class="choice-option"
          (ngModelChange)="handleAudio($event)"
        >
          <nb-radio *ngFor="let option of options" [value]="option.id">{{
            option.name
          }}</nb-radio>
        </nb-radio-group>
        <nb-tabset class="form-group">
          <nb-tab tabTitle="Main">
            <div style="padding: 1rem 0 0 0">
              <div class="mb-2">
                <label
                  class="form-label label d-flex"
                  style="align-items: center"
                >
                  <span>Choose Audio Main Prompt</span>
                  <nb-icon
                    class="ms-1"
                    style="color: #929bb3; width: 15px"
                    nbTooltip="Play or send this message when starting this action."
                    nbTooltipPlacement="bottom"
                    nbTooltipStatus="basic"
                    nbTooltipTrigger="hover"
                    icon="question-mark-circle-outline"
                  >
                  </nb-icon
                ></label>
                <text-to-speech #textToSpeech></text-to-speech>
                <nb-card
                  class="select-audio"
                  [ngClass]="{ show: data.option == 1 }"
                >
                  <nb-card-header class="audio-list-style">
                    <span>Audio List</span>
                    <nb-icon
                      class="ms-2"
                      style="color: #929bb3; width: 15px"
                      nbTooltip="Select the audio you want to play. The audio list will play in order and continuously. If no audio is selected, the system will use text to speech."
                      nbTooltipPlacement="bottom"
                      nbTooltipStatus="basic"
                      nbTooltipTrigger="hover"
                      icon="question-mark-circle-outline"
                    >
                    </nb-icon
                  ></nb-card-header>
                  <nb-card-body>
                    <simple-audio-player
                      #audioPlayer
                      [events]="eventsSubject.asObservable()"
                    ></simple-audio-player>
                    <button
                      nbButton
                      size="small"
                      status="primary"
                      (click)="showModify()"
                    >
                      <nb-icon icon="plus-circle-outline"></nb-icon> Add
                    </button>
                  </nb-card-body>
                </nb-card>
                <div
                  style="
                    display: flex;
                    flex-direction: column;
                    margin-top: 0.5rem;
                  "
                >
                  <label for="name" class="form-label label"
                    >Choose Main Videobot</label
                  >
                  <sentiment-select
                    placeHolder="Choose Main VideoBot"
                    (valueSelected)="handleSentiment($event, 'main')"
                    [value]="data.sentimentMain"
                    defaultValue="normal-speak"
                  ></sentiment-select>
                </div>
              </div></div
          ></nb-tab>
          <nb-tab tabTitle="No Input">
            <div style="padding: 1rem 0 0 0">
              <div class="mb-2">
                <label
                  class="form-label label d-flex"
                  style="align-items: center"
                >
                  <span>Choose Audio When No Input</span>
                  <nb-icon
                    class="ms-1"
                    style="color: #929bb3; width: 15px"
                    nbTooltip="Play or send this message when the user doesn't say or send message within the timeout."
                    nbTooltipPlacement="bottom"
                    nbTooltipStatus="basic"
                    nbTooltipTrigger="hover"
                    icon="question-mark-circle-outline"
                  >
                  </nb-icon
                ></label>
                <text-to-speech #textToSpeechNoInput></text-to-speech>
                <nb-card
                  class="select-audio"
                  [ngClass]="{ show: data.option == 1 }"
                >
                  <nb-card-header class="audio-list-style">
                    <span>Audio List</span>
                    <nb-icon
                      class="ms-2"
                      style="color: #929bb3; width: 15px"
                      nbTooltip="Select the audio you want to play. The audio list will play in order and continuously. If no audio is selected, the system will use text to speech."
                      nbTooltipPlacement="bottom"
                      nbTooltipStatus="basic"
                      nbTooltipTrigger="hover"
                      icon="question-mark-circle-outline"
                    >
                    </nb-icon
                  ></nb-card-header>
                  <nb-card-body>
                    <simple-audio-player
                      #audioNoInputPlayer
                      [events]="eventsSubjectNoInput.asObservable()"
                    ></simple-audio-player>
                    <button
                      nbButton
                      size="small"
                      status="primary"
                      (click)="showModifyNoInput()"
                    >
                      <nb-icon icon="plus-circle-outline"></nb-icon> Add
                    </button>
                  </nb-card-body>
                </nb-card>
                <div
                  style="
                    display: flex;
                    flex-direction: column;
                    margin-top: 0.5rem;
                  "
                >
                  <label for="name" class="form-label label"
                    >Choose No Input Videobot</label
                  >
                  <sentiment-select
                    placeHolder="Choose No Input Videobot"
                    (valueSelected)="handleSentiment($event, 'noInput')"
                    [value]="data.sentimentNoInput"
                    defaultValue="no-input"
                  ></sentiment-select>
                </div>
              </div>
            </div>
          </nb-tab>
          <nb-tab tabTitle="Not Match">
            <div style="padding: 1rem 0 0 0">
              <div class="mb-2">
                <label
                  class="form-label label d-flex"
                  style="align-items: center"
                >
                  <span>Choose Audio When Input Not Match</span>
                  <nb-icon
                    class="ms-1"
                    style="color: #929bb3; width: 15px"
                    nbTooltip="Play or send this message when the user's response is not as expected or GPT doesn't understand the user's question."
                    nbTooltipPlacement="bottom"
                    nbTooltipStatus="basic"
                    nbTooltipTrigger="hover"
                    icon="question-mark-circle-outline"
                  >
                  </nb-icon
                ></label>
                <text-to-speech #textToSpeechNotMatch></text-to-speech>
                <nb-card
                  class="select-audio"
                  [ngClass]="{ show: data.option == 1 }"
                >
                  <nb-card-header class="audio-list-style">
                    <span>Audio List</span>
                    <nb-icon
                      class="ms-2"
                      style="color: #929bb3; width: 15px"
                      nbTooltip="Select the audio you want to play. The audio list will play in order and continuously. If no audio is selected, the system will use text to speech."
                      nbTooltipPlacement="bottom"
                      nbTooltipStatus="basic"
                      nbTooltipTrigger="hover"
                      icon="question-mark-circle-outline"
                    >
                    </nb-icon
                  ></nb-card-header>
                  <nb-card-body>
                    <simple-audio-player
                      #audioNotMatchPlayer
                      [events]="eventsSubjectNotMatch.asObservable()"
                    ></simple-audio-player>
                    <button
                      nbButton
                      size="small"
                      status="primary"
                      (click)="showModifyNotMatch()"
                    >
                      <nb-icon icon="plus-circle-outline"></nb-icon> Add
                    </button>
                  </nb-card-body>
                </nb-card>
                <div
                  style="
                    display: flex;
                    flex-direction: column;
                    margin-top: 0.5rem;
                  "
                >
                  <label for="name" class="form-label label"
                    >Choose Not Match Videobot</label
                  >
                  <sentiment-select
                    placeHolder="Choose Not Match Videobot"
                    (valueSelected)="handleSentiment($event, 'notMatch')"
                    [value]="data.sentimentNotMatch"
                    defaultValue="not-match"
                  ></sentiment-select>
                </div>
              </div>
            </div>
          </nb-tab>
        </nb-tabset>
      </nb-step>
      <nb-step [label]="labelTwo">
        <ng-template #labelTwo>Settings</ng-template>
        <div class="flex-title" style="margin-top: 1rem">
          <span class="title">Speech</span>
          <span class="line"></span>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="mb-2">
              <label
                for="timeout"
                class="form-label label d-flex"
                style="align-items: center"
                ><span>Minimum Length</span>
                <nb-icon
                  class="ms-1"
                  style="color: #929bb3; width: 15px"
                  nbTooltip="Minimum length of the user's response."
                  nbTooltipPlacement="bottom"
                  nbTooltipStatus="basic"
                  icon="question-mark-circle-outline"
                >
                </nb-icon
              ></label>
              <input
                id="timeout"
                [(ngModel)]="data.minLength"
                type="number"
                min="0"
                nbInput
                fullWidth
                placeholder="Minimum Length"
                fieldSize="small"
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="mb-2">
              <label
                for="repeat"
                class="form-label label d-flex"
                style="align-items: center"
                ><span>Maximum Length</span>
                <nb-icon
                  class="ms-1"
                  style="color: #929bb3; width: 15px"
                  nbTooltip="Maximum length of the user's response."
                  nbTooltipPlacement="bottom"
                  nbTooltipStatus="basic"
                  icon="question-mark-circle-outline"
                >
                </nb-icon
              ></label>
              <input
                id="repeat"
                [(ngModel)]="data.maxLength"
                type="number"
                min="0"
                nbInput
                fullWidth
                placeholder="Maximum Length"
                fieldSize="small"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="mb-2">
              <label
                for="timeout"
                class="form-label label d-flex"
                style="align-items: center"
              >
                <span>No Input Timeout (s)</span>
                <nb-icon
                  class="ms-1"
                  style="color: #929bb3; width: 15px"
                  nbTooltip="Maximum time to wait for the user's say."
                  nbTooltipPlacement="bottom"
                  nbTooltipStatus="basic"
                  nbTooltipTrigger="hover"
                  icon="question-mark-circle-outline"
                >
                </nb-icon>
              </label>
              <input
                id="timeout"
                [(ngModel)]="data.noInputTimeout"
                type="number"
                min="0"
                nbInput
                fullWidth
                placeholder="No Input Timeout"
                fieldSize="small"
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="mb-2">
              <label
                for="repeat"
                class="form-label label d-flex"
                style="align-items: center"
              >
                <span>Max Duration (s)</span>
                <nb-icon
                  class="ms-1"
                  style="color: #929bb3; width: 15px"
                  nbTooltip="Maximum duration of time the user allowed to say."
                  nbTooltipPlacement="bottom"
                  nbTooltipStatus="basic"
                  nbTooltipTrigger="hover"
                  icon="question-mark-circle-outline"
                >
                </nb-icon
              ></label>

              <input
                id="repeat"
                [(ngModel)]="data.timeout"
                type="number"
                min="0"
                nbInput
                fullWidth
                placeholder="Max Duration"
                fieldSize="small"
              />
            </div>
          </div>
        </div>
        <div class="flex-title" style="margin-top: 1rem">
          <span class="title">Repeat</span>
          <span class="line"></span>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="mb-2">
              <label
                for="repeat"
                class="form-label label d-flex"
                style="align-items: center"
              >
                <span>Max Tries</span>
                <nb-icon
                  class="ms-1"
                  style="color: #929bb3; width: 15px"
                  nbTooltip="Maximum number of times to allow the caller to answer if their answer then GPT doesn't understand or the minimum length invalid"
                  nbTooltipPlacement="bottom"
                  nbTooltipStatus="basic"
                  nbTooltipTrigger="hover"
                  icon="question-mark-circle-outline"
                >
                </nb-icon
              ></label>
              <input
                id="repeat"
                [(ngModel)]="data.repeat"
                type="number"
                min="0"
                nbInput
                fullWidth
                placeholder="Max Tries"
                fieldSize="small"
              />
            </div>
          </div>
        </div>
        <div class="flex-title" style="margin-top: 1rem">
          <span class="title">Log</span>
          <span class="line"></span>
        </div>
        <div class="mb-3 mt-3 d-flex" style="align-items: center">
          <nb-checkbox (checkedChange)="allowLog($event)" [checked]="data.log">
            Write Log For Conversation
          </nb-checkbox>
          <nb-icon
            class="ms-2"
            style="color: #929bb3; width: 15px; margin-bottom: 2px"
            nbTooltip="Logging of GPT response and user questions information."
            nbTooltipPlacement="bottom"
            nbTooltipStatus="basic"
            nbTooltipTrigger="hover"
            icon="question-mark-circle-outline"
          >
          </nb-icon>
        </div>
      </nb-step>
      <nb-step [label]="labelThree">
        <ng-template #labelThree>Dataset</ng-template>
        <div class="mt-3">
          <div class="row">
            <div class="col-6">
              <div class="mb-2">
                <div style="display: flex; align-items: center">
                  <nb-toggle [(checked)]="data.customTrain" status="basic"
                    >Enable Custom Train</nb-toggle
                  >
                  <nb-icon
                    class="ms-2"
                    style="color: #929bb3; width: 15px"
                    nbTooltip="Allow you to select the model you trained for GPT and GPT will respond with the data you trained."
                    nbTooltipPlacement="bottom"
                    nbTooltipStatus="basic"
                    nbTooltipTrigger="hover"
                    icon="question-mark-circle-outline"
                  >
                  </nb-icon>
                </div>
              </div>
            </div>
            <div class="col-6">
              <div class="mb-2">
                <div style="display: flex; align-items: center">
                  <nb-toggle [(checked)]="data.enableCheckpoint" status="basic"
                    >Detect Intent</nb-toggle
                  >
                  <nb-icon
                    class="ms-2"
                    style="color: #929bb3; width: 15px"
                    nbTooltip="Recognize the intent and sentiment of the user question. End the conversation if the intention is detected."
                    nbTooltipPlacement="bottom"
                    nbTooltipStatus="basic"
                    nbTooltipTrigger="hover"
                    icon="question-mark-circle-outline"
                  >
                  </nb-icon>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          class="flex-title"
          style="margin-top: 1rem"
          *ngIf="this.data.customTrain === true"
        >
          <span class="title">Dataset</span>
          <span class="line"></span>
        </div>

        <div class="mb-2" *ngIf="this.data.customTrain === true">
          <label
            for="yourBot"
            class="form-label label d-flex"
            style="align-items: center"
          >
            <span>About Your Bot</span>
            <nb-icon
              class="ms-1"
              style="color: #929bb3; width: 15px"
              nbTooltip="Customize GPT to assume any role."
              nbTooltipPlacement="bottom"
              nbTooltipStatus="basic"
              nbTooltipTrigger="hover"
              icon="question-mark-circle-outline"
            >
            </nb-icon
          ></label>
          <div class="d-flex custom-gap-2">
            <textarea
              id="yourBot"
              style="min-height: 50px; max-height: 300px"
              value=""
              nbInput
              type="text"
              fieldSize="small"
              fullWidth
              placeholder="About Your Bot"
              [(ngModel)]="data.prompt"
            >
            </textarea>
          </div>
        </div>

        <div class="mb-2" *ngIf="this.data.customTrain === true">
          <div class="form-label d-flex" style="align-items: center">
            <label class="label required"> QA Dataset </label>
            <nb-icon
              class="ms-1"
              style="color: #929bb3; width: 15px"
              nbTooltip="Select the trained data using GPT"
              nbTooltipPlacement="bottom"
              nbTooltipStatus="basic"
              nbTooltipTrigger="hover"
              icon="question-mark-circle-outline"
            >
            </nb-icon>
          </div>
          <form [formGroup]="formGroup">
            <div class="d-flex custom-gap-2">
              <div class="select-style">
                <ng-select
                  formControlName="QADataset"
                  [nbSpinner]="isLoading"
                  nbSpinnerSize="medium"
                  nbSpinnerStatus="info"
                  [items]="listDataGPT"
                  bindLabel="name"
                  bindValue="id"
                  [(ngModel)]="data.idDataset"
                  placeholder="Select QA Dataset"
                  [ngClass]="{
                    isInvalid:
                      formGroup.controls['QADataset'].invalid &&
                      (formGroup.controls['QADataset'].dirty ||
                        formGroup.controls['QADataset'].touched)
                  }"
                >
                  <ng-template
                    ng-option-tmp
                    let-item="item"
                    let-index="index"
                    let-search="searchTerm"
                  >
                    <div class="function-item">
                      <span>{{ item.name }}</span>
                      <div class="function-item-action">
                        <span style="display: none"> - </span>
                        <span
                          class="btn-action failed"
                          *ngIf="
                            item.status == 'FAILED' || item.status == 'False'
                          "
                          >Failed</span
                        >
                        <span
                          class="btn-action not-train"
                          *ngIf="item.status == 'NOT-TRAINED'"
                          >Not Trained</span
                        >
                        <span
                          class="btn-action success"
                          *ngIf="
                            item.status == 'TRAINED' || item.status == 'True'
                          "
                          >Trained</span
                        >
                        <span
                          class="btn-action training"
                          *ngIf="
                            item.status == 'TRAINING' ||
                            item.status == null ||
                            item.status == ''
                          "
                          >Training</span
                        >
                        <label
                          class="btn-action not-train"
                          *ngIf="item.status == 'WAITING'"
                          >Waiting</label
                        >
                        <button
                          (click)="updateData($event, item)"
                          ghost
                          class="tbl-action"
                          nbButton
                          size="small"
                        >
                          <nb-icon icon="edit-outline"></nb-icon>
                        </button>
                        <button
                          *ngIf="data.idDataset != item.id"
                          ghost
                          (click)="removeData($event, item)"
                          class="tbl-action"
                          nbButton
                          size="small"
                        >
                          <nb-icon icon="trash-2-outline"></nb-icon>
                        </button>
                      </div>
                    </div>
                  </ng-template>
                </ng-select>

                <button
                  nbButton
                  [disabled]="!data.idDataset"
                  status="primary"
                  size="small"
                  (click)="updateCurrentData($event, data.idDataset)"
                >
                  <nb-icon icon="edit-outline"></nb-icon>
                </button>
              </div>
              <button
                type="button"
                (click)="addChatGPTDataset()"
                size="small"
                status="primary"
                nbButton
              >
                <nb-icon icon="plus-outline"></nb-icon>
              </button>
            </div>
            <ng-container
              *ngIf="
                formGroup.controls['QADataset'].invalid &&
                (formGroup.controls['QADataset'].dirty ||
                  formGroup.controls['QADataset'].touched)
              "
            >
              <p
                class="caption status-danger"
                style="margin-bottom: 0"
                *ngIf="
                  formGroup.controls['QADataset'].hasError('required') ||
                  formGroup.controls['QADataset'].hasError('whitespace')
                "
              >
                QA Dataset is required.
              </p>
            </ng-container>
          </form>
        </div>
        <div class="mb-2" *ngIf="this.data.customTrain === true">
          <label
            class="form-label label d-flex mb-0"
            style="align-items: center"
            ><span>Temperature</span>
            <nb-icon
              class="ms-1"
              style="width: 15px"
              nbTooltip="What sampling temperature to use, between 0 and 2. Higher values like 0.8 will make the output more random, while lower values like 0.2 will make it more focused and deterministic."
              nbTooltipPlacement="bottom"
              nbTooltipStatus="basic"
              icon="question-mark-circle-outline"
            >
            </nb-icon
          ></label>
          <div class="d-flex">
            <div class="progress-wrap me-4">
              <input
                type="range"
                class="progress"
                [(ngModel)]="data.temperature"
                min="0"
                max="2"
                step="0.1"
                (input)="setTemperature(duration.value)"
                #duration
                onlyDoubleNumber
              />
              <div
                class="progress-foreground"
                [style.width.%]="getTemperature()"
              ></div>
            </div>
            <input
              type="text"
              nbInput
              maxlength="3"
              onlyDoubleNumber
              fieldSize="tiny"
              style="width: 4rem; text-align: center"
              [(ngModel)]="data.temperature"
              (input)="handleMaxValueTemperature($event)"
            />
            <!-- <label class="form-label label">{{ data.temperature }}</label> -->
          </div>
        </div>
        <div class="mb-2" *ngIf="this.data.customTrain === true">
          <label
            class="form-label label d-flex mb-0"
            style="align-items: center"
            ><span>Maximum Length</span>
            <nb-icon
              class="ms-1"
              style="width: 15px"
              nbTooltip="The maximum number of tokens to generate in the completion."
              nbTooltipPlacement="bottom"
              nbTooltipStatus="basic"
              icon="question-mark-circle-outline"
            >
            </nb-icon
          ></label>
          <div class="d-flex">
            <div class="progress-wrap me-4">
              <input
                type="range"
                class="progress"
                [(ngModel)]="data.numberOutput"
                min="0"
                max="4000"
                step="1"
                (input)="setNumberOutput(numberOutput.value)"
                #numberOutput
                onlyIntNumber
              />
              <div
                class="progress-foreground"
                [style.width.%]="getNumberOutput()"
              ></div>
            </div>
            <input
              type="text"
              nbInput
              maxlength="4"
              onlyIntNumber
              fieldSize="tiny"
              style="width: 4rem; text-align: center"
              [(ngModel)]="data.numberOutput"
              (input)="handleMaxValueNumberOutput($event)"
            />
          </div>
        </div>
        <div class="mb-2" *ngIf="this.data.customTrain === true">
          <label class="form-label label d-flex" style="align-items: center">
            <span>Response Mode</span>
            <nb-icon
              class="ms-1"
              style="color: #929bb3; width: 15px"
              nbTooltip="The answer is not just responding based on your trained data or only responding based on your trained data."
              nbTooltipPlacement="bottom"
              nbTooltipStatus="basic"
              nbTooltipTrigger="hover"
              icon="question-mark-circle-outline"
            >
            </nb-icon
          ></label>
          <div
            class="d-flex justify-content-between align-items-center"
            style="gap: 1.5rem"
          >
            <nb-select
              style="flex: 1"
              class=""
              placeholder="Select Response Mode"
              size="small"
              fullWidth
              [(ngModel)]="data.responseMode"
            >
              <nb-option value="DOC&GPT">DOC and GPT</nb-option>
              <nb-option value="ONLY_DOC">Only DOC</nb-option>
            </nb-select>
            <div style="display: flex; align-items: center">
              <nb-checkbox
                (checkedChange)="allowKeepContext($event)"
                [checked]="data.keepContext"
              >
                Keep Context
              </nb-checkbox>
              <nb-icon
                class="ms-2"
                style="color: #929bb3; width: 15px"
                nbTooltip="The context will not be reset with the user's next question."
                nbTooltipPlacement="bottom"
                nbTooltipStatus="basic"
                nbTooltipTrigger="hover"
                icon="question-mark-circle-outline"
              >
              </nb-icon>
            </div>
          </div>
        </div>
        <!-- <div
          class="line mt-3"
          *ngIf="this.data.customTrain && data.enableCheckpoint"
        ></div> -->
        <div *ngIf="data.enableCheckpoint === true">
          <div class="flex-title">
            <span class="title">Grammar</span>
            <span class="line"></span>
          </div>
          <div class="mb-2 mt-2">
            <div class="mb-2">
              <nb-card style="height: auto" *ngIf="isLoadingGrammar">
                <nb-list class="example-list">
                  <nb-list-item class="example-box">
                    <div class="grammar-name-wrapper">
                      <span class="grammar-name">Loading...</span>
                    </div>
                    <div class="action-wrapper">
                      <button
                        ghost
                        class="tbl-action"
                        nbButton
                        size="small"
                        style="opacity: 0"
                      >
                        <nb-icon icon="edit-outline"></nb-icon>
                      </button>
                    </div>
                  </nb-list-item> </nb-list
              ></nb-card>

              <nb-card
                style="height: auto"
                status="basic"
                *ngIf="grammarArray.length > 0 && !isLoadingGrammar"
              >
                <nb-list
                  cdkDropList
                  class="example-list"
                  (cdkDropListDropped)="drop($event)"
                >
                  <nb-list-item
                    *ngFor="let grammar of grammarArray; let i = index"
                    class="example-box"
                    cdkDrag
                  >
                    <div class="grammar-name-wrapper" style="max-width: 30rem">
                      <span class="grammar-name">{{ grammar.name }}</span>
                    </div>
                    <div class="action-wrapper">
                      <button
                        *ngIf="grammar.type != 'BuiltIn'"
                        ghost
                        class="tbl-action"
                        nbButton
                        size="small"
                        (click)="updateCurrentDataGrammar($event, grammar.id)"
                      >
                        <nb-icon icon="edit-outline"></nb-icon>
                      </button>
                      <button
                        ghost
                        class="tbl-action"
                        nbButton
                        size="small"
                        (click)="deleteGrammarInList(i)"
                      >
                        <nb-icon icon="trash-2-outline"></nb-icon>
                      </button>

                      <div
                        class="move-icon"
                        style="display: flex; align-items: center"
                      >
                        <nb-icon
                          style="margin-right: -14px"
                          status="basic"
                          icon="more-vertical-outline"
                        ></nb-icon>
                        <nb-icon
                          status="basic"
                          icon="more-vertical-outline"
                        ></nb-icon>
                      </div>
                    </div>
                  </nb-list-item>
                </nb-list>
              </nb-card>
            </div>
            <div class="add-grammar mb-2">
              <button
                [nbPopover]="templateRef"
                shape="round"
                type="button"
                size="small"
                nbPopoverTrigger="noop"
                nbPopoverPlacement="left"
                (click)="showLog()"
                status="primary"
                nbButton
              >
                <nb-icon [icon]="icon"></nb-icon>
              </button>
              <div style="display: flex; align-items: center">
                <span style="font-size: 13.5px">Add a grammar</span>
                <nb-icon
                  class="ms-1"
                  style="color: #929bb3; width: 15px"
                  nbTooltip="Choose the grammar to validate the user response."
                  nbTooltipPlacement="bottom"
                  nbTooltipStatus="basic"
                  nbTooltipTrigger="hover"
                  icon="question-mark-circle-outline"
                >
                </nb-icon>
              </div>
            </div>
          </div>
          <div class="flex-title" style="margin-bottom: 1.25rem">
            <span class="title">Assign Variable</span>
            <span class="line"></span>
          </div>
          <div class="mb-2">
            <label class="form-label label d-flex" style="align-items: center">
              <span>Assign User Response</span>
              <nb-icon
                class="ms-1"
                style="color: #929bb3; width: 15px"
                nbTooltip="Assign the user response to the variable."
                nbTooltipPlacement="bottom"
                nbTooltipStatus="basic"
                nbTooltipTrigger="hover"
                icon="question-mark-circle-outline"
              >
              </nb-icon
            ></label>
            <ngx-input-value-static
              placeHolder="Select User Response"
              selectAttribute="true"
              [propertyInput]="data.userResponse"
              (propertyEmit)="getUserResponse($event)"
            >
            </ngx-input-value-static>
          </div>
          <div class="mb-2">
            <label class="form-label label d-flex" style="align-items: center">
              <span>Assign Intent</span>
              <nb-icon
                class="ms-1"
                style="color: #929bb3; width: 15px"
                nbTooltip="Assign the intent of the user to the variable."
                nbTooltipPlacement="bottom"
                nbTooltipStatus="basic"
                nbTooltipTrigger="hover"
                icon="question-mark-circle-outline"
              >
              </nb-icon
            ></label>
            <ngx-input-value-static
              placeHolder="Select Intent"
              selectAttribute="true"
              [propertyInput]="data.attribute"
              (propertyEmit)="getAttribute($event)"
            >
            </ngx-input-value-static>
          </div>
          <div class="mb-2">
            <label class="form-label label d-flex" style="align-items: center"
              ><span>Assign Sentiment</span>
              <nb-icon
                class="ms-1"
                style="width: 15px"
                nbTooltip="This is the emotion in the sentence. Its value is one of 'positive', 'neutral', 'negative'."
                nbTooltipPlacement="bottom"
                nbTooltipStatus="basic"
                icon="question-mark-circle-outline"
              >
              </nb-icon>
            </label>

            <ngx-input-value-static
              placeHolder="Select Sentiment"
              selectAttribute="true"
              [propertyInput]="data.sentiment"
              (propertyEmit)="getSentiment($event)"
            >
            </ngx-input-value-static>
          </div>
        </div>
      </nb-step>
    </nb-stepper>
  </div>
  <footer-action
    (handleCancelAction)="handleCancelAction()"
    (handleSaveAction)="handleSaveAction()"
  ></footer-action>
</div>
<ng-template #templateRef>
  <nb-card class="example-box-2" cdkDrag cdkDragRootElement=".cdk-overlay-pane"
    ><nb-card-header
      style="
        padding: 15px;
        display: flex;
        align-items: center;
        gap: 0.5rem;
        justify-content: space-between;
        padding-left: 1.5rem;
      "
    >
      <nb-form-field style="width: 100%">
        <input
          type="text"
          nbInput
          fullWidth
          placeholder="Search Grammar"
          fieldSize="small"
          [(ngModel)]="searchText"
          (keyup)="filterData($event)"
        />
        <button
          nbSuffix
          nbButton
          ghost
          size="tiny"
          (click)="clear()"
          *ngIf="searchText != ''"
        >
          <nb-icon icon="close-circle"></nb-icon>
        </button>
      </nb-form-field>
      <button
        nbButton
        size="small"
        status="primary"
        type="button"
        (click)="addGrammar()"
      >
        <nb-icon icon="plus-outline"></nb-icon>
      </button>
      <button nbButton size="small" ghost type="button" (click)="closeModal()">
        <nb-icon icon="close"></nb-icon>
      </button>
    </nb-card-header>
    <nb-card-body class="grammar-add">
      <div class="mb-2">
        <nb-list class="custom-list" style="max-height: 25rem">
          <nb-list-item
            class="custom-list-item"
            *ngIf="grammarsData.length <= 0 && isLoading == false"
          >
            <div class="grammar-name-wrapper">
              <span class="grammar-name">Empty</span>
            </div>
            <div class="action-wrapper">
              <button
                style="opacity: 0"
                ghost
                class="tbl-action"
                nbButton
                size="small"
              >
                <nb-icon icon="edit-outline"></nb-icon>
              </button>
            </div>
          </nb-list-item>
          <nb-list-item
            class="custom-list-item"
            *ngFor="let item of grammarsData; let x = index"
            (click)="addToObject(item)"
          >
            <div
              class="grammar-name-wrapper"
              style="overflow: hidden; word-break: break-all"
            >
              <span class="grammar-name">{{ item.name }}</span>
            </div>
            <div class="action-wrapper">
              <div style="margin-right: 5px">
                <span
                  class="btn-action failed"
                  *ngIf="item.status == 'FAILED' || item.status == 'False'"
                  >Failed</span
                >
                <span
                  class="btn-action not-train"
                  *ngIf="item.status == 'NOT-TRAINED' || item.status == ''"
                  >Not Trained</span
                >
                <span
                  class="btn-action success"
                  *ngIf="item.status == 'TRAINED' || item.status == 'True'"
                  >Trained</span
                >
                <span
                  class="btn-action training"
                  *ngIf="item.status == 'TRAINING' || item.status == null"
                  >Training</span
                >
                <label
                  class="btn-action not-train"
                  *ngIf="item.status == 'WAITING'"
                  >Waiting</label
                >
              </div>
              <button
                (click)="updateCustomGrammar($event, item)"
                ghost
                class="tbl-action"
                nbButton
                size="small"
              >
                <nb-icon icon="edit-outline"></nb-icon>
              </button>
              <button
                ghost
                (click)="removeDigitGrammar($event, item)"
                class="tbl-action"
                nbButton
                size="small"
              >
                <nb-icon icon="trash-2-outline"></nb-icon>
              </button>
            </div>
          </nb-list-item>
        </nb-list>
      </div>
    </nb-card-body>
  </nb-card>
</ng-template>
