<form [formGroup]="updateAudioForm" novalidate (submit)="onSubmit()">
  <nb-card [ngClass]="{ 'modal-create': isModal }" style="margin-bottom: 0">
    <nb-card-header>
      <div class="d-flex justify-content-between">
        {{ title }}
        <button
          (click)="dismiss()"
          type="button"
          class="btn-close"
          aria-label="Close"
        ></button>
      </div>
    </nb-card-header>
    <nb-card-body>
      <div class="row">
        <div class="col-12 mb-2">
          <nb-radio-group
            (valueChange)="createForm($event, createAudio)"
            class="choice-option"
            [(value)]="audio.isUpload"
          >
            <nb-radio [value]="false">Text To Speech</nb-radio>
            <nb-radio [value]="true">Upload Audio</nb-radio>
          </nb-radio-group>
        </div>
      </div>
      <hr />
      <div class="row mb-2">
        <div class="col-12">
          <div class="row">
            <div class="col-md-6" *ngIf="createAudio">
              <div>
                <label for="inputName" class="form-label label required"
                  >Name</label
                >
                <input
                  fullWidth
                  nbInput
                  id="inputName"
                  [status]="
                    updateAudioForm.controls['name'].invalid &&
                    (updateAudioForm.controls['name'].dirty ||
                      updateAudioForm.controls['name'].touched)
                      ? 'danger'
                      : 'basic'
                  "
                  placeholder="Name"
                  formControlName="name"
                  [(ngModel)]="audio.name"
                />
                <ng-container
                  *ngIf="
                    updateAudioForm.controls['name'].invalid &&
                    (updateAudioForm.controls['name'].dirty ||
                      updateAudioForm.controls['name'].touched)
                  "
                >
                  <p
                    class="caption status-danger"
                    *ngIf="
                      updateAudioForm.controls['name'].hasError('required')
                    "
                  >
                    Name is required.
                  </p>
                  <p
                    class="caption status-danger"
                    *ngIf="updateAudioForm.controls['name'].hasError('regex')"
                  >
                    Name contains only letters and digits.
                  </p>
                </ng-container>
              </div>
            </div>
            <div class="col-md-6" *ngIf="!audio.isUpload">
              <div>
                <label for="input-service" class="form-label label required"
                  >Service</label
                >
                <input
                  id="input-service"
                  type="text"
                  nbInput
                  fullWidth
                  formControlName="service"
                  [status]="
                    updateAudioForm.controls['service'].invalid &&
                    (updateAudioForm.controls['service'].dirty ||
                      updateAudioForm.controls['service'].touched)
                      ? 'danger'
                      : 'basic'
                  "
                  [matAutocomplete]="autoService"
                  placeholder="Select Service"
                  (ngModelChange)="onChangeService($event)"
                />
                <ng-container
                  *ngIf="
                    updateAudioForm.controls['service'].invalid &&
                    (updateAudioForm.controls['service'].dirty ||
                      updateAudioForm.controls['service'].touched)
                  "
                >
                  <p
                    class="caption status-danger"
                    *ngIf="
                      updateAudioForm.controls['service'].hasError(
                        'required'
                      ) ||
                      updateAudioForm.controls['service'].hasError('whitespace')
                    "
                  >
                    Service is required.
                  </p>
                  <p
                    class="caption status-danger"
                    *ngIf="
                      updateAudioForm.controls['service'].hasError('invalid')
                    "
                  >
                    Service is invalid.
                  </p>
                </ng-container>
                <mat-autocomplete
                  #autoService="matAutocomplete"
                  [displayWith]="displayValue"
                >
                  <mat-option
                    style="background-color: white; border: 1px solid #edf1f7"
                    [ngClass]="{
                      'bg-active':
                        service.name == updateAudioForm.value?.service?.name
                    }"
                    *ngFor="let service of filteredService | async"
                    [value]="service"
                  >
                    {{ service.name }}
                  </mat-option>
                </mat-autocomplete>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mb-2">
        <div class="col-12">
          <div class="row">
            <div class="col-md-6">
              <div>
                <div>
                  <label class="form-label label required" for="input-language"
                    >Language
                  </label>
                  <input
                    id="input-language"
                    type="text"
                    fullWidth
                    nbInput
                    [status]="
                      updateAudioForm.controls['language'].invalid &&
                      (updateAudioForm.controls['language'].dirty ||
                        updateAudioForm.controls['language'].touched)
                        ? 'danger'
                        : 'basic'
                    "
                    formControlName="language"
                    [matAutocomplete]="autoLanguage"
                    placeholder="Select Language"
                    (ngModelChange)="onChangeLanguage($event)"
                  />
                  <ng-container
                    *ngIf="
                      updateAudioForm.controls['language'].invalid &&
                      (updateAudioForm.controls['language'].dirty ||
                        updateAudioForm.controls['language'].touched)
                    "
                  >
                    <p
                      class="caption status-danger"
                      *ngIf="
                        updateAudioForm.controls['language'].hasError(
                          'required'
                        ) ||
                        updateAudioForm.controls['language'].hasError(
                          'whitespace'
                        )
                      "
                    >
                      Language is required.
                    </p>
                    <p
                      class="caption status-danger"
                      *ngIf="
                        updateAudioForm.controls['language'].hasError('invalid')
                      "
                    >
                      Language is invalid.
                    </p>
                  </ng-container>
                  <mat-autocomplete
                    #autoLanguage="matAutocomplete"
                    [displayWith]="displayValue"
                  >
                    <mat-option
                      style="background-color: white; border: 1px solid #edf1f7"
                      *ngFor="let language of filteredLanguage | async"
                      [ngClass]="{
                        'bg-active':
                          language.name == updateAudioForm.value?.language?.name
                      }"
                      [value]="language"
                    >
                      {{ language.name }}
                    </mat-option>
                  </mat-autocomplete>
                </div>
              </div>
            </div>
            <div class="col-md-6" *ngIf="!audio.isUpload">
              <div class="mb-2">
                <div>
                  <label class="form-label label required" for="input-voice"
                    >Voice</label
                  >
                  <input
                    id="input-voice"
                    type="text"
                    nbInput
                    fullWidth
                    [status]="
                      updateAudioForm.controls['voiceId'].invalid &&
                      (updateAudioForm.controls['voiceId'].dirty ||
                        updateAudioForm.controls['voiceId'].touched)
                        ? 'danger'
                        : 'basic'
                    "
                    formControlName="voiceId"
                    [matAutocomplete]="autoVoiceId"
                    placeholder="Select Voice"
                    (ngModelChange)="onChangeVoice($event)"
                  />
                  <ng-container
                    *ngIf="
                      updateAudioForm.controls['voiceId'].invalid &&
                      (updateAudioForm.controls['voiceId'].dirty ||
                        updateAudioForm.controls['voiceId'].touched)
                    "
                  >
                    <p
                      class="caption status-danger"
                      *ngIf="
                        updateAudioForm.controls['voiceId'].hasError(
                          'required'
                        ) ||
                        updateAudioForm.controls['voiceId'].hasError(
                          'whitespace'
                        )
                      "
                    >
                      Voice is required.
                    </p>
                    <p
                      class="caption status-danger"
                      *ngIf="
                        updateAudioForm.controls['voiceId'].hasError('invalid')
                      "
                    >
                      Voice is invalid.
                    </p>
                  </ng-container>
                  <mat-autocomplete
                    #autoVoiceId="matAutocomplete"
                    [displayWith]="displayValue"
                  >
                    <mat-option
                      style="background-color: white; border: 1px solid #edf1f7"
                      *ngFor="let voice of filteredVoice | async"
                      [value]="voice"
                      [ngClass]="{
                        'bg-active':
                          voice.name == updateAudioForm.value?.voiceId?.name
                      }"
                    >
                      {{ voice.name }}
                    </mat-option>
                  </mat-autocomplete>
                </div>
              </div>
            </div>
          </div>
          <div class="row" *ngIf="!audio.isUpload">
            <div class="col-md-10">
              <div>
                <div class="mb-2">
                  <label for="inputText" class="form-label label required"
                    >Text</label
                  >
                  <div>
                    <textarea
                      style="max-height: 10em; min-height: 5em"
                      id="inputText"
                      formControlName="content"
                      placeholder="Text"
                      [(ngModel)]="audio.content"
                      nbInput
                      fullWidth
                      [status]="
                        updateAudioForm.controls['content'].invalid &&
                        (updateAudioForm.controls['content'].dirty ||
                          updateAudioForm.controls['content'].touched)
                          ? 'danger'
                          : 'basic'
                      "
                    ></textarea>
                  </div>
                  <ng-container
                    *ngIf="
                      updateAudioForm.controls['content'].invalid &&
                      (updateAudioForm.controls['content'].dirty ||
                        updateAudioForm.controls['content'].touched)
                    "
                  >
                    <p
                      class="caption status-danger"
                      *ngIf="
                        updateAudioForm.controls['content'].errors.required ||
                        updateAudioForm.controls['content'].hasError(
                          'whitespace'
                        )
                      "
                    >
                      Text is required
                    </p>
                  </ng-container>
                </div>
              </div>
            </div>
            <div
              class="col-lg-2 col-md-12 d-flex align-items-start"
              style="margin-top: 1.77rem"
            >
              <button
                [disabled]="updateAudioForm.controls['content'].invalid"
                (click)="convertTTS()"
                type="button"
                size="small"
                status="primary"
                nbButton
              >
                <nb-icon
                  [icon]="
                    isPlaying ? 'stop-circle-outline' : 'play-circle-outline'
                  "
                ></nb-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="audio.isUpload">
        <div class="col-md-6">
          <label for="file" class="form-label label required">File</label>
          <div
            [ngStyle]="{
              'border-color':
                updateAudioForm.controls['file'].invalid &&
                (updateAudioForm.controls['file'].dirty ||
                  updateAudioForm.controls['file'].touched)
                  ? 'red'
                  : '#edf1f7'
            }"
            class="mb-2 d-flex align-items-center p-2 bg-file"
            (click)="audioInput.click()"
          >
            <button type="button" status="primary" nbButton size="small">
              Choose file
              <input
                id="file"
                formControlName="file"
                #audioInput
                (click)="setTouchedFile()"
                (change)="processFile(audioInput.files)"
                accept=".mp3, .wav,"
                nbInput
                fullWidth
                type="file"
                class="mb-2-file"
                [status]="
                  updateAudioForm.controls['file'].invalid &&
                  (updateAudioForm.controls['file'].dirty ||
                    updateAudioForm.controls['file'].touched)
                    ? 'danger'
                    : 'basic'
                "
                style="display: none"
                [class.invalid-input]="
                  updateAudioForm.get('file').errors?.wrongformat
                "
              />
            </button>
            <span class="ms-2 text-no-wrap-2">{{
              audio.fileName ? audio.fileName : "No files selected"
            }}</span>
          </div>
          <ng-container
            *ngIf="
              updateAudioForm.controls['file'].invalid &&
              (updateAudioForm.controls['file'].dirty ||
                updateAudioForm.controls['file'].touched)
            "
          >
            <p
              class="caption status-danger"
              *ngIf="
                updateAudioForm.controls['file'].hasError('required') ||
                updateAudioForm.controls['file'].hasError('whitespace')
              "
            >
              File is required.
            </p>
          </ng-container>
          <ng-container *ngIf="updateAudioForm.get('file').errors?.wrongformat">
            <p class="caption status-danger">
              Only MP3, WAV formats are allowed
            </p>
          </ng-container>
          <ng-container *ngIf="updateAudioForm.get('file').errors?.maxSize">
            <p class="caption status-danger">
              Maximum allowed file size is 20MB
            </p>
          </ng-container>
        </div>
        <div class="col-md-6" style="margin-top: 1.85rem">
          <button
            [disabled]="!dataAudioUpload && !pathAudioUpload"
            (click)="playAudioUpload()"
            type="button"
            size="small"
            status="primary"
            nbButton
          >
            <nb-icon
              [icon]="isPlaying ? 'stop-circle-outline' : 'play-circle-outline'"
            ></nb-icon>
          </button>
        </div>
      </div>
      <div class="mb-2">
        <audio
          (playing)="audioPlaying()"
          (ended)="audioEnded()"
          #testAudio
          [src]="dataAudio ? 'data:audio/mp3;base64,' + dataAudio : ''"
          autoplay
          class="col-12"
        >
          <source />
        </audio>
      </div>
      <div class="mb-2">
        <audio
          (playing)="audioPlaying()"
          (ended)="audioEnded()"
          #testAudioUpload
          [src]="
            dataAudioUpload
              ? 'data:audio/mp3;base64,' + dataAudioUpload
              : pathAudioUpload
          "
          class="col-12"
        >
          <source />
        </audio>
      </div>
    </nb-card-body>
    <nb-card-footer>
      <button
        [nbSpinner]="isLoading"
        nbSpinnerSize="small"
        nbSpinnerStatus="primary"
        [disabled]="!updateAudioForm.valid || isLoading"
        type="submit"
        status="primary"
        class="me-2 small"
        nbButton
        style="float: right"
        size="small"
      >
        <nb-icon icon="save-outline"></nb-icon>
        Save
      </button>
    </nb-card-footer>
  </nb-card>
</form>
