<nb-card style="width: 400px !important">
  <nb-card-header>
    <div class="d-flex justify-content-between">
      <div class="title-page-wrapper">
        <span>Dial Case</span
        ><nb-icon
          nbTooltip="Condition to trigger next action for called."
          nbTooltipPlacement="right"
          nbTooltipTrigger="hover"
          nbTooltipStatus="basic"
          icon="question-mark-circle-outline"
        ></nb-icon>
      </div>
      <button
        (click)="dismiss()"
        type="button"
        class="btn-close"
        aria-label="Close"
      ></button>
    </div>
  </nb-card-header>
  <nb-card-body>
    <div class="row m-2">
      <div class="col-12">
        <div class="mb-2">
          <label class="form-label label">Called</label>
          <nb-select
            fullWidth
            placeholder="Select Case"
            [(ngModel)]="data.method"
          >
            <nb-option *ngFor="let item of listFail" [value]="item">{{
              item
            }}</nb-option>
          </nb-select>
        </div>
      </div>
    </div>
    <button
      class="float-end"
      (click)="dismiss()"
      nbButton
      size="small"
      status="primary"
    >
      Confirm
    </button>
  </nb-card-body>
</nb-card>
