<div id="call-popup" [ngClass]="{ 'is-callflow-design': isCallflowDesign }">
  <nb-card style="margin-bottom: 0">
    <nb-card-header>
      <div class="d-flex justify-content-between" style="align-items: center">
        <nb-icon
          *ngIf="phoneStatus; else titleLogin"
          (click)="unregister()"
          class="me-1"
          icon="log-out"
          title="Log Out"
          style="cursor: pointer"
        ></nb-icon>
        {{ authorizationUsername }}
        <ng-template #titleLogin> Login </ng-template>
        <button
          (click)="dismiss()"
          type="button"
          class="btn-close"
          aria-label="Close"
        ></button>
      </div>
    </nb-card-header>
    <audio (ended)="audioEnded()" #playAudio>
      <source />
    </audio>
    <audio #callAudio>
      <p>Your browser doesn't support HTML5 audio.</p>
    </audio>
    <nb-card-body>
      <sip-login
        (credentialInfo)="getSipAccount($event)"
        *ngIf="!phoneStatus; else registered"
      ></sip-login>

      <ng-template #registered>
        <app-main-screen-phone
          [session]="session"
          (sessionEmit)="handlerSession($event)"
          (audioEmit)="playAudioCall($event)"
          (audioChange)="audioChangehandle($event)"
          [userAgent]="userAgent"
          *ngIf="dataService.callStatus != callStatusResult.Incomming"
        ></app-main-screen-phone>

        <app-incomming-dialog
          [session]="session"
          (audioEmit)="playAudioCall($event)"
          *ngIf="dataService.callStatus == callStatusResult.Incomming"
        ></app-incomming-dialog>
        <countup-timer style="visibility: hidden"></countup-timer>
      </ng-template>
      <span *ngIf="alertMsg" class="msg-alert">{{ alertMsg }}</span>
    </nb-card-body>
  </nb-card>
</div>
