<form [formGroup]="functionForm" novalidate (submit)="onSubmit()">
  <nb-card style="margin-bottom: 0">
    <nb-card-header>
      <div class="d-flex justify-content-between">
        {{ title }}
        <button
          (click)="dismiss()"
          type="button"
          class="btn-close"
          aria-label="Close"
        ></button></div
    ></nb-card-header>
    <nb-card-body>
      <div class="row">
        <div class="col-md-4 function-name">
          <div class="mb-2">
            <label for="input-name-function" class="form-label label required"
              >Function Name</label
            >
            <div
              [nbSpinner]="isFunctionLoading"
              nbSpinnerSize="medium"
              nbSpinnerStatus="info"
            >
              <input
                #inputName
                autofocus
                type="text"
                nbInput
                formControlName="name"
                [(ngModel)]="customFunction.name"
                fullWidth
                (ngModelChange)="checkFunctionName($event)"
                [attr.disabled]="
                  isFunctionLoading == false ? null : isFunctionLoading
                "
                [status]="
                  functionForm.controls['name'].invalid &&
                  (functionForm.controls['name'].dirty ||
                    functionForm.controls['name'].touched)
                    ? 'danger'
                    : 'basic'
                "
                id="input-name-function"
                fullWidth
                placeholder="Function Name"
              />
            </div>
          </div>
          <ng-container
            *ngIf="
              functionForm.controls['name'].invalid &&
              (functionForm.controls['name'].dirty ||
                functionForm.controls['name'].touched)
            "
          >
            <p
              class="caption status-danger"
              *ngIf="functionForm.controls['name'].errors.required"
            >
              Function Name is required.
            </p>
            <p
              class="caption status-danger"
              *ngIf="
                !functionForm.controls['name'].errors.required &&
                functionForm.controls['name'].errors.functionInvalid
              "
            >
              Function Name is invalid.
            </p>
            <p
              class="caption status-danger"
              *ngIf="
                !functionForm.controls['name'].errors.isExist &&
                !functionForm.controls['name'].errors.functionInvalid &&
                functionForm.controls.name['errorMessages'][0]
              "
            >
              {{ functionForm.controls.name["errorMessages"][0] }}
            </p>
            <p
              class="caption status-danger"
              *ngIf="functionForm.controls['name'].errors.serverError"
            >
              {{ functionForm.controls["name"].errors.serverError }}
            </p>
          </ng-container>
          <ngx-input-param
            (paramEmit)="getParam($event)"
            (errorEmit)="getError($event)"
            [paramInput]="customFunction?.param"
            paramName="Param Name"
          >
          </ngx-input-param>
        </div>
        <div class="col-md-8 function-code">
          <div class="label-wrapper">
            <label class="form-label label required">Code</label>
            <div class="mb-1">
              <button
                type="button"
                [disabled]="isLoadingRun || !customFunction.id"
                [nbSpinner]="isLoadingRun"
                (click)="run()"
                status="primary"
                class="me-2"
                size="tiny"
                nbButton
              >
                <nb-icon icon="play-circle-outline"></nb-icon>Run
              </button>
              <nb-select
                class="me-2"
                size="tiny"
                formControlName="language"
                [(ngModel)]="customFunction.language"
                (selectedChange)="selectLanguage($event)"
              >
                <nb-option *ngFor="let item of languages" [value]="item.key">{{
                  item.language
                }}</nb-option>
              </nb-select>
              <button type="button" nbButton size="tiny" (click)="fullScreen()">
                <nb-icon
                  [icon]="expand ? 'collapse-outline' : 'expand-outline'"
                ></nb-icon>
              </button>
            </div>
          </div>
          <div
            [nbSpinner]="isCodeLoading"
            nbSpinnerSize="medium"
            nbSpinnerStatus="info"
            class="editor-container"
          >
            <div
              class="app-ace-editor"
              #editor
              style="
                width: 100%;
                height: 100%;
                font-size: 14px;
                border: 1px solid lightgray;
              "
            ></div>
            <!-- <ace-editor
              theme="xcode"
              mode="php"
              style="
                height: 100%;
                width: 100%;
                font-size: 14px;
                border: 1px solid lightgray;
              "
              [(text)]="customFunction.code"
              [(ngModel)]="customFunction.code"
              [autoUpdateContent]="true"
              errorReporting="fatal"
              formControlName="code"
            ></ace-editor> -->
          </div>
        </div>
      </div>
    </nb-card-body>
    <nb-card-footer>
      <div class="d-flex justify-content-end">
        <button
          [disabled]="
            !functionForm.valid ||
            isLoading ||
            isFunctionLoading ||
            errorForm ||
            isExist
          "
          size="small"
          nbButton
          type="submit"
          status="primary"
        >
          <nb-icon icon="save-outline"></nb-icon>Save
        </button>
      </div>
    </nb-card-footer>
  </nb-card>
</form>
