import {
  AudioLanguageDto,
  UpdateAudioLanguageCommand,
  ServiceTTS,
} from "./../../../../System-api";
import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  HostListener,
} from "@angular/core";
import {
  NbToastrService,
  NbGlobalPhysicalPosition,
  NbDialogRef,
} from "@nebular/theme";
import {
  RxFormBuilder,
  RxwebValidators,
  fileSizeAsync,
} from "@rxweb/reactive-form-validators";
import { FormControl, UntypedFormGroup, Validators } from "@angular/forms";
import {
  AudioClient,
  ConvertAudioCommand,
  CreateAudioLanguageCommand,
  AudioLanguageClient,
} from "../../../../System-api";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { noWhitespaceValidator } from "../../../../@core/utils/helpers";

@Component({
  selector: "audio-create",
  templateUrl: "./create-audio.component.html",
  styleUrls: ["./create-audio.component.scss"],
})
export class CreateAudioComponent implements OnInit {
  @ViewChild("testAudio", { static: true })
  public testAudio: ElementRef;
  @ViewChild("testAudioUpload", { static: true })
  public testAudioUpload: ElementRef;
  @ViewChild("audioInput", { static: true }) audioInput: ElementRef;
  updateAudioForm: UntypedFormGroup;
  audio: any;
  audioUpdate: AudioLanguageDto;
  dataAudio;
  extensionAudio;
  isModal: boolean;
  createAudio: boolean;
  title: string = "Create New Audio";
  audioSelect;
  audioBefore: CreateAudioLanguageCommand;
  isPlaying: boolean;
  listLanguage;
  listVoice;
  listCountry;
  listService;
  onAdd = new EventEmitter();
  filteredService: Observable<any[]>;
  filteredLanguage: Observable<any[]>;
  filteredVoice: Observable<any[]>;
  isLoading: boolean = false;
  isUpload: boolean = false;
  maxSize: number;
  dataAudioUpload;
  pathAudioUpload: string = "";
  @Output() refeshList = new EventEmitter<number>();

  @HostListener('window:popstate', ['$event'])
    onPopState(event) {
      this.dismiss();
  }
  constructor(
    private formBuilder: RxFormBuilder,
    private audioClient: AudioClient,
    private audioLanguageClient: AudioLanguageClient,
    private toastrService: NbToastrService,
    public ref: NbDialogRef<CreateAudioComponent>
  ) {
    this.listService = [
      { name: "Primas", code: "0" },
      { name: "Soundhound", code: "1" },
      { name: "AmazonPolly", code: "2" },
      { name: "Google", code: "3" },
      { name: "Azure", code: "4" },
    ];
    this.maxSize = 20 * 1024 * 1024;
  }

  ngOnInit(): void {
    if (this.audioUpdate) {
      this.audio = new UpdateAudioLanguageCommand();
      this.audio.isUpload = this.audioUpdate.isUpload;
      if (this.audioUpdate.isUpload) {
        this.pathAudioUpload = this.audioUpdate.path + "?" + Date.now();
        this.audio.fileName = this.audioUpdate.fileName;
      }
    } else {
      this.audio = new CreateAudioLanguageCommand();
      this.audio.isUpload = false;
    }
    this.createForm(this.audio.isUpload, this.createAudio);
    this.audioBefore = new CreateAudioLanguageCommand();
    this.audio.audioId = this.audioSelect.id;
    this.audio.name = this.audioSelect.name;
    if (this.audio.fileName) {
      this.updateAudioForm.controls["file"]?.setValue(this.audio.fileName);
    }

    this.getDataAudioUpdate();
  }
  getAllLanguage() {
    this.audioClient.getAllLanguage().subscribe((rs) => {
      this.listLanguage = rs?.languages;
      this.filteredLanguage = this.updateAudioForm
        .get("language")
        .valueChanges.pipe(
          startWith(""),
          map((value) => {
            const name = typeof value === "string" ? value : value?.name;
            return name
              ? this._filterValue(name as string, "language")
              : this.listLanguage?.slice();
          })
        );
    });
  }
  getDataAudioUpdate() {
    if (this.audioUpdate) {
      let codeService;
      this.listService.forEach((item) => {
        if (item.name == this.audioUpdate.service) {
          codeService = item.code;
        }
      });
      this.updateAudioForm.controls["service"]?.setValue({
        name: this.audioUpdate.service,
        code: codeService,
      });
      this.onChangeService({
        name: this.audioUpdate.service,
        code: codeService,
      });

      if (
        (this.audioUpdate.isUpload && this.isUpload) ||
        !this.audioUpdate.isUpload
      ) {
        this.updateAudioForm.controls["language"]?.setValue({
          name: this.audioUpdate.languageName,
          code: this.audioUpdate.language,
        });
        this.setLanguage({
          name: this.audioUpdate.languageName,
          code: this.audioUpdate.language,
        });
        this.updateAudioForm.controls["voiceId"]?.setValue({
          id: this.audioUpdate.voiceId,
          name: this.audioUpdate.voiceId,
          gender: this.audioUpdate.gender,
        });
        this.onChangeVoice({
          id: this.audioUpdate.voiceId,
          name: this.audioUpdate.voiceId,
          gender: this.audioUpdate.gender,
        });
      }

      this.audio.content = this.audioUpdate?.content;
    }
  }
  displayValue(event: any) {
    return event && event.name ? event.name : "";
  }
  displayLanguage(language: any) {
    return language && language.name ? language.name : "";
  }
  displayService(service: any) {
    return service && service.name ? service.name : "";
  }
  displayVoice(voice: any) {
    return voice && voice.name ? voice.name : "";
  }
  private _filterValue(name: string, listName: string): any[] {
    const filterValue = name.toLowerCase();
    if (listName == "service") {
      return this.listService.filter((option) =>
        option.name.toLowerCase().includes(filterValue)
      );
    }
    if (listName == "language") {
      return this.listLanguage.filter((option) =>
        option.name.toLowerCase().includes(filterValue)
      );
    }
    if (listName == "voiceId") {
      return this.listVoice.filter((option) =>
        option.name.toLowerCase().includes(filterValue)
      );
    }
  }
  checkInvalid(type: string, event: any) {
    let check = false;
    let name = event?.name || event;
    if (name) {
      switch (type) {
        case "service":
          this.listService?.forEach((service) => {
            if (service.name == name) {
              check = true;
            }
          });
          break;
        case "language":
          this.listLanguage?.forEach((language) => {
            if (language.name == name) {
              check = true;
            }
          });
          break;
        case "voice":
          this.listVoice?.forEach((voice) => {
            if (voice.name == name) {
              check = true;
            }
          });
          break;
        default:
          break;
      }
      return check;
    } else {
      return true;
    }
  }
  onChangeService(event: any) {
    if (this.checkInvalid("service", event)) {
      this.audio.service = event?.code;
      this.updateAudioForm.controls["language"]?.setValue(undefined);
      this.updateAudioForm.controls["voiceId"]?.setValue(undefined);
      this.filteredLanguage = new Observable<any[]>();
      this.filteredVoice = new Observable<any[]>();
      switch (Number(event.code)) {
        case ServiceTTS.Primas:
          this.getLanguage(ServiceTTS.Primas);
          break;
        case ServiceTTS.AmazonPolly:
          this.getLanguage(ServiceTTS.AmazonPolly);
          break;
        case ServiceTTS.Soundhound:
          this.getLanguage(ServiceTTS.Soundhound);
          break;
        case ServiceTTS.Azure:
          this.getLanguage(ServiceTTS.Azure);
          break;
        case ServiceTTS.Google:
          this.getLanguage(ServiceTTS.Google);
          break;
        default:
          break;
      }
    } else {
      this.updateAudioForm.controls["service"].setErrors({ invalid: true });
    }
  }
  onChangeLanguage(event: any) {
    if (this.checkInvalid("language", event)) {
      this.audio.language = event?.code;
      this.updateAudioForm.controls["voiceId"]?.setValue(undefined);
      this.filteredVoice = new Observable<any[]>();
      const serviceCode = this.updateAudioForm.controls["service"]?.value?.code;
      if (event || event != undefined) {
        switch (Number(serviceCode)) {
          case ServiceTTS.Primas:
            this.getVoiceByLanguage(ServiceTTS.Primas, event?.code);
            break;
          case ServiceTTS.AmazonPolly:
            this.getVoiceByLanguage(ServiceTTS.AmazonPolly, event?.code);
            break;
          case ServiceTTS.Soundhound:
            this.getVoiceByLanguage(ServiceTTS.Soundhound, event?.code);
            break;
          case ServiceTTS.Azure:
            this.getVoiceByLanguage(ServiceTTS.Azure, event?.code);
            break;
          case ServiceTTS.Google:
            this.getVoiceByLanguage(ServiceTTS.Google, event?.code);
            break;
          default:
            break;
        }
      }
    } else {
      this.updateAudioForm.controls["language"].setErrors({ invalid: true });
    }
  }
  setLanguage(event: any) {
    this.audio.language = event?.code;
    this.updateAudioForm.controls["voiceId"]?.setValue(undefined);
    this.filteredVoice = new Observable<any[]>();
    const serviceCode = this.updateAudioForm.controls["service"]?.value?.code;
    if (event || event != undefined) {
      switch (Number(serviceCode)) {
        case ServiceTTS.Primas:
          this.getVoiceByLanguage(ServiceTTS.Primas, event?.code);
          break;
        case ServiceTTS.AmazonPolly:
          this.getVoiceByLanguage(ServiceTTS.AmazonPolly, event?.code);
          break;
        case ServiceTTS.Soundhound:
          this.getVoiceByLanguage(ServiceTTS.Soundhound, event?.code);
          break;
        case ServiceTTS.Azure:
          this.getVoiceByLanguage(ServiceTTS.Azure, event?.code);
          break;
        case ServiceTTS.Google:
          this.getVoiceByLanguage(ServiceTTS.Google, event?.code);
          break;
        default:
          break;
      }
    }
  }
  onChangeVoice(event: any) {
    if (this.checkInvalid("voice", event)) {
      this.audio.voiceId = event?.name;
    } else {
      this.updateAudioForm.controls["voiceId"]?.setErrors({ invalid: true });
    }
  }
  convertTTS() {
    if (this.isPlaying) {
      this.isPlaying = false;
      this.testAudio.nativeElement.pause();
      this.testAudio.nativeElement.currentTime = 0;
      return;
    }

    if (
      this.audioBefore.content == this.audio.content &&
      this.audioBefore.service == this.audio.service &&
      this.audioBefore.language == this.audio.language &&
      this.audioBefore.voiceId == this.audio.voiceId
    )
      this.testAudio.nativeElement.play();
    else {
      this.audioBefore.content = this.audio.content;
      this.audioBefore.service = this.audio.service;
      this.audioBefore.language = this.audio.language;
      if (this.audio.voiceId == undefined) {
        this.audio.voiceId = this.audioUpdate.voiceId;
      }
      this.audioBefore.voiceId = this.audio.voiceId;
      this.audioClient
        .convert(
          new ConvertAudioCommand({
            content: this.audio.content,
            service: this.audio.service,
            language: this.audio.language,
            voiceId: this.audio.voiceId,
          })
        )
        .subscribe((result) => {
          this.dataAudio = result;
          this.isPlaying = true;
        });
    }
  }
  checkAllowSize(allowSize) {
    if (allowSize > this.maxSize) {
      return false;
    }
    return true;
  }
  onSubmit() {
    this.audio.service = this.updateAudioForm.value?.service?.code;
    this.audio.language = this.updateAudioForm.value?.language?.code;
    this.audio.voiceId = this.updateAudioForm.value?.voiceId?.name;
    this.audio.gender = this.updateAudioForm.value?.voiceId?.gender;
    this.audio.languageName = this.updateAudioForm.value?.language?.name;
    if (this.audio.isUpload) {
      if (this.dataAudioUpload) {
        this.audio.audioFile = this.extensionAudio + "," + this.dataAudioUpload;
      } else {
        this.audio.audioFile = null;
      }
      this.audio.service = ServiceTTS.Google;
    }
    if (this.audioUpdate) {
      this.audio.audioId = this.audioUpdate.id;
      this.audio.name = this.audioUpdate.name;
      this.audio.content = this.audio.content?.trim();
      this.isLoading = true;
      this.audioLanguageClient.update(this.audio).subscribe(
        (rs) => {
          this.isLoading = false;
          if (rs > 0) {
            this.showToast(true);
            if (this.isModal) {
              this.onAdd.emit(rs);
            }
            this.createForm(this.audio.isUpload, this.createAudio);
            this.dataAudio = null;
            this.refeshList.emit(rs);
          } else this.showToast(false);
        },
        (err) => {
          this.showToast(false);
          this.isLoading = false;
        }
      );
    } else {
      this.audio.content = this.audio.content?.trim();
      this.isLoading = true;
      this.audioLanguageClient.create(this.audio).subscribe(
        (rs) => {
          this.isLoading = false;
          if (rs > 0) {
            this.showToast(true);
            if (this.isModal) {
              this.onAdd.emit(rs);
            }
            this.createForm(this.audio.isUpload, this.createAudio);
            this.dataAudio = null;
            this.refeshList.emit(rs);
          } else this.showToast(false);
        },
        (err) => {
          this.isLoading = false;
          this.showToast(false);
        }
      );
    }
  }
  getLanguage(service: ServiceTTS) {
    if (service == ServiceTTS.Google) {
      this.audioClient.getLanguageGoogle().subscribe((rs) => {
        const data = JSON.parse(rs);
        this.listLanguage = data?.languages;
        this.filteredLanguage = this.updateAudioForm
          .get("language")
          .valueChanges.pipe(
            startWith(""),
            map((value) => {
              const name = typeof value === "string" ? value : value?.name;
              return name
                ? this._filterValue(name as string, "language")
                : this.listLanguage?.slice();
            })
          );
      });
    } else {
      this.audioClient.getLanguage(service).subscribe((rs) => {
        this.listLanguage = rs?.languages;
        this.filteredLanguage = this.updateAudioForm
          .get("language")
          .valueChanges.pipe(
            startWith(""),
            map((value) => {
              const name = typeof value === "string" ? value : value?.name;
              return name
                ? this._filterValue(name as string, "language")
                : this.listLanguage?.slice();
            })
          );
      });
    }
  }
  dismiss() {
    this.ref.close(true);
  }
  getLanguageCode(service: ServiceTTS, languageName: string) {
    this.audioClient.getLanguage(service).subscribe((rs) => {
      this.listLanguage = rs?.languages;
      if (this.listLanguage) {
        this.listLanguage.forEach((item) => {
          if (item.name == languageName) {
            return item.code;
          }
        });
      }
    });
  }
  getVoiceByLanguage(service: ServiceTTS, languageCode: string) {
    if (languageCode) {
      this.listVoice = [];
      this.audioClient
        .getVoiceByLanguage(service, languageCode)
        .subscribe((rs) => {
          if (rs.voices[0].id == "{ }") {
            this.updateAudioForm.controls["language"]?.setValue(undefined);
          }
          if (service == ServiceTTS.Google) {
            const x = JSON.parse(rs.voices[0].name);
            x.voices?.forEach((item) => {
              var id = { id: item.name };
              var gender = { gender: item.ssmlGender };
              item = { ...item, ...id, ...gender };
              delete item["ssmlGender"];
              delete item["naturalSampleRateHertz"];
              delete item["languageCodes"];
              const dataExist = this.listVoice.find((x) => x.name == item.name);
              if (!dataExist) {
                this.listVoice.push(item);
              }
            });
          } else {
            this.listVoice = rs?.voices;
          }
          this.filteredVoice = this.updateAudioForm
            .get("voiceId")
            ?.valueChanges.pipe(
              startWith(""),
              map((value) => {
                const name = typeof value === "string" ? value : value?.name;
                return name
                  ? this._filterValue(name as string, "voiceId")
                  : this.listVoice?.slice();
              })
            );
        });
    }
  }
  getService() {
    this.filteredService = this.updateAudioForm
      .get("service")
      ?.valueChanges.pipe(
        startWith(""),
        map((value) => {
          const name = typeof value === "string" ? value : value?.name;
          return name
            ? this._filterValue(name as string, "service")
            : this.listService?.slice();
        })
      );
  }

  createForm(isUpload, createAudio) {
    if (!createAudio) {
      if (!isUpload) {
        this.updateAudioForm = this.formBuilder.group({
          service: new FormControl("", RxwebValidators.required()),
          content: new FormControl("", [
            RxwebValidators.required(),
            noWhitespaceValidator,
          ]),
          language: new FormControl("", [
            RxwebValidators.required(),
            noWhitespaceValidator,
          ]),
          voiceId: new FormControl("", [
            RxwebValidators.required(),
            noWhitespaceValidator,
          ]),
        });
      } else {
        this.updateAudioForm = this.formBuilder.group({
          language: new FormControl("", [
            RxwebValidators.required(),
            noWhitespaceValidator,
          ]),
          file: new FormControl("", [Validators.required]),
        });
      }
    } else {
      if (!isUpload) {
        this.updateAudioForm = this.formBuilder.group({
          name: new FormControl("", [
            RxwebValidators.required(),
            RxwebValidators.pattern({
              expression: {
                regex: /^[a-zA-Z0-9]+$/,
              },
            }),
          ]),
          service: new FormControl("", [
            RxwebValidators.required(),
            noWhitespaceValidator,
          ]),
          content: new FormControl("", [
            RxwebValidators.required(),
            noWhitespaceValidator,
          ]),
          language: new FormControl("", [
            RxwebValidators.required(),
            noWhitespaceValidator,
          ]),
          voiceId: new FormControl("", [
            RxwebValidators.required(),
            noWhitespaceValidator,
          ]),
        });
      } else {
        this.updateAudioForm = this.formBuilder.group({
          name: new FormControl("", [
            RxwebValidators.required(),
            RxwebValidators.pattern({
              expression: {
                regex: /^[a-zA-Z0-9]+$/,
              },
            }),
          ]),
          language: new FormControl("", [
            RxwebValidators.required(),
            noWhitespaceValidator,
          ]),
          file: [null, Validators.required],
        });
        this.dataAudioUpload = null;
      }
    }
    if (isUpload) {
      this.getAllLanguage();
    } else {
      this.getService();
    }
    if (this.testAudio.nativeElement.currentTime > 0) {
      this.testAudio.nativeElement.pause();
      this.testAudio.nativeElement.currentTime = 0;
    }
    this.isUpload = isUpload;
    this.getDataAudioUpdate();
  }

  setTouchedFile() {
    this.audio.fileName = "";
    this.updateAudioForm.controls["file"].setValue("");
    this.dataAudioUpload = "";
    this.pathAudioUpload = "";
    this.updateAudioForm.controls["file"].markAllAsTouched();
  }

  showToast(result) {
    if (result) {
      this.toastrService.show("Save audio successfully!", `Notification`, {
        position: NbGlobalPhysicalPosition.BOTTOM_LEFT,
        status: "success",
      });
    } else {
      this.toastrService.show("Save audio unsuccessfully!", `Notification`, {
        position: NbGlobalPhysicalPosition.BOTTOM_LEFT,
        status: "danger",
      });
    }
  }
  playAudioUpload() {
    if (this.isPlaying) {
      this.isPlaying = false;
      this.testAudioUpload.nativeElement.pause();
      this.testAudioUpload.nativeElement.currentTime = 0;
      return;
    } else {
      this.testAudioUpload.nativeElement.src;
      this.testAudioUpload.nativeElement.play();
      this.isPlaying = true;
    }
  }
  processFile(files: any) {
    this.isPlaying = false;
    this.dataAudioUpload = null;
    if (files.length === 0) return;
    var mimeType = files[0]?.type;
    if (!mimeType.match(/audio\/(mp3|wav|mpeg)/)) {
      this.updateAudioForm.get("file").setErrors({ wrongformat: true });
      this.audio.fileName = files[0].name;
      return;
    } else if (!this.checkAllowSize(files[0]?.size)) {
      this.updateAudioForm.get("file").setErrors({ maxSize: true });
      this.audio.fileName = files[0].name;
    } else {
      this.audio.fileName = files[0].name;
      var reader = new FileReader();
      reader.readAsDataURL(files[0]);
      reader.onload = (_event) => {
        let temp = reader.result.toString().split(",");
        this.extensionAudio = temp[0];
        this.dataAudioUpload = temp[1];
      };
    }
  }

  audioPlaying() {
    this.isPlaying = true;
  }
  audioEnded() {
    this.isPlaying = false;
  }
}
