import { DataService } from "./../../../../../@core/utils/data.service";
import { Component, ElementRef, Input, OnDestroy, OnInit } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { NbDialogService } from "@nebular/theme";
import { SetMediaFileModalComponent } from "../../../../../shared/set-media-file-modal/set-media-file-modal.component";
import { Carousel, Fancybox } from "@fancyapps/ui";
import { Thumbs } from "@fancyapps/ui/dist/carousel/carousel.thumbs.esm.js";
import { ConfirmDialogComponent } from "../../../../../shared/confirm-dialog/confirm-dialog.component";
@Component({
  selector: "setting-media",
  templateUrl: "./setting-media.component.html",
  styleUrls: ["./setting-media.component.scss"],
})
export class SettingMediaComponent implements OnInit, OnDestroy {
  @Input() parentForm: UntypedFormGroup;
  @Input() callSetting;
  previewVideo: any = null;
  name = "";
  url = "";
  type = "";
  types = [
    { name: "Video", value: "video" },
    { name: "Image", value: "image" },
    { name: "Gif", value: "gif" },
  ];
  sentiments = [];
  currentName = "";
  currentDefault = false;
  invalidName = false;
  actionType = "Add";
  invalidUrl = "";
  constructor(
    private dialogService: NbDialogService,
    private elRef: ElementRef,
    private dataService: DataService
  ) {}
  ngOnDestroy(): void {
    Fancybox.unbind(this.elRef.nativeElement);
    Fancybox.close();
  }
  loadMedia() {
    this.sentiments = JSON.parse(this.dataService.CallSetting?.media);
    this.callSetting.media = JSON.stringify(this.sentiments);
  }
  ngOnInit() {
    this.loadMedia();
    Fancybox.bind(this.elRef.nativeElement, "[data-fancybox='gallery']", {
      Toolbar: {
        display: {
          left: ["infobar"],
          right: ["zoomIn", "zoomOut", "slideshow", "close"],
          middle: [],
        },
      },
    });
  }
  handleSelect(event) {
    this.callSetting.speechToTextService = event;
  }
  handlePlayVideo(data) {
    if (this.previewVideo == data.name) {
      this.previewVideo = null;
    } else {
      this.previewVideo = data.name;
    }
  }
  handleEdit(data, e) {
    this.invalidUrl = "";
    this.invalidName = false;
    this.currentName = data.name;
    this.currentDefault = data.isDefault;
    this.actionType = "Update";
    this.name = data.name;
    this.url = data.mediaUrl;
    this.type = data.mediaType;
  }
  remove(data, index) {
    if (!data.isDefault) {
      this.dialogService
        .open(ConfirmDialogComponent, {
          autoFocus: false,
          context: {
            question:
              "Are you sure you want to delete '" +
              this.sentiments[index].name +
              "' ?",
            textYes: "Delete",
            textNo: "Cancel",
            statusYes: "danger",
            statusNo: "basic",
          },
        })
        .onClose.subscribe((isConfirm) => {
          if (isConfirm) {
            if (index != 0) {
              this.sentiments.splice(index, 1);
            } else {
              this.sentiments.shift();
            }
            this.callSetting.media = JSON.stringify(this.sentiments);
          }
        });
    }
  }
  getIconType(type) {
    switch (type) {
      case "video":
        return "video";
      case "image":
        return "image";
      case "gif":
        return "image";
      default:
        return "";
    }
  }
  handleWhiteSpace(type, event) {
    if (type == "name") {
      if (event?.trim()) {
        this.checkInvalidName(event);
      } else {
        this.name = "";
      }
    }
    if (!this.name.trim() && !this.url.trim()) {
      this.currentName = "";
      this.invalidUrl = "";
      this.currentDefault = false;
      this.actionType = "Add";
      this.invalidName = false;
    }
    if (type == "url" && this.url?.trim() && this.type) {
      this.checkURL(this.url?.trim(), this.type);
    }
  }
  checkURL(url, type) {
    switch (type) {
      case "video":
        if (!this.isVideo(url?.trim())) {
          this.invalidUrl = "Incorrect URL type.";
        } else {
          this.invalidUrl = "";
        }
        break;
      case "image":
        if (!this.isImage(url?.trim())) {
          this.invalidUrl = "Incorrect URL type.";
        } else {
          this.invalidUrl = "";
        }
        break;
      case "gif":
        if (!this.isGif(url?.trim())) {
          this.invalidUrl = "Incorrect URL type.";
        } else {
          this.invalidUrl = "";
        }
        break;
      default:
        this.invalidUrl = "";
        break;
    }
  }
  changeType(event) {
    this.type = event;
    if (this.url?.trim()) {
      this.checkURL(this.url?.trim(), this.type);
    }
  }
  checkInvalidName($event) {
    const data = this.sentiments.find((x) => {
      return (
        x.name?.toLowerCase().trim() == $event?.toLowerCase().trim() &&
        $event?.toLowerCase().trim() != this.currentName.toLowerCase().trim()
      );
    });
    if (data) {
      this.invalidName = true;
    } else {
      this.invalidName = false;
    }
  }
  cancelUpdate() {
    this.currentName = "";
    this.invalidUrl = "";
    this.currentDefault = false;
    this.actionType = "Add";
    this.invalidName = false;
    this.name = "";
    this.url = "";
    this.type = "";
  }
  isImage(url) {
    let checkImage =
      /\.(jpg|jpeg|png|webp|avif|svg|bmp|tiff|tif|icon|jp2)$/.test(url);
    if (!checkImage) {
      checkImage =
        /\.(jpg|jpeg|png|webp|avif|svg|bmp|tiff|tif|icon|jp2)\?/i.test(url);
    }
    return checkImage;
  }
  isVideo(url) {
    let checkVideo =
      /\.(mp4|avi|mkv|mov|wmv|flv|webm|mpg|mpeg|3gp|ogv|mp2|mp3)$/.test(url);
    if (!checkVideo) {
      checkVideo =
        /\.(mp4|avi|mkv|mov|wmv|flv|webm|mpg|mpeg|3gp|ogv|mp2|mp3)\?/i.test(
          url
        );
    }
    return checkVideo;
  }
  isGif(url) {
    let checkGif = /\.(gif)$/.test(url);
    if (!checkGif) {
      checkGif = /\.(gif)\?/i.test(url);
    }
    return checkGif;
  }

  addSentiment() {
    const data = this.sentiments.find(
      (x) =>
        x.name?.toLowerCase().trim() == this.currentName?.toLowerCase().trim()
    );
    if (data) {
      const index = this.sentiments.findIndex(
        (x) =>
          x.name?.toLowerCase().trim() == this.currentName?.toLowerCase().trim()
      );
      this.sentiments[index].name = this.name?.trim();
      this.sentiments[index].mediaUrl = this.url?.trim();
      this.sentiments[index].mediaType = this.type;
      this.name = "";
      this.url = "";
      this.type = "";
      this.previewVideo = null;
      this.currentName = "";
      this.currentDefault = false;
      this.actionType = "Add";
      this.invalidUrl = "";
    } else {
      const newData = {
        name: this.name?.trim(),
        mediaType: this.type,
        mediaUrl: this.url?.trim(),
        isDefault: false,
      };
      this.sentiments.push(newData);
      this.name = "";
      this.url = "";
      this.type = "";
      this.previewVideo = null;
      this.currentName = "";
      this.currentDefault = false;
      this.invalidUrl = "";
    }
    this.callSetting.media = JSON.stringify(this.sentiments);
  }
}
