<div style="width: 200px">
  <div style="margin: auto" class="row mt-2">
    <span>
      Call from <b>{{ this.session.request.from.uri.user }}</b>
    </span>
  </div>
  <div class="row mt-2">
    <button (click)="acceptCall()" class="mb-2" nbButton status="success">
      Accept
    </button>
    <button (click)="rejectCall()" nbButton status="danger">
      Reject
    </button>
  </div>
</div>
