import { Component, OnInit, ViewChild } from "@angular/core";
import {
  NbDialogRef,
  NbGlobalPhysicalPosition,
  NbToastrService,
} from "@nebular/theme";
import { DataService } from "../../../../@core/utils/data.service";
import {
  CallVm,
  ExportLogCommands,
  Page,
  TraceLogClient,
} from "../../../../System-api";
import { LogListComponent } from "./log-list/log-list.component";

@Component({
  selector: "log-modal",
  templateUrl: "log-modal.component.html",
  styleUrls: ["./log-modal.component.scss"],
})
export class LogModalComponent implements OnInit {
  @ViewChild(LogListComponent, { static: true })
  myChild: LogListComponent;
  logList: any;
  currentCallId: any;
  callIdList: any;
  phoneNumberList: any;
  fromNumberList: any[] = [];
  toNumberList: any[] = [];
  selectedFromNumber: any;
  selectedToNumber: any;
  selectedCallId: any;
  page: Page = new Page();
  rows = [];
  loading = false;
  isReadOnly = false;
  callDetail: CallVm;
  disabled: boolean = true;
  isLoading: boolean = false;
  constructor(
    protected ref: NbDialogRef<LogModalComponent>,
    private tracelogClient: TraceLogClient,
    private dataService: DataService,
    private toastrService: NbToastrService
  ) {
    this.page.pageNumber = 0;
    this.page.size = Math.floor((window.innerHeight * 0.55) / Number(34));
    this.page.sortBy = "id";
  }
  ngOnInit() {
    this.getCallId();
    //this.getPhoneNumberList();
  }
  getCallId() {
    this.tracelogClient
      .getAllCallId(this.dataService.CallFlow.id)
      .subscribe((rs) => {
        this.callIdList = [...rs.callId];
        this.callIdList.unshift({ callId: "All Log" });
        this.currentCallId = rs.callId[0]?.callId;
        if (this.currentCallId) {
          this.myChild.callId = this.currentCallId;
          this.getLog();
        }
      });
  }
  updateLoading(value: boolean) {
    this.isLoading = value;
  }
  export() {
    this.myChild.exportLog();
  }
  dataLogExport(event) {
    if (event.length > 0) {
      let data = new ExportLogCommands();
      //variable name file
      let callID = event
        .find((f) => f.callId.trim())
        ?.callId?.replaceAll(".", "_");
      let type = event.find((f) => f.type.trim())?.type;
      let fromNumber = event.find((f) => f.fromNumber.trim())?.fromNumber;
      let toNumber = event.find((f) => f.toNumber.trim())?.toNumber;
      //null value
      event.forEach((log) => {
        if (!log.fromNumber.trim()) {
          log.fromNumber = "Unknown";
        }
        if (!log.toNumber.trim()) {
          log.toNumber = "Unknown";
        }
      });
      data.callLogList = event;
      data.callLogList.forEach((callLog) => {
        let date = callLog.created;
        var timeZoneOffset = new Date().getTimezoneOffset();
        var hours = timeZoneOffset / 60;
        var now_utc = Date.UTC(
          date.getUTCFullYear(),
          date.getUTCMonth(),
          date.getUTCDate(),
          date.getUTCHours() - hours,
          date.getUTCMinutes(),
          date.getUTCSeconds()
        );
        callLog.created = new Date(now_utc);
      });
      this.tracelogClient.export(data).subscribe((rs) => {
        let file = this.dataURLtoFile("data:application/xlsx;base64," + rs);
        this.downloadData(
          `${callID || "Unknown"}-${fromNumber || "Unknown"}-${
            toNumber || "Unknown"
          }-${type || "Unknown"}.xlsx`,
          file
        );
      });
    }
  }
  dataURLtoFile(dataurl) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], "", { type: mime });
  }

  downloadData(filenameForDownload: string, data: any) {
    var textUrl = URL.createObjectURL(data);
    var element = document.createElement("a");
    element.setAttribute("href", textUrl);
    element.setAttribute("download", filenameForDownload);
    element.style.display = "none";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }
  setDisabledExport(value: boolean) {
    this.disabled = value;
  }

  getLog() {
    this.myChild.callId = this.currentCallId;
    this.myChild.setPage({ offset: 0 });
  }

  clear() {
    if (this.isReadOnly != true) {
      this.tracelogClient
        .deleteAll(this.dataService.CallFlow.id.toString())
        .subscribe((rs) => {
          this.showToast(
            rs,
            "Clear log successfully",
            "Clear log unsuccessfully"
          );
          if (rs == true) {
            this.callIdList = null;
            this.currentCallId = null;
            this.getLog();
          }
        });
    }
  }

  dismiss() {
    this.ref.close(false);
  }
  showToast(result, successText, failText) {
    if (result) {
      this.toastrService.show(successText, `Notification`, {
        position: NbGlobalPhysicalPosition.BOTTOM_LEFT,
        status: "success",
      });
    } else {
      this.toastrService.show(failText, `Notification`, {
        position: NbGlobalPhysicalPosition.BOTTOM_LEFT,
        status: "danger",
      });
    }
  }

  // selectedFromNumberChange(event){
  //   this.myChild.fromNumber = event;
  //   this.getLog();
  // };
  // selectedToNumberChange(event){
  //   this.myChild.toNumber = event;
  //   this.getLog();
  // }

  searchDatatable($event) {
    this.myChild.searchDataSearch($event);
  }
  searchFromNumber($event) {
    this.myChild.searchFromNumber($event);
  }

  clearLog() {
    this.myChild.callId = "All Log";
  }
}
