import { Component, HostListener, OnInit, ViewChild } from "@angular/core";
import { MxCell, MxGraph } from "../../../../@core/model/mxgraph";
import { OutreachAction } from "../../../../@core/model/outreach-action";
import { NbDialogService, NbWindowRef } from "@nebular/theme";
import { CallFlowClient, TrunkClient, TrunkVm } from "../../../../System-api";
import { Router } from "@angular/router";
import { DialogAttributeComponent } from "../../other/dialog-attribute/dialog-attribute.component";
import {
  RxFormBuilder,
  RxwebValidators,
} from "@rxweb/reactive-form-validators";
import { UntypedFormGroup } from "@angular/forms";
import { noWhitespaceValidator } from "../../../../@core/utils/helpers";
import { GraphHandlerService } from "../../../../@core/utils/graph.service";

@Component({
  selector: "outreach-action",
  templateUrl: "./outreach-action.component.html",
  styleUrls: ["./outreach-action.component.scss"],
})
export class OutreachActionComponent implements OnInit {
  @ViewChild("autoInput") input;
  cell: MxCell;
  graph: MxGraph;
  callSetting: any;
  listlanguage: any[];
  data: OutreachAction = new OutreachAction();
  trunksNumber: TrunkVm[];
  callflows: any;
  viewDisabled: boolean = true;
  callflowIdSelected: number = 0;
  options: object[] = [
    { id: "phonecall", name: "Phone call" },
    { id: "sms", name: "SMS" },
    { id: "smsandphonecall", name: "Phone call & SMS" },
  ];
  cancelAction = false;
  formGroup: UntypedFormGroup;
  isLoading: boolean = false;
  constructor(
    protected windowRef: NbWindowRef,
    private callFlowClient: CallFlowClient,
    private trunkClient: TrunkClient,
    private router: Router,
    private dialogService: NbDialogService,
    private formBuilder: RxFormBuilder,
    private graphService: GraphHandlerService
  ) {
    this.createForm();
  }
  createForm() {
    this.formGroup = this.formBuilder.group({
      callflow: ["", [RxwebValidators.required(), noWhitespaceValidator]],
      phoneNumber: ["", [RxwebValidators.required(), noWhitespaceValidator]],
      caller: ["", [RxwebValidators.required(), noWhitespaceValidator]],
      callerNumber: ["", [RxwebValidators.required(), noWhitespaceValidator]],
    });
  }
  handleSaveAction() {
    this.windowRef.close();
  }
  handleCancelAction() {
    this.cancelAction = true;
    this.windowRef.close(true);
  }
  ngOnInit(): void {
    this.getTrunks();
    this.getCallflows();
    this.data.outreachType = "phonecall";
    //language
    if (!(this?.callSetting?.languageSetting == undefined)) {
      this.listlanguage =
        JSON.parse(this?.callSetting?.languageSetting) == null
          ? []
          : JSON.parse(this?.callSetting?.languageSetting);
    }
    if (this.listlanguage != undefined && this.listlanguage.length > 0) {
      this.listlanguage = this.listlanguage.sort((a, b) =>
        a.name.localeCompare(b.name)
      );
    }
    this.windowRef.onClose.subscribe(() => this.submit());
  }

  submit() {
    if (!this.cancelAction) {
      for (let [key, value] of Object.entries(this.data)) {
        if (typeof this.data[key] == "string") {
          value = value?.trim();
        }
        this.cell.setAttribute(key, value || "");
      }
      const outreachCell = this.graph.getModel().getCell(this.cell.getId());
      const fieldsRequired = [
        this.data.callflowId,
        this.data.phoneNumber.trim(),
        this.data.callerId,
      ];
      const check = this.graphService.checkIsRequired(
        this.graph,
        fieldsRequired,
        outreachCell
      );
      this.cell.setAttribute("checkFields", check?.toString());
    }
  }

  getCallflows() {
    this.isLoading = true;
    this.callFlowClient
      .getAll(0, 1000, "id", true, "all", null)
      .subscribe((item: any) => {
        this.callflows = item.callFlows;
        for (let [key, value] of Object.entries(this.data)) {
          this.data[key] = this.cell.getAttribute(key);
        }
        if (this.data.callflowId) {
          let dataExist = this.callflows?.find(
            (x) => x.id == this.data.callflowId
          );
          if (dataExist) {
            this.callflowIdSelected =
              Number(this.data.callflowId) == 0 ||
              isNaN(Number(this.data.callflowId))
                ? null
                : Number(this.data.callflowId);
            if (!this.data.flowName || this.data.flowName == "") {
              this.data.flowName = dataExist.name;
            }
          } else {
            this.callflowIdSelected = null;
          }
        } else {
          this.callflowIdSelected = null;
        }
        this.isLoading = false;
      });
  }

  getTrunks() {
    this.trunkClient.getAll().subscribe((item) => {
      this.trunksNumber = item.trunks;
    });
  }

  addAttribute() {
    this.dialogService
      .open(DialogAttributeComponent, { autoFocus: false })
      .onClose.subscribe((rs) => {
        if (rs) {
        }
      });
  }

  checkViewDisabled(callFlow) {
    if (callFlow != undefined) {
      this.viewDisabled = false;
      this.data.callflowId = callFlow.id;
      this.data.flowName = callFlow.name;
    } else {
      this.viewDisabled = true;
      this.data.callflowId = "";
    }
  }

  viewSubFlow(callFlowId) {
    this.router.navigate([]).then((result) => {
      window.open(`callflow-design/${callFlowId}`, "_blank");
    });
  }

  createFlow() {
    const newWindow = window.open("/portal/studio/callflows", "_blank");
    if (newWindow) {
      newWindow.addEventListener("load", () => {
        newWindow.postMessage({ action: "loaded" }, "*");
      });
    }
  }

  handleAudio(value: string) {
    this.data.outreachType = value;
  }

  getAttribute($event) {
    this.data.attribute = $event;
  }
}
