<nb-card>
  <nb-card-header>
    <div class="d-flex justify-content-between">
      {{ title }}
      <button
        (click)="dismiss()"
        type="button"
        class="btn-close"
        aria-label="Close"
      ></button>
    </div>
  </nb-card-header>
  <nb-card-body>
    <div [innerHTML]="question"></div>
  </nb-card-body>
  <nb-card-footer>
    <div class="float-end">
      <button
        (click)="onSubmit()"
        type="submit"
        size="small"
        class="me-2"
        status="{{ statusYes }}"
        nbButton
      >
        {{ textYes }}
      </button>
      <button (click)="dismiss()" size="small" status="{{ statusNo }}" nbButton>
        {{ textNo }}
      </button>
    </div>
  </nb-card-footer>
</nb-card>
