import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "sip-login",
  templateUrl: "./sip-login.component.html",
  styleUrls: ["./sip-login.component.scss"],
})
export class SipLoginComponent implements OnInit {
  @Output() credentialInfo = new EventEmitter<any>();

  userName: string;
  passWord: string;
  constructor() {}

  ngOnInit() {}
  submit() {
    if (this.userName && this.passWord) {
      this.credentialInfo.emit({
        userName: this.userName,
        password: this.passWord,
      });
    }
  }
}
