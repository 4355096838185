import { Component, Input, OnInit } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";

@Component({
  selector: "setting-speech-to-text",
  templateUrl: "./setting-speech-to-text.component.html",
  styleUrls: ["./setting-speech-to-text.component.scss"],
})
export class SettingLanguageSpeechToTextComponent implements OnInit {
  @Input() parentForm: UntypedFormGroup;
  @Input() callSetting;
  constructor() {}
  ngOnInit() {}
  handleSelect(event) {
    this.callSetting.speechToTextService = event;
  }
}
