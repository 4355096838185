import { IApplicationData } from "./common";
export class GetInput implements IApplicationData {
  constructor() {
    this.name = "Prompt & Collect";
    this.audioConfirmId = "";
    this.audioConfirmPath = "";
    this.audioId = "";
    this.audioPath = "";
    this.audioIdRepeat = "";
    this.audioPathRepeat = "";
    this.audioNoInputId = "";
    this.audioNotMatchId = "";
    this.audioNotMatchPath = "";
    this.audioNoInputPath = "";
    this.text = "";
    this.textRepeat = "";
    this.textChatBot = "";
    this.textNotMatch = "";
    this.textNoInput = "";
    this.textConfirm = "";
    this.option = 1;
    this.attribute = "";
    this.allowSpeech = true;
    this.timeout = "10";
    this.speechTimeout = "10";
    this.noInputTimeout = "5";
    this.chatTimeout = "0";
    this.noInputSpeechTimeout = "5";
    this.repeat = "2";
    this.grammar = "";
    this.grammarArray = "[]";
    this.keywordsRepeat = "[]";
    this.allowBreak = true;
    this.translateGrammar = false;
    this.multipleOtherCase = true;
    this.transition = true;
    this.typeGrammar = "BuiltIn";
    this.maxLength = "10";
    this.maxLengthSpeech = "20";
    this.minLength = "1";
    this.minLengthSpeech = "1";
    this.functionId = "";
    this.grammarName = "";
    this.entity = "";
    this.entityAttribute = "";
    this.engine = false;
    this.extenData = "";
    this.sentiment = "";
    this.sentimentNoInput = "";
    this.sentimentNotMatch = "";
    this.keyNumberRepeat = "-1";
    this.multipleCase = true;
    this.idGrammar = 0;
    this.answer = "";
    this.allowAnswerOption = false;
    this.userResponse = "";
    this.rememberRepeat = false;
    this.isConfirm = false;
    this.log = false;
    this.params = "";
    this.isRequired = true;
    this.checkFields = false;
    this.spelling = false;
    this.spellingPosition = "2";
  }
  name: string;
  audioPath: string;
  audioId: string;
  audioPathRepeat: string;
  audioIdRepeat: string;
  audioConfirmPath: string;
  audioConfirmId: string;
  functionId: string;
  audioNotMatchId: string;
  audioNoInputId: string;
  audioNotMatchPath: string;
  audioNoInputPath: string;
  text: string;
  textRepeat: string;
  textChatBot: string;
  translateGrammar: boolean;
  textNotMatch: string;
  textNoInput: string;
  textConfirm: string;
  option: number;
  attribute: string;
  allowSpeech: boolean;
  allowBreak: boolean;
  allowRepeat: boolean;
  timeout: string;
  speechTimeout: string;
  noInputTimeout: string;
  chatTimeout: string;
  noInputSpeechTimeout: string;
  repeat: string;
  keyNumberRepeat: string;
  keywordsRepeat: string;
  typeGrammar: string;
  grammar: string;
  grammarArray: string;
  multipleOtherCase: boolean;
  transition: boolean;
  maxLength: string;
  maxLengthSpeech: string;
  minLength: string;
  minLengthSpeech: string;
  grammarName: string;
  entity: string;
  entityAttribute: string;
  engine: boolean;
  extenData: string;
  sentiment: string;
  sentimentNoInput: string;
  sentimentNotMatch: string;
  idGrammar: number;
  multipleCase: boolean;
  answer: string;
  allowAnswerOption: boolean;
  userResponse: string;
  rememberRepeat: boolean;
  params: string;
  log: boolean;
  isConfirm: boolean;
  isRequired: boolean;
  checkFields: boolean;
  spelling: boolean;
  spellingPosition: string;
}
