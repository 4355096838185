import { NgSelectModule } from "@ng-select/ng-select";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import {
  NbCardModule,
  NbRadioModule,
  NbSelectModule,
  NbStepperModule,
  NbTabsetModule,
  NbPopoverModule,
  NbBadgeModule,
  NbListModule,
  NbOptionModule,
  NbCheckboxModule,
  NbAutocompleteModule,
  NbTreeGridModule,
  NbIconModule,
  NbAccordionModule,
  NbTagModule,
  NbFormFieldModule,
  NbTooltipModule,
  NbActionsModule,
  NbSpinnerModule,
  NbUserModule,
  NbContextMenuModule,
} from "@nebular/theme";
import { GoToQueueComponent } from "./action/go-to-queue/go-to-queue.component";
import { HttpRequestComponent } from "./action/http-request/http-request.component";
import { CheckAttributeComponent } from "./action/check-attribute/check-attribute.component";
import { GetInputComponent } from "./action/get-input/get-input.component";
import { OutreachActionComponent } from "./action/outreach-action/outreach-action.component";
import { HangupActionComponent } from "./action/hangup-action/hangup-action.component";
import { AudioModule } from "../../portal/customer-role/audios/audio.module";
import { SharedModule } from "../../shared/shared.module";
import { CheckTimeComponent } from "./action/check-time/check-time.component";
import { TagInputModule } from "ngx-chips";
import { PlayAudioComponent } from "./action/playaudio/callflows-playaudio";
import { RedirectCallflowComponent } from "./action/redirect-callflow/redirect-callflow.component";
import { SayNumberComponent } from "./action/say-number/say-number.component";
import { SetAttributeComponent } from "./action/set-attribute/set-attribute.component";
import { SetLanguageComponent } from "./action/set-language/set-language.component";
import { TransferComponent } from "./action/transfer/transfer.component";
import { VoiceMailComponent } from "./action/voice-mail/voice-mail.component";
import { ChooseTestNumberComponent } from "./other/choose-test-number/choose-test-number.component";
import { RequestModificationDialogComponent } from "./other/request-modification/request-modification.component";
import { InputStaticComponent } from "./other/input-static/input-static.component";
import { DialogAttributeComponent } from "./other/dialog-attribute/dialog-attribute.component";
import { RevisionHistoryComponent } from "./other/revision-history/revision-history.component";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { ResponseDefineComponent } from "./other/response-define/response-define.component";
import { ConditionDefineComponent } from "./other/condition-define/condition-define.component";
import { CustomFunctionComponent } from "./action/custom-function/custom-function.component";
import { WaitActionComponent } from "./action/wait-action/wait-action.component";
import { FailureCaseDefinationComponent } from "./other/failure-case-defination/failure-case-defination.component";
import { InputAttributeComponent } from "./action/custom-function/input-param/input-param.component";
import { CreateCustomFunctionComponent } from "./action/custom-function/create-custom-function/create-custom-function.component";
import { CreateGrammarComponent } from "./action/get-input/create-grammar/create-grammar.component";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { TraceLogComponent } from "./action/trace-log/trace-log.component";
import { DataSetActionComponent } from "./action/dataset-action/dataset-action.component";
import { DatasetComponent } from "../../portal/customer-role/dataset/dataset.component";
import { TextareaHighlightModule } from "../../shared/textarea-highlight/textarea-highlight.module";
import { ChatGPTComponent } from "./action/chatgpt/chatgpt.component";
import { CreateGPTDatasetComponent } from "./action/chatgpt/create-gpt-dataset/create-gpt-dataset.component";
import { UppyAngularDashboardModule } from "@uppy/angular";
import { MentionModule } from "angular-mentions";
import { DialComponent } from "./action/dial/dial.component";
import { DialCaseDefinationComponent } from "./other/dial-case-defination/dial-case-defination.component";
import { DialCaseContextComponent } from "./other/dial-case-context/dial-case-context.component";
import { ImportFlowModalComponent } from "./other/import-flow-modal/import-flow-modal.component";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { MatTreeModule } from "@angular/material/tree";
import { MatTooltipModule } from "@angular/material/tooltip";
import { SentimentSelectComponent } from "./other/sentiment-select/sentiment-select.component";
import { CustomEventComponent } from "./action/custom-event/custom-event.component";
import { OnlyNumberDirective } from "./action/chatgpt/only-int-number.directive";
import { OnlyDoubleNumberDirective } from "./action/chatgpt/only-double-number.directive";
import { MatInputModule } from "@angular/material/input";
import { MatFormFieldModule } from "@angular/material/form-field";
import { ActionFooterComponent } from "./other/action-footer/action-footer.component";
import { TranslateActionComponent } from "./action/translate-action/translate-action.component";
import { InputValueStaticComponent } from "./other/input-value-static/input-value-static.component";
import { AnswerDetectComponent } from "./action/answer-detect/answer-detect.component";
import { AnswerDetectStatusComponent } from "./other/answer-detect-status/answer-detect-status.component";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { MatMenuModule } from "@angular/material/menu";
import { FormsComponent } from "./action/forms/formscomponent";
import { MultipleGrammarComponent } from "./other/mutiple-grammar/multiple-grammar.component";
import { IntentRecognitionComponent } from "./action/intent-recognition/intent-recognition.component";
import { SendMailActionComponent } from "./action/send-mail/send-mail.component";
import { CMSActionComponent } from "./action/cms-action/cms-action.component";
import { LLMComponent } from "./action/llm/llm.component";

@NgModule({
  imports: [
    CommonModule,
    NbCardModule,
    NbRadioModule,
    NbSelectModule,
    FormsModule,
    AudioModule,
    NbPopoverModule,
    NbStepperModule,
    NbBadgeModule,
    SharedModule,
    NbTabsetModule,
    NbListModule,
    NbOptionModule,
    NbCheckboxModule,
    TagInputModule,
    NgxDatatableModule,
    NbAutocompleteModule,
    NbTreeGridModule,
    NbIconModule,
    NbCheckboxModule,
    NbAccordionModule,
    NbTagModule,
    MatAutocompleteModule,
    TextareaHighlightModule,
    NbFormFieldModule,
    UppyAngularDashboardModule,
    MentionModule,
    NbTooltipModule,
    NbActionsModule,
    NbSpinnerModule,
    MatTreeModule,
    MatIconModule,
    MatButtonModule,
    MatTooltipModule,
    NbUserModule,
    NgSelectModule,
    MatInputModule,
    MatFormFieldModule,
    NbContextMenuModule,
    DragDropModule,
    MatMenuModule,
  ],
  exports: [
    InputStaticComponent,
    SharedModule,
    CreateGrammarComponent,
    NgSelectModule,
    InputValueStaticComponent,
    ChooseTestNumberComponent,
  ],
  declarations: [
    DatasetComponent,
    DialogAttributeComponent,
    SentimentSelectComponent,
    VoiceMailComponent,
    RedirectCallflowComponent,
    CheckTimeComponent,
    SayNumberComponent,
    RequestModificationDialogComponent,
    PlayAudioComponent,
    CheckAttributeComponent,
    DataSetActionComponent,
    GoToQueueComponent,
    TransferComponent,
    DialComponent,
    ChooseTestNumberComponent,
    HttpRequestComponent,
    SetAttributeComponent,
    SetLanguageComponent,
    GetInputComponent,
    OutreachActionComponent,
    InputStaticComponent,
    RevisionHistoryComponent,
    ResponseDefineComponent,
    CustomEventComponent,
    ConditionDefineComponent,
    CustomFunctionComponent,
    WaitActionComponent,
    FailureCaseDefinationComponent,
    DialCaseDefinationComponent,
    InputAttributeComponent,
    CreateCustomFunctionComponent,
    CreateGrammarComponent,
    CreateGPTDatasetComponent,
    TraceLogComponent,
    ChatGPTComponent,
    DialCaseContextComponent,
    ImportFlowModalComponent,
    OnlyNumberDirective,
    OnlyDoubleNumberDirective,
    ActionFooterComponent,
    TranslateActionComponent,
    HangupActionComponent,
    InputValueStaticComponent,
    AnswerDetectComponent,
    AnswerDetectStatusComponent,
    FormsComponent,
    MultipleGrammarComponent,
    IntentRecognitionComponent,
    SendMailActionComponent,
    CMSActionComponent,
    LLMComponent,
  ],
  providers: [],
  entryComponents: [
    DatasetComponent,
    DialogAttributeComponent,
    VoiceMailComponent,
    CustomEventComponent,
    RedirectCallflowComponent,
    CheckTimeComponent,
    SayNumberComponent,
    PlayAudioComponent,
    CheckAttributeComponent,
    DataSetActionComponent,
    GoToQueueComponent,
    TransferComponent,
    DialComponent,
    ChooseTestNumberComponent,
    HttpRequestComponent,
    SetAttributeComponent,
    SetLanguageComponent,
    GetInputComponent,
    OutreachActionComponent,
    RevisionHistoryComponent,
    ResponseDefineComponent,
    ConditionDefineComponent,
    InputStaticComponent,
    CustomFunctionComponent,
    WaitActionComponent,
    FailureCaseDefinationComponent,
    DialCaseDefinationComponent,
    InputAttributeComponent,
    CreateCustomFunctionComponent,
    CreateGrammarComponent,
    CreateGPTDatasetComponent,
    DatasetComponent,
    ChatGPTComponent,
    DialCaseContextComponent,
    RequestModificationDialogComponent,
    TranslateActionComponent,
    HangupActionComponent,
    AnswerDetectComponent,
    AnswerDetectStatusComponent,
  ],
})
export class ActionModule {}
