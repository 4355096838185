import { ExtraOptions, RouterModule, Routes } from "@angular/router";
import { NgModule } from "@angular/core";
import { AuthGuard } from "./@core/utils/auth-guard.service";
import { UnauthorizedComponent } from "./error-page/unauthorized/unauthorized.component";
import { CallflowsDesignComponent } from "./callflows-design/callflows-design.component";
import { PageNotFoundComponent } from "./error-page/404/page-not-found.component";

const routes: Routes = [
  {
    path: "auth",
    loadChildren: () =>
      import("./auth/auth.module").then((m) => m.NgxAuthModule),
  },
  {
    path: "error",
    component: UnauthorizedComponent,
    canActivate: [AuthGuard],
    data: {
      permission: "access",
      resource: "error",
    },
    title: "Error",
  },
  {
    path: "callflow-design/:id",
    component: CallflowsDesignComponent,
    canActivate: [AuthGuard],
    data: {
      permission: "access",
      resource: "design",
    },
  },
  {
    path: "portal",
    loadChildren: () =>
      import("./portal/portal.module").then((m) => m.PortalModule),
  },

  {
    path: "",
    pathMatch: "full",
    data: {
      permission: "access",
      resource: "home",
    },
    canActivate: [AuthGuard],
  },
  {
    path: "**",
    component: PageNotFoundComponent,
  },
];

const config: ExtraOptions = {
  useHash: false,
  relativeLinkResolution: "legacy",
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
