import {
  AgentClient,
  ChangeFollowMeCommand,
  ChangeStatusCommand,
  SettingsClient,
} from "./../../../System-api";
import { Component, OnDestroy, OnInit, TemplateRef } from "@angular/core";
import {
  NbDialogService,
  NbGlobalPhysicalPosition,
  NbMenuService,
  NbSidebarService,
  NbToastrService,
} from "@nebular/theme";

import { Subject } from "rxjs";
import { NbAuthService, NbAuthJWTToken, NbTokenService } from "@nebular/auth";
import { Router } from "@angular/router";
import { DataService } from "../../../@core/utils/data.service";
import { AuthClient } from "../../../System-api";
import { EventBusService } from "../../../@core/utils/eventbus.service";
import { version } from "../../../../assets/version";
import { UpgradeComponent } from "../upgrade/upgrade.component";
import { ConfirmDialogComponent } from "../../../shared/confirm-dialog/confirm-dialog.component";
import { documentation } from "../../../../environments/environment";

@Component({
  selector: "ngx-header",
  styleUrls: ["./header.component.scss"],
  templateUrl: "./header.component.html",
})
export class HeaderComponent implements OnInit, OnDestroy {
  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  user: any;
  latestVersion;
  canUpdate = false;
  version = version || "2.9";
  userMenu = [];
  userMenuChat = [];
  linkDocumentation = documentation;
  constructor(
    private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private authService: NbAuthService,
    private router: Router,
    private authClient: AuthClient,
    private tokenService: NbTokenService,
    private eventBusService: EventBusService,
    public dataService: DataService,
    private agentClient: AgentClient,
    private settingClient: SettingsClient,
    private toastrService: NbToastrService,
    private dialogService: NbDialogService
  ) {
    this.authService.onTokenChange().subscribe((token: NbAuthJWTToken) => {
      if (token.isValid()) {
        this.user = token.getPayload();
        if (this.user?.picture) {
          let date = new Date();
          this.user.picture = this.user.picture + "?" + date.getTime();
        }
        if (this.user.backadmin) {
          this.userMenu = [
            {
              title: "My Account",
              icon: "lock",
              data: "youraccount",
            },
            {
              title: "Back admin",
              icon: "flip-2-outline",
              data: "backadmin",
            },
          ];
        } else {
          this.userMenu = [
            {
              title: "My Account",
              icon: "lock",
              data: "youraccount",
            },
            {
              title: "Logout",
              icon: "log-out",
              data: "logout",
            },
          ];
        }
      }
    });
  }
  focusInput() {
    if (this.dataService.openChatContent == true) {
      let primasBot = document.getElementById("primas-bot-portal") as any;
      if (primasBot) {
        primasBot.contentWindow.postMessage(
          {
            type: "FOCUS_INPUT",
            value: {},
          },
          "*"
        );
      }
    }
  }
  chatWithUs() {
    this.dataService.openChatButton = true;
    this.dataService.openChatContent = true;
    this.focusInput();
  }
  change(event) {
    this.dataService.openChatButton = event;
    this.focusInput();
  }
  helpCenter() {
    this.router.navigate([]).then((result) => {
      window.open(this.linkDocumentation, "_blank");
    });
  }
  showToast(result) {
    if (result) {
      this.toastrService.show("Successfully", `Notification`, {
        position: NbGlobalPhysicalPosition.BOTTOM_LEFT,
        status: "success",
      });
    } else {
      this.toastrService.show("Unsuccessfully", `Notification`, {
        position: NbGlobalPhysicalPosition.BOTTOM_LEFT,
        status: "danger",
      });
    }
  }

  openUpgrade() {
    this.dialogService.open(UpgradeComponent, {
      autoFocus: false,
      closeOnEsc: false,
      closeOnBackdropClick: false,
    });
  }

  updateIsOnline(e) {
    let data = new ChangeStatusCommand();
    data.agentId = this.dataService.AgentInfo.id;
    data.isOnline = e;
    this.agentClient.changeStatus(data).subscribe(
      (rs) => {
        if (rs) {
        } else {
          this.showToast(false);
          this.dataService.AgentInfo.isOnline = !e;
        }
      },
      (error) => {
        this.showToast(false);
        this.dataService.AgentInfo.isOnline = !e;
      }
    );
  }
  toggleFollowMe(e) {
    let data = new ChangeFollowMeCommand();
    data.agentId = this.dataService.AgentInfo.id;
    data.followMe = e;
    this.agentClient.changeFollowMe(data).subscribe(
      (rs) => {
        if (rs) {
        } else {
          this.showToast(false);
          this.dataService.AgentInfo.followMe = !e;
        }
      },
      (error) => {
        this.showToast(false);
        this.dataService.AgentInfo.followMe = !e;
      }
    );
  }
  ngOnInit() {
    this.menuService.onItemClick().subscribe((event) => {
      if (event.item.data === "logout") {
        this.router.navigate(["/auth/logout"]);
      }
      if (event.item.data === "youraccount") {
        this.router.navigate(["/portal/myaccount"]);
      }
      if (event.item.data === "backadmin") {
        this.authClient.impersonate(this.user.backadmin).subscribe((rs) => {
          localStorage.setItem("auth_app_token", rs);
          this.authService.refreshToken("email", rs);
          this.authService
            .getToken()
            .subscribe((token) => this.tokenService.set(token));
          this.router.navigate(["/portal/manage-customer"]);
        });
      }
    });
    this.eventBusService.on("updateAvatar", (avatar: string) => {
      this.user.picture = avatar;
    });

    this.settingClient.getLatestVersion().subscribe((rs) => {
      const numbersArray: number[] = rs.split("\n").map(Number);
      const maxValue: number = Math.max(...numbersArray);

      var repoVersion = rs.match(/[\d.]+/);
      if (repoVersion) {
        if (repoVersion[0].replace(".", "") > version.replace(".", "")) {
          this.latestVersion = maxValue;
          this.canUpdate = true;
        } else {
          this.latestVersion = maxValue;
          this.canUpdate = false;
        }
      }
    });
  }
  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    this.dataService.AgentInfo = null;
  }
  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, "menu-sidebar");
    return false;
  }
  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }
}
