<audio
  (playing)="audioPlaying()"
  (ended)="audioEnded()"
  #testAudio
  [src]="dataAudio ? 'data:audio/mp3;base64,' + dataAudio : ''"
  autoplay
>
  <source />
</audio>
<div style="margin-bottom: 1rem">
  <nb-accordion>
    <nb-accordion-item
      [expanded]="checkDefaultLanguageUser(data)"
      *ngFor="let data of dataText; let i = index"
    >
      <nb-accordion-item-header class="is-call-flow-name">{{
        data.name
      }}</nb-accordion-item-header>
      <nb-accordion-item-body>
        <nb-list
          cdkDropList
          class="example-list"
          (cdkDropListDropped)="drop($event, i)"
        >
          <nb-list-item class="label-prompt"
            ><span>Phone Call</span>
            <nb-icon
              [nbPopover]="templateRef"
              nbPopoverPlacement="bottom"
              nbPopoverTrigger="hover"
              class="ms-1"
              style="color: #929bb3; width: 15px"
              icon="question-mark-circle-outline"
            >
            </nb-icon
          ></nb-list-item>
          <nb-list-item
            class="example-box"
            *ngFor="let text of data.text; trackBy: trackByFn; let j = index"
            cdkDrag
          >
            <div class="play-wrapper">
              <button
                class="icon-action"
                ghost
                (click)="convertTTS(i, j, 'phone')"
                type="button"
                size="small"
                status="primary"
                nbButton
              >
                <nb-icon
                  [icon]="
                    text == currentText && isPlaying
                      ? 'stop-circle-outline'
                      : 'play-circle-outline'
                  "
                ></nb-icon>
              </button>
              <textarea
                class="text-item"
                id="{{ type }}-text-item-{{ i }}-{{ j }}"
                fullWidth
                [(ngModel)]="dataText[i].text[j]"
                (click)="handleEdit(i, j)"
                [mentionConfig]="mentionConfig"
                (ngModelChange)="onChangeContentText(i, j)"
              ></textarea>
            </div>
            <div class="action-wrapper">
              <button
                ghost
                click
                (click)="handleRemovePrompt(i, j)"
                class="tbl-action"
                nbButton
                size="small"
              >
                <nb-icon icon="trash-2-outline"></nb-icon>
              </button>
              <div
                cdkDragHandle
                class="move-icon"
                style="display: flex; align-items: center"
              >
                <nb-icon
                  style="margin-right: -14px"
                  status="basic"
                  icon="more-vertical-outline"
                ></nb-icon>
                <nb-icon status="basic" icon="more-vertical-outline"></nb-icon>
              </div>
            </div>
          </nb-list-item>
        </nb-list>
        <div class="input-prompt-wrapper">
          <textarea
            nbInput
            id="{{ type }}-input-prompt-text-{{ i }}"
            class="input-prompt"
            placeholder="Enter Text For {{ data.name }}"
            fullWidth
            [(ngModel)]="newPrompt[i]"
            (ngModelChange)="onChangeContent(i)"
            [mentionConfig]="mentionConfig"
          ></textarea>
          <button
            title="Add prompt"
            status="primary"
            (click)="handleAddNewPrompt(i)"
            ghost
            nbButton
            size="small"
          >
            <nb-icon icon="plus-circle-outline"></nb-icon>
          </button>
        </div>
      </nb-accordion-item-body>
      <nb-accordion-item-body *ngIf="isChatBot">
        <nb-list
          cdkDropList
          class="example-list"
          (cdkDropListDropped)="dropBot($event, i)"
        >
          <nb-list-item class="label-prompt" style="margin-top: 1rem"
            ><span>Chat Bot</span>
            <nb-icon
              [nbPopover]="templateRef2"
              nbPopoverPlacement="bottom"
              nbPopoverTrigger="hover"
              class="ms-1"
              style="color: #929bb3; width: 15px"
              icon="question-mark-circle-outline"
            >
            </nb-icon
          ></nb-list-item>
          <nb-list-item
            class="example-box"
            *ngFor="
              let textBot of dataTextBot[i].textChatBot;
              trackBy: trackByFnBot;
              let k = index
            "
            cdkDrag
          >
            <div class="play-wrapper">
              <button
                class="icon-action"
                ghost
                (click)="convertTTS(i, k, 'bot')"
                type="button"
                size="small"
                status="primary"
                nbButton
              >
                <nb-icon
                  [icon]="
                    textBot == currentText && isPlaying
                      ? 'stop-circle-outline'
                      : 'play-circle-outline'
                  "
                ></nb-icon>
              </button>
              <textarea
                class="text-item"
                id="{{ type }}-text-item-bot-{{ i }}-{{ k }}"
                fullWidth
                (click)="handleEditBot(i, k)"
                [mentionConfig]="mentionConfig"
                [(ngModel)]="dataTextBot[i].textChatBot[k]"
                (ngModelChange)="onChangeContentTextBot(i, k)"
              ></textarea>
            </div>
            <div class="action-wrapper">
              <button
                ghost
                click
                (click)="handleRemovePromptBot(i, k)"
                class="tbl-action"
                nbButton
                status="primary"
                size="small"
              >
                <nb-icon icon="trash-2-outline"></nb-icon>
              </button>
              <div
                cdkDragHandle
                class="move-icon"
                style="display: flex; align-items: center"
              >
                <nb-icon
                  style="margin-right: -14px"
                  status="basic"
                  icon="more-vertical-outline"
                ></nb-icon>
                <nb-icon status="basic" icon="more-vertical-outline"></nb-icon>
              </div>
            </div>
          </nb-list-item>
        </nb-list>
        <div class="input-prompt-wrapper">
          <textarea
            nbInput
            id="{{ type }}-input-prompt-text-bot-{{ i }}"
            class="input-prompt"
            placeholder="Enter Text For {{ dataTextBot[i].name }}"
            fullWidth
            [(ngModel)]="newPromptBot[i]"
            (input)="onChangeContentBot(i)"
            [mentionConfig]="mentionConfig"
          ></textarea>
          <button
            title="Add prompt"
            status="primary"
            (click)="handleAddNewPromptBot(i)"
            ghost
            nbButton
            size="small"
          >
            <nb-icon icon="plus-circle-outline"></nb-icon>
          </button>
        </div>
      </nb-accordion-item-body>
    </nb-accordion-item>
  </nb-accordion>
</div>
<ng-template #templateRef>
  <nb-card style="max-width: 500px; margin-bottom: 0">
    <nb-card-body style="font-size: 13px">
      <div
        style="margin-bottom: 0.5rem"
        *ngIf="type == 'main' && isMainPromptAndCollect == false"
      >
        Play a text-to-speech message, or send a chat message. Messages will be
        played or sent randomly.
      </div>
      <div
        style="margin-bottom: 0.5rem"
        *ngIf="type != 'main' || isMainPromptAndCollect == true"
      >
        Play a text-to-speech message, or send a chat message. Messages will be
        played or sent in order.
      </div>
      <div style="margin-bottom: 0.5rem">
        <b>Using variables:</b> you can insert the variables to message by
        typing open curly braces '&#123;' and end by typing close curly braces
        '&#125;'. For example: Hello &#123;name_variable&#125;. How can I help
        you?
      </div>
      <div><b>Variable type:</b> if the type of variable is not a string:</div>
      <div style="margin-left: 1.5rem">
        &bull; Array or List: &#123;name_variable->0&#125;
      </div>
      <div style="margin-left: 1.5rem">
        &bull; Object: &#123;name_variable->key&#125;
      </div>
      <div style="margin-top: 0.5rem">
        <b>Read:</b> you can also specify how the variable is read:
      </div>
      <div style="margin-left: 1.5rem">
        &bull; Spell: &#123;name_variable->spell()&#125;
      </div>
      <div style="margin-left: 1.5rem">
        &bull; Say digits: &#123;name_variable->sayDigits()&#125;
      </div>
      <div style="margin-left: 1.5rem">
        &bull; Say number: &#123;name_variable->sayNumber()&#125;
      </div>
      <div style="margin-top: 0.5rem">
        <b>Operators:</b> you can also use some operators with variables:
        <a
          href="https://docs.primas.net/display/CAL/Variable"
          style="text-decoration: none"
          target="_blank"
          >Learn more</a
        >
      </div>
    </nb-card-body>
  </nb-card>
</ng-template>
<ng-template #templateRef2>
  <nb-card style="max-width: 500px; margin-bottom: 0">
    <nb-card-body style="font-size: 13px">
      <span
        >With chatbot: The system will prioritize using messages at
        ChatBot.</span
      >
    </nb-card-body>
  </nb-card>
</ng-template>
