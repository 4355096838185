<nb-card
  style="
    margin-bottom: 0;
    min-width: 500px;
    max-width: 500px;
    min-height: 600px;
    max-height: 90vh;
  "
>
  <nb-card-header
    style="display: flex; align-items: center; justify-content: space-between"
  >
    <span>OUTPUT</span>
    <div class="close-area">
      <div class="status">
        Status:
        <span
          [ngClass]="
            runFunctionResponse?.status == 'SUCCESS'
              ? 'color-success'
              : 'color-failed'
          "
          >{{
            runFunctionResponse?.status == "SUCCESS" ? "SUCCESS" : "FAILED"
          }}</span
        >
      </div>
      <div class="execute-time">
        Time:
        {{
          runFunctionResponse?.executeTime
            ? runFunctionResponse?.executeTime
            : 0
        }}
        ms
      </div>
      <button
        class="close-icon"
        type="button"
        nbButton
        ghost
        size="tiny"
        (click)="closeTerminal()"
      >
        <nb-icon icon="close-outline"></nb-icon>
      </button>
    </div>
  </nb-card-header>
  <nb-card-body>
    <ngx-json-viewer
      *ngIf="runFunctionResponse?.status == 'SUCCESS'"
      [json]="runFunctionResponse?.result"
    ></ngx-json-viewer>
  </nb-card-body>
</nb-card>
