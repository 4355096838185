import { saveAs } from "file-saver";
import {
  Component,
  OnInit,
  ViewChild,
  AfterViewInit,
  ElementRef,
} from "@angular/core";
import { MxCell, MxGraph } from "../../../../@core/model/mxgraph";
import { HttpRequestData } from "../../../../@core/model/http-request";
import {
  NbDialogService,
  NbGlobalPhysicalPosition,
  NbMenuService,
  NbToastrService,
  NbWindowRef,
} from "@nebular/theme";
import { DataService } from "../../../../@core/utils/data.service";
import { Observable, filter, map, of } from "rxjs";
import { DialogAttributeComponent } from "../../other/dialog-attribute/dialog-attribute.component";
import { NgSelectComponent } from "@ng-select/ng-select";
import * as ace from "ace-builds";
import "ace-builds/src-noconflict/ext-language_tools";
import { Clipboard } from "@angular/cdk/clipboard";
import { UntypedFormGroup } from "@angular/forms";
import {
  RxFormBuilder,
  RxwebValidators,
} from "@rxweb/reactive-form-validators";
import { CallFlowClient } from "../../../../System-api";
import { InputStaticComponent } from "../../other/input-static/input-static.component";
import { noWhitespaceValidator } from "../../../../@core/utils/helpers";
import { GraphHandlerService } from "../../../../@core/utils/graph.service";
import { Subscription } from "rxjs";

@Component({
  selector: "ngx-http-request",
  templateUrl: "./http-request.component.html",
  styleUrls: ["./http-request.component.scss"],
})
export class HttpRequestComponent implements OnInit, AfterViewInit {
  @ViewChild("editor") private editor: ElementRef<HTMLElement>;
  @ViewChild("editorResponse") private editorResponse: ElementRef<HTMLElement>;
  @ViewChild("paramsInput")
  paramsInput: InputStaticComponent;
  @ViewChild("paramsHeaders")
  paramsHeaders: InputStaticComponent;
  @ViewChild("paramsBody")
  paramsBody: InputStaticComponent;
  @ViewChild("paramsAttribute")
  paramsAttribute: InputStaticComponent;
  event: Subscription;
  cell: MxCell;
  graph: MxGraph;
  data: HttpRequestData = new HttpRequestData();
  pressAndSpeechCases: {
    press: string;
    text: string;
    display: string;
  }[] = [];
  attributes: any = this.dataService.ivrAttribute;
  ivrFunction: any = this.dataService.ivrFunction;
  mentionConfig = {
    items: this.attributes,
    triggerChar: "{",
    labelKey: "value",
    disableSort: true,
    mentionSelect: (item) => {
      return "{" + item.value + "}";
    },
  };
  cancelAction = false;
  headerParams: Observable<any[]> = of([
    { default: "Accept", value: "Accept" },
    { default: "Accept-Encoding", value: "Accept-Encoding" },
    { default: "Accept-Language", value: "Accept-Language" },
    { default: "Authorization", value: "Authorization" },
    { default: "Cache-Control", value: "Cache-Control" },
    { default: "Content-Type", value: "Content-Type" },
    { default: "Cookie", value: "Cookie" },
    { default: "Host", value: "Host" },
    { default: "Origin", value: "Origin" },
    { default: "Referer", value: "Referer" },
    { default: "User-Agent", value: "User-Agent" },
    { default: "ETag", value: "ETag" },
    { default: "If-Match", value: "If-Match" },
    { default: "If-None-Match", value: "If-None-Match" },
    { default: "Location", value: "Location" },
    { default: "Server", value: "Server" },
    { default: "WWW-Authenticate", value: "WWW-Authenticate" },
    { default: "X-Frame-Options", value: "X-Frame-Options" },
    { default: "X-Content-Type-Options", value: "X-Content-Type-Options" },
    { default: "X-XSS-Protection", value: "X-XSS-Protection" },
  ]);
  @ViewChild("selectComponents", { static: true })
  selectComponents: NgSelectComponent;
  @ViewChild("selectComponentsCode", { static: true })
  selectComponentsCode: NgSelectComponent;
  @ViewChild("selectComponentsHeader", { static: true })
  selectComponentsHeader: NgSelectComponent;
  filteredOptions$: Observable<any[]> = of(this.dataService.ivrAttribute);
  options: object[] = [
    { id: 1, name: "Key Pairs" },
    { id: 2, name: "Raw" },
  ];
  loadRaw = true;
  loadResponse = true;
  responseReview: string = "Incoming soon";
  showResponse = false;
  showStatus = false;
  typeResponse = "text";
  optionResponse = [
    { title: "Save Response To File" },
    { title: "Copy Response" },
    { title: "Clear Response" },
  ];
  selectedOptionLog = [];
  formGroup: UntypedFormGroup;
  LogData = [
    { name: "Params", value: "Params" },
    { name: "Headers", value: "Headers" },
    { name: "Body", value: "Body" },
    { name: "Response", value: "Response" },
  ];
  loadingRequest = false;
  responseData = null;
  method: string;
  duration: number;
  size: number;
  statusCode: number;
  colorStatus: string;
  url: string = "";
  listVariablesCreated: any = [];
  oldheaderResponse: string = null;
  oldresponse: string = null;
  oldstatusCode: string = null;
  constructor(
    protected windowRef: NbWindowRef,
    public dataService: DataService,
    private dialogService: NbDialogService,
    private nbMenuService: NbMenuService,
    private clipboard: Clipboard,
    private toastrService: NbToastrService,
    private callflowClient: CallFlowClient,
    private formBuilder: RxFormBuilder,
    private graphService: GraphHandlerService
  ) {
    this.createForm();
  }
  createForm() {
    this.formGroup = this.formBuilder.group({
      url: ["", [RxwebValidators.required(), noWhitespaceValidator]],
    });
  }

  setResponseReview(type) {
    if (this.loadResponse) {
      ace.config.set(
        "basePath",
        "https://unpkg.com/ace-builds@1.4.12/src-noconflict"
      );
      const aceEditor = ace.edit(this.editorResponse.nativeElement);
      aceEditor.getSession().setValue(this.responseReview);
      aceEditor.setTheme("ace/theme/xcode");
      aceEditor.session.setMode(`ace/mode/${type ? type : "json"}`);
      aceEditor.getSession().setUseWorker(true);
      aceEditor.setOptions({
        wrap: true,
      });
      aceEditor.on("change", () => {
        this.responseReview = aceEditor.getValue();
      });
      this.loadResponse = false;
    }
  }
  setBodyRaw(type) {
    if (this.loadRaw) {
      ace.config.set(
        "basePath",
        "https://unpkg.com/ace-builds@1.4.12/src-noconflict"
      );
      const aceEditor = ace.edit(this.editor.nativeElement);
      aceEditor.getSession().setValue(this.data.bodyRaw);
      aceEditor.setTheme("ace/theme/xcode");
      aceEditor.session.setMode(`ace/mode/${type ? type : "json"}`);
      aceEditor.getSession().setUseWorker(true);
      aceEditor.setOptions({
        wrap: true,
        enableBasicAutocompletion: true,
        enableSnippets: true,
        enableLiveAutocompletion: true,
      });
      aceEditor.on("change", () => {
        this.data.bodyRaw = aceEditor.getValue();
      });
      this.loadRaw = false;
    }
  }
  selectedOption(value) {
    this.data.selectedOptionLog = JSON.stringify(value);
    this.selectedOptionLog = JSON.parse(this.data.selectedOptionLog);
  }
  handleTypeBody(type) {
    const aceEditor = ace.edit(this.editor.nativeElement);
    aceEditor.session.setMode(`ace/mode/${type ? type : "json"}`);
  }
  handleTypeResponse(type) {
    const aceEditor = ace.edit(this.editorResponse.nativeElement);
    aceEditor.session.setMode(`ace/mode/${type ? type : "json"}`);
  }
  ngAfterViewInit(): void {
    if (this.data.option == 2) {
      this.setBodyRaw(this.data.typeRaw);
    }
  }

  selectVariable(value, key: string) {
    if (value?.default == "VARIABLE") {
      this.dialogService
        .open(DialogAttributeComponent, { autoFocus: false })
        .onClose.subscribe((rs) => {
          if (rs) {
            this.filteredOptions$ = this.filteredOptions$.pipe(
              map((options) => [...options])
            );
            switch (key) {
              case "statusCode":
                this.selectComponentsCode.writeValue(rs);
                this.oldstatusCode = rs;
                break;
              case "headerResponse":
                this.selectComponentsHeader.writeValue(rs);
                this.oldheaderResponse = rs;
                break;
              case "response":
                this.selectComponents.writeValue(rs);
                this.oldresponse = rs;
                break;
              default:
                break;
            }
            this.data[key] = rs;
          } else {
            switch (key) {
              case "statusCode":
                this.data.statusCode = this.oldstatusCode;
                break;
              case "headerResponse":
                this.data.headerResponse = this.oldheaderResponse;
                break;
              case "response":
                this.data.response = this.oldresponse;
                break;
              default:
                break;
            }
          }
        });
    } else {
      switch (key) {
        case "statusCode":
          this.oldstatusCode = value?.value;
          break;
        case "headerResponse":
          this.oldheaderResponse = value?.value;
          break;
        case "response":
          this.oldresponse = value?.value;
          break;
        default:
          break;
      }
    }
  }
  handleBody(value: number) {
    this.data.option = value;
    if (this.data.option == 2) {
      this.setBodyRaw(this.data.typeRaw);
    }
  }
  handleSaveAction() {
    this.windowRef.close();
  }
  handleCancelAction() {
    this.cancelAction = true;
    this.windowRef.close(true);
  }
  onChange(event) {
    this.attributes.find((x) => {
      if (event.match(`{${x.value}}`)) {
        if (x.content != undefined) {
          this.url = this.data.url.replace(`{${x.value}}`, `${x.content}`);
        } else this.url = this.data.url.replace(`{${x.value}}`, "");
      }
    });
    const textArea = document.getElementById("input-url");
    if (textArea) {
      if (event?.trim()) {
        textArea.style.height = `${textArea.scrollHeight}px`;
      } else {
        textArea.style.height = "32.4px";
      }
    }
  }
  ngOnInit() {
    for (let [key, value] of Object.entries(this.data)) {
      this.data[key] =
        this.cell.getAttribute(key) != undefined
          ? this.cell.getAttribute(key)
          : "";
    }
    if (
      this.data.timeout == "" ||
      !this.data.timeout ||
      parseFloat(this.data.timeout) < 0 ||
      !Number.isInteger(parseFloat(this.data.timeout))
    ) {
      this.data.timeout = "5";
    }
    if (
      !this.data.retry ||
      this.data.retry == "" ||
      parseFloat(this.data.retry) < 0 ||
      !Number.isInteger(parseFloat(this.data.retry))
    ) {
      this.data.retry = "0";
    }
    if (!this.data.params) {
      this.data.params = "";
    }
    if (!this.data.statusCode) {
      this.data.statusCode = null;
    }
    if (!this.data.headerResponse) {
      this.data.headerResponse = null;
    }

    if (!this.data.typeRaw) {
      this.data.typeRaw = "text";
    }
    if (!this.data.response) {
      this.data.response = null;
    }
    if (!this.data.bodyRaw) {
      this.data.bodyRaw = "";
    }
    if (this.data.log) {
      this.data.log = this.data.log.toString() === "true";
    } else {
      this.data.log = false;
    }
    if (this.data.option) {
      this.data.option = Number(this.data.option);
    } else {
      this.data.option = 1;
    }
    this.attributes.find((x) => {
      if (this.data.url.match(`{${x.value}}`)) {
        if (x.content != undefined) {
          this.url = this.data.url.replace(`{${x.value}}`, `${x.content}`);
        } else this.url = this.data.url.replace(`{${x.value}}`, "");
      }
      if (x.hasOwnProperty("content")) {
        this.listVariablesCreated.push(x);
      }
    });
    this.filteredOptions$ = this.filteredOptions$.pipe(
      map((options) => [{ default: "VARIABLE", value: "" }, ...options])
    );
    this.setVariableToList(this.data.response);
    this.setVariableToList(this.data.statusCode);
    this.setVariableToList(this.data.headerResponse);
    this.event = this.nbMenuService
      .onItemClick()
      .pipe(
        filter(({ tag }) => tag === "my-context-menu"),
        map(({ item: { title } }) => title)
      )
      .subscribe((title) => {
        switch (title) {
          case "Copy Response":
            this.clipboard.copy(this.responseReview);
            this.toastrService.show("Copied to Clipboard", "Notification", {
              position: NbGlobalPhysicalPosition.BOTTOM_LEFT,
              status: "success",
            });
            break;
          case "Clear Response":
            this.responseReview = "";
            this.showResponse = false;
            this.showStatus = false;
            break;
          case "Save Response To File":
            let blob = new Blob([this.responseReview], {
              type: "application/json",
            });
            let fileExtention = this.typeResponse;
            if (this.typeResponse == "text") fileExtention = "txt";
            saveAs(blob, `response.${fileExtention}`);
            break;
          default:
            break;
        }
      });
    if (this.data.selectedOptionLog) {
      this.selectedOptionLog = JSON.parse(this.data.selectedOptionLog);
    }
    this.windowRef.onClose.subscribe(() => this.submit());
  }
  setVariableToList(data) {
    if (data && data != "") {
      let dataExist = this.dataService.ivrAttribute.find(
        (x) => x.value == data
      );
      if (!dataExist && data.trim() != "") {
        this.filteredOptions$ = this.filteredOptions$.pipe(
          map((options) => [...options, { default: data, value: data }])
        );
      }
    }
  }
  sendRequest() {
    this.loadingRequest = true;
    this.paramsInput.getValue();
    this.paramsHeaders.getValue();
    this.paramsBody.getValue();
    this.paramsAttribute.getValue();
    var dataSend = Object.assign({}, this.data);
    dataSend.params = this.getProperty(dataSend.params);
    dataSend.headers = this.getProperty(dataSend.headers);
    dataSend.body = this.getProperty(dataSend.body);
    dataSend.bodyRaw = this.getProperty(dataSend.bodyRaw);
    if (this.url != "") {
      dataSend.url = this.url;
    }
    this.callflowClient.testRequest(dataSend as any).subscribe(
      (rs) => {
        this.method = rs.method;
        this.responseReview = rs.response;
        this.duration = rs.duration;
        this.size = rs.size;
        this.statusCode = rs.statusCode;
        this.loadingRequest = false;
        this.showResponse = true;
        if (this.method != "") {
          this.showStatus = true;
          const color = this.statusCode / 100;
          if (color === 1) {
            this.colorStatus = "info";
          } else if (color === 2) {
            this.colorStatus = "success";
          } else if (color === 3) {
            this.colorStatus = "redirection";
          } else {
            this.colorStatus = "error";
          }
        } else this.showStatus = false;
        this.loadResponse = true;
        this.setResponseReview("text");
      },
      (error) => {
        this.loadingRequest = false;
      }
    );
  }
  getProperty(property) {
    this.listVariablesCreated.forEach((replacement) => {
      const regex = new RegExp(`\\{${replacement.value}\\}`, "g");
      property = property.replace(regex, replacement.content);
    });
    return property;
  }
  submit() {
    if (!this.cancelAction) {
      if (
        !this.data.retry ||
        this.data.retry == "" ||
        parseFloat(this.data.retry) < 0 ||
        !Number.isInteger(parseFloat(this.data.retry))
      ) {
        this.data.retry = "0";
      }
      if (parseFloat(this.data.retry) > 10) {
        this.data.retry = "10";
      }
      for (let [key, value] of Object.entries(this.data)) {
        if (typeof this.data[key] == "string") {
          value = value?.trim();
        }
        this.cell.setAttribute(key, value || "");
      }
      const httpRequestCell = this.graph.getModel().getCell(this.cell.getId());
      const fieldsRequired = [this.data.url.trim()];
      const check = this.graphService.checkIsRequired(
        this.graph,
        fieldsRequired,
        httpRequestCell
      );
      this.cell.setAttribute("checkFields", check?.toString());
    }
  }

  getAttribute($event) {
    this.data.attribute = $event;
  }

  getHttpHeaders($event) {
    this.data.headers = $event;
  }

  getHttpBody($event) {
    this.data.body = $event;
  }
  getHttpParams($event) {
    this.data.params = $event;
  }

  drop($event) {
    this.data.url += "{" + $event.dataTransfer.getData("text").trim() + "}";
    $event.preventDefault();
  }

  ngOnDestroy() {
    this.event.unsubscribe();
  }
}
