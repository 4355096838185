import { Component, OnInit, Output, EventEmitter, OnDestroy, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Observable, map } from 'rxjs';
import { DataService } from '../../../../../@core/utils/data.service';
import { EventBusService } from '../../../../../@core/utils/eventbus.service';
import { ValidInput } from '../../../../../@core/utils/helpers';
import { FormArray, FormControl, FormGroup, UntypedFormGroup } from '@angular/forms';
import { RxFormBuilder, RxwebValidators } from '@rxweb/reactive-form-validators';

@Component({
  selector: 'ngx-input-param',
  templateUrl: './input-param.component.html',
  styleUrls: ['./input-param.component.scss']
})
export class InputAttributeComponent implements OnInit, OnDestroy, OnChanges {

  @Output() paramEmit = new EventEmitter<string>();
  @Output() errorEmit = new EventEmitter<boolean>();

  @Input() paramInput: string;
  @Input() paramName: string;

  filteredOptions$: Observable<string[]>;
  params: Param[] = [];
  inputParamForm = new FormGroup({
    nameForm: new FormArray([]),
  });
  formStatus: any;

  constructor(
    public dataService: DataService,
    private eventBusService: EventBusService,
    private formBuilder: RxFormBuilder,
  ) {
    this.eventBusService.on('ClickSubmitCustomFunction', (isTrue: boolean) => {
      if (isTrue) {
        this.paramEmit.emit(this.paramToString());
      }
    });
  }

  ngOnDestroy(): void {
    this.paramEmit.emit(this.paramToString());
  }

  ngOnInit() {
    this.formStatus = this.inputParamForm.statusChanges.pipe(
      map((v) => {
        v == "INVALID" ? this.errorEmit.emit(true) : this.errorEmit.emit(false)
      })
    );
  }

  get nameFormGroups() {
    return this.inputParamForm.get('nameForm') as FormArray;
  }

  ngOnChanges(changes: SimpleChanges): void {
    const changeParam = changes.paramInput;
    if (changeParam && changeParam.currentValue != changeParam.previousValue) {
      this.stringToParam();
    }
  }

  addParam() {
    let paramArray = this.inputParamForm.get('nameForm') as FormArray;
    let newNameGroup: FormGroup = this.formBuilder.group(
      {
        name: new FormControl('', ValidInput.variable),
      },
    );
    paramArray.push(newNameGroup);
  }

  paramToString() {
    var array = new Array();
    this.inputParamForm.value?.nameForm?.forEach((element) => {
      if (element.name) {
        let objString = element.name
        array.push(objString);
      }
    });
    return array.join('|');
  }

  removeParam(index) {
    let paramArray = this.inputParamForm.get('nameForm') as FormArray;
    paramArray.removeAt(index);
    this.paramEmit.emit(this.paramToString());
  }

  stringToParam() {
    if (this.paramInput != '' && this.paramInput != undefined) {
      this.params = [];
      let inputparamArray = this.inputParamForm.get('nameForm') as FormArray;
      inputparamArray.clear();
      let arrayString = this.paramInput.split('|');
      arrayString.forEach(element => {
        let dto = element.split('=');
        let newNameGroup: FormGroup = this.formBuilder.group({
          name: new FormControl('', ValidInput.variable),
        });
        newNameGroup.get('name')?.setValue(dto[0]);
        inputparamArray.push(newNameGroup);
      });
    }
  }
}

export class Param {
  name: string;
}
