import { IApplicationData } from "./common";
export class AnswerDetect implements IApplicationData {
  constructor() {
    this.name = "AMD";
    this.option = 1;
    this.audioId = "";
    this.audioName = "";
    this.audioPath = "";
    this.text = "";
    this.textChatBot = "";
    this.initialSilence = 2500;
    this.greeting = 1500;
    this.afterGreetingSilence = 800;
    this.totalAnalysisTime = 5000;
    this.miniumWordLength = 100;
    this.betweenWordSilence = 50;
    this.maximumNumberOfWords = 3;
    this.silenceThreshold = 256;
    this.maximumWordLength = 500;
    this.multipleCase = true;
    this.transition = true;
    this.attribute = "";
    this.isRequired = true;
  }
  name: string;
  option: number;
  audioId: string;
  attribute: string;
  audioName: string;
  audioPath: string;
  text: string;
  textChatBot: string;
  initialSilence: number;
  greeting: number;
  afterGreetingSilence: number;
  totalAnalysisTime: number;
  miniumWordLength: number;
  betweenWordSilence: number;
  maximumNumberOfWords: number;
  silenceThreshold: number;
  maximumWordLength: number;
  multipleCase: boolean;
  transition: boolean;
  isRequired: boolean;
}
