import { Clipboard } from "@angular/cdk/clipboard";
import { Component, OnInit, ViewChild } from "@angular/core";
import { UntypedFormGroup, Validators } from "@angular/forms";
import {
  NbDialogRef,
  NbDialogService,
  NbGlobalPhysicalPosition,
  NbPopoverDirective,
  NbToastrService,
} from "@nebular/theme";
import {
  RxFormBuilder,
  RxwebValidators,
} from "@rxweb/reactive-form-validators";
import { ValidInput } from "../../@core/utils/helpers";
import { ModalScriptComponent } from "./modal-script/modal-script.component";
import { HttpClient } from "@angular/common/http";

@Component({
  selector: "generate-script",
  templateUrl: "./generate-script.component.html",
  styleUrls: ["./generate-script.component.scss"],
})
export class GenerateScriptComponent implements OnInit {
  @ViewChild(NbPopoverDirective) popover: NbPopoverDirective;
  prominence = [
    { name: "Button", value: "0", des: "Show only button to launch the chat." },
    {
      name: "Chat plugin window",
      value: "1",
      des: "Automatically expand the entire Chat Plugin window on the website.",
    },
  ];
  siteArray = [
    { name: "Right", value: "0" },
    {
      name: "Left",
      value: "1",
    },
  ];
  chatToken = "";
  src = "";
  script = "";
  show = "0";
  color = "#1FAD53";
  posA = "20";
  posB = "20";
  width = "375";
  height = "550";
  icon = "60";
  site = "0";
  name = "";
  logo = "";
  greeting = "";
  dataCallFlow = null;
  form: UntypedFormGroup;
  logoDefault = "../../../assets/images/logo-bot-default.png";
  constructor(
    protected ref: NbDialogRef<GenerateScriptComponent>,
    private clipboard: Clipboard,
    private toastrService: NbToastrService,
    private formBuilder: RxFormBuilder,
    private dialogService: NbDialogService
  ) {
    this.createForm();
  }

  copy() {
    this.clipboard.copy(this.script);
    this.toastrService.show("Copied to Clipboard", "Notification", {
      position: NbGlobalPhysicalPosition.BOTTOM_LEFT,
      status: "success",
    });
  }

  ngOnInit() {
    this.name =
      this.dataCallFlow.callSettings[0].botName?.trim() ||
      this.dataCallFlow.name?.trim();
    this.logo = this.dataCallFlow.callSettings[0].botLogo?.trim() || "";
    this.greeting =
      this.dataCallFlow.callSettings[0].botGreetingMessages?.trim() || "";
    if (this.chatToken) {
      const searchStr = "botconnector/";
      const index = this.chatToken.indexOf(searchStr);
      if (index !== -1) {
        this.src =
          this.chatToken.substring(0, index + searchStr.length) +
          "static/js/primas.chat.min.js";
      }
    }
    this.update();
  }
  dismiss() {
    this.ref.close();
  }

  showPopover() {
    this.popover?.toggle();
  }
  view() {}
  update() {
    let defaultName =
      this.dataCallFlow.callSettings[0].botName?.trim() ||
      this.dataCallFlow.name?.trim();
    let logo = this.logo?.trim() ? "&logoUrl=" + this.logo.trim() : "";
    let logoParams = this.logo?.trim()
      ? `logo-chatbox="` + this.logo.trim() + `"`
      : "";
    let greetingMessages = this.greeting?.trim()
      ? `greeting="` + this.greeting.trim() + `"`
      : "";
    let name = this.name?.trim()
      ? "&name=" + this.name.trim()
      : "&name=" + defaultName;
    this.script = `<script id="primas-chat-script" type="text/javascript" src="${
      this.src
    }"
    chat-token="${this.chatToken + "?displayControl=1" + name + logo}" pos-a="${
      this.posA || "20"
    }" pos-b="${this.posB || "20"}"
    c-color="${this.color.slice(1) || "1FAD53"}" p-width="${
      this.width || "375"
    }" p-height="${this.height || "550"}" c-size="${this.icon || "60"}" show="${
      this.show || "0"
    }" c-site="${
      this.site || "1"
    }" ${logoParams} ${greetingMessages} ></script>`;
  }
  reset() {
    this.show = "0";
    this.color = "#1FAD53";
    this.posA = "20";
    this.posB = "20";
    this.width = "375";
    this.height = "550";
    this.icon = "60";
    this.site = "0";
    this.name =
      this.dataCallFlow.callSettings[0].botName?.trim() ||
      this.dataCallFlow.name?.trim();
    this.logo = this.dataCallFlow.callSettings[0].botLogo?.trim() || "";
    this.greeting =
      this.dataCallFlow.callSettings[0].botGreetingMessages?.trim() || "";
    this.update();
  }
  done() {
    this.update();
    this.dialogService
      .open(ModalScriptComponent, {
        autoFocus: false,
        context: { script: this.script },
      })
      .onClose.subscribe((rs) => {
        if (rs == false) {
          this.dismiss();
        }
      });
  }
  createForm() {
    this.form = this.formBuilder.group({
      show: [""],
      site: [""],
      color: [""],
      name: [""],
      logo: [""],
      greeting: [""],
      width: [
        "",
        [
          RxwebValidators.required(),
          RxwebValidators.maxLength({
            value: 3,
          }),
          RxwebValidators.digit(),
        ],
      ],
      height: [
        "",
        [
          RxwebValidators.required(),
          RxwebValidators.maxLength({
            value: 3,
          }),
          RxwebValidators.digit(),
        ],
      ],
      posB: [
        "",
        [
          RxwebValidators.required(),
          RxwebValidators.maxLength({
            value: 3,
          }),
          RxwebValidators.digit(),
        ],
      ],
      posA: [
        "",
        [
          RxwebValidators.required(),
          RxwebValidators.maxLength({
            value: 3,
          }),
          RxwebValidators.digit(),
        ],
      ],
      icon: [
        "",
        [
          RxwebValidators.required(),
          RxwebValidators.maxLength({
            value: 3,
          }),
          RxwebValidators.digit(),
        ],
      ],
    });
  }
}
