import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
} from "@angular/core";
import { DataTablePagerComponent as SuperDataTablePagerComponent } from "@swimlane/ngx-datatable";

@Component({
  selector: "footer-table",
  template: `
    <div
      style="  display: flex;
  align-items: center;
  justify-content: space-between;"
    >
      <span> Total: {{ count }} </span>
      <ul class="pager">
        <li
          style="margin-right: 1rem !important; font-weight:600; display: inline-flex; align-items:center; gap:0.25rem; font-size:13px"
        >
          <span style="margin-right: 0.5rem">Page:</span>

          <div style="min-width:5.5rem; width:5.5rem; height:2rem">
            <ng-select
              style="min-width:5.5rem; width:5.5rem; position:absolute;"
              [items]="pageArray"
              bindLabel="value"
              bindValue="value"
              notFoundText="Not found"
              (change)="selectPage($event)"
              [(ngModel)]="_pageTemp"
            >
            </ng-select>
          </div>

          <span style="margin-left: 0.5rem !important">of</span>
          <span>{{ totalPages }}</span>
        </li>
        <li [class.disabled]="!canPrevious()">
          <a href="javascript:void(0)" (click)="selectPage(1)">
            <i class="{{ pagerPreviousIcon }}"></i>
          </a>
        </li>
        <li [class.disabled]="!canPrevious()">
          <a href="javascript:void(0)" (click)="prevPage()">
            <i class="{{ pagerLeftArrowIcon }}"></i>
          </a>
        </li>
        <li
          class="pages"
          *ngFor="let pg of pages"
          [class.active]="pg.number === page"
        >
          <a href="javascript:void(0)" (click)="selectPage(pg.number)">
            {{ pg.text }}
          </a>
        </li>
        <li [class.disabled]="!canNext()">
          <a href="javascript:void(0)" (click)="nextPage()">
            <i class="{{ pagerRightArrowIcon }}"></i>
          </a>
        </li>
        <li [class.disabled]="!canNext()">
          <a href="javascript:void(0)" (click)="selectPage(totalPages)">
            <i class="{{ pagerNextIcon }}"></i>
          </a>
        </li>
      </ul>
    </div>
  `,
  host: {
    class: "datatable-pager",
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DataTablePagerComponent extends SuperDataTablePagerComponent {
  @Input() pagerLeftArrowIcon: string = "datatable-icon-left";
  @Input() pagerRightArrowIcon: string = "datatable-icon-right";
  @Input() pagerPreviousIcon: string = "datatable-icon-prev";
  @Input() pagerNextIcon: string = "datatable-icon-skip";

  @Input()
  set size(val: number) {
    this._size = val;
    this.pages = this.calcPages();
  }

  get size(): number {
    return this._size;
  }

  @Input()
  set count(val: number) {
    this._count = val;
    this.pages = this.calcPages();
  }

  get count(): number {
    return this._count;
  }

  @Input()
  set page(val: number) {
    this._page = val;
    this._pageTemp = val;
    this.pages = this.calcPages();
  }

  get page(): number {
    return this._page;
  }

  get totalPages(): number {
    const count = this.size < 1 ? 1 : Math.ceil(this.count / this.size);

    return Math.max(count || 0, 1);
  }

  @Output() change: EventEmitter<any> = new EventEmitter();

  public _visiblePagesCount: number = 3;

  @Input()
  set visiblePagesCount(val: number) {
    this._visiblePagesCount = val;
    this.pages = this.calcPages();
  }

  get visiblePagesCount(): number {
    return this._visiblePagesCount;
  }

  _count: number = 0;
  _page: number = 1;
  _pageTemp: number = 1;
  _size: number = 0;
  pages: any;
  pageArray: any = [];
  canPrevious(): boolean {
    return this.page > 1;
  }

  canNext(): boolean {
    return this.page < this.totalPages;
  }

  prevPage(): void {
    this.selectPage(this.page - 1);
  }

  nextPage(): void {
    this.selectPage(this.page + 1);
  }

  selectPage(page: number): void {
    if (page && page > 0 && page <= this.totalPages && page !== this.page) {
      this.page = page;
      this.change.emit({
        page,
      });
    }
  }

  calcPages(page?: number): any[] {
    this.pageArray = Array(this.totalPages)
      .fill(0)
      .map((x, i) => i + 1);
    const pages = [];
    let startPage = 1;
    let endPage = this.totalPages;
    const maxSize = Number(this.visiblePagesCount);
    const isMaxSized = maxSize < this.totalPages;

    page = page || this.page;

    if (isMaxSized) {
      startPage = page - Math.floor(maxSize / 2);
      endPage = page + Math.floor(maxSize / 2);

      if (startPage < 1) {
        startPage = 1;
        endPage = Math.min(startPage + maxSize - 1, this.totalPages);
      } else if (endPage > this.totalPages) {
        startPage = Math.max(this.totalPages - maxSize + 1, 1);
        endPage = this.totalPages;
      }
    }

    for (let num = startPage; num <= endPage; num++) {
      pages.push({
        number: num,
        text: <string>(<any>num),
      });
    }

    return pages;
  }
}
