<div class="action-config-wrapper" style="max-width: 550px">
  <div class="config-action">
    <nb-radio-group
      [(ngModel)]="data.option"
      class="choice-option"
      (ngModelChange)="handleAudio($event)"
    >
      <nb-radio *ngFor="let option of options" [value]="option.id"
        >{{option.name}}</nb-radio
      >
    </nb-radio-group>
    <div
      class="form-group"
      [ngClass]="{'show': data.option == 1}"
      style="padding: 1rem 0; width: 100%"
    >
      <text-to-speech #textToSpeech></text-to-speech>
      <nb-card class="select-audio" [ngClass]="{'show': data.option == 1}">
        <nb-card-header class="audio-list-style">
          <span>Audio List</span>
          <nb-icon
            class="ms-2"
            style="color: #929bb3; width: 15px"
            nbTooltip="Select the audio you want to play. The audio list will play in order and continuously. If no audio is selected, the system will use text to speech."
            nbTooltipPlacement="bottom"
            nbTooltipStatus="basic"
            nbTooltipTrigger="hover"
            icon="question-mark-circle-outline"
          >
          </nb-icon
        ></nb-card-header>
        <nb-card-body>
          <simple-audio-player
            #audioPlayer
            [events]="eventsSubject.asObservable()"
          ></simple-audio-player>
          <button nbButton size="small" status="primary" (click)="showModify()">
            <nb-icon icon="plus-circle-outline"></nb-icon> Add
          </button>
        </nb-card-body>
      </nb-card>
    </div>
    <div class="row" [ngClass]="{'padding-wrap': data.option == 1}">
      <div class="mb-2 col-md-6" style="display: flex; align-items: center">
        <nb-checkbox
          (checkedChange)="allowBreak($event)"
          [checked]="data.allowBreak"
        >
          Allow Break Prompt
        </nb-checkbox>
        <nb-icon
          class="ms-2"
          style="color: #929bb3; width: 15px; margin-bottom: 2px"
          nbTooltip="Allows the caller to press any key to break current prompt."
          nbTooltipPlacement="bottom"
          nbTooltipStatus="basic"
          nbTooltipTrigger="hover"
          icon="question-mark-circle-outline"
        >
        </nb-icon>
      </div>
      <div class="mb-2 col-md-6" style="display: flex; align-items: center">
        <nb-checkbox (checkedChange)="allowLog($event)" [checked]="data.log">
          Enable Log
        </nb-checkbox>
        <nb-icon
          class="ms-2"
          style="color: #929bb3; width: 15px; margin-bottom: 2px"
          nbTooltip="Logging of prompts information."
          nbTooltipPlacement="bottom"
          nbTooltipStatus="basic"
          nbTooltipTrigger="hover"
          icon="question-mark-circle-outline"
        >
        </nb-icon>
      </div>
    </div>
    <div
      class="mb-3"
      style="display: flex; flex-direction: column; margin-top: 0.5rem"
    >
      <label for="name" class="form-label label">Choose Videobot</label>
      <sentiment-select
        placeHolder="Choose Videobot"
        (valueSelected)="handleSentiment($event)"
        [value]="data.sentiment"
        defaultValue="normal-speak"
      ></sentiment-select>
    </div>
  </div>
  <footer-action
    (handleCancelAction)="handleCancelAction()"
    (handleSaveAction)="handleSaveAction()"
  ></footer-action>
</div>
