import { NgModule } from "@angular/core";
import {
  NbCardModule,
  NbButtonModule,
  NbActionsModule,
  NbTabsetModule,
  NbChatModule,
  NbPopoverModule,
} from "@nebular/theme";
import { SharedModule } from "../../shared/shared.module";
import { SoftPhoneComponent } from "./soft-phone.component";
import { NgxTimerModule } from "ngx-timer";
import { IncommingDialogComponent } from "./incomming-dialog/incomming-dialog.component";
import { MainScreenPhoneComponent } from "./main-screen-phone/main-screen-phone.component";
import { SipLoginComponent } from "./sip-login/sip-login.component";

@NgModule({
  imports: [
    NbCardModule,
    SharedModule,
    NbButtonModule,
    NbActionsModule,
    NgxTimerModule,
    NbTabsetModule,
    NbChatModule,
    NbPopoverModule
  ],
  exports: [SoftPhoneComponent],
  declarations: [
    IncommingDialogComponent,
    MainScreenPhoneComponent,
    SoftPhoneComponent,
    SipLoginComponent
  ],
})
export class SoftPhoneModule {}
