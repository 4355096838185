<div class="action-config-wrapper" style="max-width: 590px">
  <div class="config-action">
    <div class="method-http">
      <div class="method-http-item mb-2">
        <label class="form-label label">Method</label>
        <nb-select
          placeholder="Select Method"
          size="small"
          [(ngModel)]="data.method"
          class="method-option"
        >
          <nb-option value="GET">GET</nb-option>
          <nb-option value="POST">POST</nb-option>
          <nb-option value="PUT">PUT</nb-option>
        </nb-select>
      </div>
      <div class="method-http-item mb-2" style="flex: 1">
        <label for="input-url" class="form-label label required"
          >Request URL</label
        >
        <form [formGroup]="formGroup">
          <textarea
            formControlName="url"
            (drop)="drop($event)"
            type="text"
            nbInput
            fullWidth
            [(ngModel)]="data.url"
            (ngModelChange)="onChange($event)"
            id="input-url"
            placeholder="Request URL"
            fieldSize="small"
            style="height: 32.4px; max-height: 150px; min-height: 32.4px"
            [mention]="attributes"
            [mentionConfig]="mentionConfig"
            [status]="
              formGroup.controls['url'].invalid &&
              (formGroup.controls['url'].dirty ||
                formGroup.controls['url'].touched)
                ? 'danger'
                : 'basic'
            "
          >
          </textarea>
          <ng-container
            *ngIf="
              formGroup.controls['url'].invalid &&
              (formGroup.controls['url'].dirty ||
                formGroup.controls['url'].touched)
            "
          >
            <p
              class="caption status-danger"
              style="margin-bottom: 0"
              *ngIf="
                formGroup.controls['url'].hasError('required') ||
                formGroup.controls['url'].hasError('whitespace')
              "
            >
              Request URL is required.
            </p>
          </ng-container>
        </form>
      </div>
      <div class="method-http-item mb-2">
        <button
          [nbSpinner]="loadingRequest"
          [disabled]="loadingRequest"
          nbSpinnerStatus="primary"
          title="Send Request"
          nbButton
          status="primary"
          size="small"
          style="margin-top: 1.5rem"
          (click)="sendRequest()"
        >
          Send
        </button>
      </div>
    </div>
    <label class="form-label label">Request Options</label>
    <nb-tabset style="height: 100%">
      <nb-tab tabTitle="Params">
        <ngx-input-static
          #paramsInput
          title="params"
          [propertyInput]="data.params"
          propertyName="Key"
          propertyValue="Value"
          (propertyEmit)="getHttpParams($event)"
        ></ngx-input-static>
      </nb-tab>
      <nb-tab tabTitle="Headers">
        <ngx-input-static
          #paramsHeaders
          title="headers"
          selectAttribute="true"
          [disabledAddNew]="true"
          [propertyInput]="data.headers"
          [filteredOptions$]="headerParams"
          propertyName="Key"
          propertyValue="Value"
          (propertyEmit)="getHttpHeaders($event)"
        ></ngx-input-static>
      </nb-tab>
      <nb-tab tabTitle="Body">
        <div class="action-group-http">
          <nb-radio-group
            [(ngModel)]="data.option"
            class="choice-option"
            (ngModelChange)="handleBody($event)"
          >
            <nb-radio *ngFor="let option of options" [value]="option.id">{{
              option.name
            }}</nb-radio>
          </nb-radio-group>
          <nb-select
            size="small"
            [(ngModel)]="data.typeRaw"
            (ngModelChange)="handleTypeBody($event)"
            *ngIf="data.option == 2"
          >
            <nb-option value="text">Text</nb-option>
            <nb-option value="json">JSON</nb-option>
            <nb-option value="xml">XML</nb-option>
            <nb-option value="html">HTML</nb-option>
          </nb-select>
        </div>
        <ngx-input-static
          #paramsBody
          class="hidden"
          [ngClass]="{ show: data.option == 1 }"
          [propertyInput]="data.body"
          propertyName="Key"
          propertyValue="Value"
          (propertyEmit)="getHttpBody($event)"
        ></ngx-input-static>
        <div
          class="app-ace-editor hidden"
          [ngClass]="{ show: data.option == 2 }"
          #editor
          style="
            width: 100%;
            min-height: 400px;
            font-size: 14px;
            border: 1px solid lightgray;
          "
        ></div>
      </nb-tab>
      <nb-tab tabTitle="Response">
        <div class="flex-title">
          <span class="title">Response</span>
          <span class="line"></span>
        </div>
        <div
          class="response-status show-flex-status"
          [ngClass]="{ hidden: showResponse }"
        >
          <span>Click Send to get a response</span>
        </div>
        <div
          class="mb-2 response-wrap hidden"
          [ngClass]="{
            'show-flex': showResponse,
            'response-item-end': !showStatus
          }"
        >
          <div class="response-item-wrap" *ngIf="showStatus">
            <div class="response-item">
              <span>Status: </span>
              <span class="response-item-value-{{ colorStatus }}">{{
                statusCode
              }}</span>
            </div>
            <div class="response-item">
              <span>Time: </span>
              <span class="response-item-value-{{ colorStatus }}"
                >{{ duration }}ms</span
              >
            </div>
            <div class="response-item">
              <span>Size: </span>
              <span class="response-item-value-{{ colorStatus }}"
                >{{ size }}B</span
              >
            </div>
          </div>
          <div class="response-item">
            <nb-select
              size="small"
              [(ngModel)]="typeResponse"
              (ngModelChange)="handleTypeResponse($event)"
            >
              <nb-option value="text">Text</nb-option>
              <nb-option value="json">JSON</nb-option>
              <nb-option value="xml">XML</nb-option>
              <nb-option value="html">HTML</nb-option>
            </nb-select>
            <button
              nbButton
              size="small"
              type="button"
              [nbContextMenu]="optionResponse"
              nbContextMenuTag="my-context-menu"
            >
              <nb-icon icon="more-horizontal-outline"></nb-icon>
            </button>
          </div>
        </div>
        <div
          class="hidden"
          [ngClass]="{ show: showResponse }"
          #editorResponse
          style="
            width: 100%;
            min-height: 300px;
            font-size: 14px;
            border: 1px solid lightgray;
          "
        ></div>
        <div class="flex-title" style="margin-top: 1rem">
          <span class="title">Set Variable</span>
          <span class="line"></span>
        </div>
        <div class="row">
          <div class="mb-2">
            <label class="form-label label">Status Code</label>
            <ng-select
              #selectComponentsCode
              [items]="filteredOptions$ | async"
              [addTag]="true"
              fullWidth
              placeholder="Select Status Code"
              [(ngModel)]="data.statusCode"
              bindLabel="value"
              bindValue="value"
              (change)="selectVariable($event, 'statusCode')"
              addTagText="Custom variable"
              [editableSearchTerm]="true"
            >
              <ng-template
                ng-option-tmp
                let-item="item"
                let-index="index"
                let-search="searchTerm"
              >
                <div *ngIf="item.default == 'VARIABLE'" class="add-attribute">
                  <nb-icon icon="plus-circle-outline"></nb-icon>
                  <span>VARIABLE</span>
                </div>
                <div
                  *ngIf="item.default != 'VARIABLE'"
                  style="word-break: break-word; white-space: normal"
                >
                  {{ item.value || data.statusCode }}
                </div>
              </ng-template>
              >
            </ng-select>
          </div>
        </div>
        <div class="row">
          <div class="mb-2">
            <label class="form-label label">Header Response</label>
            <ng-select
              #selectComponentsHeader
              [items]="filteredOptions$ | async"
              [addTag]="true"
              fullWidth
              placeholder="Select Header Response"
              [(ngModel)]="data.headerResponse"
              bindLabel="value"
              bindValue="value"
              (change)="selectVariable($event, 'headerResponse')"
              addTagText="Custom variable"
              [editableSearchTerm]="true"
            >
              <ng-template
                ng-option-tmp
                let-item="item"
                let-index="index"
                let-search="searchTerm"
              >
                <div *ngIf="item.default == 'VARIABLE'" class="add-attribute">
                  <nb-icon icon="plus-circle-outline"></nb-icon>
                  <span>VARIABLE</span>
                </div>
                <div
                  *ngIf="item.default != 'VARIABLE'"
                  style="word-break: break-word; white-space: normal"
                >
                  {{ item.value || data.headerResponse }}
                </div>
              </ng-template>
              >
            </ng-select>
          </div>
        </div>
        <div class="row">
          <div class="mb-2">
            <label class="form-label label">Body Response</label>
            <ng-select
              #selectComponents
              [items]="filteredOptions$ | async"
              [addTag]="true"
              fullWidth
              placeholder="Select Body Response"
              [(ngModel)]="data.response"
              bindLabel="value"
              bindValue="value"
              (change)="selectVariable($event, 'response')"
              addTagText="Custom variable"
              [editableSearchTerm]="true"
            >
              <ng-template
                ng-option-tmp
                let-item="item"
                let-index="index"
                let-search="searchTerm"
              >
                <div *ngIf="item.default == 'VARIABLE'" class="add-attribute">
                  <nb-icon icon="plus-circle-outline"></nb-icon>
                  <span>VARIABLE</span>
                </div>
                <div
                  *ngIf="item.default != 'VARIABLE'"
                  style="word-break: break-word; white-space: normal"
                >
                  {{ item.value || data.response }}
                </div>
              </ng-template>
              >
            </ng-select>
          </div>
        </div>
        <div class="flex-title" style="margin-top: 1rem">
          <span class="title">Set Response Variable</span>
          <span class="line"></span>
        </div>
        <ngx-input-static
          #paramsAttribute
          selectAttribute="true"
          [propertyInput]="data.attribute"
          propertyName="Flow Variable"
          propertyValue="Response Key"
          (propertyEmit)="getAttribute($event)"
        ></ngx-input-static>
      </nb-tab>
      <nb-tab tabTitle="Settings">
        <div class="row">
          <div class="mb-2">
            <div class="flex-title">
              <span class="title">Settings</span>
              <span class="line"></span>
            </div>
            <div class="mb-2">
              <label for="timeout" class="form-label label"
                >Request Timeout (s)</label
              >
              <input
                id="timeout"
                [(ngModel)]="data.timeout"
                type="number"
                min="0"
                nbInput
                fullWidth
                placeholder="Request Timeout"
                fieldSize="small"
              />
            </div>
            <div>
              <label
                for="retry"
                class="form-label d-flex label"
                style="align-items: center"
                ><span>Max Retries</span>
                <nb-icon
                  class="ms-1"
                  style="width: 15px"
                  nbTooltip="The limit number of retries when has error."
                  nbTooltipPlacement="end"
                  nbTooltipStatus="basic"
                  icon="question-mark-circle-outline"
                >
                </nb-icon
              ></label>
              <input
                id="retry"
                [(ngModel)]="data.retry"
                type="number"
                min="0"
                nbInput
                fullWidth
                placeholder="Max Retries"
                fieldSize="small"
              />
            </div>

            <div
              class="flex-title mt-2"
              style="
                margin-top: 1rem !important;
                margin-bottom: 1rem !important;
              "
            >
              <span class="title">Log</span>
              <span class="line"></span>
            </div>
            <div class="mb-2">
              <nb-toggle status="basic" class="mb-2" [(ngModel)]="data.log"
                >Write log
              </nb-toggle>
            </div>
            <div *ngIf="data.log">
              <nb-select
                multiple
                placeholder="Select Option"
                size="small"
                [ngModel]="selectedOptionLog"
                (ngModelChange)="selectedOption($event)"
                fullWidth
              >
                <nb-option *ngFor="let item of LogData" [value]="item.name">{{
                  item.name
                }}</nb-option>
              </nb-select>
            </div>
          </div>
        </div>
      </nb-tab>
    </nb-tabset>
  </div>
  <footer-action
    (handleCancelAction)="handleCancelAction()"
    (handleSaveAction)="handleSaveAction()"
  ></footer-action>
</div>
