import { DataService } from "./../../../../@core/utils/data.service";
import { Component, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { NbDialogService, NbWindowRef } from "@nebular/theme";
import { MxCell, MxGraph } from "../../../../@core/model/mxgraph";
import { RedirectCallflow } from "../../../../@core/model/redirectcallflow";
import { CallFlowClient, CallFlowDto } from "../../../../System-api";
import { DialogAttributeComponent } from "../../other/dialog-attribute/dialog-attribute.component";
import { Observable, of } from "rxjs";
import {
  RxFormBuilder,
  RxwebValidators,
} from "@rxweb/reactive-form-validators";
import { noWhitespaceValidator } from "../../../../@core/utils/helpers";
import { UntypedFormGroup } from "@angular/forms";
import { GraphHandlerService } from "../../../../@core/utils/graph.service";
import { CallFlowsComponent } from "../../../../portal/customer-role/studio/callflows/callflows.component";

@Component({
  selector: "ngx-redirect-callflow",
  templateUrl: "./redirect-callflow.component.html",
  styleUrls: ["./redirect-callflow.component.scss"],
})
export class RedirectCallflowComponent implements OnInit {
  @ViewChild("autoInput") input;
  cell: MxCell;
  graph: MxGraph;
  data: RedirectCallflow = new RedirectCallflow();
  callflows: any;
  variables = [];
  allowLoad = false;
  inputOption = [];
  outputOption = [];
  subflowVariable = [];
  type = "select";
  filteredOptions$: Observable<string[]>;
  filteredOptionsOutput$: Observable<string[]>;
  isLoading = false;
  filteredCallflow: CallFlowDto[];
  cancelAction = false;
  formGroup: UntypedFormGroup;
  oldOutputAttributeValue: any[] = [];
  constructor(
    protected windowRef: NbWindowRef,
    private router: Router,
    private callFlowClient: CallFlowClient,
    private dialogService: NbDialogService,
    private dataService: DataService,
    private formBuilder: RxFormBuilder,
    private graphService: GraphHandlerService
  ) {
    this.createForm();
  }
  createForm() {
    this.formGroup = this.formBuilder.group({
      callflow: ["", [RxwebValidators.required(), noWhitespaceValidator]],
    });
  }
  handleSaveAction() {
    this.windowRef.close();
  }
  handleCancelAction() {
    this.cancelAction = true;
    this.windowRef.close(true);
  }
  getCallflows() {
    this.isLoading = true;
    this.callFlowClient
      .getAll(0, 1000, "id", false, "all", null)
      .subscribe((item: any) => {
        this.callflows = item.callFlows;
        for (let [key, value] of Object.entries(this.data)) {
          this.data[key] = this.cell.getAttribute(key);
        }
        if (this.data.callFlowId) {
          let dataExist = this.callflows?.find(
            (x) => x.id == this.data.callFlowId
          );
          if (dataExist) {
            this.data.callFlowId =
              Number(this.data.callFlowId) == 0 || isNaN(this.data.callFlowId)
                ? null
                : Number(this.data.callFlowId);

            if (this.data.inputOption && this.data.inputOption != "[]") {
              this.inputOption = JSON.parse(this.data.inputOption);
            }
            if (this.data.outputOption && this.data.outputOption != "[]") {
              this.outputOption = JSON.parse(this.data.outputOption);
              this.outputOption.forEach((output) => {
                if (output.value == "") {
                  output.value = null;
                }
              });
            }
            this.subflowVariable = JSON.parse(dataExist.attribute) || [];
            this.subflowVariable = this.subflowVariable.filter(
              (item2) => item2.default === 0
            );
            this.filteredOptions$ = of(this.subflowVariable);
            this.filteredOptionsOutput$ = of(this.dataService.ivrAttribute);
            if (this.data.variables) {
              this.variables = JSON.parse(this.data.variables);
            }
          } else {
            this.data.callFlowId = null;
            this.data.flowName = "";
            this.data.inputOption = "[]";
            this.data.outputOption = "[]";
            this.data.variables = "";
          }
        } else {
          this.data.callFlowId = null;
        }
        this.allowLoad = true;
        this.isLoading = false;
      });
  }

  private filterOutput(value: string): any[] {
    const filterValue = value?.toLowerCase();
    return this.dataService.ivrAttribute.filter((optionValue) => {
      optionValue.value?.toLowerCase().includes(filterValue);
    });
  }

  onModelChangeOutput(value: string, i) {
    this.filteredOptionsOutput$ = of(this.filterOutput(value));
  }

  ngOnInit() {
    this.getCallflows();
    this.windowRef.onClose.subscribe(() => this.submit());
  }
  addInput() {
    let newInput = { value: "", name: "" };
    this.inputOption.push(newInput);
  }
  addOutput() {
    let newOutput = {
      value: null,
      name: "",
    };
    this.outputOption.push(newOutput);
  }
  changeAttributeValue(value, index) {
    if (value?.name == null) {
    this.dialogService
      .open(DialogAttributeComponent, { autoFocus: false })
      .onClose.subscribe((rs) => {
        if (rs) {
          this.outputOption[index].name = rs;
          this.oldOutputAttributeValue[index] = rs;
          this.outputOption = this.outputOption.map((el) =>
            Object.assign({}, el)
          );
        } else {
          this.outputOption[index].name = this.oldOutputAttributeValue[index];
        }
      });
    } else {
      this.oldOutputAttributeValue[index]= value?.name;
    }
  }

  getVariableName(event, i) {
    this.outputOption[i].name = event;
  }

  removeInput(index) {
    if (index != 0) {
      this.inputOption.splice(index, 1);
    } else {
      this.inputOption.shift();
    }
  }
  removeOutput(index) {
    if (index != 0) {
      this.outputOption.splice(index, 1);
    } else {
      this.outputOption.shift();
    }
    this.oldOutputAttributeValue.splice(index, 1);
  }
  setFlow(event) {
    if (event) {
      this.isLoading = true;
      this.callFlowClient.get(event.id).subscribe((rs) => {
        if (rs) {
          this.data.flowName = rs.name;
          this.data.variables = rs.attribute;
          this.variables = JSON.parse(this.data.variables);
          this.inputOption = [];
          this.outputOption = [];
          this.subflowVariable = JSON.parse(this.data.variables);
          this.subflowVariable = this.subflowVariable?.filter(
            (item) => item.default === 0
          );
          this.filteredOptions$ = of(this.subflowVariable);
        }
        this.isLoading = false;
      });
    }
  }
  getInputAttribute($event) {
    this.data.inputOption = $event;
  }
  submit() {
    this.outputOption.forEach((output) => {
      output.value = output.value?.trim();
      output.name = output.name?.trim();
    })
    this.inputOption.forEach((input) => {
      input.value = input.value?.trim();
      input.name = input.name?.trim();
    })
    if (this.data.callFlowId) {
      let subFlow = this.callflows.find(
        (x) => x.id == Number(this.data?.callFlowId)
      );
      if (subFlow) {
        this.data.flowName = subFlow.name;
      }
    }
    if (!this.cancelAction && !this.isLoading) {
      this.data.outputOption = JSON.stringify(this.outputOption);
      for (let [key, value] of Object.entries(this.data)) {
        this.cell.setAttribute(key, value || "");
      }
      const subflowCell = this.graph.getModel().getCell(this.cell.getId());
      const fieldsRequired = [this.data.callFlowId];
      const check = this.graphService.checkIsRequired(
        this.graph,
        fieldsRequired,
        subflowCell
      );
      this.cell.setAttribute("checkFields", check?.toString());
    }
  }
  viewSubFlow(callFlowId) {
    this.router.navigate([]).then((result) => {
      window.open(`callflow-design/${callFlowId}`, "_blank");
    });
  }

  createFlow() {
    const newWindow = window.open("/portal/studio/callflows", "_blank");
    if (newWindow) {
      newWindow.addEventListener("load", () => {
        newWindow.postMessage({ action: "loaded" }, "*");
      });
    }
  }
}
