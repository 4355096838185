<form [formGroup]="datasetForm" novalidate (submit)="onSubmit()">
  <nb-card style="margin-bottom: 0" [nbSpinner]="isLoading" nbSpinnerSize="giant" nbSpinnerStatus="primary">
    <nb-card-header>
      <div class="d-flex justify-content-between">
        QA Dataset
        <button (click)="dismiss()" type="button" class="btn-close" aria-label="Close"></button>
      </div>
    </nb-card-header>
    <nb-card-body>
      <div class="dataset-wrap">
        <div class="row">
          <div class="col-md-6 mb-2">
            <label for="input-name" class="form-label label required">Name</label>
            <input
              #inputName
              type="text"
              formControlName="name"
              nbInput
              [(ngModel)]="data.name"
              fullWidth
              [status]="
                datasetForm.controls['name'].invalid &&
                (datasetForm.controls['name'].dirty || datasetForm.controls['name'].touched)
                  ? 'danger'
                  : 'basic'
              "
              id="input-name"
              placeholder="Name"
              [attr.disabled]="isNameLoading == false ? null : isNameLoading"
              fieldSize="small"
            />
            <ng-container
              *ngIf="
                datasetForm.controls['name'].invalid &&
                (datasetForm.controls['name'].dirty || datasetForm.controls['name'].touched)
              "
            >
              <p
                class="caption status-danger"
                style="margin-bottom: 0"
                *ngIf="
                  datasetForm.controls['name'].hasError('required') || datasetForm.controls['name'].hasError('whitespace')
                "
              >
                Name is required
              </p>
              <p class="caption status-danger" style="margin-bottom: 0" *ngIf="datasetForm.controls['name'].errors.isExist">
                Name already exist.
              </p>
              <p
                class="caption status-danger"
                style="margin-bottom: 0"
                *ngIf="
                  datasetForm.controls['name'].errors.maxlength &&
                  !datasetForm.controls['name'].hasError('required') &&
                  !datasetForm.controls['name'].hasError('whitespace')
                "
              >
                Maximum length is 50 characters.
              </p>
              <p class="caption status-danger" *ngIf="datasetForm.controls['name'].errors.serverError">
                {{ datasetForm.controls["name"].errors.serverError }}
              </p>
            </ng-container>
          </div>
          <div class="col-md-6 mb-2" *ngIf="data.type == 'DOCUMENT'">
            <label for="type" class="form-label label required">Type</label>
            <nb-select fullWidth placeholder="Select Type" [(ngModel)]="data.type" formControlName="type" size="small">
              <nb-option value="URL">URL</nb-option>
              <nb-option value="DOCUMENT">DOCUMENT</nb-option>
            </nb-select>
          </div>
          <div class="col-md-3 mb-2" *ngIf="data.type == 'URL'">
            <label for="type" class="form-label label required">Type</label>
            <nb-select fullWidth placeholder="Select Type" [(ngModel)]="data.type" formControlName="type" size="small">
              <nb-option value="URL">URL</nb-option>
              <nb-option value="DOCUMENT">DOCUMENT</nb-option>
            </nb-select>
          </div>
          <div class="col-md-3 mb-2" *ngIf="data.type == 'URL'">
            <label for="type" class="form-label label">Retrain Time (day)</label>
            <input
              type="text"
              nbInput
              fieldSize="small"
              placeholder="Retrain Time"
              formControlName="retrainTime"
              [(ngModel)]="data.retrainTime"
              fullWidth
              [status]="
                datasetForm.controls['retrainTime'].invalid &&
                (datasetForm.controls['retrainTime'].dirty || datasetForm.controls['retrainTime'].touched)
                  ? 'danger'
                  : 'basic'
              "
            />
            <ng-container
              *ngIf="
                datasetForm.controls['retrainTime'].invalid &&
                (datasetForm.controls['retrainTime'].dirty || datasetForm.controls['retrainTime'].touched)
              "
            >
              <p
                class="caption status-danger"
                style="margin-bottom: 0 !important"
                *ngFor="let errorMessage of datasetForm.controls.retrainTime['errorMessages']"
              >
                {{ errorMessage }}
              </p>
            </ng-container>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 mb-2">
            <label for="description" class="form-label label">Description</label>
            <input
              id="description"
              type="text"
              nbInput
              fieldSize="small"
              placeholder="Description"
              formControlName="description"
              [(ngModel)]="data.description"
              fullWidth
            />
          </div>
        </div>
        <div class="row mb-3 document" *ngIf="data.type == 'DOCUMENT'" style="overflow-y: scroll">
          <div class="col-md-12">
            <label for="name" class="form-label label required">Data</label>
            <div>
              <uppy-dashboard [uppy]="uppy"> </uppy-dashboard>
            </div>
          </div>
        </div>
        <div class="row" *ngIf="data.type == 'URL'">
          <div class="col-md-12 mb-3">
            <div class="label-wrapper">
              <label for="name" class="form-label label">URL</label>
              <nb-checkbox style="margin-bottom: 6px" (checkedChange)="toggleExtract($event)" [checked]="checkExtract"
                >Extract URLs</nb-checkbox
              >
            </div>
            <div class="url-group">
              <textarea
                formControlName="mainURL"
                nbInput
                (input)="onChangeText($event)"
                [(ngModel)]="mainURL"
                fullWidth
                style="height: 90px"
                id="input-name"
                placeholder="URL"
              >
              </textarea>
              <div
                nbTooltip="Searching child URLs... please wait..."
                nbTooltipPlacement="bottom"
                nbTooltipStatus="control"
                [nbTooltipDisabled]="!isLoadingExtract"
              >
                <button
                  nbButton
                  status="primary"
                  size="small"
                  type="button"
                  (click)="extractURL()"
                  [disabled]="!mainURL || isLoadingExtract"
                >
                  <nb-icon
                    style="width: 19px; height: 19px"
                    [icon]="getIcon()"
                    [ngClass]="{ 'loading-icon': isLoadingExtract }"
                  ></nb-icon>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="row mb-3" *ngIf="data.type == 'URL'" style="overflow-y: scroll !important">
          <div #urlBox class="col-md-12 mb-3 urls-wrapper">
            <label for="name" class="form-label label required">Data</label>
            <div *ngIf="listUrl.length <= 0">
              <span style="font-size: 13px">No data to display</span>
            </div>
            <div style="flex: 1" class="check-wrapper-all" *ngIf="listUrl.length > 0">
              <nb-checkbox (checkedChange)="toggleAll($event)" [checked]="checkAll">All URLs</nb-checkbox>
              <button nbButton size="tiny" type="button" (click)="removeAll()" *ngIf="!checkAll">
                <nb-icon icon="trash" status="basic"></nb-icon>
              </button>
            </div>
            <div style="flex: 1; padding-left: 24px" *ngFor="let url of listUrl; let i = index" class="check-wrapper">
              <nb-checkbox (checkedChange)="toggle($event, i)" [checked]="url.enable">{{ url.url }}</nb-checkbox>
              <button nbButton size="tiny" type="button" (click)="removeUrl(i)">
                <nb-icon icon="trash" status="basic"></nb-icon>
              </button>
            </div>
          </div>
        </div>
        <div class="total-count" *ngIf="data.type == 'URL'">
          <span>{{ listUrl.length }} total</span>
        </div>
        <div class="last-modified" *ngIf="data.lastModified">
          <span>Last modified: {{ data.lastModified | date : "medium" }}</span>
        </div>
      </div>
    </nb-card-body>
    <nb-card-footer>
      <div class="d-flex justify-content-end">
        <button
          nbButton
          size="small"
          [disabled]="
            !datasetForm.valid ||
            isLoading ||
            isExist ||
            (data.type == 'URL' && listUrl.length <= 0) ||
            (data.type == 'DOCUMENT' && totalFile <= 0)
          "
          status="primary"
        >
          <nb-icon icon="save-outline"></nb-icon>Save
        </button>
      </div>
    </nb-card-footer>
  </nb-card>
</form>
