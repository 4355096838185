import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { CountupTimerService } from "ngx-timer";
import {
  Registerer,
  RegistererState,
  SessionState,
  UserAgent,
  UserAgentOptions,
} from "sip.js/lib/api";
import { DataService } from "../../@core/utils/data.service";
import { AgentClient } from "../../System-api";

@Component({
  selector: "ngx-soft-phone",
  templateUrl: "./soft-phone.component.html",
  styleUrls: ["./soft-phone.component.scss"],
})
export class SoftPhoneComponent implements OnInit, OnDestroy {
  @Output() handlerCallControll = new EventEmitter<boolean>();
  @Input() isCallflowDesign: boolean = false;

  userAgent: UserAgent;
  session: any;

  server;
  aor;
  authorizationUsername;
  authorizationPassword;

  isPlaying: boolean = false;
  phoneStatus: boolean = false;
  alertMsg: string;
  localStream = new MediaStream();
  @ViewChild("playAudio", {
    static: true,
  })
  public playAudio: ElementRef;
  @ViewChild("callAudio", {
    static: true,
  })
  public callAudio: ElementRef;

  constructor(
    public dataService: DataService,
    public countupTimerService: CountupTimerService,
    public agentClient: AgentClient
  ) {}

  ngOnInit() {
    var userName = localStorage.getItem("sipUser");
    var password = localStorage.getItem("sipPassword");

    if (userName && password) {
      this.phoneStatus = true;
      this.getSipAccount({
        userName: userName,
        password: password,
      });
    }
    // this.handlerCallControll.emit(false);
  }

  getSipAccount(credential) {
    var url = location.origin;
    var urlLink = new URL(url);
    var protocol = urlLink.protocol === "https:" ? "wss" : "ws";
    
    url = urlLink.hostname;
    var port = "5060";

    var userName = credential.userName;
    var password = credential.password;
    this.server = protocol + "://" + url + "" + "/ws";
    this.aor = "sip:" + userName + "@" + url + ":" + port;
    this.authorizationUsername = userName;
    this.authorizationPassword = password;
    this.initPhone();
  }

  initPhone() {
    const uri = UserAgent.makeURI(this.aor);
    const transportOptions = {
      server: this.server,
    };
    const userAgentOptions: UserAgentOptions = {
      uri,
      logBuiltinEnabled: false,
      logConfiguration: false,
      authorizationPassword: this.authorizationPassword,
      hackIpInContact: true,
      authorizationUsername: this.authorizationUsername,
      transportOptions,
      sessionDescriptionHandlerFactoryOptions: {
        constraints: {
          audio: true,
          video: false,
        },
      },
      delegate: {
        onInvite: (invitation) => {
          debugger;
          this.playAudioCall("assets/audio/ringtone.wav");
          this.dataService.callStatus = CallStatus.Incomming;
          this.handlerCallControll.emit(true);
          this.handlerSession(invitation);
        },
        onDisconnect: () => {
          this.phoneStatus = false;
        },
      },
    };

    this.userAgent = new UserAgent(userAgentOptions);

    //register extension
    const registerer = new Registerer(this.userAgent);
    registerer.stateChange.addListener((newState) => {
      this.alertMsg = undefined;
      switch (newState) {
        case RegistererState.Unregistered:
          this.phoneStatus = false;
          this.alertMsg = "Login failed";
          this.authorizationUsername = "";
          break;
        case RegistererState.Registered:
          localStorage.setItem("sipUser", this.authorizationUsername);
          localStorage.setItem("sipPassword", this.authorizationPassword);
          this.phoneStatus = true;
          this.dataService.callStatus = CallStatus.NoCall;
          break;
        case RegistererState.Terminated:
          this.phoneStatus = false;
          break;
      }
    });
    this.userAgent.start().then(() => {
      registerer.register();
    });
  }

  public get callStatusResult(): typeof CallStatus {
    return CallStatus;
  }

  playAudioCall(audioFile) {
    if (this.isPlaying == false) {
      this.playAudio.nativeElement.src = audioFile;
      this.playAudio.nativeElement.play();
      this.isPlaying = true;
    }
  }

  dismiss() {
    document.getElementById("call-popup").style.animation = "moveoutcall 0.6s";
    setTimeout(() => {
      this.handlerCallControll.emit(false);
      document.getElementById("call-popup").style.animation = "movetocall 0.6s";
    }, 500);
  }

  handlerSession(session) {
    this.session = session;
    // Handle outgoing session state changes.
    this.session.stateChange.addListener((newState: SessionState) => {
      switch (newState) {
        case SessionState.Establishing:
          this.playAudioCall("assets/audio/ringing.wav");
          this.dataService.callStatus = CallStatus.Ringging;
          break;
        case SessionState.Established:
          this.playAudio.nativeElement.pause();
          this.dataService.callStatus = CallStatus.InCall;
          this.countupTimerService.startTimer();
          this.setupRemoteMedia(this.session);
          break;
        case SessionState.Terminated:
          this.isPlaying = false;
          this.playAudioCall("assets/audio/hangup.wav");

          this.countupTimerService.stopTimer();
          this.dataService.callStatus = CallStatus.NoCall;
          this.session = null;
          this.cleanupMedia();
          break;
        default:
          break;
      }
    });
  }

  audioEnded() {
    this.isPlaying = false;
  }

  disconnectServer() {
    this.phoneStatus = false;
  }

  setupRemoteMedia(session: any) {
    var remoteStream = new MediaStream();
    session.sessionDescriptionHandler.peerConnection
      .getReceivers()
      .forEach((receiver) => {
        if (receiver.track) {
          remoteStream.addTrack(receiver.track);
        }
      });
    this.callAudio.nativeElement.srcObject = remoteStream;
    this.callAudio.nativeElement.play();
  }

  audioChangehandle(status) {
    this.callAudio.nativeElement.muted = !status;
  }

  cleanupMedia() {
    this.callAudio.nativeElement.srcObject = null;
    this.callAudio.nativeElement.pause();
  }

  ngOnDestroy(): void {
    if (this.userAgent != undefined) {
      this.userAgent.stop();
    }
    this.session?.dispose();
  }

  unregister() {
    if (this.userAgent != undefined) {
      localStorage.removeItem("sipUser");
      localStorage.removeItem("sipPassword");
      this.userAgent.stop();
    }
  }
}

export enum CallStatus {
  NoCall = 0,
  Calling = 1,
  Ringging = 2,
  Incomming = 3,
  InCall = 4,
}
