import {
  NumericValueType,
  RxwebValidators,
} from "@rxweb/reactive-form-validators";
import { UntypedFormControl } from "@angular/forms";

export const ValidInput = {
  password: [
    RxwebValidators.required(),
    RxwebValidators.password({
      validation: {
        maxLength: 15,
        minLength: 6,
        digit: true,
        upperCase: true,
        lowerCase: true,
      },
      message: {
        minLength:
          "Password must have at least 6 characters including: uppercase, lowercase, number",
        maxLength:
          "Password must have at most 15 characters including: uppercase, lowercase, number",
        upperCase: "Password must have at least 1 uppercase character",
        lowerCase: "Password must have at least 1 lowercase character",
        digit: "Password must have at least 1 digit character",
      },
    }),
  ],
  newpassword: [
    RxwebValidators.password({
      validation: {
        maxLength: 15,
        minLength: 6,
        digit: true,
        upperCase: true,
        lowerCase: true,
      },
      message: {
        minLength:
          "New Password must have at least 6 characters including: uppercase, lowercase, number",
        maxLength:
          "New Password must have at most 15 characters including: uppercase, lowercase, number",
        upperCase: "New Password must have at least 1 uppercase character",
        lowerCase: "New Password must have at least 1 lowercase character",
        digit: "New Password must have at least 1 digit character",
      },
    }),
  ],
  email: [
    RxwebValidators.required(),
    RxwebValidators.email({ message: "Email is invalid." }),
    RxwebValidators.pattern({
      expression: {
        regex:
          /^[a-zA-Z0-9]+(?:[-._]?[a-zA-Z0-9]+)+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      },
    }),
  ],


  name: [
    RxwebValidators.required(),
    noWhitespaceValidator,
    RxwebValidators.maxLength({
      value: 100,
      message: "Maximum length is 100 characters.",
    }),
  ],

  customName: [
    RxwebValidators.required(),
    noWhitespaceValidator,
    RxwebValidators.maxLength({
      value: 50,
      message: "Maximum length is 50 characters.",
    }),
  ],

  extension: [
    RxwebValidators.required(),
    noWhitespaceValidator,
    RxwebValidators.maxLength({
      value: 50,
      message: "Maximum length is 50 characters.",
    }),
    RxwebValidators.alphaNumeric({
      message: "Extension must contain only letters, numbers.",
    }),
  ],

  phone: [
    RxwebValidators.required(),
    RxwebValidators.maxLength({
      value: 15,
      message: "Maximum length is 15 characters.",
    }),
    RxwebValidators.digit({
      message: "Phone Number must contain only numbers.",
    }),
  ],

  username: [
    RxwebValidators.required(),
    noWhitespaceValidator,
    RxwebValidators.maxLength({
      value: 50,
      message: "Maximum length is 50 characters.",
    }),
    RxwebValidators.alphaNumeric({
      message: "User Name must contain only letters, numbers.",
    }),
  ],

  dob: [RxwebValidators.required(), AgeValidator],

  required: RxwebValidators.required(),

  cost: [
    RxwebValidators.required(),
    RxwebValidators.numeric({
      allowDecimal: true,
      message: "The cost must contain only numbers.",
    }),
  ],
  retrainTime: [
    RxwebValidators.digit({
      message: "Retrain Time must contain only numbers.",
    }),
  ],

  tax: [
    RxwebValidators.required(),
    RxwebValidators.numeric({
      allowDecimal: true,
      message: "The tax must contain only numbers.",
    }),
  ],

  port: [
    RxwebValidators.required(),
    RxwebValidators.numeric({
      allowDecimal: true,
      message: "The port must contain only numbers.",
    }),
    RxwebValidators.numeric({
      acceptValue: NumericValueType.PositiveNumber,
      allowDecimal: false,
    }),
  ],
  date: [
    RxwebValidators.required(),
    RxwebValidators.numeric({
      allowDecimal: true,
      message: "The date must contain only numbers.",
    }),
    RxwebValidators.numeric({
      acceptValue: NumericValueType.PositiveNumber,
      allowDecimal: false,
    }),
  ],
  variable: [
    RxwebValidators.required(),
    noWhitespaceValidator,
    RxwebValidators.maxLength({
      value: 50,
      message: "Maximum length is 50 characters.",
    }),
    VariableValidator,
  ],
};
export function noWhitespaceValidator(control: UntypedFormControl) {
  if (typeof control.value === "string") {
    return (control.value || "")?.trim().length ? null : { whitespace: true };
  }
}
export function timeRange(control: UntypedFormControl) {
  let reg = new RegExp(
    "^([0-1]?[0-9]|2[0-3]):[0-5][0-9]-([0-1]?[0-9]|2[0-3]):[0-5][0-9]$"
  );
  let rs = reg.test(control.value);
  if (!rs) {
    return {
      timerange: true,
    };
  }
  return null;
}

export function AgeValidator(
  control: UntypedFormControl
): { [key: string]: any } | null {
  if (control.value) {
    const dateOfBirth: Date = new Date(control.value);
    const currentDate: Date = new Date();

    const minAgeDate: Date = new Date();
    minAgeDate.setFullYear(currentDate.getFullYear() - 16);

    const maxAgeDate: Date = new Date();
    maxAgeDate.setFullYear(currentDate.getFullYear() - 90);

    if (dateOfBirth.getFullYear() > minAgeDate.getFullYear()) {
      return { ageMin: true };
    }

    if (dateOfBirth.getFullYear() < maxAgeDate.getFullYear()) {
      return { ageMax: true };
    }
  }

  return null;
}

export function isJSON(str) {
  try {
    JSON.parse(str);
    return true;
  } catch (error) {
    return false;
  }
}

export function VariableValidator(control: UntypedFormControl) {
  let reg = new RegExp("^[a-zA-Z_][a-zA-Z0-9_]*$");
  let rs = reg.test(control.value);
  if (!rs) {
    return {
      variable: true,
    };
  }
  return null;
}
