<div class="action-config-wrapper" style="max-width: 575px;">
  <div class="row">
    <div class="col-12">
      <label class="form-label label">Timezone</label>
      <nb-radio-group
        name="radio-start"
        [(ngModel)]="data.timezoneOption"
        class="choice-option mb-2"
        (ngModelChange)="handleTimezoneOption($event)"
      >
        <nb-radio *ngFor="let option of optionsTimezone" [value]="option.id">{{
          option.name
        }}</nb-radio>
      </nb-radio-group>
      <nb-select
        *ngIf="data.timezoneOption == 1"
        fullWidth
        placeholder="Select Timezone"
        size="small"
        [(ngModel)]="data.timezone"
      >
        <nb-option
          *ngFor="let timezone of listTimezone"
          [value]="timezone.value"
          >{{ timezone.name }}</nb-option
        >
      </nb-select>
      <input
        *ngIf="data.timezoneOption == 2"
        type="text"
        nbInput
        fullWidth
        [(ngModel)]="data.timezoneCustom"
        placeholder="Timezone"
        fieldSize="small"
        [mention]="attributes"
        [mentionConfig]="mentionConfig"
      />
    </div>
  </div>
  <div class="config-action">
    <div class="mt-4">
      <label class="form-label label" style="margin-bottom: 1rem !important"
        >Valid Time Range</label
      >
      <div
        class="div-day"
        style="position: relative"
        *ngFor="let day of dayofweek; let i = index"
      >
        <tag-input
          style="width: 100%"
          [(ngModel)]="day.time"
          secondaryPlaceholder=""
          placeholder=""
          [errorMessages]="errorMessages"
          [validators]="validators"
        >
        </tag-input>
        <nb-badge
          position="top right"
          [status]="day.time.length > 0 ? 'primary' : 'basic'"
          [text]="day.name"
        ></nb-badge>
      </div>
    </div>
  </div>
  <footer-action
    (handleCancelAction)="handleCancelAction()"
    (handleSaveAction)="handleSaveAction()"
  ></footer-action>
</div>
