import { IApplicationData } from "./common";
export class ChatGPT implements IApplicationData {
  constructor() {
    this.name = "ChatGPT";
    this.audioId = "";
    this.audioPath = "";
    this.audioNoInputId = "";
    this.audioNotMatchId = "";
    this.audioNotMatchPath = "";
    this.audioNoInputPath = "";
    this.text = "";
    this.textChatBot = "";
    this.textNotMatch = "";
    this.textNoInput = "";
    this.option = 1;
    this.idGrammar = 0;
    this.attribute = "";
    this.directory = "";
    this.datasetName = "";
    this.idDataset = "";
    this.multipleOtherCase = true;
    this.transition = true;
    this.type = "";
    this.timeout = "10";
    this.noInputTimeout = "5";
    this.minLength = "1";
    this.maxLength = "20";
    this.log = false;
    this.repeat = "2";
    this.grammarName = "";
    this.entity = "";
    this.entityAttribute = "";
    this.engine = true;
    this.extenData = "";
    this.grammar = "";
    this.grammarArray = "[]";
    this.intentQA = "";
    this.enableCheckpoint = true;
    this.customTrain = true;
    this.sentiment = "";
    this.prompt = "Assuming IT support";
    this.sentimentMain = "";
    this.sentimentNoInput = "";
    this.sentimentNotMatch = "";
    this.responseMode = "DOC&GPT";
    this.temperature = 0.3;
    this.numberOutput = 512;
    this.multipleCase = true;
    this.userResponse = "";
    this.isRequired = true;
    this.checkFields = false;
    this.keepContext = false;
  }
  name: string;
  audioPath: string;
  audioId: string;
  functionId: string;
  audioNotMatchId: string;
  audioNoInputId: string;
  audioNotMatchPath: string;
  grammar: string;
  audioNoInputPath: string;
  text: string;
  textChatBot: string;
  datasetName: string;
  idDataset: string;
  idGrammar: number;
  attribute: string;
  directory: string;
  multipleOtherCase: boolean;
  transition: boolean;
  type: string;
  option: number;
  timeout: string;
  noInputTimeout: string;
  minLength: string;
  maxLength: string;
  log: boolean;
  repeat: string;
  textNotMatch: string;
  textNoInput: string;
  entity: string;
  grammarName: string;
  entityAttribute: string;
  engine: boolean;
  extenData: string;
  intentQA: string;
  enableCheckpoint: boolean;
  prompt: string;
  customTrain: boolean;
  sentiment: string;
  sentimentMain: string;
  sentimentNoInput: string;
  sentimentNotMatch: string;
  temperature: number;
  responseMode: string;
  numberOutput: number;
  multipleCase: boolean;
  userResponse: string;
  grammarArray: string;
  isRequired: boolean;
  checkFields: boolean;
  keepContext: boolean;
}
