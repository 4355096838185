<div class="action-config-wrapper" style="max-width: 500px">
  <div class="config-action">
    <nb-stepper orientation="horizontal" selectedIndex="0">
      <nb-step [label]="labelOne">
        <ng-template #labelOne>Setup</ng-template>
        <nb-radio-group
          class="choice-option mt-2"
          [(ngModel)]="data.option"
          (ngModelChange)="handleType($event)"
        >
          <nb-radio *ngFor="let option of options" [value]="option.id">{{
            option.name
          }}</nb-radio>
        </nb-radio-group>
        <div class="row hidden" [ngClass]="{ show: data.option == 2 }">
          <div class="col-md-12">
            <div class="mb-2">
              <label for="url" class="form-label label">URL</label>
              <textarea
                id="url"
                type="text"
                nbInput
                fullWidth
                placeholder="URL"
                fieldSize="small"
                [(ngModel)]="data.url"
                style="min-height: 50px; max-height: 150px"
              ></textarea>
            </div>
          </div>
        </div>
        <div class="hidden" [ngClass]="{ show: data.option == 1 }">
          <div class="flex-title">
            <span class="title">Form</span>
            <span class="line"></span>
          </div>
          <!-- <nb-card status="basic" *ngFor="let data of languagesSetting; let i = index">
            <nb-card-header>{{data.name}}</nb-card-header>
            <nb-card-body style="display: flex; flex-direction: column">
              <label for="title" class="form-label label">Title</label>
              <input
                id="title"
                type="text"
                nbInput
                fullWidth
                [(ngModel)]="title"
                placeholder="Title"
                fieldSize="small"
              />
              <label
                for="title"
                class="form-label label"
                style="margin-top: 8px"
                >Description</label
              >
              <textarea
                id="description"
                type="text"
                nbInput
                fullWidth
                [(ngModel)]="description"
                placeholder="Description"
                fieldSize="small"
                style="min-height: 50px; max-height: 150px"
              ></textarea>
            </nb-card-body>
          </nb-card> -->
          <nb-accordion class="is-language">
            <nb-accordion-item
              [expanded]="checkDefaultLanguageUser(data)"
              *ngFor="let data of dataConvert; let i = index"
            >
              <nb-accordion-item-header class="is-language">{{
                data.name
              }}</nb-accordion-item-header>
              <nb-accordion-item-body>
                <label for="title" class="form-label label">Title</label>
                <input
                  type="text"
                  nbInput
                  fullWidth
                  [(ngModel)]="data.textTitle"
                  placeholder="Title"
                  fieldSize="small"
                />
                <label
                  for="description"
                  class="form-label label"
                  style="margin-top: 8px"
                  >Description</label
                >
                <textarea
                  type="text"
                  nbInput
                  fullWidth
                  [(ngModel)]="data.textDescription"
                  placeholder="Description"
                  fieldSize="small"
                  style="min-height: 50px; max-height: 150px"
                ></textarea>
              </nb-accordion-item-body>
            </nb-accordion-item>
          </nb-accordion>

          <div class="flex-title">
            <span class="title">Questions</span>
            <span class="line"></span>
          </div>
          <div class="mt-3" *ngIf="questions.length > 0">
            <nb-card
              style="height: auto"
              status="basic"
              [nbPopover]="templateRef2"
              nbPopoverTrigger="noop"
              nbPopoverPlacement="left"
              (click)="openTemplateRef2()"
            >
              <nb-list
                cdkDropList
                class="example-list"
                (cdkDropListDropped)="drop($event)"
              >
                <nb-list-item
                  [ngClass]="{ 'border-active': current == i }"
                  *ngFor="let question of questions; let i = index"
                  class="example-box"
                  cdkDrag
                >
                  <div class="grammar-name-wrapper">
                    <nb-icon
                      status="basic"
                      [icon]="question.icon || 'hash-outline'"
                    ></nb-icon>
                    <span class="grammar-type">Q{{ i + 1 }}: </span>
                    <span class="grammar-name">{{ question.label }}</span>
                  </div>
                  <div class="action-wrapper">
                    <button
                      ghost
                      class="tbl-action"
                      nbButton
                      size="small"
                      (click)="editQuestion(i)"
                    >
                      <nb-icon icon="settings-2-outline"></nb-icon>
                    </button>
                    <button
                      ghost
                      class="tbl-action"
                      nbButton
                      size="small"
                      (click)="deleteInList(i)"
                    >
                      <nb-icon icon="trash-2-outline"></nb-icon>
                    </button>
                    <div
                      class="move-icon"
                      style="display: flex; align-items: center"
                    >
                      <nb-icon
                        style="margin-right: -14px"
                        status="basic"
                        icon="more-vertical-outline"
                      ></nb-icon>
                      <nb-icon
                        status="basic"
                        icon="more-vertical-outline"
                      ></nb-icon>
                    </div>
                  </div>
                </nb-list-item>
              </nb-list>
            </nb-card>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="add-grammar mt-3 mb-2">
                <button
                  [nbPopover]="templateRef"
                  shape="round"
                  type="button"
                  size="small"
                  nbPopoverTrigger="click"
                  nbPopoverPlacement="left"
                  (click)="showModal()"
                  status="primary"
                  nbButton
                >
                  <nb-icon [icon]="icon"></nb-icon>
                </button>
                <span style="font-size: 13.5px">More Questions</span>
              </div>
            </div>
          </div>
        </div>
      </nb-step>
      <nb-step [label]="labelTwo">
        <ng-template #labelTwo>Settings</ng-template>
        <div>
          <div class="flex-title">
            <span class="title">Button Submit</span>
            <span class="line"></span>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="mb-2">
                <label for="submit-button" class="form-label label"
                  >Label</label
                >
                <input
                  id="submit-button"
                  type="text"
                  nbInput
                  fullWidth
                  [(ngModel)]="data.buttonLabel"
                  placeholder="Label"
                  fieldSize="small"
                />
              </div>
            </div>
          </div>
          <div class="flex-title">
            <span class="title">Required Field</span>
            <span class="line"></span>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="mb-2">
                <nb-checkbox
                  (checkedChange)="allowMarkRequiredFields($event)"
                  [checked]="data.markRequiredFields"
                >
                  Mark required fields with a * on the label
                </nb-checkbox>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="mb-2">
                <label for="field-error" class="form-label label"
                  >Error Message</label
                >
                <input
                  id="field-error"
                  type="text"
                  nbInput
                  fullWidth
                  [(ngModel)]="data.errorMessage"
                  placeholder="Error Message"
                  fieldSize="small"
                />
              </div>
            </div>
          </div>
          <div class="flex-title">
            <span class="title">Option</span>
            <span class="line"></span>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="mb-2">
                <nb-checkbox
                  (checkedChange)="allowSkip($event)"
                  [checked]="data.skip"
                >
                  Allows Skip Form
                </nb-checkbox>
              </div>
            </div>
          </div>
        </div>
      </nb-step>
    </nb-stepper>
  </div>
  <footer-action
    (handleCancelAction)="handleCancelAction()"
    (handleSaveAction)="handleSaveAction()"
  ></footer-action>
</div>
<ng-template #templateRef2>
  <nb-card class="example-box-3" id="box-questions">
    <nb-card-header
      style="display: flex; align-items: center; justify-content: space-between"
    >
      <span>Question {{ current + 1 }}: {{ questions[current].name }}</span>
      <button nbButton size="small" ghost type="button" (click)="closeModal()">
        <nb-icon icon="close"></nb-icon>
      </button>
    </nb-card-header>
    <nb-card-body class="question-edit">
      <div class="row">
        <div class="col-md-12">
          <div class="mb-2">
            <label for="label-question" class="form-label label">Label</label>
            <input
              id="label-question"
              type="text"
              nbInput
              fullWidth
              [(ngModel)]="questions[current].label"
              placeholder="Label"
              fieldSize="small"
            />
          </div>
        </div>
      </div>
      <div
        class="row"
        *ngIf="
          questions[current].type == 'short' ||
          questions[current].type == 'long' ||
          questions[current].type == 'select'
        "
      >
        <div class="col-md-12">
          <div class="mb-2">
            <label for="placeholder" class="form-label label"
              >Placeholder</label
            >
            <input
              id="label-placeholder"
              type="text"
              nbInput
              fullWidth
              [(ngModel)]="questions[current].placeholder"
              placeholder="Placeholder"
              fieldSize="small"
            />
          </div>
        </div>
      </div>
      <div class="row" *ngIf="questions[current].type == 'short'">
        <div class="col-md-12">
          <div class="mb-2">
            <label for="input-type" class="form-label label">Input Type</label>
            <nb-select
              size="small"
              [(ngModel)]="questions[current].inputType"
              fullWidth
              placeholder="Select Input Type"
            >
              <nb-option value="text">Text</nb-option>
              <nb-option value="number">Number</nb-option>
              <nb-option value="email">Email</nb-option>
              <nb-option value="password">Password</nb-option>
              <nb-option value="date">Date</nb-option>
              <nb-option value="datetime">Datetime</nb-option>
              <!-- <nb-option value="menu-option">Menu Option</nb-option> -->
            </nb-select>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div
            class="mt-2 mb-2"
            style="
              display: flex;
              align-items: center;
              justify-content: space-between;
            "
          >
            <label for="re" class="form-label label" style="margin-bottom: 0"
              >Required</label
            >
            <nb-toggle
              [(checked)]="questions[current].isRequired"
              status="basic"
            >
            </nb-toggle>
          </div>
        </div>
      </div>

      <div
        class="row"
        *ngIf="
          (questions[current].type == 'short' ||
            questions[current].type == 'long') &&
          questions[current].inputType != 'menu-option' &&
          questions[current].inputType != 'date' &&
          questions[current].inputType != 'datetime'
        "
      >
        <div class="col-md-6">
          <div class="mb-2">
            <label for="label-min" class="form-label label"
              >Min Characters</label
            >
            <input
              id="label-min"
              type="number"
              nbInput
              min="0"
              fullWidth
              [(ngModel)]="questions[current].minLength"
              (input)="handleOnlyIntegerNumber($event)"
              placeholder="Min Characters"
              fieldSize="small"
            />
          </div>
        </div>
        <div class="col-md-6">
          <div class="mb-2">
            <label for="label-max" class="form-label label"
              >Max Characters</label
            >
            <input
              id="label-max"
              type="number"
              nbInput
              fullWidth
              min="1"
              [(ngModel)]="questions[current].maxLength"
              (input)="handleOnlyIntegerNumber($event)"
              placeholder="Max Characters"
              fieldSize="small"
            />
          </div>
        </div>
      </div>
      <div
        class="row"
        *ngIf="
          (questions[current].type == 'short' ||
            questions[current].type == 'long') &&
          questions[current].inputType != 'menu-option'
        "
      >
        <div class="col-md-12">
          <div class="mb-2">
            <label for="label-validation" class="form-label label"
              >Validation Error Message</label
            >
            <input
              id="label-validation"
              type="text"
              nbInput
              fullWidth
              [(ngModel)]="questions[current].validationErrorMessage"
              placeholder="Validation Error Message"
              fieldSize="small"
            />
          </div>
        </div>
      </div>
      <div
        class="flex-title"
        *ngIf="
          questions[current].type != 'short' &&
          questions[current].type != 'long'
        "
      >
        <span class="title">Menu Option</span>

        <span class="line"></span>
      </div>
      <div
        class="row"
        *ngIf="
          questions[current].type != 'short' &&
          questions[current].type != 'long'
        "
      >
        <div class="col-md-12">
          <div
            class="mb-2 example-list"
            cdkDropList
            (cdkDropListDropped)="dropOptions($event)"
          >
            <div class="row">
              <div class="col-md-5">
                <label for="dn" class="form-label label">Display Name</label>
              </div>
              <div class="col-md-5">
                <label for="vn" class="form-label label">Value</label>
              </div>
            </div>
            <div
              cdkDrag
              *ngFor="
                let answer of questions[current].answerOption;
                let x = index
              "
              class="menu-option example-box mb-2"
              style="
                align-items: end;
                cursor: default;
                padding: 0 !important;
                width: 96%;
              "
            >
              <div class="col-md-5">
                <div>
                  <nb-form-field>
                    <nb-icon
                      nbPrefix
                      [icon]="questions[current].icon"
                      pack="eva"
                    ></nb-icon>
                    <input
                      [id]="'dn' + x"
                      type="text"
                      nbInput
                      fullWidth
                      [(ngModel)]="answer.name"
                      placeholder="Display Name"
                      fieldSize="small"
                      (blur)="onNameChange($event, x)"
                    />
                  </nb-form-field>
                </div>
              </div>
              <div class="col-md-5">
                <div>
                  <input
                    [id]="'va' + x"
                    type="text"
                    nbInput
                    fullWidth
                    [(ngModel)]="answer.value"
                    placeholder="Value"
                    fieldSize="small"
                    (blur)="onValueChange($event, x)"
                  />
                </div>
              </div>
              <div class="col-md-1">
                <div>
                  <button nbButton size="small" (click)="removeOption(x)">
                    <nb-icon icon="trash-2-outline"></nb-icon>
                  </button>
                </div>
              </div>
              <div style="height: 1.65rem; cursor: grab">
                <div
                  cdkDragHandle
                  class="move-icon"
                  style="display: flex; align-items: center"
                >
                  <nb-icon
                    style="margin-right: -14px"
                    status="basic"
                    icon="more-vertical-outline"
                  ></nb-icon>
                  <nb-icon
                    status="basic"
                    icon="more-vertical-outline"
                  ></nb-icon>
                </div>
              </div>
            </div>
          </div>
          <div class="add-grammar mt-3 mb-2">
            <button
              (click)="addNewOption()"
              shape="round"
              type="button"
              size="tiny"
              status="primary"
              nbButton
            >
              <nb-icon icon="plus-outline"></nb-icon>
            </button>
            <span style="font-size: 13px">More Options</span>
          </div>
        </div>
      </div>
      <div
        class="row"
        *ngIf="
          (questions[current].type == 'short' ||
            questions[current].type == 'long') &&
          questions[current].inputType != 'menu-option'
        "
      >
        <div class="col-md-12">
          <div class="mb-2">
            <ngx-multiple-grammar
              [grammarArray]="questions[current].grammar"
              [sizeButton]="'tiny'"
              [customGrammar]="false"
              [phraseListGrammar]="false"
            ></ngx-multiple-grammar>
          </div>
        </div>
      </div>
      <div
        class="flex-title"
        *ngIf="
          (questions[current].type == 'short' ||
            questions[current].type == 'long') &&
          questions[current].inputType == 'menu-option'
        "
      >
        <span class="title">Menu Option</span>

        <span class="line"></span>
      </div>
      <div
        class="row"
        *ngIf="
          (questions[current].type == 'short' ||
            questions[current].type == 'long') &&
          questions[current].inputType == 'menu-option'
        "
      >
        <div class="col-md-12">
          <div class="mb-2">
            <div
              style="display: flex; flex-direction: column; gap: 1rem"
              class="example-list"
              cdkDropList
              (cdkDropListDropped)="dropMenuOptions($event)"
            >
              <div
                cdkDrag
                class="example-box"
                style="
                  display: flex;
                  flex-direction: column;
                  gap: 1rem;
                  padding: 0 !important;
                "
                *ngFor="
                  let item of questions[current].menuOption;
                  let i = index
                "
              >
                <div
                  style="
                    width: 100%;
                    display: flex;
                    align-items: start;
                    justify-content: space-between;
                    gap: 0.5rem;
                  "
                >
                  <nb-accordion
                    style="
                      flex: 1;
                      box-shadow: 0 0 #0000;
                      border: 1px solid #e9edf4;
                      border-radius: 5px;
                    "
                  >
                    <nb-accordion-item style="border-top: 0">
                      <nb-accordion-item-header style="padding: 0">
                        <input
                          type="text"
                          fieldSize="small"
                          nbInput
                          style="border: none; padding-right: 3rem"
                          fullWidth
                          [(ngModel)]="item.name"
                          (click)="$event.stopPropagation()"
                          (keydown)="$event.stopPropagation()"
                          placeholder="Answer Value"
                        />
                      </nb-accordion-item-header>
                      <nb-accordion-item-body>
                        <div
                          *ngFor="let itemLanguage of item.value; let j = index"
                          style="margin-bottom: 1rem"
                        >
                          <label
                            [for]="'itemLanguage' + i + j"
                            class="form-label label"
                            >{{ itemLanguage.name }}</label
                          >
                          <input
                            [id]="'itemLanguage' + i + j"
                            type="text"
                            fieldSize="small"
                            nbInput
                            fullWidth
                            [(ngModel)]="itemLanguage.value"
                            [placeholder]="
                              'Answer Display ' + itemLanguage.name
                            "
                          />
                        </div>
                      </nb-accordion-item-body>
                    </nb-accordion-item>
                  </nb-accordion>
                  <button
                    type="button"
                    size="small"
                    (click)="removeMenuOption(i)"
                    nbButton
                  >
                    <nb-icon icon="trash-2-outline"></nb-icon>
                  </button>
                  <div style="margin-top: 0.3rem; cursor: grab">
                    <div
                      cdkDragHandle
                      class="move-icon"
                      style="display: flex; align-items: center"
                    >
                      <nb-icon
                        style="margin-right: -14px"
                        status="basic"
                        icon="more-vertical-outline"
                      ></nb-icon>
                      <nb-icon
                        status="basic"
                        icon="more-vertical-outline"
                      ></nb-icon>
                    </div>
                  </div>
                </div>
              </div>
              <div class="add-grammar mb-2" style="margin-top: 0">
                <button
                  (click)="addNewAnswer()"
                  shape="round"
                  type="button"
                  size="tiny"
                  status="primary"
                  nbButton
                >
                  <nb-icon icon="plus-outline"></nb-icon>
                </button>
                <span style="font-size: 13px">More Options</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="flex-title" style="margin-bottom: 1.25rem">
        <span class="title">Assign Variable</span>
        <span class="line"></span>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="mb-2">
            <label for="a" class="form-label label">User Answer</label>
            <ngx-input-value-static
              placeHolder="Select User Answer"
              selectAttribute="true"
              [propertyInput]="questions[current].answerVariable"
              (propertyEmit)="getAttribute($event)"
            >
            </ngx-input-value-static>
          </div>
        </div>
      </div>
      <!-- <div
        class="row"
        *ngIf="
          questions[current].type == 'short' ||
          questions[current].type == 'long'
        "
      >
        <div class="col-md-12">
          <div class="mb-2">
            <label for="intent" class="form-label label">User Intent</label>
            <ngx-input-value-static
              selectAttribute="true"
              [propertyInput]="questions[current].intentVariable"
              (propertyEmit)="getAttributeIntent($event)"
            >
            </ngx-input-value-static>
          </div>
        </div>
      </div> -->
    </nb-card-body>
  </nb-card>
</ng-template>
<ng-template #templateRef>
  <nb-card class="example-box-2" cdkDrag cdkDragRootElement=".cdk-overlay-pane">
    <nb-card-header
      style="
        padding: 1rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 13px;
      "
    >
      <span>Question Type</span>
    </nb-card-header>
    <nb-card-body class="question-add">
      <nb-list class="custom-list" style="max-height: 25rem">
        <nb-list-item
          class="custom-list-item"
          *ngFor="let item of questionArray"
          (click)="addToObject(item)"
        >
          <div class="grammar-name-wrapper">
            <nb-icon
              status="basic"
              [icon]="item.icon || 'hash-outline'"
            ></nb-icon>
            <span class="grammar-name">{{ item.name }}</span>
          </div>
          <div class="action-wrapper">
            <button
              style="opacity: 0"
              ghost
              class="tbl-action"
              nbButton
              size="small"
            >
              <nb-icon icon="edit-outline"></nb-icon>
            </button>
          </div>
        </nb-list-item> </nb-list
    ></nb-card-body>
  </nb-card>
</ng-template>
