<ngx-datatable
  #table
  class="bootstrap material custom-spinner"
  [ngClass]="{ 'is-not-modal': !isModal }"
  [footerHeight]="50"
  [columnMode]="'flex'"
  [columns]="columns"
  rowHeight="'flex-grow'"
  [rows]="rows"
  [externalPaging]="true"
  [count]="page.totalElements"
  [externalSorting]="true"
  [offset]="page.pageNumber"
  [limit]="page.size"
  (page)="setPage($event)"
  (sort)="onSort($event)"
  [nbSpinner]="loading"
  nbSpinnerSize="giant"
  nbSpinnerStatus="info"
>
  <ng-template #header let-column="column">
    <header-table
      [column]="column"
      (handleFilter)="filterHeader($event)"
    ></header-table>
  </ng-template>
  <ng-template #headerDay let-column="column">
    <header-table
      [column]="column"
      [type]="'date'"
      (handleFilter)="filterHeader($event)"
    ></header-table>
  </ng-template>
  <ngx-datatable-footer>
    <ng-template
      let-rowCount="rowCount"
      let-pageSize="pageSize"
      let-selectedCount="selectedCount"
      let-curPage="curPage"
      let-offset="offset"
      ngx-datatable-footer-template
    >
      <footer-table
        *ngIf="rowCount > 0"
        [page]="curPage"
        [visiblePagesCount]="'5'"
        [size]="pageSize"
        [count]="rowCount"
        [hidden]="false"
        (change)="table.onFooterPage($event)"
      >
      </footer-table>
    </ng-template>
  </ngx-datatable-footer>
  <ngx-datatable-column
    *ngIf="showMoreContent"
    name="ID"
    prop="callId"
    [flexGrow]="2"
    [draggable]="false"
    [resizeable]="false"
    [headerTemplate]="header"
  >
  </ngx-datatable-column>
  <ngx-datatable-column
    *ngIf="showMoreContent"
    [draggable]="false"
    [flexGrow]="1.5"
    [resizeable]="false"
    name="From"
    prop="fromNumber"
    [headerTemplate]="header"
  >
    <ng-template let-row="row" ngx-datatable-cell-template>
      {{ row.fromNumber | phone }}
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column
    *ngIf="showMoreContent"
    [draggable]="false"
    [resizeable]="false"
    [flexGrow]="1.5"
    name="To"
    prop="toNumber"
    [headerTemplate]="header"
  >
    <ng-template let-row="row" ngx-datatable-cell-template>
      {{ row.toNumber | phone }}
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column
    [draggable]="false"
    [resizeable]="false"
    name="Type"
    [flexGrow]="1"
    prop="type"
    [headerTemplate]="header"
  >
  </ngx-datatable-column>
  <ngx-datatable-column
    [flexGrow]="2"
    [draggable]="false"
    [resizeable]="false"
    name="Created"
    prop="created"
    [headerTemplate]="headerDay"
  >
    <ng-template let-row="row" ngx-datatable-cell-template>
      {{ row.created | date : "MM/dd/yyyy HH:mm:ss" }}
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column
    [draggable]="false"
    [resizeable]="false"
    [flexGrow]="1.5"
    name="Log Level"
    prop="logType"
    [headerTemplate]="header"
  >
    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
      <span
        *ngIf="row.logType == 'error'"
        title="ERROR"
        class="status-code error"
      >
        ERROR
      </span>
      <span
        *ngIf="row.logType == 'action' || row.logType == 'debug'"
        title="DEBUG"
        class="status-code debug"
      >
        DEBUG
      </span>
      <span
        *ngIf="
          row.logType == 'info' ||
          (row.logType != 'error' &&
            row.logType != 'action' &&
            row.logType != 'debug')
        "
        title="INFO"
        class="status-code"
      >
        INFO
      </span>
    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column
    [draggable]="false"
    [resizeable]="false"
    name="Log Content"
    prop="logContent"
    [flexGrow]="6"
    [headerTemplate]="header"
  >
    <ng-template let-row="row" ngx-datatable-cell-template>
      <span
        style="word-break: break-word"
        *ngFor="let line of row.logContent.split('|'); let last = last"
      >
        {{ line }}<ng-container *ngIf="!last"><br /></ng-container>
      </span>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column
    [draggable]="false"
    [resizeable]="false"
    name="Variables"
    prop="attribute"
    [headerTemplate]="header"
    [flexGrow]="2"
  >
    <ng-template let-row="row" ngx-datatable-cell-template>
      <button
        [nbPopover]="templateRef"
        nbButton
        size="tiny"
        nbPopoverTrigger="click"
        style="height: 1rem; width: 2.8rem"
        (click)="showLog(row.attribute)"
      >
        Show
      </button>
    </ng-template>
  </ngx-datatable-column>
</ngx-datatable>
<ng-template #templateRef>
  <nb-card style="margin-bottom: 0" id="popup-variables"
    ><nb-card-header
      style="
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
      "
      ><span>Variables</span>
    </nb-card-header>
    <nb-card-body style="padding: 0">
      <div class="log-content">
        <div class="fix">
          <ngx-json-viewer [json]="logContent"></ngx-json-viewer>
        </div>
      </div>
    </nb-card-body>
    <nb-card-footer style="padding-top: 0.5rem; padding-bottom: 0.5rem">
      <div style="width: 100%; display: flex; justify-content: end">
        <button
          nbButton
          type="button"
          size="small"
          [title]="copyTitle"
          (click)="copyAttribute()"
        >
          <nb-icon icon="copy-outline"></nb-icon>
        </button></div
    ></nb-card-footer>
  </nb-card>
</ng-template>
