<div class="action-button">
  <button nbButton size="small" (click)="handleCancelActions()">
    <nb-icon icon="close-outline"></nb-icon> <span>Cancel</span>
  </button>
  <button nbButton size="small" status="primary" (click)="handleSaveActions()">
    <nb-icon icon="save-outline"></nb-icon>

    <span> Save</span>
  </button>
</div>
