<form
  [formGroup]="datasetForm"
  novalidate
  (submit)="onSubmit()"
  style="height: 100%"
>
  <nb-card [ngClass]="{ 'card-action': isAction }" style="height: 100%">
    <nb-card-header>
      <div
        *ngIf="!isAction"
        class="d-flex justify-content-between align-items-center w-100"
      >
        <div>
          <button
            type="button"
            (click)="back()"
            status="basic"
            size="small"
            nbButton
          >
            <nb-icon icon="arrow-ios-back"></nb-icon>
          </button>
          <button
            type="submit"
            [disabled]="
              !datasetForm.valid ||
              isLoading ||
              isSaveLoading ||
              (data.type == 'URL' && listUrl.length <= 0) ||
              (data.type == 'DOCUMENT' && totalFile <= 0)
            "
            [nbSpinner]="isLoading"
            nbSpinnerSize="small"
            nbSpinnerStatus="primary"
            status="primary"
            class="ms-2"
            size="small"
            nbButton
          >
            <nb-icon icon="save-outline"></nb-icon>Save & Train
          </button>
        </div>
        <div>
          <span
            class="btn-action failed"
            *ngIf="data.status == 'FAILED' || data.status == 'False'"
            >Failed</span
          >
          <span
            class="btn-action not-train"
            *ngIf="data.status == 'NOT-TRAINED' || data.status == ''"
            >Not Trained</span
          >
          <span
            class="btn-action success"
            *ngIf="data.status == 'TRAINED' || data.status == 'True'"
            >Trained</span
          >
          <span
            class="btn-action training"
            *ngIf="data.status == 'TRAINING' || data.status == null"
            >Training</span
          >
        </div>
      </div>
      <div class="d-flex justify-content-between" *ngIf="isAction">
        <div>
          {{ title }} - Status:
          <span
            class="btn-action failed"
            *ngIf="data.status == 'FAILED' || data.status == 'False'"
            >Failed</span
          >
          <span
            class="btn-action not-train"
            *ngIf="data.status == 'NOT-TRAINED' || data.status == ''"
            >Not Trained</span
          >
          <span
            class="btn-action success"
            *ngIf="data.status == 'TRAINED' || data.status == 'True'"
            >Trained</span
          >
          <span
            class="btn-action training"
            *ngIf="data.status == 'TRAINING' || data.status == null"
            >Training</span
          >
        </div>
        <button
          (click)="dismiss()"
          type="button"
          class="btn-close"
          aria-label="Close"
        ></button>
      </div>
    </nb-card-header>
    <nb-card-body>
      <div class="dataset-wrap">
        <div class="row">
          <div class="col-md-5 pe-4">
            <div class="col-md-12 mb-2">
              <label for="input-name" class="form-label label required"
                >Name</label
              >
              <input
                autofocus
                #inputName
                type="text"
                formControlName="name"
                nbInput
                [(ngModel)]="data.name"
                fullWidth
                [status]="
                  datasetForm.controls['name'].invalid &&
                  (datasetForm.controls['name'].dirty ||
                    datasetForm.controls['name'].touched)
                    ? 'danger'
                    : 'basic'
                "
                id="input-name"
                placeholder="Name"
                fieldSize="small"
              />
              <ng-container
                *ngIf="
                  datasetForm.controls['name'].invalid &&
                  (datasetForm.controls['name'].dirty ||
                    datasetForm.controls['name'].touched)
                "
              >
                <p
                  class="caption status-danger"
                  style="margin-bottom: 0"
                  *ngIf="
                    datasetForm.controls['name'].hasError('required') ||
                    datasetForm.controls['name'].hasError('whitespace')
                  "
                >
                  Name is required
                </p>
                <p
                  class="caption status-danger"
                  style="margin-bottom: 0"
                  *ngIf="datasetForm.controls['name'].errors.isExist"
                >
                  Name already exist.
                </p>
                <p
                  class="caption status-danger"
                  style="margin-bottom: 0"
                  *ngIf="
                    datasetForm.controls['name'].errors.maxlength &&
                    !datasetForm.controls['name'].hasError('required') &&
                    !datasetForm.controls['name'].hasError('whitespace')
                  "
                >
                  Maximum length is 50 characters.
                </p>
                <p
                  class="caption status-danger"
                  style="margin-bottom: 0"
                  *ngIf="
                    datasetForm.controls['name'].errors.regex &&
                    !datasetForm.controls['name'].errors.maxlength &&
                    !datasetForm.controls['name'].hasError('required') &&
                    !datasetForm.controls['name'].hasError('whitespace')
                  "
                >
                  Name cannot contain any of the following characters: \ / : *?
                  " < > |
                </p>
                <p
                  class="caption status-danger"
                  *ngIf="datasetForm.controls['name'].errors.serverError"
                >
                  {{ datasetForm.controls["name"].errors.serverError }}
                </p>
              </ng-container>
            </div>
            <div class="col-md-12 mb-2" *ngIf="data.type == 'DOCUMENT'">
              <label for="type" class="form-label label required">Type</label>
              <nb-select
                fullWidth
                placeholder="Select Type"
                [(ngModel)]="data.type"
                formControlName="type"
                size="small"
              >
                <nb-option value="URL">URL</nb-option>
                <nb-option value="DOCUMENT">DOCUMENT</nb-option>
              </nb-select>
            </div>
            <div class="col-md-12 mb-2" *ngIf="data.type == 'URL'">
              <label for="type" class="form-label label required">Type</label>
              <nb-select
                fullWidth
                placeholder="Select Type"
                [(ngModel)]="data.type"
                formControlName="type"
                size="small"
              >
                <nb-option value="URL">URL</nb-option>
                <nb-option value="DOCUMENT">DOCUMENT</nb-option>
              </nb-select>
            </div>
            <!-- <div class="col-md-3 mb-2" *ngIf="data.type == 'URL'">
            <label for="type" class="form-label label">Retrain Time (day)</label>
            <input
              type="text"
              nbInput
              fieldSize="small"
              placeholder="Retrain Time"
              formControlName="retrainTime"
              [(ngModel)]="data.retrainTime"
              fullWidth
              [status]="
                datasetForm.controls['retrainTime'].invalid &&
                (datasetForm.controls['retrainTime'].dirty || datasetForm.controls['retrainTime'].touched)
                  ? 'danger'
                  : 'basic'
              "
            />
            <ng-container
              *ngIf="
                datasetForm.controls['retrainTime'].invalid &&
                (datasetForm.controls['retrainTime'].dirty || datasetForm.controls['retrainTime'].touched)
              "
            >
              <p
                class="caption status-danger"
                style="margin-bottom: 0 !important"
                *ngFor="let errorMessage of datasetForm.controls.retrainTime['errorMessages']"
              >
                {{ errorMessage }}
              </p>
            </ng-container>
          </div> -->
            <div class="col-md-12 mb-2">
              <label for="description" class="form-label label"
                >Description</label
              >
              <textarea
                id="description"
                type="text"
                nbInput
                placeholder="Description"
                formControlName="description"
                style="height: 90px"
                [(ngModel)]="data.description"
                fullWidth
              >
              </textarea>
            </div>
            <div class="mb-2" *ngIf="data.type == 'URL'">
              <nb-checkbox
                (checkedChange)="toggleExtract($event)"
                [checked]="checkExtract"
                >Extract URLs</nb-checkbox
              >
            </div>
            <div class="col-md-12" *ngIf="data.type == 'URL'">
              <div class="col-md-12 mb-3">
                <div class="label-wrapper">
                  <label for="name" class="form-label label">Website URL</label>
                </div>
                <div class="url-group">
                  <input
                    formControlName="mainURL"
                    nbInput
                    fieldSize="small"
                    [(ngModel)]="mainURL"
                    fullWidth
                    id="input-name"
                    placeholder="Website URL"
                  />
                  <div
                    nbTooltip="Searching child URLs... please wait..."
                    nbTooltipPlacement="bottom"
                    nbTooltipStatus="control"
                    [nbTooltipDisabled]="!isLoadingExtract"
                  >
                    <button
                      nbButton
                      status="primary"
                      size="small"
                      type="button"
                      (click)="extractURL()"
                      [disabled]="!mainURL || isLoadingExtract"
                    >
                      <nb-icon
                        style="width: 19px; height: 19px"
                        [icon]="getIcon()"
                        [ngClass]="{ 'loading-icon': isLoadingExtract }"
                      ></nb-icon>
                    </button>
                  </div>
                </div>
              </div>
              <div *ngIf="isRetrieve" class="retrieve-processing">
                <div class="p-4 text-center">
                  <div>
                    Please wait while we retrieve the pages. This may take some
                    time.
                  </div>
                  <div>Please don't close or refresh the page.</div>
                  <div
                    style="height: 25px"
                    [nbSpinner]="isLoadingExtract"
                    nbSpinnerSize="medium"
                    nbSpinnerStatus="info"
                  ></div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-7 mb-3" *ngIf="data.type == 'URL'">
            <div
              #urlBox
              class="col-md-12 mb-3 pe-3 urls-wrapper"
              [ngClass]="isAction ? 'url-area-action' : 'url-area'"
            >
              <label for="name" class="form-label label required">Pages</label>
              <div *ngIf="listUrl.length <= 0">
                <span style="font-size: 13px">No data to display</span>
              </div>
              <div class="check-wrapper-all" *ngIf="listUrl.length > 0">
                <nb-checkbox
                  (checkedChange)="toggleAll($event)"
                  [checked]="checkAll"
                  >All URLs</nb-checkbox
                >
                <button
                  nbButton
                  size="tiny"
                  type="button"
                  (click)="removeAll()"
                  *ngIf="!checkAll"
                >
                  <nb-icon icon="trash" status="basic"></nb-icon>
                </button>
              </div>
              <div
                style="padding-left: 24px"
                *ngFor="let url of listUrl; let i = index"
                class="check-wrapper"
              >
                <nb-checkbox
                  (checkedChange)="toggle($event, i)"
                  [checked]="url.enable"
                  >{{ url?.tokens ? url.tokens + " tokens - " : "" }}
                  {{ url.url }}</nb-checkbox
                >
                <button
                  nbButton
                  size="tiny"
                  type="button"
                  (click)="removeUrl(i)"
                >
                  <nb-icon icon="trash" status="basic"></nb-icon>
                </button>
              </div>
            </div>
            <div class="total-count" *ngIf="data.type == 'URL'">
              <span>{{ listUrl.length }} total</span>
            </div>
          </div>
          <div
            class="col-md-7 document"
            *ngIf="data.type == 'DOCUMENT'"
            style="overflow-y: scroll"
          >
            <div class="col-md-12">
              <label for="name" class="form-label label required">Data</label>
              <div>
                <uppy-dashboard [uppy]="uppy"> </uppy-dashboard>
              </div>
            </div>
          </div>
        </div>
        <div class="last-modified" *ngIf="data.lastModified">
          <span
            >Last modified:
            {{ data.lastModified | date : "MM/dd/yyyy HH:mm:ss" }}</span
          >
        </div>
      </div>
    </nb-card-body>
    <nb-card-footer *ngIf="isAction">
      <div class="d-flex justify-content-end">
        <button
          type="submit"
          [disabled]="
            !datasetForm.valid ||
            isLoading ||
            isSaveLoading ||
            (data.type == 'URL' && listUrl.length <= 0) ||
            (data.type == 'DOCUMENT' && totalFile <= 0)
          "
          status="primary"
          class="ms-2"
          size="small"
          nbButton
        >
          <nb-icon icon="save-outline"></nb-icon>Save & Train
        </button>
      </div>
    </nb-card-footer>
  </nb-card>
</form>
