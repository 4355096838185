import { Component, HostListener, OnInit } from "@angular/core";
import { MxCell, MxGraph } from "../../../../@core/model/mxgraph";
import {
  NbDialogService,
  NbGlobalPhysicalPosition,
  NbToastrService,
  NbWindowRef,
} from "@nebular/theme";
import { DataService } from "../../../../@core/utils/data.service";
import { DataSet } from "../../../../@core/model/dataset";
import { DataSetClient, DatasetDto } from "../../../../System-api";
import { DatasetComponent } from "../../../../portal/customer-role/dataset/dataset.component";
import { ConfirmDialogComponent } from "../../../../shared/confirm-dialog/confirm-dialog.component";
import { UntypedFormGroup } from "@angular/forms";
import {
  RxFormBuilder,
  RxwebValidators,
} from "@rxweb/reactive-form-validators";
import { GraphHandlerService } from "../../../../@core/utils/graph.service";
import { noWhitespaceValidator } from "../../../../@core/utils/helpers";

@Component({
  selector: "ngx-dataset",
  templateUrl: "./dataset-action.component.html",
  styleUrls: ["./dataset-action.component.scss"],
})
export class DataSetActionComponent implements OnInit {
  cell: MxCell;
  graph: MxGraph;
  data: DataSet = new DataSet();
  datasets: DatasetDto[];
  datasetIdSelected: number = 0;
  datasetSelected: DatasetDto;
  labels: any[];
  isLoading = false;
  cancelAction = false;
  formGroup: UntypedFormGroup;
  attributes: any = this.dataService.ivrAttribute;
  mentionConfig = {
    items: this.attributes,
    triggerChar: "{",
    labelKey: "value",
    disableSort: true,
    mentionSelect: (item) => {
      return "{" + item.value + "}";
    },
  };
  constructor(
    protected windowRef: NbWindowRef,
    public dataService: DataService,
    private dataSetClient: DataSetClient,
    private dialogService: NbDialogService,
    private toastrService: NbToastrService,
    private formBuilder: RxFormBuilder,
    private graphService: GraphHandlerService
  ) {
    this.createForm();
  }
  createForm() {
    this.formGroup = this.formBuilder.group({
      dataset: ["", [RxwebValidators.required(), noWhitespaceValidator]],
      labels: ["", [RxwebValidators.required(), noWhitespaceValidator]],
    });
  }
  handleSaveAction() {
    this.windowRef.close();
  }
  handleCancelAction() {
    this.cancelAction = true;
    this.windowRef.close(true);
  }
  getDataSets() {
    this.isLoading = true;
    this.dataSetClient.getAll().subscribe((result) => {
      if (result != null) {
        this.datasets = result.datasetDtos;
        if (this.data.datasetId == "0") {
          this.data.datasetId =
            this.datasets[this.datasets.length - 1].id.toString();
          this.data.labels = "";
        }
        if (this.data.datasetId != "") {
          this.datasetSelected = this.datasets?.find(
            (o) => o.id.toString() == this.data.datasetId
          );
          if (this.datasetSelected) {
            if (this.datasetSelected?.labels?.length > 0) {
              this.labels = this.datasetSelected.labels?.split(",");
            }
            if (this.data.labels != "") {
              let checkExist = this.labels?.find(
                (label) => label == this.data.labels
              );
              if (!checkExist) {
                this.data.labels = "";
              }
            }
            if (!this.data.nameDataset || this.data.nameDataset == "") {
              this.data.nameDataset = this.datasetSelected.name;
            }
            if (this.datasetSelected) {
              this.datasetIdSelected =
                Number(this.data.datasetId) == 0 ||
                isNaN(Number(this.data.datasetId))
                  ? null
                  : Number(this.data.datasetId);
            } else {
              this.datasetIdSelected = null;
            }
          } else {
            this.data.datasetId = null;
            this.datasetIdSelected = null;
            this.data.labels = "";
          }
        } else {
          this.data.datasetId = null;
          this.data.labels = "";
          this.datasetIdSelected = null;
        }
      }
      this.isLoading = false;
    });
  }
  viewDataset(id) {
    let data = this.datasets?.find((x) => x.id == id);
    this.dialogService
      .open(DatasetComponent, {
        autoFocus: false,
        context: {
          dataset: data,
        },
      })
      .onClose.subscribe(() => {
        this.getDataSets();
      });
  }
  ngOnInit() {
    for (let [key, value] of Object.entries(this.data)) {
      this.data[key] = this.cell.getAttribute(key);
    }
    this.getDataSets();
    this.windowRef.onClose.subscribe(() => this.submit());
  }

  addDataset() {
    const createDataset = this.dialogService.open(DatasetComponent, {
      autoFocus: false,
    });
    createDataset.componentRef.instance.onAdd.subscribe((rs) => {
      if (rs) {
        this.getDataSets();
        this.data.datasetId = "0";
      }
    });
  }

  datasetChange(event) {
    this.data.labels = "";
    this.labels = [];
    if (event?.id) {
      this.datasetSelected = this.datasets.find((o) => o.id == event.id);
      if (this.datasetSelected.labels.length > 0) {
        this.labels = this.datasetSelected.labels.split(",");
      }
      this.data.datasetId = event.id;
      this.data.nameDataset = event.name;
    } else {
      this.data.datasetId = "";
      this.data.nameDataset = "";
    }
  }

  updateDataset(e: any, dataset: any) {
    e.stopPropagation();
    this.dialogService
      .open(DatasetComponent, {
        autoFocus: false,
        context: {
          dataset: dataset,
        },
      })
      .onClose.subscribe(() => {
        this.getDataSets();
      });
  }

  removeDataset(e: any, dataset: any) {
    e.stopPropagation();
    this.dialogService
      .open(ConfirmDialogComponent, {
        autoFocus: false,
        context: {
          question: "This dataset will be deleted. Sure?",
          textYes: "Delete",
          textNo: "Cancel",
          statusYes: "danger",
          statusNo: "basic",
        },
      })
      .onClose.subscribe((isConfirm) => {
        if (isConfirm) {
          this.isLoading = true;
          this.dataSetClient.delete(dataset.id).subscribe((rs) => {
            this.showToast(rs);
            this.isLoading = false;
            if (rs) {
              if (this.data.datasetId == dataset.id) {
                this.labels = [];
                this.data.datasetId = "";
              }
              this.getDataSets();
            }
          });
        }
      });
  }

  showToast(result) {
    if (result) {
      this.toastrService.show("Delete dataset successfully", `Notification`, {
        position: NbGlobalPhysicalPosition.BOTTOM_LEFT,
        status: "success",
      });
    } else {
      this.toastrService.show("Delete dataset unsuccessfully", `Notification`, {
        position: NbGlobalPhysicalPosition.BOTTOM_LEFT,
        status: "danger",
      });
    }
  }

  submit() {
    if (!this.cancelAction && !this.isLoading) {
      for (let [key, value] of Object.entries(this.data)) {
        if (typeof this.data[key] == "string") {
          value = value?.trim();
        }
        this.cell.setAttribute(key, value || "");
      }
      const reportCell = this.graph.getModel().getCell(this.cell.getId());
      const fieldsRequired = [this.data.datasetId];
      const check = this.graphService.checkIsRequired(
        this.graph,
        fieldsRequired,
        reportCell
      );
      this.cell.setAttribute("checkFields", check?.toString());
    }
  }
}
