<div class="action-config-wrapper">
  <div class="config-action">
    <div class="mb-2">
      <label
        for="log-level"
        class="form-label d-flex label"
        style="align-items: center"
        ><span>Log Level</span>
        <nb-icon
          class="ms-1"
          style="width: 15px"
          nbTooltip="You can turn off the 'info' logging at settings."
          nbTooltipPlacement="end"
          nbTooltipStatus="basic"
          icon="question-mark-circle-outline"
        >
        </nb-icon
      ></label>
      <nb-select
        placeholder="Select Log Level"
        size="small"
        [(ngModel)]="data.type"
        fullWidth
        id="log-level"
      >
        <nb-option *ngFor="let type of types" [value]="type.value">{{
          type.name
        }}</nb-option>
      </nb-select>
    </div>
    <div class="mb-2">
      <label for="content-log" class="form-label label required">Content</label>
      <form [formGroup]="formGroup">
        <textarea
          nbInput
          fullWidth
          formControlName="content"
          placeholder="Content"
          [(ngModel)]="data.content"
          (drop)="drop($event)"
          style="
            display: block;
            overflow-y: scroll;
            max-height: 450px;
            height: 120px;
            min-height: 120px;
          "
          (input)="onChangeContent()"
          id="content-log"
          [mention]="attributes"
          [mentionConfig]="mentionConfig"
          [status]="
            formGroup.controls['content'].invalid &&
            (formGroup.controls['content'].dirty ||
              formGroup.controls['content'].touched)
              ? 'danger'
              : 'basic'
          "
        ></textarea>
        <ng-container
          *ngIf="
            formGroup.controls['content'].invalid &&
            (formGroup.controls['content'].dirty ||
              formGroup.controls['content'].touched)
          "
        >
          <p
            class="caption status-danger"
            style="margin-bottom: 0"
            *ngIf="
              formGroup.controls['content'].hasError('required') ||
              formGroup.controls['content'].hasError('whitespace')
            "
          >
            Content is required.
          </p>
        </ng-container>
      </form>
    </div>
  </div>
  <footer-action
    (handleCancelAction)="handleCancelAction()"
    (handleSaveAction)="handleSaveAction()"
  ></footer-action>
</div>
