import { ConfirmDialogComponent } from "./../../../../../shared/confirm-dialog/confirm-dialog.component";
import { filter } from "rxjs";
import { HttpClient } from "@angular/common/http";
import * as cheerio from "cheerio";
import {
  ChatGPTClient,
  CheckExistedGPTCommand,
  ExtractURLCommand,
  GetDocumentDataQuery,
} from "./../../../../../System-api";
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  ViewChild,
} from "@angular/core";
import { UntypedFormGroup, Validators } from "@angular/forms";
import {
  NbDialogRef,
  NbDialogService,
  NbGlobalPhysicalPosition,
  NbToastrService,
} from "@nebular/theme";
import {
  RxFormBuilder,
  RxwebValidators,
} from "@rxweb/reactive-form-validators";
import { Uppy } from "@uppy/core";
import { ValidInput } from "../../../../../@core/utils/helpers";
@Component({
  selector: "create-gpt-dataset",
  templateUrl: "./create-gpt-dataset.component.html",
  styleUrls: ["./create-gpt-dataset.component.scss"],
})
export class CreateGPTDatasetComponent implements OnInit {
  @ViewChild("inputName") inputName: ElementRef<any>;
  uppy: Uppy = new Uppy({
    autoProceed: true,
    restrictions: {
      maxFileSize: 90000000,
      maxNumberOfFiles: 5,
      minNumberOfFiles: 1,
      allowedFileTypes: [".txt", ".pdf", ".doc", ".json"],
    },
  });
  @ViewChild("textarea") $textarea: ElementRef<HTMLTextAreaElement>;
  @ViewChild("urlBox") urlBox: ElementRef<HTMLDivElement>;
  title: string;
  datasetForm: UntypedFormGroup;
  data: any = {
    id: 0,
    name: "",
    description: "",
    type: "",
    dataTrain: "",
    retrainTime: "",
    files: null,
  };
  url: string;
  isLoading = false;
  previousLength = 0;
  fileUpload: any;
  onAdd = new EventEmitter();
  mainURL: string = null;
  isLoadingExtract = false;
  checkAll = true;
  checkExtract = false;
  listUrl = [];
  newUrl = "";
  isExist: boolean = false;
  isNameLoading: boolean = false;
  currentName: string;
  timeout: any = null;
  totalFile = 0;
  constructor(
    private toastrService: NbToastrService,
    private formBuilder: RxFormBuilder,
    public ref: NbDialogRef<CreateGPTDatasetComponent>,
    private chatGPTClient: ChatGPTClient,
    private http: HttpClient,
    private dialogService: NbDialogService
  ) {}

  ngOnInit(): void {
    this.createForm();
    if (this.data.type) {
      if (this.data.type == "URL") {
        try {
          if (this.data.dataTrain) {
            this.listUrl = JSON.parse(this.data.dataTrain);
            this.resetToggle();
          }
        } catch {
          const dataTrain = this.data.dataTrain.split("\u2022").filter(Boolean);
          dataTrain.forEach((element) => {
            this.listUrl.push({ url: element, enable: true });
          });
          this.resetToggle();
        }
      } else {
        if (this.data.dataTrain) {
          const data = new GetDocumentDataQuery();
          data.path = this.data.dataTrain;
          this.isLoading = true;
          this.chatGPTClient.getDocumentData(data).subscribe((rs) => {
            if (rs?.documents.length > 0) {
              rs?.documents.forEach(
                (document) => {
                  this.handleAddFile(document);
                  this.isLoading = false;
                },
                (error) => {
                  this.isLoading = false;
                }
              );
            }
          });
        }
      }
      this.currentName = this.data.name;
    } else {
      this.data.type = "URL";
    }
    this.uppy.on("file-added", (file) => {
      var files = this.uppy.getFiles();
      this.totalFile = files.length;
    });
    this.uppy.on("file-removed", (file, reason) => {
      var files = this.uppy.getFiles();
      this.totalFile = files.length;
    });
  }

  handleAddFile(document) {
    const byteCharacters = atob(document.data);
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    const blob = new Blob(byteArrays, { type: "application/octet-stream" });

    // Set the file in Uppy
    this.uppy.addFile({
      source: document.source,
      data: blob,
      name: document.name,
    });
  }
  handleFocus() {
    setTimeout(() => {
      this.inputName?.nativeElement?.focus();
    }, 0);
  }
  removeAll() {
    this.dialogService
      .open(ConfirmDialogComponent, {
        autoFocus: false,
        context: {
          question: "All unchecked URLs will be deleted. Sure?",
          textYes: "Delete",
          textNo: "Cancel",
          statusYes: "danger",
          statusNo: "basic",
        },
      })
      .onClose.subscribe((isConfirm) => {
        if (isConfirm) {
          this.listUrl = this.listUrl.filter((item) => {
            return item.enable == true;
          });
          this.resetToggle();
          this.toastrService.show(
            `Remove All URLs successfully`,
            `Notification`,
            {
              position: NbGlobalPhysicalPosition.BOTTOM_LEFT,
              status: "success",
            }
          );
        }
      });
  }
  onChangeText(event) {
    const text = event.target.value;

    // Save the selection range
    const selectionStart = event.srcElement.selectionStart;
    const selectionEnd = event.srcElement.selectionEnd;

    // Replace the text
    let newText = text.replace(/(^|\n)([^\u2022])/g, "$1\u2022 $2");
    newText = newText.replace(/\u2022\s*\u2022/g, "\u2022");
    event.srcElement.value = newText;

    // Restore the selection range
    const newSelectionStart = selectionStart + (newText.length - text.length);
    const newSelectionEnd = selectionEnd + (newText.length - text.length);
    event.srcElement.setSelectionRange(newSelectionStart, newSelectionEnd);
    this.mainURL = newText;
    return newText;
  }
  removeUrl(index) {
    if (index != 0) {
      this.listUrl.splice(index, 1);
    } else {
      this.listUrl.shift();
    }
    this.resetToggle();
  }
  getIcon() {
    if (!this.isLoadingExtract && this.checkExtract) {
      return "search-outline";
    } else if (!this.isLoadingExtract && !this.checkExtract) {
      return "plus-outline";
    } else {
      return "refresh-outline";
    }
  }
  isURL(value: string): boolean {
    const urlPattern =
      /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
    return urlPattern.test(value);
  }
  addNewURL(url) {
    if (url && this.isURL(url)) {
      const data = this.listUrl.filter((item) => {
        return item.url == url;
      });
      if (data.length <= 0) {
        var obj = { url: url, enable: true };
        this.listUrl.push(obj);
        this.toastrService.show(`Add URL successfully`, `Notification`, {
          position: NbGlobalPhysicalPosition.BOTTOM_LEFT,
          status: "success",
        });
        setTimeout(() => {
          this.urlBox.nativeElement.scrollTop =
            this.urlBox.nativeElement.scrollHeight;
        }, 50);
      } else {
        this.toastrService.show(`${url} already exists`, `Notification`, {
          position: NbGlobalPhysicalPosition.BOTTOM_LEFT,
          status: "danger",
        });
      }
    } else {
      this.toastrService.show(`${url} not valid`, `Notification`, {
        position: NbGlobalPhysicalPosition.BOTTOM_LEFT,
        status: "danger",
      });
    }
  }
  toggle(event, index) {
    this.listUrl[index].enable = event;
    this.resetToggle();
  }
  resetToggle() {
    const data = this.listUrl?.filter((element) => {
      return element.enable == true;
    });
    if (data.length == this.listUrl.length) {
      this.checkAll = true;
    } else {
      this.checkAll = false;
    }
  }
  toggleExtract(event) {
    this.checkExtract = event;
  }
  toggleAll(event) {
    this.listUrl.forEach((item, index) => {
      this.listUrl[index].enable = event;
    });
    this.checkAll = event;
  }
  handleGetURL() {
    const dataTrain: string[] = this.mainURL.split("\n\u2022").filter(Boolean);
    if (dataTrain.length > 0) {
      dataTrain[0] = dataTrain[0].replace("\u2022", "").trim();
    }
    return dataTrain;
  }
  extractURL() {
    if (this.mainURL) {
      const urls = this.handleGetURL();
      if (this.checkExtract) {
        this.isLoadingExtract = true;
        let data = new ExtractURLCommand();
        data.urls = urls;
        this.chatGPTClient.extractURL(data).subscribe(
          (rs) => {
            if (rs && rs.length > 0) {
              let count = 0;
              rs.forEach((item) => {
                const data = this.listUrl?.filter((element) => {
                  return element.url == item;
                });
                if (data.length <= 0) {
                  this.listUrl.push({ url: item, enable: true });
                  count++;
                }
              });
              this.toastrService.show(
                `Result: ${count} new ${
                  count > 1 ? "URLs" : "URL"
                } added to the list`,
                `Notification`,
                {
                  position: NbGlobalPhysicalPosition.BOTTOM_LEFT,
                  status: "success",
                }
              );
            } else {
              this.toastrService.show(
                "Somethings went wrong, try again",
                `Notification`,
                {
                  position: NbGlobalPhysicalPosition.BOTTOM_LEFT,
                  status: "danger",
                }
              );
            }
            this.isLoadingExtract = false;
          },
          (error) => {
            this.isLoadingExtract = false;
            this.toastrService.show(
              "Somethings went wrong, try again",
              `Notification`,
              {
                position: NbGlobalPhysicalPosition.BOTTOM_LEFT,
                status: "danger",
              }
            );
          }
        );
      } else {
        urls.forEach((url) => {
          this.addNewURL(url.trim());
        });
      }
    }
  }
  checkDataName(value) {
    this.isExist = true;
    if (value == "" || value == undefined || value == this.currentName) {
      this.isExist = false;
      return;
    }

    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.isNameLoading = true;
      let data = new CheckExistedGPTCommand();
      data.name = value?.trim();

      this.chatGPTClient.checkExistedGPT(data).subscribe({
        next: (rs) => {
          if (rs && value?.trim() != this.currentName) {
            this.datasetForm.controls["name"].setErrors({
              isExist: rs,
            });
            this.handleFocus();
          } else {
            this.isExist = false;
          }
          this.isNameLoading = false;
        },
        error: () => {
          this.handleFocus();
        },
      });
      this.data.name = this.datasetForm.controls["name"]?.value
        ?.toString()
        ?.trim();
    }, 1500);
  }
  createForm() {
    this.datasetForm = this.formBuilder.group({
      name: ["", [RxwebValidators.required(), Validators.maxLength(50)]],
      type: ["", [RxwebValidators.required()]],
      description: [""],
      mainURL: [""],
      retrainTime: ["", ValidInput.retrainTime],
    });
  }
  onChangeContent(event) {
    const text = event.target.value;

    // Save the selection range
    const selectionStart = event.srcElement.selectionStart;
    const selectionEnd = event.srcElement.selectionEnd;

    // Replace the text
    let newText = text.replace(/(^|\n)([^\u2022])/g, "$1\u2022 $2");
    newText = newText.replace(/\u2022\s*\u2022/g, "\u2022");
    event.srcElement.value = newText;
    this.url = event.target.value;

    // Restore the selection range
    const newSelectionStart = selectionStart + (newText.length - text.length);
    const newSelectionEnd = selectionEnd + (newText.length - text.length);
    event.srcElement.setSelectionRange(newSelectionStart, newSelectionEnd);
  }

  setValidationErrors(errorData) {
    var errorData = JSON.parse(errorData);
    if (errorData) {
      for (const [key, value] of Object.entries(errorData)) {
        const fieldName = key.toLowerCase(); // Ensure case matches form control names
        const errorMessage = value[0];
        if (this.datasetForm.get(fieldName)) {
          const control = this.datasetForm.get(fieldName);
          control.setErrors({ serverError: errorMessage });
          control.markAsDirty();
        }
      }
    }
  }

  onSubmit() {
    if (this.datasetForm.valid) {
      this.isLoading = true;
      if (this.data.type == "URL")
        this.data.dataTrain = JSON.stringify(this.listUrl);
      if (this.data.type == "DOCUMENT") {
        this.data.retrainData = "";
        this.fileUpload = this.getAllFiles();
      }
      this.chatGPTClient
        .create(
          this.data.id,
          this.data.name,
          this.data.description,
          this.data.type,
          this.data.dataTrain,
          this.data.retrainTime,
          this.fileUpload as any
        )
        .subscribe({
          next: (rs) => {
            this.isLoading = false;
            this.showToast(rs);
            if (rs == true) {
              this.ref.close({ rs: rs, name: this.data.name });
            }
          },
          error: (error) => {
            this.isLoading = false;
            this.showToast(false);
            if (error.status == 422) {
              this.setValidationErrors(error.response);
            }
          },
        });
    }
  }
  getAllFiles() {
    var files = this.uppy.getFiles();
    files.forEach((element) => {
      (element as any).fileName = element.name;
    });
    return files;
  }

  dismiss() {
    this.ref.close(true);
  }

  showToast(result) {
    if (result == "required") {
      this.toastrService.show("Data is required", `Notification`, {
        position: NbGlobalPhysicalPosition.BOTTOM_LEFT,
        status: "danger",
      });
    } else if (result == true) {
      this.toastrService.show("Save GPT Dataset successfully", `Notification`, {
        position: NbGlobalPhysicalPosition.BOTTOM_LEFT,
        status: "success",
      });
    } else {
      this.toastrService.show(
        "Save GPT Dataset unsuccessfully",
        `Notification`,
        {
          position: NbGlobalPhysicalPosition.BOTTOM_LEFT,
          status: "danger",
        }
      );
    }
  }
}
