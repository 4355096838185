import { Component, HostListener, OnInit, ViewChild } from "@angular/core";
import { Subject, Subscription } from "rxjs";
import { NbDialogService, NbWindowRef } from "@nebular/theme";
import { MxCell, MxGraph } from "../../../../@core/model/mxgraph";
import { SimpleAudioPlayerComponent } from "../../../../shared/simple-audio-player/simple-audio-player.component";
import { AudioClient, TrunkClient, TrunkVm } from "../../../../System-api";
import { SimpleAudioPlayerModalComponent } from "../../../../shared/simple-audio-player-modal/simple-audio-player-modal.component";
import { TextToSpeechModalComponent } from "../../../../shared/text-to-speech-modal/text-to-speech-modal.component";
import { Dial } from "../../../../@core/model/dial";
import { DataService } from "../../../../@core/utils/data.service";
import { DialogAttributeComponent } from "../../other/dialog-attribute/dialog-attribute.component";
import { UntypedFormGroup } from "@angular/forms";
import {
  RxFormBuilder,
  RxwebValidators,
} from "@rxweb/reactive-form-validators";
import { noWhitespaceValidator } from "../../../../@core/utils/helpers";
import { GraphHandlerService } from "../../../../@core/utils/graph.service";
import { TextToSpeechComponent } from "../../../../shared/text-to-speech/text-to-speech.component";

@Component({
  selector: "dial",
  templateUrl: "./dial.component.html",
  styleUrls: ["./dial.component.scss"],
})
export class DialComponent implements OnInit {
  @ViewChild("audioPlayer", {
    static: true,
  })
  audioPlayer: SimpleAudioPlayerComponent;
  @ViewChild("textToSpeech", {
    static: true,
  })
  textToSpeech: TextToSpeechComponent;
  selectedNumber: string;
  trunksNumber: TrunkVm[];
  cell: MxCell;
  graph: MxGraph;
  selectedSubscription: Subscription;
  data: Dial = new Dial();
  dataTTS: any = [];
  options: object[] = [
    { id: 1, name: "Audio From Library" },
    { id: 2, name: "Text To Speech" },
  ];
  eventsSubject: Subject<void> = new Subject<void>();
  isLoading = false;
  cancelAction = false;
  formGroup: UntypedFormGroup;
  attributes: any = this.dataService.ivrAttribute;
  mentionConfig = {
    items: this.attributes,
    triggerChar: "{",
    labelKey: "value",
    disableSort: true,
    mentionSelect: (item) => {
      return "{" + item.value + "}";
    },
  };
  constructor(
    protected windowRef: NbWindowRef,
    private dialogService: NbDialogService,
    private trunkClient: TrunkClient,
    public dataService: DataService,
    private audioClient: AudioClient,
    private formBuilder: RxFormBuilder,
    private graphService: GraphHandlerService
  ) {
    this.createForm();
  }
  createForm() {
    this.formGroup = this.formBuilder.group({
      dialDestination: [
        "",
        [RxwebValidators.required(), noWhitespaceValidator],
      ],
    });
  }
  getTrunks() {
    this.isLoading = true;
    this.trunkClient.getAll().subscribe((item) => {
      this.trunksNumber = item.trunks;
      this.trunksNumber = item.trunks;
      this.trunksNumber.unshift(
        new TrunkVm({
          name: "Internal",
        })
      );

      this.getDefaultSelectedPhoneNumber();
      this.isLoading = false;
    });
  }
  handleSaveAction() {
    this.windowRef.close();
  }
  handleCancelAction() {
    this.cancelAction = true;
    this.windowRef.close(true);
  }
  addAttribute() {
    this.dialogService
      .open(DialogAttributeComponent, { autoFocus: false })
      .onClose.subscribe((rs) => {
        if (rs) {
          this.data.attribute = rs;
        }
      });
  }
  ngOnInit() {
    this.selectedSubscription = this.audioPlayer.selectedAudio.subscribe(
      (audio) => {
        let audioPath = [];
        let audioId = [];
        if (audio) {
          audio.forEach((obj) => {
            audioPath.push(obj.pathAsterisk);
            audioId.push(obj.id);
          });
        }
        this.data.audioId = audio !== undefined ? audioId.toString() : "";
        this.data.audioPath = audio !== undefined ? audioPath.toString() : "";
      }
    );

    this.selectedSubscription = this.textToSpeech.selectedTTS.subscribe(
      (tts) => {
        this.data.text = JSON.stringify(tts);
      }
    );

    for (let [key, value] of Object.entries(this.data)) {
      this.data[key] = this.cell.getAttribute(key);
    }

    if (this.data.audioId !== "") {
      this.audioClient.get().subscribe((audioVm) => {
        this.audioPlayer.listAudioInAction = audioVm.audios;
        this.audioPlayer.playSelectedAudio(this.data.audioId);
      });
    }
    if (this.data.option !== null) {
      this.handleAudio(Number(this.data.option));
    }

    if (this.data.text) {
      this.textToSpeech.selectCurrentTTS(this.data.text);
    }
    if (this.data.record) {
      this.data.record = this.data.record.toString() === "true";
    } else {
      this.data.record = false;
    }
    this.getTrunks();
    this.audioPlayer.showAddButton = true;
    this.audioPlayer.isCallFlowAction = true;
    this.windowRef.onClose.subscribe(() => this.submit());
  }
  handleAudio(value: number) {
    this.data.option = value;
  }
  drop($event) {
    this.data.dialDestination +=
      "{" + $event.dataTransfer.getData("text").trim() + "}";
    $event.preventDefault();
  }
  showModify() {
    let createAudio = this.dialogService.open(SimpleAudioPlayerModalComponent, {
      autoFocus: false,
      context: {
        showAddButton: true,
        audioListId: this.data.audioId.split(","),
      },
    });
    createAudio.componentRef.instance.onAdd.subscribe((rs) => {
      createAudio.close();
      this.eventsSubject.next(rs);
    });
  }
  getPathAttribute($event) {
    this.data.pathAttribute = $event;
  }
  getTranscriptionAttribute($event) {
    this.data.transcriptionAttribute = $event;
  }
  submit() {
    if (!this.cancelAction && !this.isLoading) {
      if (
        this.data.timeout == "" ||
        !this.data.timeout ||
        parseFloat(this.data.timeout) < 0 ||
        !Number.isInteger(parseFloat(this.data.timeout))
      ) {
        this.data.timeout = "";
      }
      for (let [key, value] of Object.entries(this.data)) {
        if (typeof this.data[key] == "string") {
          value = value?.trim();
        }
        this.cell.setAttribute(key, value || "");
      }
      const dialCell = this.graph.getModel().getCell(this.cell.getId());
      const fieldsRequired = [this.data.dialDestination.trim()];
      const check = this.graphService.checkIsRequired(
        this.graph,
        fieldsRequired,
        dialCell
      );
      this.cell.setAttribute("checkFields", check?.toString());
    }
  }
  handleSentiment($event) {
    this.data.sentiment = $event;
  }
  phoneNumberSelectedChange(trunkSelected) {
    trunkSelected = trunkSelected?.name;
    var isPattern = /\{|}/.test(trunkSelected);
    if (trunkSelected) {
      if (trunkSelected != "Internal" && !isPattern) {
        let selectedNumber = this.trunksNumber
          .filter((trunk) => trunk.name.toString() === trunkSelected)
          .pop();
        if (selectedNumber) {
          this.data.trunkNumber = selectedNumber.name;
          this.data.trunkNumberId = selectedNumber.name;
        } else {
          this.data.trunkNumber = trunkSelected;
          this.data.trunkNumberId = trunkSelected;
        }
      } else {
        this.data.trunkNumber = trunkSelected;
        this.data.trunkNumberId = trunkSelected;
      }
    }
  }

  getDefaultSelectedPhoneNumber() {
    if (this.data.trunkNumberId == "") {
      this.selectedNumber = "Internal";
    } else {
      this.selectedNumber = this.data.trunkNumberId;
    }
  }
  getSipHeaders($event) {
    this.data.headers = $event;
  }
}
