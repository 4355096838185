<div class="action-config-wrapper" style="max-width: 550px">
  <div class="config-action">
    <nb-stepper orientation="horizontal" selectedIndex="0">
      <nb-step [label]="labelOne">
        <ng-template #labelOne>Say Somethings</ng-template>
        <nb-radio-group
          name="radio-start"
          [(ngModel)]="data.option"
          class="choice-option"
          (ngModelChange)="handleAudioStart($event)"
        >
          <nb-radio *ngFor="let option of options" [value]="option.id">{{
            option.name
          }}</nb-radio>
        </nb-radio-group>
        <div
          class="form-group"
          style="padding: 1rem 0; width: 100%; display: inline-grid"
        >
          <text-to-speech #textToSpeechStart></text-to-speech>
          <nb-card class="select-audio" [ngClass]="{ show: data.option == 1 }">
            <nb-card-header class="audio-list-style">
              <span>Audio List</span>
              <nb-icon
                class="ms-2"
                style="color: #929bb3; width: 15px"
                nbTooltip="Select the audio you want to play. The audio list will play in order and continuously. If no audio is selected, the system will use text to speech."
                nbTooltipPlacement="bottom"
                nbTooltipStatus="basic"
                nbTooltipTrigger="hover"
                icon="question-mark-circle-outline"
              >
              </nb-icon
            ></nb-card-header>
            <nb-card-body>
              <simple-audio-player
                #audioStartPlayer
                [events]="eventsSubjectStart.asObservable()"
              ></simple-audio-player>
              <button
                nbButton
                size="small"
                status="primary"
                (click)="showModifyStart()"
              >
                <nb-icon icon="plus-circle-outline"></nb-icon> Add
              </button>
            </nb-card-body>
          </nb-card>
        </div>

        <div
          class="mb-3"
          style="display: flex; flex-direction: column; margin-top: 0.5rem"
        >
          <label for="name" class="form-label label">Choose Videobot</label>
          <sentiment-select
            placeHolder="Choose Videobot"
            (valueSelected)="handleSentiment($event)"
            [value]="data.sentiment"
            defaultValue="normal-speak"
          ></sentiment-select>
        </div>
      </nb-step>
      <nb-step [label]="labelTwo">
        <ng-template #labelTwo>Settings</ng-template>
        <form [formGroup]="formGroup">
          <div class="flex-title">
            <span class="title">Configure</span>
            <span class="line"></span>
          </div>
          <div class="row">
            <div class="mb-2">
              <label
                for="timeout"
                class="form-label label d-flex"
                style="align-items: center"
                ><span>Silent Timeout (s)</span>
                <nb-icon
                  class="ms-1"
                  style="color: #929bb3; width: 15px"
                  nbTooltip="Maximum time to wait for the user's say."
                  nbTooltipPlacement="bottom"
                  nbTooltipStatus="basic"
                  nbTooltipTrigger="hover"
                  icon="question-mark-circle-outline"
                >
                </nb-icon
              ></label>
              <form>
                <input
                  id="timeout"
                  [ngModel]="data.timeout"
                  (input)="handleOnlyIntegerNumber($event)"
                  type="number"
                  min="0"
                  nbInput
                  fullWidth
                  placeholder="Silent Timeout"
                  fieldSize="small"
                />
              </form>
            </div>
          </div>
          <div class="row">
            <div class="mb-2">
              <div class="form-label d-flex" style="align-items: center">
                <label for="maxDuration" class="label required">
                  Max Duration (s)
                </label>
                <nb-icon
                  class="ms-1"
                  style="color: #929bb3; width: 15px"
                  nbTooltip="Maximum duration of time the user allowed to say."
                  nbTooltipPlacement="bottom"
                  nbTooltipStatus="basic"
                  nbTooltipTrigger="hover"
                  icon="question-mark-circle-outline"
                >
                </nb-icon>
              </div>
              <input
                formControlName="duration"
                id="maxDuration"
                fieldSize="small"
                maxlength="3"
                nbPopover="Without requiring the user to press the # key."
                nbPopoverPlacement="top"
                type="text"
                [(ngModel)]="data.maxDuration"
                nbInput
                fullWidth
                placeholder="Max Duration"
                [status]="
                  formGroup.controls['duration'].invalid &&
                  (formGroup.controls['duration'].dirty ||
                    formGroup.controls['duration'].touched)
                    ? 'danger'
                    : 'basic'
                "
              />
            </div>
          </div>
          <ng-container
            *ngIf="
              formGroup.controls['duration'].invalid &&
              (formGroup.controls['duration'].dirty ||
                formGroup.controls['duration'].touched)
            "
          >
            <p
              class="caption status-danger"
              style="margin-bottom: 0"
              *ngIf="
                formGroup.controls['duration'].hasError('required') ||
                formGroup.controls['duration'].hasError('whitespace')
              "
            >
              Max Duration is required.
            </p>
          </ng-container>
          <div class="flex-title" style="margin-bottom: 1.25rem">
            <span class="title">Assign Variable </span>
            <span class="line"></span>
          </div>
          <div class="row">
            <div class="mb-2">
              <label
                for="speechTimeout"
                class="form-label label d-flex"
                style="align-items: center"
                ><span>Audio Path</span>
                <nb-icon
                  class="ms-1"
                  style="color: #929bb3; width: 15px"
                  nbTooltip="Assign audio recording path to the variable."
                  nbTooltipPlacement="bottom"
                  nbTooltipStatus="basic"
                  nbTooltipTrigger="hover"
                  icon="question-mark-circle-outline"
                >
                </nb-icon
              ></label>
              <ngx-input-value-static
                placeHolder="Select Audio Path"
                selectAttribute="true"
                [propertyInput]="data.pathAttribute"
                (propertyEmit)="getPathAttribute($event)"
              >
              </ngx-input-value-static>
            </div>
          </div>
          <div class="row">
            <div class="mb-2 mt-2">
              <div class="mb-2" style="display: flex; align-items: center">
                <nb-checkbox
                  (checkedChange)="allowSpeechToText($event)"
                  [checked]="data.speechtotext"
                >
                  Convert Audio To Text
                </nb-checkbox>
                <nb-icon
                  class="ms-2"
                  style="color: #929bb3; width: 15px"
                  nbTooltip="Allows to convert audio recording to transcription after the flow end."
                  nbTooltipPlacement="bottom"
                  nbTooltipStatus="basic"
                  nbTooltipTrigger="hover"
                  icon="question-mark-circle-outline"
                >
                </nb-icon>
              </div>
            </div>
          </div>
          <div class="row" *ngIf="data.speechtotext == true">
            <div class="mb-2">
              <label class="form-label label d-flex" style="align-items: center"
                ><span>Transcription</span>
                <nb-icon
                  class="ms-1"
                  style="color: #929bb3; width: 15px"
                  nbTooltip="Assign transcription to the variable."
                  nbTooltipPlacement="bottom"
                  nbTooltipStatus="basic"
                  nbTooltipTrigger="hover"
                  icon="question-mark-circle-outline"
                >
                </nb-icon
              ></label>
              <ngx-input-value-static
                placeHolder="Select Transcription"
                selectAttribute="true"
                [propertyInput]="data.attribute"
                (propertyEmit)="getAttribute($event)"
              >
              </ngx-input-value-static>
            </div>
          </div>
        </form>
      </nb-step>
    </nb-stepper>
  </div>
  <footer-action
    (handleCancelAction)="handleCancelAction()"
    (handleSaveAction)="handleSaveAction()"
  ></footer-action>
</div>
