import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  EventEmitter,
} from "@angular/core";
import { AudioClient, AudioDto } from "../../System-api";
import {
  NbDialogRef,
  NbDialogService,
  NbGlobalPhysicalPosition,
  NbToastrService,
} from "@nebular/theme";
import { InstructionService } from "../../@core/utils/instruction.service";
import { DataService } from "../../@core/utils/data.service";
import { EventBusService } from "../../@core/utils/eventbus.service";
import { EventData } from "../model/eventdata";

import { CreateAudioComponent } from "../../portal/customer-role/audios/create/create-audio.component";

@Component({
  selector: "simple-audio-player-modal",
  templateUrl: "./simple-audio-player-modal.component.html",
  styleUrls: ["./simple-audio-player-modal.component.scss"],
})
export class SimpleAudioPlayerModalComponent implements OnInit {
  @ViewChild("mainAudio", { static: true }) mainAudio: ElementRef;
  @ViewChild('closeButton', { static: true }) closeButton: ElementRef;
  selectAudiodto: AudioDto = new AudioDto();
  newAudiodto: AudioDto = new AudioDto();
  selectedAudio: EventEmitter<AudioDto> = new EventEmitter<AudioDto>();
  audios: AudioDto[];
  audioData: AudioDto[];
  filteredData: AudioDto[];
  filterColumn = [];
  audioList: any = [];
  audioListId: any = [];
  isPlaying: boolean;
  currentAudio: AudioDto = new AudioDto();
  idNew: number;
  showAddButton: boolean;
  searchText: any;
  onAdd = new EventEmitter();
  audioCheckedState: { [key: string]: boolean } = {}; 
  audioIdUpdated: number = 0;
  constructor(
    private audioClient: AudioClient,
    private eventBusService: EventBusService,
    private dataService: DataService,
    private dialogService: NbDialogService,
    private introService: InstructionService,
    private toastrService: NbToastrService,
    private ref: NbDialogRef<SimpleAudioPlayerModalComponent>,
  ) {
    this.filterColumn = ["name"];
  }
  ngOnInit() {
    this.loadAudios(0);
    this.closeButton.nativeElement.blur();
  }
  filterDatatable(event) {
    let val = event.target.value.toLowerCase();
    let colsAmt = this.audioData.length;
    let keys = Object.keys(this.audioData[0]);
    this.audios = this.filteredData.filter((item) => {
      for (let i = 0; i < colsAmt; i++) {
        if (this.filterColumn.includes(keys[i])) {
          if (
            item[keys[i]].toString().toLowerCase().indexOf(val) !== -1 ||
            !val
          ) {
            return true;
          }
        }
      }
    });
  }
  loadAudios(idNew) {
    this.audioClient.get().subscribe((audioVm) => {
      this.audios = audioVm.audios;
      this.audioData = audioVm.audios;
      this.filteredData = audioVm.audios;
      this.idNew = idNew;
      this.getAudioList(this.audios);
      if (this.selectAudiodto.id !== undefined) {
        let selectedAudio = this.audios
          .filter((audio) => audio.id == this.selectAudiodto.id)
          .pop();
        this.selectAudiodto = selectedAudio;
        this.clickSelect(this.selectAudiodto);
        this.audios = this.audios.filter(
          (audio) => ![this.selectAudiodto].includes(audio)
        );
        this.audios.unshift(this.selectAudiodto);
      }
    });
  }
  getAudioList(audios) {
    if (this.audioListId?.length > 0) {
      this.audioListId.forEach((audioList) => {
        audios.forEach((audio) => {
          if (audio.id == audioList) {
            this.audioList.push(audio);
            this.audioCheckedState[audio.id] = true;
          }
        });
      });
    }

    if (this.audioIdUpdated !== 0) {
      const audioUpdated = this.audios.find((audio) => audio.id === this.audioIdUpdated);
      const index = this.audioList.findIndex((audio) => audio.id === this.audioIdUpdated);   
      if (index !== -1) {
        this.audioList[index] = audioUpdated;
      }    
      this.audioIdUpdated = 0;
    }
  }

  checkAudio(audio: any) {
    let includes = false;
    this.audioList.forEach((element) => {
      if (element.id == audio.id) {
        includes = true;
      }
    });
    return includes;
  }
  setAudio(event, audio: any) {
    event.stopPropagation();
    let check = this.checkAudio(audio);
    if (!check) {
      this.audioList.push(audio);
    } else {
      this.audioList = this.audioList.filter(
        (audioList) => audio.id != audioList.id
      );
    }
    this.audioCheckedState[audio.id] = !this.audioCheckedState[audio.id]; 
  }
  saveAudio() {
    this.onAdd.emit(this.audioList);
  }
  handleUpdateAudio(event, audio) {
    this.currentAudio = audio;
    event.stopPropagation();
    let createAudio = this.dialogService.open(CreateAudioComponent, {
      closeOnBackdropClick: false,
      autoFocus: false,
      context: {
        isModal: true,
        audioSelect: this.newAudiodto,
        createAudio: false,
        audioUpdate: audio,
        title: "Update Audio",
      },
    });
    createAudio.componentRef.instance.onAdd.subscribe((rs) => {
      createAudio.close();
      this.loadAudios(rs);
      let hasAudioUpdated = this.audioList?.some((audio) => audio.id == rs);
      if (hasAudioUpdated) {
        this.audioIdUpdated = rs;
      }
      this.mainAudio.nativeElement.src = audio.path + "?" + Date.now();
      this.mainAudio.nativeElement.load();
    });
  }
  changeAudioSource(audio: AudioDto) {
    if (this.currentAudio != null && this.currentAudio.id == audio.id) {
      if (this.isPlaying) {
        this.isPlaying = false;
        this.mainAudio.nativeElement.pause();
        this.mainAudio.nativeElement.currentTime = 0;
      } else {
        this.isPlaying = true;
        this.mainAudio.nativeElement.play();
      }
    } else {
      this.currentAudio = audio;
      this.mainAudio.nativeElement.src = audio.path;
      this.mainAudio.nativeElement.load();
      this.mainAudio.nativeElement.play();
    }
  }
  clickSelect(audio: AudioDto) {
    this.selectAudiodto = audio;
    this.eventBusService.emit(new EventData("changeAudio", true));
  }
  playSelectedAudio(id) {
    this.selectAudiodto.id = id;
  }
  audioPlaying() {
    this.isPlaying = true;
  }
  audioEnded() {
    this.isPlaying = false;
  }

  getBackground(audio) {
    if (this.selectAudiodto != null && this.selectAudiodto.id == audio.id) {
      return true;
    }
    return false;
  }
  showModify(event) {
    event.stopPropagation();
    let updateAudio = this.dialogService.open(CreateAudioComponent, {
      closeOnBackdropClick: false,
      autoFocus: false,
      context: {
        isModal: true,
        audioSelect: this.selectAudiodto,
        createAudio: false,
      },
    });
    updateAudio.componentRef.instance.onAdd.subscribe((rs) => {
      updateAudio.close();
      this.loadAudios(rs);
    });
  }
  showModifyCreate() {
    let createAudio = this.dialogService.open(CreateAudioComponent, {
      closeOnBackdropClick: false,
      autoFocus: false,
      context: {
        isModal: true,
        audioSelect: this.newAudiodto,
        createAudio: true,
      },
    });
    createAudio.componentRef.instance.onAdd.subscribe((rs) => {
      createAudio.close();
      this.loadAudios(rs);
    });
  }
  showToast() {
    this.toastrService.show("Add audio successfully", `Notification`, {
      position: NbGlobalPhysicalPosition.BOTTOM_LEFT,
      status: "success",
    });
  }
  dismiss() {
    this.ref.close();
  }
}
