import { saveAs } from "file-saver";
import { TextareaHighlightComponent } from "./../../../../../shared/textarea-highlight/textarea-highlight.component";
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from "@angular/core";
import { UntypedFormGroup, Validators } from "@angular/forms";
import {
  NbDialogRef,
  NbDialogService,
  NbGlobalPhysicalPosition,
  NbPopoverDirective,
  NbToastrService,
} from "@nebular/theme";
import {
  RxFormBuilder,
  RxwebValidators,
} from "@rxweb/reactive-form-validators";
import { map, Observable, startWith } from "rxjs";
import { DataService } from "../../../../../@core/utils/data.service";
import {
  ValidInput,
  noWhitespaceValidator,
} from "../../../../../@core/utils/helpers";
import {
  CheckGrammarNameCommand,
  CheckGrammarQuery,
  CreateGrammarCommand,
  GrammarClient,
} from "../../../../../System-api";
import { Papa } from "ngx-papaparse";
import { ConfirmDialogComponent } from "../../../../../shared/confirm-dialog/confirm-dialog.component";
import { isThisSecond } from "date-fns";

@Component({
  selector: "create-grammar",
  templateUrl: "./create-grammar.component.html",
  styleUrls: ["./create-grammar.component.scss"],
})
export class CreateGrammarComponent implements OnInit, AfterViewInit {
  @ViewChildren(TextareaHighlightComponent)
  highlight!: QueryList<TextareaHighlightComponent>;
  @ViewChild(NbPopoverDirective) popover: NbPopoverDirective;
  @ViewChild("inputName") inputName: ElementRef<any>;
  title: string;
  isImport = false;
  checkGrammar: CheckGrammarQuery;
  createGrammar: CreateGrammarCommand;
  updateGrammar;
  languageSelect;
  listLanguage = JSON.parse(this.dataService.CallSetting.languageSetting);
  isMatched: boolean = false;
  grammarForm: UntypedFormGroup;
  isUpdate: boolean = false;
  filteredLanguage: Observable<any[]>;
  onAdd = new EventEmitter();
  typeGrammar = "";
  fileName = null;
  textUpdate;
  translateGrammar: boolean = false;
  engine: boolean = false;
  engineType: string = "EMBEDDING";
  trainingStatus: string = "NOT-TRAINED";
  isGPTAction: boolean = false;
  fileError = false;
  fileMaximum = false;
  hiddenLanguageFlags: boolean[] = [];
  listIntent = [
    {
      code: "en",
      name: "English",
      value: [
        {
          intent: "",
          value: {
            text: "",
            entity: [],
          },
        },
      ],
    },
  ];
  entity = "";
  entityData = [];
  listIntentValue: string[] = [];
  intentImport: any = null;
  intentImportIndex: any = null;
  currentName: string;
  timeout: any = null;
  isExist: boolean = false;
  isNameLoading: boolean = false;
  maxSize: number;
  loading: boolean = false;
  phraseList: any = [{ code: "en", name: "English", value: [] }];
  constructor(
    private toastrService: NbToastrService,
    private grammarClient: GrammarClient,
    private formBuilder: RxFormBuilder,
    private dataService: DataService,
    private ref: NbDialogRef<CreateGrammarComponent>,
    private papa: Papa,
    private dialogService: NbDialogService
  ) {
    this.maxSize = 20 * 1024 * 1024;
  }
  ngOnInit(): void {
    this.createForm();
    this.checkGrammar = new CheckGrammarQuery();
    this.createGrammar = new CreateGrammarCommand();
    if (this.typeGrammar == "Custom" || this.typeGrammar == "PhraseList") {
      this.defaultLanguage();
    }
    if (this.typeGrammar == "Custom" && this.isUpdate) {
      this.entityData = JSON.parse(this.updateGrammar.entity);
      this.handleDisplayDataUpdate(this.updateGrammar.pattern);
    }
    if (this.typeGrammar == "PhraseList" && this.isUpdate) {
      this.handleDisplayPhraseListUpdate(this.updateGrammar.pattern);
    }
    if (this.isUpdate) {
      this.createGrammar.name = this.updateGrammar.name;
      this.createGrammar.id = this.updateGrammar.id;
      this.checkGrammar.pattern = this.updateGrammar.pattern;
      this.translateGrammar = this.updateGrammar.autoTranslate;
      this.engine = this.updateGrammar.engine;
      this.engineType = this.updateGrammar.engineType ?? "EMBEDDING";
      this.currentName = this.updateGrammar.name;
      this.trainingStatus = this.updateGrammar.status;
    }
    this.hideLanguage();
  }
  defaultLanguage() {
    let defaultLanguage = [];
    const languageSetting = JSON.parse(
      this.dataService.CallSetting.languageSetting
    );
    languageSetting.forEach((language) => {
      if (language.language.split("-")[0] !== "en") {
        defaultLanguage.push({
          name: language.name,
          code: language.language.split("-")[0],
        });
      }
    });
    defaultLanguage.forEach((item) => {
      const obj = {
        code: item.code,
        name: item.name,
        value: [],
      };
      this.phraseList.push(obj);
      const objCustom = {
        code: item.code,
        name: item.name,
        value: [
          {
            intent: "",
            value: {
              text: "",
              entity: [],
            },
          },
        ],
      };
      this.listIntent.push(objCustom);
    });
  }
  downloadFile(type) {
    let url = "";
    if (type == "json") {
      url = "/assets/sample-file/SampleIntent.json";
    } else {
      url = "/assets/sample-file/SampleIntent.csv";
    }
    const element = document.createElement("a");
    element.href = url;
    element.download = url.split("/").pop();
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }
  importIntent(files) {
    this.intentImport = null;
    this.fileName = "";
    this.fileError = false;
    this.fileMaximum = false;
    var file = files[0];
    var mimeType = files[0]?.type;
    if (!this.checkAllowSize(files[0]?.size)) {
      this.fileName = file.name;
      this.fileMaximum = true;
      return false;
    } else if (mimeType == "application/json" || mimeType == "text/csv") {
      this.fileName = file.name;
      if (mimeType == "application/json") {
        this.handleUploadJSON(file);
      } else {
        this.handleUploadCSV(file);
      }
    } else {
      this.fileName = file.name;
      this.fileError = true;
      return false;
    }
  }
  handleUpload(e, index) {
    e.stopPropagation();
    this.intentImportIndex = index;
    this.popover.show();
  }
  handleUploadJSON(file) {
    var reader = new FileReader();
    reader.readAsText(file);
    reader.onload = (event: any) => {
      var json = event.target.result;
      if (JSON.parse(json).length > 0) {
        this.intentImport = JSON.parse(json);
      }
    };
  }
  handleUploadCSV(file) {
    var reader = new FileReader();
    reader.readAsText(file);
    reader.onload = (event: any) => {
      var csv = event.target.result; // Content of CSV file
      this.papa.parse(csv, {
        skipEmptyLines: true,
        header: true,
        complete: (results) => {
          if (results?.data.length > 0) {
            let dataImport = [];
            let data = results?.data;
            Object.keys(data[0]).forEach((key) => {
              if (key.trim() != "") {
                dataImport.push({ intent: key.trim(), value: [] });
              }
            });
            data.forEach((item) => {
              for (let [key, value] of Object.entries(
                item as Record<string, string>
              )) {
                if (key.trim() != "" && value.trim() != "") {
                  dataImport.forEach((dataImportItem) => {
                    if (dataImportItem.intent == key.trim()) {
                      dataImportItem.value.push(value.trim());
                    }
                  });
                }
              }
            });
            this.intentImport = dataImport;
          }
        },
      });
    };
  }
  confirmUpload() {
    if (this.intentImport) {
      try {
        this.intentImport.forEach((intent) => {
          let text = "";
          intent.value?.forEach((value) => {
            let textData = "\u2022 " + value.trim() + "\n";
            text = text + textData;
          });
          if (text.trim() != "") {
            let checkExist = -1;
            this.listIntent[this.intentImportIndex].value.forEach(
              (intentValue, index) => {
                if (intentValue.intent.trim() == intent.intent) {
                  checkExist = index;
                }
              }
            );
            if (checkExist != -1) {
              this.listIntent[this.intentImportIndex].value[
                checkExist
              ].value.text =
                this.listIntent[this.intentImportIndex].value[checkExist].value
                  .text + text;
            } else {
              let dataNew = {
                intent: intent.intent,
                value: {
                  text: text,
                  entity: [],
                },
              };
              this.listIntent[this.intentImportIndex].value.push(dataNew);
            }
          }
        });
        this.popover.hide();
        this.handleUpdateView();
        this.showToast(true, "Upload intent successfully.");
      } catch (error) {
        this.showToast(false, "The file is not in the correct format.");
      }
    } else {
      this.popover.hide();
      this.showToast(false, "Upload intent unsuccessfully.");
    }
  }
  handleUpdateView() {
    this.fileName = "";
    this.intentImport = null;
    this.intentImportIndex = null;
    setTimeout(() => {
      let index = 0;
      this.listIntent.forEach((intentLanguage, i) => {
        intentLanguage.value?.forEach((intent, j) => {
          if (this.highlight.toArray()[index] != undefined) {
            this.highlight.toArray()[index].textValue = intent.value?.text;
          }
          index++;
          this.listIntent[i].value[j].value.entity = [];
        });
      });
    }, 100);
  }
  handleDisplayDataUpdate(data) {
    let code;
    this.listIntent = JSON.parse(data);
    const languageSetting = JSON.parse(
      this.dataService.CallSetting.languageSetting
    );
    languageSetting.forEach((language) => {
      code = language.language.split("-")[0];
      if (!this.listIntent.some((intent) => intent.code === code)) {
        const obj = {
          code: code,
          name: language.name,
          value: [
            {
              intent: "",
              value: {
                text: "",
                entity: [],
              },
            },
          ],
        };
        this.listIntent.push(obj);
      }
    });
  }
  handleDisplayPhraseListUpdate(data) {
    let code;
    this.phraseList = JSON.parse(data);
    const languageSetting = JSON.parse(
      this.dataService.CallSetting.languageSetting
    );
    languageSetting.forEach((language) => {
      code = language.language.split("-")[0];
      if (!this.phraseList.some((intent) => intent.code === code)) {
        const obj = {
          code: code,
          name: language.name,
          value: [],
        };
        this.phraseList.push(obj);
      }
    });
  }

  hideLanguage() {
    const languageSetting = JSON.parse(
      this.dataService.CallSetting.languageSetting
    );
    const languageCodes = languageSetting.map(
      (language) => language.language.split("-")[0]
    );
    let dataHandle = this.listIntent;
    if (this.typeGrammar == "PhraseList") {
      dataHandle = this.phraseList;
    }
    dataHandle.forEach((intent) => {
      const isHidden = !languageCodes.some(
        (languageCode) => languageCode === intent.code
      );
      this.hiddenLanguageFlags.push(isHidden);
    });
  }
  ngAfterViewInit(): void {
    let index = 0;
    this.listIntent.forEach((intentLanguage, i) => {
      intentLanguage.value?.forEach((intent, j) => {
        if (this.highlight.toArray()[index] != undefined) {
          this.highlight.toArray()[index].textValue = intent.value?.text;
        }
        index++;
        this.listIntent[i].value[j].value.entity = [];
      });
    });
  }
  displayValue(event: any) {
    return event && event.name ? event.name : "";
  }
  private _filterValue(name: string, listName: string): any[] {
    const filterValue = name.toLowerCase();
    if (listName == "language") {
      return this.listLanguage.filter((option) =>
        option.name.toLowerCase().includes(filterValue)
      );
    }
  }
  getAllLanguage() {
    this.filteredLanguage = this.grammarForm.get("language").valueChanges.pipe(
      startWith(""),
      map((value) => {
        const name = typeof value === "string" ? value : value?.name;
        return name
          ? this._filterValue(name as string, "language")
          : this.listLanguage?.slice();
      })
    );
  }
  handleAddNewIntentByLanguage() {
    this.languageSelect = this.grammarForm.value?.language;
    let check = 0;
    const itemCode = this.languageSelect.language.split("-");
    this.listIntent.forEach((item) => {
      if (item.code == itemCode[0]) {
        check = 1;
      }
    });
    if (check == 0 && itemCode[0]) {
      var obj = {
        code: itemCode[0],
        name: this.languageSelect.name,
        value: [
          {
            intent: "",
            value: {
              text: "",
              entity: [],
            },
          },
        ],
      };
      this.listIntent.push(obj);
    }
    this.languageSelect = {};
    this.grammarForm.controls["language"].setValue({});
  }
  checkDefaultLanguageUser(data) {
    let check = false;
    const languageSetting = JSON.parse(
      this.dataService.CallSetting.languageSetting
    );
    languageSetting.forEach((element) => {
      const languageCode = element.language.split("-");
      if (element.defaultUser == true && languageCode[0] == data.code) {
        check = true;
      }
    });
    return check;
  }
  handleText(event, index, i) {
    this.listIntent[index].value[i].value.text = event;
  }
  handleAddEntity(event) {
    if (!event.isUpdate && !event.isRemove) {
      this.addNewEntity(event);
    } else {
      for (let i = 0; i < this.entityData.length; i++) {
        if (this.entityData[i].value.includes(event.value)) {
          this.entityData[i].value = this.entityData[i].value.filter(
            (e) => e !== event.value
          );
        }
        if (this.entityData[i].value.length <= 0) {
          if (i != 0) {
            this.entityData.splice(i, 1);
          } else {
            this.entityData.shift();
          }
          i--;
        }
      }
      if (event.isUpdate) {
        this.addNewEntity(event);
      }
    }
  }
  addNewEntity(event) {
    if (this.entityData.length > 0) {
      let checkEntityExist = 0;
      this.entityData.forEach((element, j) => {
        if (element.label == event.label) {
          checkEntityExist = 1;
          this.entityData[j].value.push(event.value);
        }
      });
      if (checkEntityExist == 0) {
        let newEntity = {
          label: event.label,
          value: [event.value],
        };
        this.entityData.push(newEntity);
      }
    } else {
      let newEntity = {
        label: event.label,
        value: [event.value],
      };
      this.entityData.push(newEntity);
    }
  }
  handleAddWord(languageCode: any) {
    this.listIntent.forEach((item) => {
      if (item.code == languageCode) {
        var obj = {
          intent: "",
          value: {
            text: "",
            entity: [],
          },
        };
        item.value.push(obj);
      }
    });
  }
  handleRemoveWordInList(index: any, languageCode: any, indexList: any) {
    this.listIntent.forEach((item) => {
      if (item.code == languageCode) {
        if (index != 0) {
          item.value.splice(index, 1);
        } else {
          item.value.shift();
          if (item.value.length == 0 && languageCode !== "en") {
            if (indexList != 0) {
              this.listIntent.splice(indexList, 1);
            } else {
              this.listIntent.shift();
            }
          }
        }
      }
    });
  }
  createForm() {
    switch (this.typeGrammar) {
      case "Digit":
        this.grammarForm = this.formBuilder.group({
          name: [
            "",
            [
              RxwebValidators.required(),
              noWhitespaceValidator,
              Validators.maxLength(50),
              RxwebValidators.pattern({
                expression: {
                  regex: /^[^\\\/:*?"<>|]+$/,
                },
              }),
            ],
          ],
          pattern: ["", ValidInput.name],
          testValue: [""],
        });
        break;
      case "Custom":
        this.grammarForm = this.formBuilder.group({
          name: [
            "",
            [
              RxwebValidators.required(),
              noWhitespaceValidator,
              Validators.maxLength(50),
              RxwebValidators.pattern({
                expression: {
                  regex: /^[^\\\/:*?"<>|]+$/,
                },
              }),
            ],
          ],
          entity: [""],
          language: [""],
          engineType: [""],
        });
        break;
      case "PhraseList":
        this.grammarForm = this.formBuilder.group({
          name: [
            "",
            [
              RxwebValidators.required(),
              noWhitespaceValidator,
              Validators.maxLength(50),
              RxwebValidators.pattern({
                expression: {
                  regex: /^[^\\\/:*?"<>|]+$/,
                },
              }),
            ],
          ],
        });
        break;
      default:
        break;
    }
  }
  handleData() {
    this.entityData?.forEach((entityItem, i) => {
      entityItem.value.forEach((value, j) => {
        this.handleFormatListIntent(entityItem.label, value);
      });
    });
  }
  handleFormatListIntent(label, value) {
    this.listIntent.forEach((intentLanguage, i) => {
      intentLanguage.value.forEach((intent, j) => {
        if (intent.value.text.includes(value)) {
          if (this.listIntent[i].value[j].value.entity.length > 0) {
            let checkEntityExist = 0;
            this.listIntent[i].value[j].value.entity.forEach((element) => {
              if (element.label == label) {
                checkEntityExist = 1;
              }
            });
            if (checkEntityExist == 1) {
              this.listIntent[i].value[j].value.entity.forEach(
                (entityObject, k) => {
                  if (entityObject.label == label) {
                    if (!entityObject.value.includes(value)) {
                      this.listIntent[i].value[j].value.entity[k].value.push(
                        value
                      );
                    }
                  }
                }
              );
            } else {
              let newEntity = {
                label: label,
                value: [value],
              };
              this.listIntent[i].value[j].value.entity.push(newEntity);
            }
          } else {
            let newEntity = {
              label: label,
              value: [value],
            };
            this.listIntent[i].value[j].value.entity.push(newEntity);
          }
        }
      });
    });
  }
  handleConfirmImport(files) {
    this.dialogService
      .open(ConfirmDialogComponent, {
        autoFocus: false,
        context: {
          question: "Are you sure you want to import this grammar?",
          textYes: "Yes",
          textNo: "Cancel",
          statusYes: "info",
          statusNo: "basic",
        },
      })
      .onClose.subscribe((isConfirm) => {
        if (isConfirm) {
          this.importGrammar(files);
        }
      });
  }
  checkAllowSize(allowSize) {
    if (allowSize > this.maxSize) {
      return false;
    }
    return true;
  }
  importGrammar(files) {
    this.fileName = "";
    var file = files[0];
    var mimeType = files[0]?.type;

    if (mimeType == "application/json") {
      if (!this.checkAllowSize(files[0]?.size)) {
        this.toastrService.show(
          "Maximum allowed file size is 20 MB",
          `Notification`,
          {
            position: NbGlobalPhysicalPosition.BOTTOM_LEFT,
            status: "danger",
          }
        );
        return false;
      }
      var reader = new FileReader();
      reader.readAsText(file);
      reader.onload = (event: any) => {
        try {
          var json = event.target.result;
          if (JSON.parse(json)) {
            let item = JSON.parse(json);
            if (
              (item.hasOwnProperty("intent") ||
                item.hasOwnProperty("entity")) &&
              typeof item == "object"
            ) {
              for (let [key, value] of Object.entries(
                item as Record<string, string>
              )) {
                if (key == "intent") {
                  let dataIntent = item.intent;
                  dataIntent.forEach((element) => {
                    if (element?.code) {
                      let dataExist = this.listIntent.findIndex(
                        (x) =>
                          x.code.split("-")[0] == element.code.split("-")[0]
                      );
                      if (dataExist >= 0) {
                        if (this.typeGrammar == "Custom") {
                          element?.value.forEach((intent) => {
                            this.listIntent[dataExist].value.push(intent);
                          });
                        } else {
                          element?.value.forEach((intent) => {
                            if (typeof intent?.value == "string") {
                              let existData = this.phraseList[
                                dataExist
                              ].value.find(
                                (x) => x.value?.trim() == intent?.value.trim()
                              );
                              if (!existData) {
                                this.phraseList[dataExist].value.push(intent);
                              }
                            }
                          });
                        }
                      } else {
                        if (this.typeGrammar == "Custom") {
                          this.listIntent.push(element);
                        } else {
                          this.phraseList.push(element);
                        }
                      }
                    }
                  });
                }
                if (key == "entity") {
                  this.entityData = item.entity;
                }
              }
              this.isImport = true;
              this.handleUpdateView();
              this.showToast(true, "Import grammar successfully.");
            } else {
              this.toastrService.show(
                "The file is not in the correct format.",
                `Notification`,
                {
                  position: NbGlobalPhysicalPosition.BOTTOM_LEFT,
                  status: "danger",
                }
              );
            }
          }
        } catch (error) {
          this.toastrService.show(
            "The file is not in the correct format.",
            `Notification`,
            {
              position: NbGlobalPhysicalPosition.BOTTOM_LEFT,
              status: "danger",
            }
          );
        }
      };
    } else {
      this.toastrService.show("Only JSON formats are allowed", `Notification`, {
        position: NbGlobalPhysicalPosition.BOTTOM_LEFT,
        status: "danger",
      });
      return false;
    }
  }
  exportGrammar() {
    let data: any = { intent: this.listIntent, entity: this.entityData };
    if (this.typeGrammar == "PhraseList") {
      data = { intent: this.phraseList };
    }
    let blob = new Blob([JSON.stringify(data)], {
      type: "application/json",
    });
    saveAs(
      blob,
      this.createGrammar.name ? this.createGrammar.name : "grammar" + ".json"
    );
  }
  handleFormatDataListIntent() {
    let data = this.handleGetListPrompts(this.listIntent);
    let dataFormat = data.dataFormat;
    let listPrompts = data.listPrompts;
    this.listIntent.forEach((intentLanguage, index) => {
      intentLanguage.value.forEach((intent) => {
        if (intent.value.entity.length > 0) {
          intent.value.entity.forEach((entity) => {
            entity.value.forEach((entityValue) => {
              let checkEntityExist = this.handleCheckLabelValueExist(
                entity.label,
                entityValue,
                dataFormat[index].dataTrain.data.entities
              );
              if (checkEntityExist == false) {
                dataFormat[index].dataTrain.data.entities.push({
                  label: entity.label,
                  value: entityValue,
                  prompts: this.handleGetPromptByEntityValue(
                    entityValue,
                    listPrompts[index].dataTrain
                  ),
                });
              }
            });
          });
        }
      });
    });
    return dataFormat;
  }
  handleCheckLabelValueExist(label, value, listEntity) {
    let check = false;
    if (listEntity.length <= 0) return false;
    else {
      listEntity.forEach((element) => {
        if (element.label == label && element.value == value) {
          check = true;
        }
      });
      return check;
    }
  }
  handleGetPromptByEntityValue(value, listPrompts) {
    const filteredArr = listPrompts.filter((item) => item.includes(value));
    const promptEntity = [];
    filteredArr.forEach((item) => promptEntity.push(item));
    return promptEntity;
  }
  handleGetListPrompts(listIntent) {
    let dataArray = [];
    let listPromptsArray = [];
    listIntent.forEach((intentLanguage) => {
      let dataFormat = {
        refId: this.createGrammar.name,
        data: {
          intents: [],
          entities: [],
        },
      };
      let listPrompts = [];
      intentLanguage.value.forEach((intent) => {
        let promptsValue = this.handleTrimDataArray(
          intent.value.text.split("\u2022")
        );
        dataFormat.data.intents.push({
          intent: intent.intent,
          prompts: promptsValue,
        });
        promptsValue.forEach((prompts) => {
          if (!listPrompts.includes(prompts)) {
            listPrompts.push(prompts);
          }
        });
      });
      dataArray.push({ code: intentLanguage.code, dataTrain: dataFormat });
      listPromptsArray.push({
        code: intentLanguage.code,
        dataTrain: listPrompts,
      });
    });
    return { dataFormat: dataArray, listPrompts: listPromptsArray };
  }
  handleTrimDataArray(data) {
    data.forEach((element, index) => {
      data[index] = element.trim();
    });
    for (let j = 0; j < data.length; j++) {
      if (data[j] == "") {
        if (j != 0) {
          data.splice(j, 1);
        } else {
          data.shift();
        }
        j--;
      }
    }
    return data;
  }
  handleCheckListIntent() {
    for (let i = 0; i < this.listIntent.length; i++) {
      for (let j = 0; j < this.listIntent[i].value.length; j++) {
        if (
          this.listIntent[i].value[j].intent.trim() == "" &&
          this.listIntent[i].value[j].value.text.trim() == ""
        ) {
          if (j != 0) {
            this.listIntent[i].value.splice(j, 1);
          } else {
            this.listIntent[i].value.shift();
          }
          j--;
        }
      }
      if (this.listIntent[i].value.length <= 0) {
        if (i != 0) {
          this.listIntent.splice(i, 1);
        } else {
          this.listIntent.shift();
        }
        i--;
      }
    }
  }

  checkGrammarName(value) {
    this.isExist = true;
    if (value == "" || value == undefined || value == this.currentName) {
      this.isExist = false;
      return;
    }

    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.isNameLoading = true;
      let data = new CheckGrammarNameCommand();
      data.grammarName = value?.trim();
      data.grammarType = this.typeGrammar;
      this.grammarClient.checkExistedGrammarName(data).subscribe({
        next: (rs) => {
          if (rs && value?.trim() != this.currentName) {
            this.grammarForm.controls["name"].setErrors({
              isExist: rs,
            });
            this.handleFocus();
          } else {
            this.isExist = false;
          }
          this.isNameLoading = false;
        },
        error: () => {
          this.handleFocus();
        },
      });
      this.createGrammar.name = this.grammarForm.controls["name"]?.value
        ?.toString()
        ?.trim();
    }, 1500);
  }

  handleFocus() {
    setTimeout(() => {
      this.inputName?.nativeElement?.focus();
    }, 0);
  }

  setValidationErrors(errorData) {
    var errorData = JSON.parse(errorData);
    if (errorData) {
      for (const [key, value] of Object.entries(errorData)) {
        const fieldName = key.toLowerCase(); // Ensure case matches form control names
        const errorMessage = value[0];
        if (this.grammarForm.get(fieldName)) {
          const control = this.grammarForm.get(fieldName);
          control.setErrors({ serverError: errorMessage });
          control.markAsDirty();
        }
      }
    }
  }

  onSubmit() {
    setTimeout(() => {
      this.createGrammar.engine = this.engine;
      this.createGrammar.autoTranslate = this.translateGrammar;

      if (this.grammarForm.valid) {
        this.createGrammar.name = this.createGrammar.name?.trim();
        this.listIntent.forEach((element) => {
          element.value.forEach(
            (value) => (value.intent = value.intent.trim())
          );
        });
        if (this.typeGrammar == "Digit") {
          this.createGrammar.pattern = this.checkGrammar.pattern;
        } else if (this.typeGrammar == "PhraseList") {
          this.createGrammar.pattern = JSON.stringify(this.phraseList);
        } else {
          this.handleData();
          let dataFormat = this.handleFormatDataListIntent();
          this.createGrammar.pattern = JSON.stringify(this.listIntent);
          this.createGrammar.entity = JSON.stringify(this.entityData);
          this.createGrammar.patternTrain = JSON.stringify(dataFormat);
          this.createGrammar.engineType = this.engineType;
        }
        if (this.listIntent.length > 0 && this.listIntent[0].code == "en") {
          if (
            this.typeGrammar == "Custom" &&
            this.engine &&
            this.engineType == "FINETUNING"
          ) {
            let isValid = true;
            let dataFormat = JSON.parse(this.createGrammar.patternTrain);
            dataFormat?.some((item) => {
              let count = 0;
              if (
                item.dataTrain &&
                item.dataTrain?.data &&
                item.dataTrain.data?.intents
              ) {
                item.dataTrain.data?.intents?.forEach((element) => {
                  count += element?.prompts?.length;
                });
                if (count < 10) {
                  isValid = false;
                  return true;
                }
              }
            });
            if (isValid) {
              this.dialogService
                .open(ConfirmDialogComponent, {
                  autoFocus: false,
                  context: {
                    question:
                      "You are using ChatGPT engine, this action may change the model. Sure?",
                    textYes: "Yes",
                    textNo: "Cancel",
                    statusYes: "info",
                    statusNo: "basic",
                  },
                })
                .onClose.subscribe((isConfirm) => {
                  if (isConfirm) {
                    this.createGrammar.type = this.typeGrammar;
                    this.loading = true;
                    this.grammarClient.create(this.createGrammar).subscribe({
                      next: (result) => {
                        if (result !== null) {
                          this.showToast(true, "Save Grammar successfully");
                        } else {
                          this.trainingStatus = "FAILED";
                          this.showToast(false, "Save Grammar unsuccessfully");
                        }
                        this.loading = false;
                        if (result) {
                          this.onAdd.emit({
                            name: this.createGrammar.name?.trim(),
                            result: result,
                            isTranslate: this.translateGrammar,
                            engine: this.engine,
                          });
                        }
                      },
                      error: (error) => {
                        this.loading = false;
                        this.showToast(false, "Save Grammar unsuccessfully");
                        if (error.status == 422) {
                          this.setValidationErrors(error.response);
                        }
                      },
                    });
                  }
                });
            } else {
              this.showToast(
                false,
                "You are using ChatGPT, each language must have at least 10 Expressions"
              );
            }
          } else {
            this.createGrammar.type = this.typeGrammar;
            this.loading = true;
            this.grammarClient.create(this.createGrammar).subscribe({
              next: (result) => {
                if (result !== null) {
                  this.showToast(true, "Save Grammar successfully");
                } else {
                  this.trainingStatus = "FAILED";
                  this.showToast(false, "Save Grammar unsuccessfully");
                }
                this.loading = false;
                if (result) {
                  this.onAdd.emit({
                    name: this.createGrammar.name?.trim(),
                    result: result,
                    isTranslate: this.translateGrammar,
                    engine: this.engine,
                  });
                }
              },
              error: (error) => {
                this.loading = false;
                this.showToast(false, "Save Grammar unsuccessfully");
                if (error.status == 422) {
                  this.setValidationErrors(error.response);
                }
              },
            });
          }
        } else if (this.listIntent.length == 0) {
          this.showToast("required", "English Grammar is required");
          this.setDefaultCustomGrammar();
        } else {
          this.showToast("required", "English Grammar is required");
          const intentEnglish = {
            code: "en",
            name: "English",
            value: [
              {
                intent: "",
                value: {
                  text: "",
                  entity: [],
                },
              },
            ],
          };
          this.listIntent.unshift(intentEnglish);
        }
      }
    }, 100);
  }
  setDefaultCustomGrammar() {
    this.listIntent = [
      {
        code: "en",
        name: "English",
        value: [
          {
            intent: "",
            value: {
              text: "",
              entity: [],
            },
          },
        ],
      },
    ];
  }
  checkPatternGrammar(value) {
    if (this.checkGrammar.value && this.checkGrammar.pattern) {
      this.grammarClient.checkGrammar(this.checkGrammar).subscribe((result) => {
        this.isMatched = result;
      });
    }
  }

  dismiss() {
    this.ref.close();
  }

  showToast(result, message) {
    if (result == "required") {
      this.toastrService.show(message, `Notification`, {
        position: NbGlobalPhysicalPosition.BOTTOM_LEFT,
        status: "danger",
      });
    } else if (result == true) {
      this.toastrService.show(message, `Notification`, {
        position: NbGlobalPhysicalPosition.BOTTOM_LEFT,
        status: "success",
      });
    } else {
      this.toastrService.show(message, `Notification`, {
        position: NbGlobalPhysicalPosition.BOTTOM_LEFT,
        status: "danger",
      });
    }
  }
}
