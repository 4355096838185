<div class="main-screen" (keydown.enter)="triggerCallBtn()">
  <div class="row mb-3">
    <div *ngIf="dataService.callStatus === CallStatus.InCall" class="row">
      <span *ngIf="enterNumpad" style="margin: auto; text-align: center">{{
        this.countupTimerService.timerValue.mins +
          ":" +
          this.countupTimerService.timerValue.seconds
      }}</span>
      <nb-user
        *ngIf="!enterNumpad"
        class="mb-3"
        size="large"
        [name]="
          dataService.stateCall == 'incall'
            ? session.request.from.uri.user
            : dialNumber
        "
        [title]="
          this.countupTimerService.timerValue.mins +
          ':' +
          this.countupTimerService.timerValue.seconds
        "
        picture="assets/images/user.jpg"
      >
      </nb-user>
    </div>

    <div class="d-flex justify-content-between">
      <nb-icon
        style="margin: auto"
        (click)="openKeypad(false)"
        *ngIf="this.countupTimerService.totalSeconds > 0 && enterNumpad"
        icon="arrow-back"
      ></nb-icon>
      <input
        (keydown)="onKeyDown($event)"
        autofocus
        *ngIf="enterNumpad"
        [(ngModel)]="enterNumber"
        type="text"
        class="input-center w-100"
        placeholder="Enter a number"
      />

      <nb-icon
        style="margin: auto"
        (click)="backspaceHandle()"
        *ngIf="enterNumpad && enterNumber"
        icon="backspace"
      ></nb-icon>
    </div>
  </div>

  <div class="numpad-div" *ngIf="enterNumpad == true">
    <div class="row mb-2" style="margin: auto">
      <div
        (click)="keyPress(key)"
        *ngFor="let key of keys.slice(0, 3); let i = index"
        class="span4"
      >
        <div class="num">
          <div class="txt">
            {{ key }}
          </div>
        </div>
      </div>
    </div>

    <div class="row mb-2" style="margin: auto">
      <div
        (click)="keyPress(key)"
        *ngFor="let key of keys.slice(3, 6); let i = index"
        class="span4"
      >
        <div class="num">
          <div class="txt">
            {{ key }}
          </div>
        </div>
      </div>
    </div>

    <div class="row mb-2" style="margin: auto">
      <div
        (click)="keyPress(key)"
        *ngFor="let key of keys.slice(6, 9); let i = index"
        class="span4"
      >
        <div class="num">
          <div class="txt">
            {{ key }}
          </div>
        </div>
      </div>
    </div>

    <div class="row mb-2" style="margin: auto">
      <div
        (click)="keyPress(key)"
        *ngFor="let key of keys.slice(9, 12); let i = index"
        class="span4"
      >
        <div class="num">
          <div class="txt">
            {{ key }}
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="enterNumpad == false">
    <div class="row call-action" style="margin: auto">
      <div class="span4">
        <div
          (click)="toggleMic()"
          [ngClass]="{ 'action-active': !mic }"
          class="num mb-2"
        >
          <div class="txt">
            <nb-icon [icon]="mic ? 'mic' : 'mic-off'"></nb-icon>
          </div>
        </div>
        <span class="action-title">{{ micLabel }}</span>
      </div>

      <div class="span4">
        <div (click)="openKeypad(true)" class="num mb-2">
          <div class="txt">
            <nb-icon icon="keypad"></nb-icon>
          </div>
        </div>
        <span class="action-title">Dialpad</span>
      </div>

      <div class="span4">
        <div
          (click)="changeAudio()"
          [ngClass]="{ 'action-active': !audio }"
          class="num mb-2"
        >
          <div class="txt">
            <nb-icon [icon]="audio ? 'volume-up' : 'volume-off'"></nb-icon>
          </div>
        </div>
        <span class="action-title">{{ audioLabel }}</span>
      </div>
    </div>

    <div class="row" style="margin: auto">
      <div class="span4">
        <div
          (click)="toggleHold()"
          [ngClass]="{ 'action-active': hold }"
          class="num mb-2"
        >
          <div class="txt">
            <nb-icon [icon]="hold ? 'pause-circle' : 'play-circle'"></nb-icon>
          </div>
        </div>
        <span class="action-title">{{ holdLabel }}</span>
      </div>

      <div class="span4">
        <div (click)="openTransfer()" class="num mb-2">
          <div class="txt">
            <nb-icon icon="undo"></nb-icon>
          </div>
        </div>
        <span class="action-title">Transfer</span>
      </div>
    </div>
  </div>
  <div class="row mt-3">
    <button
      size="large"
      class="callbtn"
      shape="round"
      [disabled]="getDisableCall()"
      (click)="clickProcessCall()"
      nbButton
      [status]="getCallProcesStatus()"
    >
      <nb-icon icon="phone"></nb-icon>
    </button>

    <button
      size="large"
      class="callbtn"
      shape="round"
      *ngIf="transfer"
      (click)="transferCall()"
      nbButton
    >
      <nb-icon icon="phone"></nb-icon>
    </button>
  </div>

  <ng-template #templateRef>
    <nb-card style="margin-bottom: 0">
      <nb-card-body style="padding: 1rem"> </nb-card-body>
    </nb-card>
  </ng-template>
</div>
