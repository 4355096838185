import { Component, EventEmitter, Input, Output } from "@angular/core";
import { CountupTimerService } from "ngx-timer";
import { Inviter, UserAgent } from "sip.js/lib/api";
import { DataService } from "../../../@core/utils/data.service";
import { CallStatus } from "../soft-phone.component";
import { Web } from "sip.js";

@Component({
  selector: "app-main-screen-phone",
  templateUrl: "./main-screen-phone.component.html",
  styleUrls: ["./main-screen-phone.component.scss"],
})
export class MainScreenPhoneComponent {
  @Input() session: any;
  @Input() userAgent: any;

  @Output() audioEmit = new EventEmitter<string>();
  @Output() sessionEmit = new EventEmitter<any>();
  @Output() audioChange = new EventEmitter<any>();

  keys = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "*", "0", "#"];

  mic: boolean = true;
  audio: boolean = true;
  hold: boolean = false;
  transfer: boolean = false;

  micLabel: string = "Mute";
  audioLabel: string = "Off";
  holdLabel: string = "Hold";

  enterNumber: string = "";
  enterNumpad = true;
  answered = false;

  CallStatus = CallStatus;

  constructor(
    public dataService: DataService,
    public countupTimerService: CountupTimerService
  ) {}

  ngOnInit() {}

  keyPress(key) {
    if (key) this.enterNumber += key;
    if (this.dataService.callStatus == CallStatus.InCall && !this.transfer) {
      const options = {
        requestOptions: {
          body: {
            contentDisposition: "render",
            contentType: "application/dtmf-relay",
            content: `Signal=${key}\r\nDuration=150`,
          },
        },
      };
      this.session.info(options);
    }
  }

  onKeyDown(event) {
    const specialKeys = ["Backspace", "Enter", "Delete"];
    if (!specialKeys.includes(event.key)) {
      event.preventDefault();
    }

    if (this.keys.includes(event.key)) {
      this.keyPress(event.key);
    }
  }

  toggleMic() {
    this.mic = !this.mic;
    this.micLabel = this.mic ? "Mute" : "Unmute";
    var self = this;
    var pc = this.session.sessionDescriptionHandler.peerConnection;
    pc.getLocalStreams().forEach(function (stream) {
      stream.getAudioTracks().forEach(function (track) {
        track.enabled = self.mic;
      });
    });
  }

  changeAudio() {
    this.audio = !this.audio;
    this.audioLabel = this.audio ? "Off" : "On";
    this.audioChange.emit(this.audio);
  }

  toggleHold() {
    this.hold = !this.hold;
    this.holdLabel = this.hold ? "Unhold" : "Hold";
    var optionsplus;
    if (this.hold) {
      optionsplus = {
        sessionDescriptionHandlerModifiers: [Web.holdModifier],
      };
    } else {
      optionsplus = {
        sessionDescriptionHandlerModifiers: [],
      };
    }

    if (this.session) {
      this.session.invite(optionsplus);
    }
  }

  getDisableCall() {
    switch (this.dataService.callStatus) {
      case CallStatus.Calling:
        return false;
      case CallStatus.Ringging:
        return false;
      case CallStatus.InCall:
        if (!this.answered) {
          this.enterNumpad = false;
          this.answered = true;
        }
        return false;
      case CallStatus.NoCall:
        this.enterNumpad = true;
        this.answered = false;
        if (this.enterNumber != "") {
          return false;
        } else {
          return true;
        }
    }
  }

  getCallProcesStatus() {
    switch (this.dataService.callStatus) {
      case CallStatus.Ringging:
        return "danger";
      case CallStatus.InCall:
        return "danger";
      case CallStatus.NoCall:
        return "success";
      case CallStatus.Calling:
        return "danger";
    }
  }

  clickProcessCall() {
    this.dataService.stateCall = "outcall";
    switch (this.dataService.callStatus) {
      case CallStatus.Ringging:
        this.dataService.callStatus = CallStatus.NoCall;
        this.enterNumber = "";
        this.transfer = false;
        this.session.dispose();
        break;
      case CallStatus.Calling:
        this.dataService.callStatus = CallStatus.NoCall;
        this.enterNumber = "";
        this.transfer = false;
        this.session.dispose();
        break;
      case CallStatus.InCall:
        this.resetPhone();
        break;
      case CallStatus.NoCall:
        this.dataService.callStatus = CallStatus.Calling;
        this.outCalling();
        break;
    }
  }

  dialNumber: string;
  outCalling() {
    this.dialNumber = this.enterNumber;
    const target = UserAgent.makeURI(
      "sip:" +
        this.enterNumber +
        "@" +
        this.userAgent.options.uri.host +
        ":" +
        this.userAgent.options.uri.port
    );
    const inviter = new Inviter(this.userAgent, target);
    this.sessionEmit.emit(inviter);
    inviter.invite().then((rs) => {});
  }

  openKeypad(rs) {
    this.enterNumber = "";
    this.enterNumpad = rs;
    if (!rs) {
      this.transfer = false;
    }
  }

  openTransfer() {
    this.enterNumber = "";
    this.transfer = true;
    this.enterNumpad = true;
  }
  transferCall() {
    this.dialNumber = this.enterNumber;
    const target = UserAgent.makeURI(
      "sip:" +
        this.enterNumber +
        "@" +
        this.userAgent.options.uri.host +
        ":" +
        this.userAgent.options.uri.port
    );
    this.session.refer(target).then((rs) => {});
    this.resetPhone();
  }

  triggerCallBtn() {
    if (
      !this.getDisableCall() &&
      this.dataService.callStatus == CallStatus.NoCall
    ) {
      this.clickProcessCall();
    }
  }

  backspaceHandle() {
    if (this.enterNumber.length > 0) {
      this.enterNumber = this.enterNumber.slice(0, -1);
    }
  }
  resetPhone() {
    this.dataService.callStatus = CallStatus.NoCall;
    this.countupTimerService.stopTimer();
    this.enterNumber = "";
    this.transfer = false;
    this.session.bye();
  }
}
