<div class="action-config-wrapper" style="max-width: 615px">
  <div class="config-action">
    <nb-stepper [linear]="false" orientation="horizontal" selectedIndex="0">
      <nb-step [label]="labelOne">
        <ng-template #labelOne>Say Somethings</ng-template>
        <nb-radio-group
          [(ngModel)]="data.option"
          class="choice-option"
          (ngModelChange)="handleAudio($event)"
        >
          <nb-radio *ngFor="let option of options" [value]="option.id">{{
            option.name
          }}</nb-radio>
        </nb-radio-group>
        <nb-tabset class="form-group">
          <nb-tab tabTitle="Main">
            <div style="padding: 1rem 0 0 0">
              <div class="mb-2">
                <label
                  class="form-label label d-flex"
                  style="align-items: center"
                >
                  <span>Choose Audio Main Prompt</span>
                  <nb-icon
                    class="ms-1"
                    style="color: #929bb3; width: 15px"
                    nbTooltip="Play or send this message when starting this action."
                    nbTooltipPlacement="bottom"
                    nbTooltipStatus="basic"
                    nbTooltipTrigger="hover"
                    icon="question-mark-circle-outline"
                  >
                  </nb-icon
                ></label>
                <text-to-speech #textToSpeech></text-to-speech>
                <nb-card
                  class="select-audio"
                  [ngClass]="{ show: data.option == 1 }"
                >
                  <nb-card-header class="audio-list-style">
                    <span>Audio List</span>
                    <nb-icon
                      class="ms-2"
                      style="color: #929bb3; width: 15px"
                      nbTooltip="Select the audio you want to play. The audio list will play in order and continuously. If no audio is selected, the system will use text to speech."
                      nbTooltipPlacement="bottom"
                      nbTooltipStatus="basic"
                      nbTooltipTrigger="hover"
                      icon="question-mark-circle-outline"
                    >
                    </nb-icon
                  ></nb-card-header>
                  <nb-card-body>
                    <simple-audio-player
                      #audioPlayer
                      [events]="eventsSubject.asObservable()"
                    ></simple-audio-player>
                    <button
                      nbButton
                      size="small"
                      status="primary"
                      (click)="showModify('main')"
                    >
                      <nb-icon icon="plus-circle-outline"></nb-icon> Add
                    </button>
                  </nb-card-body>
                </nb-card>
                <div
                  style="
                    display: flex;
                    flex-direction: column;
                    margin-top: 0.5rem;
                  "
                >
                  <label for="name" class="form-label label"
                    >Choose Main Videobot</label
                  >
                  <sentiment-select
                    placeHolder="Choose Main VideoBot"
                    (valueSelected)="handleSentiment($event, 'main')"
                    [value]="data.sentiment"
                    defaultValue="normal-speak"
                  ></sentiment-select>
                </div>
                <div class="flex-title">
                  <span class="title">Menu Option</span>
                  <span class="line"></span>
                </div>
                <div
                  class="mt-3"
                  style="display: flex; align-items: center; margin-bottom: 5px;"
                >
                  <nb-checkbox
                    (checkedChange)="allowAnswerOption($event)"
                    [checked]="data.allowAnswerOption"
                  >
                    Enable
                  </nb-checkbox>
                  <nb-icon
                    class="ms-2"
                    style="color: #929bb3; width: 15px; margin-bottom: 2px"
                    nbTooltip="Allows you to configure the answer button for the user (only for Chatbot)."
                    nbTooltipPlacement="bottom"
                    nbTooltipStatus="basic"
                    nbTooltipTrigger="hover"
                    icon="question-mark-circle-outline"
                  >
                  </nb-icon>
                </div>
                <div
                  *ngIf="data.allowAnswerOption === true"
                  class="answer-option-wrapper"
                >
                  <nb-list
                    cdkDropList
                    class="example-list"
                    (cdkDropListDropped)="drop($event)"
                  >
                    <nb-list-item
                      class="example-box"
                      style="border: none; margin-left: -16px;"
                      *ngFor="let item of answer; let i = index"
                      cdkDrag
                    >
                      <div class="answer-item">
                        <nb-accordion style="flex: 1">
                          <nb-accordion-item>
                            <nb-accordion-item-header style="padding: 0">
                              <input
                                type="text"
                                fieldSize="small"
                                nbInput
                                style="border: none; padding-right: 3rem"
                                fullWidth
                                [(ngModel)]="item.name"
                                (click)="$event.stopPropagation()"
                                (keydown)="$event.stopPropagation()"
                                placeholder="Answer Value"
                              />
                            </nb-accordion-item-header>
                            <nb-accordion-item-body>
                              <div *ngFor="let itemLanguage of item.value">
                                <label
                                  for="itemLanguage"
                                  class="form-label label"
                                  >{{ itemLanguage.name }}</label
                                >
                                <input
                                  id="itemLanguage"
                                  type="text"
                                  fieldSize="small"
                                  nbInput
                                  fullWidth
                                  [(ngModel)]="itemLanguage.value"
                                  [placeholder]="
                                    'Answer Display ' + itemLanguage.name
                                  "
                                />
                              </div>
                            </nb-accordion-item-body>
                          </nb-accordion-item>
                        </nb-accordion>
                        <button
                          type="button"
                          size="small"
                          (click)="removeAnswer(i)"
                          nbButton
                        >
                          <nb-icon icon="trash-2-outline"></nb-icon>
                        </button>
                        <div
                          cdkDragHandle
                          class="move-icon"
                        >
                          <nb-icon
                            style="margin-right: -14px"
                            status="basic"
                            icon="more-vertical-outline"
                          ></nb-icon>
                          <nb-icon status="basic" icon="more-vertical-outline"></nb-icon>
                        </div>
                      </div>                 
                    </nb-list-item>
                  </nb-list>
                  <div>
                    <button
                      type="button"
                      size="small"
                      status="primary"
                      class="small"
                      nbButton
                      (click)="addNewAnswer()"
                    >
                      <nb-icon icon="plus-circle-outline"></nb-icon>
                      <span>Add</span>
                    </button>
                  </div>
                </div>
              </div>
            </div></nb-tab
          >
          <nb-tab tabTitle="No Input">
            <div style="padding: 1rem 0 0 0">
              <div class="mb-2">
                <label
                  class="form-label label d-flex"
                  style="align-items: center"
                >
                  <span>Choose Audio When No Input</span>
                  <nb-icon
                    class="ms-1"
                    style="color: #929bb3; width: 15px"
                    nbTooltip="Play or send this message when the user doesn't say or send message within the timeout."
                    nbTooltipPlacement="bottom"
                    nbTooltipStatus="basic"
                    nbTooltipTrigger="hover"
                    icon="question-mark-circle-outline"
                  >
                  </nb-icon
                ></label>
                <text-to-speech #textToSpeechNoInput></text-to-speech>
                <nb-card
                  class="select-audio"
                  [ngClass]="{ show: data.option == 1 }"
                >
                  <nb-card-header class="audio-list-style">
                    <span>Audio List</span>
                    <nb-icon
                      class="ms-2"
                      style="color: #929bb3; width: 15px"
                      nbTooltip="Select the audio you want to play. The audio list will play in order and continuously. If no audio is selected, the system will use text to speech."
                      nbTooltipPlacement="bottom"
                      nbTooltipStatus="basic"
                      nbTooltipTrigger="hover"
                      icon="question-mark-circle-outline"
                    >
                    </nb-icon
                  ></nb-card-header>
                  <nb-card-body>
                    <simple-audio-player
                      #audioNoInputPlayer
                      [events]="eventsSubjectNoInput.asObservable()"
                    ></simple-audio-player>
                    <button
                      nbButton
                      size="small"
                      status="primary"
                      (click)="showModify('no-input')"
                    >
                      <nb-icon icon="plus-circle-outline"></nb-icon> Add
                    </button>
                  </nb-card-body>
                </nb-card>
                <div
                  style="
                    display: flex;
                    flex-direction: column;
                    margin-top: 0.5rem;
                  "
                >
                  <label for="name" class="form-label label"
                    >Choose No Input Videobot</label
                  >
                  <sentiment-select
                    placeHolder="Choose No Input Videobot"
                    (valueSelected)="handleSentiment($event, 'noInput')"
                    [value]="data.sentimentNoInput"
                    defaultValue="no-input"
                  ></sentiment-select>
                </div>
              </div>
            </div>
          </nb-tab>
          <nb-tab tabTitle="Not Match">
            <div style="padding: 1rem 0 0 0">
              <div class="mb-2">
                <label
                  class="form-label label d-flex"
                  style="align-items: center"
                >
                  <span>Choose Audio When Input Not Match</span>
                  <nb-icon
                    class="ms-1"
                    style="color: #929bb3; width: 15px"
                    nbTooltip="Play or send this message when the user's response is not as expected."
                    nbTooltipPlacement="bottom"
                    nbTooltipStatus="basic"
                    nbTooltipTrigger="hover"
                    icon="question-mark-circle-outline"
                  >
                  </nb-icon
                ></label>
                <text-to-speech #textToSpeechNotMatch></text-to-speech>
                <nb-card
                  class="select-audio"
                  [ngClass]="{ show: data.option == 1 }"
                >
                  <nb-card-header class="audio-list-style">
                    <span>Audio List</span>
                    <nb-icon
                      class="ms-2"
                      style="color: #929bb3; width: 15px"
                      nbTooltip="Select the audio you want to play. The audio list will play in order and continuously. If no audio is selected, the system will use text to speech."
                      nbTooltipPlacement="bottom"
                      nbTooltipStatus="basic"
                      nbTooltipTrigger="hover"
                      icon="question-mark-circle-outline"
                    >
                    </nb-icon
                  ></nb-card-header>
                  <nb-card-body>
                    <simple-audio-player
                      #audioNotMatchPlayer
                      [events]="eventsSubjectNotMatch.asObservable()"
                    ></simple-audio-player>
                    <button
                      nbButton
                      size="small"
                      status="primary"
                      (click)="showModify('not-match')"
                    >
                      <nb-icon icon="plus-circle-outline"></nb-icon> Add
                    </button>
                  </nb-card-body>
                </nb-card>
                <div
                  style="
                    display: flex;
                    flex-direction: column;
                    margin-top: 0.5rem;
                  "
                >
                  <label for="name" class="form-label label"
                    >Choose Not Match Videobot</label
                  >
                  <sentiment-select
                    placeHolder="Choose Not Match Videobot"
                    (valueSelected)="handleSentiment($event, 'notMatch')"
                    [value]="data.sentimentNotMatch"
                    defaultValue="not-match"
                  ></sentiment-select>
                </div>
              </div>
            </div>
          </nb-tab>
          <nb-tab tabTitle="Repeat">
            <div style="padding: 1rem 0 0 0">
              <div class="mb-2">
                <label
                  for="key-repeat"
                  class="form-label label d-flex"
                  style="align-items: center"
                  ><span>Key Repeat</span>
                  <nb-icon
                    class="ms-1"
                    style="color: #929bb3; width: 15px"
                    nbTooltip="Choose the key for the user to press to repeat the main prompt."
                    nbTooltipPlacement="bottom"
                    nbTooltipStatus="basic"
                    nbTooltipTrigger="hover"
                    icon="question-mark-circle-outline"
                  >
                  </nb-icon
                ></label>
                <nb-select
                  id="key-repeat"
                  size="small"
                  fullWidth
                  placeholder="Select Key Repeat"
                  [(ngModel)]="data.keyNumberRepeat"
                >
                  <nb-option
                    *ngFor="let item of listKeyNumber"
                    [value]="item.value"
                    >{{ item.name }}
                  </nb-option>
                </nb-select>
              </div>
              <div class="mb-2">
                <label
                  for="keyword"
                  class="form-label label d-flex"
                  style="align-items: center"
                  ><span>Keywords Repeat</span>
                  <nb-icon
                    class="ms-1"
                    style="color: #929bb3; width: 15px"
                    nbTooltip="Defines the keywords contained in the user's response to repeat the main prompt."
                    nbTooltipPlacement="bottom"
                    nbTooltipStatus="basic"
                    nbTooltipTrigger="hover"
                    icon="question-mark-circle-outline"
                  >
                  </nb-icon
                ></label>
                <form>
                  <tag-input
                    style="width: 100%"
                    [(ngModel)]="data.keywordsRepeat"
                    secondaryPlaceholder=""
                    addOnBlur="true"
                    placeholder=""
                    [onTextChangeDebounce]="10"
                    (onTextChange)="onTextChange($event)"
                  >
                  </tag-input>
                </form>
              </div>
              <div class="mb-2">
                <label
                  class="form-label label d-flex"
                  style="align-items: center"
                >
                  <span>Choose Audio Repeat Prompt</span>
                  <nb-icon
                    class="ms-1"
                    style="color: #929bb3; width: 15px"
                    nbTooltip="Play or send this message after the main prompt has finished playing."
                    nbTooltipPlacement="bottom"
                    nbTooltipStatus="basic"
                    nbTooltipTrigger="hover"
                    icon="question-mark-circle-outline"
                  >
                  </nb-icon
                ></label>
                <text-to-speech
                  #textToSpeechRepeat
                  [ngClass]="{ textToSpeech: data.option != 2 }"
                ></text-to-speech>
                <nb-card
                  class="select-audio"
                  [ngClass]="{ show: data.option == 1 }"
                >
                  <nb-card-header class="audio-list-style">
                    <span>Audio List</span>
                    <nb-icon
                      class="ms-2"
                      style="color: #929bb3; width: 15px"
                      nbTooltip="Select the audio you want to play. The audio list will play in order and continuously."
                      nbTooltipPlacement="bottom"
                      nbTooltipStatus="basic"
                      nbTooltipTrigger="hover"
                      icon="question-mark-circle-outline"
                    >
                    </nb-icon
                  ></nb-card-header>
                  <nb-card-body>
                    <simple-audio-player
                      #audioPlayerRepeat
                      [events]="eventsSubjectRepeat.asObservable()"
                    ></simple-audio-player>
                    <button
                      nbButton
                      size="small"
                      status="primary"
                      (click)="showModify('repeat')"
                    >
                      <nb-icon icon="plus-circle-outline"></nb-icon> Add
                    </button>
                  </nb-card-body>
                </nb-card>
              </div>
            </div>
          </nb-tab>
          <nb-tab tabTitle="Confirm">
            <div style="padding: 1rem 0 0 0">
              <div
                style="display: flex; align-items: center; margin-bottom: 1rem"
              >
                <nb-checkbox
                  (checkedChange)="allowConfirm($event)"
                  [checked]="data.isConfirm"
                >
                  Enable Confirm
                </nb-checkbox>
                <nb-icon
                  class="ms-2"
                  style="color: #929bb3; width: 15px; margin-bottom: 2px"
                  nbTooltip="Confirm user responses with yes/no questions."
                  nbTooltipPlacement="bottom"
                  nbTooltipStatus="basic"
                  nbTooltipTrigger="hover"
                  icon="question-mark-circle-outline"
                >
                </nb-icon>
              </div>
              <div
                class="mb-2"
                [ngClass]="{ 'hidden-confirm': !data.isConfirm }"
              >
                <label
                  class="form-label label d-flex"
                  style="align-items: center"
                >
                  <span>Choose Audio Confirm Prompt</span>
                  <nb-icon
                    class="ms-1"
                    style="color: #929bb3; width: 15px"
                    nbTooltip="Play or send this message after collecting user responses as expected."
                    nbTooltipPlacement="bottom"
                    nbTooltipStatus="basic"
                    nbTooltipTrigger="hover"
                    icon="question-mark-circle-outline"
                  >
                  </nb-icon
                ></label>
                <text-to-speech #textToSpeechConfirm></text-to-speech>
                <nb-card
                  class="select-audio"
                  [ngClass]="{ show: data.option == 1 }"
                >
                  <nb-card-header class="audio-list-style">
                    <span>Audio List</span>
                    <nb-icon
                      class="ms-2"
                      style="color: #929bb3; width: 15px"
                      nbTooltip="Select the audio you want to play. The audio list will play in order and continuously. If no audio is selected, the system will use text to speech."
                      nbTooltipPlacement="bottom"
                      nbTooltipStatus="basic"
                      nbTooltipTrigger="hover"
                      icon="question-mark-circle-outline"
                    >
                    </nb-icon
                  ></nb-card-header>
                  <nb-card-body>
                    <simple-audio-player
                      #audioPlayerConfirm
                      [events]="eventsSubjectConfirm.asObservable()"
                    ></simple-audio-player>
                    <button
                      nbButton
                      size="small"
                      status="primary"
                      (click)="showModify('confirm')"
                    >
                      <nb-icon icon="plus-circle-outline"></nb-icon> Add
                    </button>
                  </nb-card-body>
                </nb-card>
              </div>
            </div></nb-tab
          >
        </nb-tabset>
      </nb-step>
      <nb-step [label]="labelTwo">
        <ng-template #labelTwo>Settings</ng-template>
        <div class="row" style="margin-top: 0.75rem">
          <div class="col-md-6">
            <div class="mb-2" style="display: flex; align-items: center">
              <nb-toggle [(checked)]="data.allowSpeech" status="basic"
                >Allow Speech</nb-toggle
              >
              <nb-icon
                class="ms-2"
                style="color: #929bb3; width: 15px"
                nbTooltip="Enable voice recognition."
                nbTooltipPlacement="bottom"
                nbTooltipStatus="basic"
                nbTooltipTrigger="hover"
                icon="question-mark-circle-outline"
              >
              </nb-icon>
            </div>
          </div>
          <div class="col-md-6">
            <div class="mb-2" style="display: flex; align-items: center">
              <nb-toggle [(checked)]="data.allowBreak" status="basic"
                >Allow Break Prompt</nb-toggle
              >
              <nb-icon
                class="ms-2"
                style="color: #929bb3; width: 15px"
                nbTooltip="Allows the caller to press any key to break current prompt."
                nbTooltipPlacement="bottom"
                nbTooltipStatus="basic"
                nbTooltipTrigger="hover"
                icon="question-mark-circle-outline"
              >
              </nb-icon>
            </div>
          </div>
        </div>
        <div class="flex-title">
          <span class="title">DTMF</span>
          <span class="line"></span>
        </div>

        <div class="row">
          <div class="col-md-6">
            <div class="mb-2">
              <label
                for="minLength"
                class="form-label label d-flex"
                style="align-items: center"
                ><span>Minimum Length</span>
                <nb-icon
                  class="ms-1"
                  style="color: #929bb3; width: 15px"
                  nbTooltip="Minimum length of the user's response."
                  nbTooltipPlacement="bottom"
                  nbTooltipStatus="basic"
                  nbTooltipTrigger="hover"
                  icon="question-mark-circle-outline"
                >
                </nb-icon
              ></label>
              <input
                id="minLength"
                [ngModel]="data.minLength"
                (input)="handleOnlyIntegerNumber($event)"
                type="number"
                min="0"
                nbInput
                fullWidth
                placeholder="Minimum Length"
                fieldSize="small"
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="mb-2">
              <label
                for="maxLength"
                class="form-label label d-flex"
                style="align-items: center"
                ><span>Maximum Length</span>
                <nb-icon
                  class="ms-1"
                  style="color: #929bb3; width: 15px"
                  nbTooltip="Maximum length of the user's response."
                  nbTooltipPlacement="bottom"
                  nbTooltipStatus="basic"
                  nbTooltipTrigger="hover"
                  icon="question-mark-circle-outline"
                >
                </nb-icon
              ></label>
              <input
                id="maxLength"
                [ngModel]="data.maxLength"
                (input)="handleOnlyIntegerNumber($event)"
                nbInput
                min="1"
                type="number"
                fullWidth
                placeholder="Maximum Length"
                fieldSize="small"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="mb-2">
              <label
                for="noInputTimeout"
                class="form-label label d-flex"
                style="align-items: center"
                ><span>No Input Timeout (s)</span>
                <nb-icon
                  class="ms-1"
                  style="color: #929bb3; width: 15px"
                  nbTooltip="Maximum time to wait for the user's input."
                  nbTooltipPlacement="bottom"
                  nbTooltipStatus="basic"
                  nbTooltipTrigger="hover"
                  icon="question-mark-circle-outline"
                >
                </nb-icon
              ></label>
              <input
                id="noInputTimeout"
                [ngModel]="data.noInputTimeout"
                (input)="handleOnlyIntegerNumber($event)"
                nbInput
                min="1"
                type="number"
                fullWidth
                placeholder="No Input Timeout"
                fieldSize="small"
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="mb-2">
              <label
                for="timeout"
                class="form-label label d-flex"
                style="align-items: center"
                ><span>Timeout (s)</span>
                <nb-icon
                  class="ms-1"
                  style="color: #929bb3; width: 15px"
                  nbTooltip="Maximum time to wait for the user to press the next key."
                  nbTooltipPlacement="bottom"
                  nbTooltipStatus="basic"
                  nbTooltipTrigger="hover"
                  icon="question-mark-circle-outline"
                >
                </nb-icon
              ></label>
              <input
                id="timeout"
                [ngModel]="data.timeout"
                (input)="handleOnlyIntegerNumber($event)"
                type="number"
                min="0"
                nbInput
                fullWidth
                placeholder="Timeout"
                fieldSize="small"
              />
            </div>
          </div>
        </div>
        <div class="flex-title" *ngIf="this.data.allowSpeech === true">
          <span class="title">Speech</span>
          <span class="line"></span>
        </div>
        <div class="row" *ngIf="this.data.allowSpeech === true">
          <div class="col-md-6">
            <div class="mb-2">
              <label
                for="minLengthSpeech"
                class="form-label label d-flex"
                style="align-items: center"
                ><span>Minimum Length</span>
                <nb-icon
                  class="ms-1"
                  style="color: #929bb3; width: 15px"
                  nbTooltip="Minimum length of the user's response."
                  nbTooltipPlacement="bottom"
                  nbTooltipStatus="basic"
                  nbTooltipTrigger="hover"
                  icon="question-mark-circle-outline"
                >
                </nb-icon
              ></label>
              <input
                id="minLengthSpeech"
                [ngModel]="data.minLengthSpeech"
                (input)="handleOnlyIntegerNumber($event)"
                type="number"
                min="1"
                nbInput
                fullWidth
                placeholder="Minimum Length"
                fieldSize="small"
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="mb-2">
              <label
                for="maxLengthSpeech"
                class="form-label label d-flex"
                style="align-items: center"
                ><span>Maximum Length</span>
                <nb-icon
                  class="ms-1"
                  style="color: #929bb3; width: 15px"
                  nbTooltip="Minimum length of the user's response."
                  nbTooltipPlacement="bottom"
                  nbTooltipStatus="basic"
                  nbTooltipTrigger="hover"
                  icon="question-mark-circle-outline"
                >
                </nb-icon
              ></label>
              <input
                id="maxLengthSpeech"
                [ngModel]="data.maxLengthSpeech"
                (input)="handleOnlyIntegerNumber($event)"
                type="number"
                min="1"
                nbInput
                fullWidth
                placeholder="Maximum Length"
                fieldSize="small"
              />
            </div>
          </div>
        </div>
        <div class="row" *ngIf="this.data.allowSpeech === true">
          <div class="col-md-6">
            <div class="mb-2">
              <label
                for="noInputSpeechTimeout"
                class="form-label label d-flex"
                style="align-items: center"
                ><span>No Input Timeout (s)</span>
                <nb-icon
                  class="ms-1"
                  style="color: #929bb3; width: 15px"
                  nbTooltip="Maximum time to wait for the user's say."
                  nbTooltipPlacement="bottom"
                  nbTooltipStatus="basic"
                  nbTooltipTrigger="hover"
                  icon="question-mark-circle-outline"
                >
                </nb-icon
              ></label>
              <input
                id="noInputSpeechTimeout"
                [ngModel]="data.noInputSpeechTimeout"
                (input)="handleOnlyIntegerNumber($event)"
                type="number"
                min="0"
                nbInput
                fullWidth
                placeholder="No Input Timeout"
                fieldSize="small"
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="mb-2">
              <label
                for="speechTimeout"
                class="form-label label d-flex"
                style="align-items: center"
                ><span>Max Duration (s)</span>
                <nb-icon
                  class="ms-1"
                  style="color: #929bb3; width: 15px"
                  nbTooltip="Maximum duration of time the user allowed to say."
                  nbTooltipPlacement="bottom"
                  nbTooltipStatus="basic"
                  nbTooltipTrigger="hover"
                  icon="question-mark-circle-outline"
                >
                </nb-icon
              ></label>
              <input
                id="speechTimeout"
                [ngModel]="data.speechTimeout"
                (input)="handleOnlyIntegerNumber($event)"
                type="number"
                min="0"
                nbInput
                fullWidth
                placeholder="Max Duration"
                fieldSize="small"
              />
            </div>
          </div>
        </div>
        <div class="flex-title">
          <span class="title">Chatbot</span>
          <span class="line"></span>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="mb-2">
              <label
                for="chatTimeout"
                class="form-label label d-flex"
                style="align-items: center"
              >
                <span>No Input Timeout (s)</span>
                <nb-icon
                  class="ms-1"
                  style="color: #929bb3; width: 15px"
                  nbTooltip="Maximum time to accept the user's messages."
                  nbTooltipPlacement="bottom"
                  nbTooltipStatus="basic"
                  nbTooltipTrigger="hover"
                  icon="question-mark-circle-outline"
                >
                </nb-icon
              ></label>

              <input
                id="chatTimeout"
                [ngModel]="data.chatTimeout"
                (input)="handleOnlyIntegerNumber($event)"
                type="number"
                min="0"
                nbInput
                fullWidth
                placeholder="No Input Timeout"
                fieldSize="small"
              />
            </div>
          </div>
        </div>
        <div class="flex-title">
          <span class="title">Repeat</span>
          <span class="line"></span>
        </div>
        <div class="mt-2" style="display: flex; align-items: center">
          <nb-checkbox
            id="rememberRepeat"
            (checkedChange)="allowRememberRepeat($event)"
            [checked]="data.rememberRepeat"
          >
            Remember Prompt Position
          </nb-checkbox>
          <nb-icon
            class="ms-2"
            style="color: #929bb3; width: 15px; margin-bottom: 2px"
            nbTooltip="The 'Max Tries' will not reset when the flow returns to this action (loop)."
            nbTooltipPlacement="bottom"
            nbTooltipStatus="basic"
            nbTooltipTrigger="hover"
            icon="question-mark-circle-outline"
          >
          </nb-icon>
        </div>
        <div class="mb-3 mt-2">
          <label
            for="repeat"
            class="form-label label d-flex"
            style="align-items: center"
          >
            <span>Max Tries</span>
            <nb-icon
              class="ms-1"
              style="color: #929bb3; width: 15px"
              nbTooltip="Maximum number of times to allow the user to say or send message if the user's response is not as expected (Not match with Grammar)."
              nbTooltipPlacement="bottom"
              nbTooltipStatus="basic"
              nbTooltipTrigger="hover"
              icon="question-mark-circle-outline"
            >
            </nb-icon
          ></label>
          <input
            id="repeat"
            [ngModel]="data.repeat"
            (input)="handleOnlyIntegerNumber($event)"
            type="number"
            min="0"
            nbInput
            fullWidth
            placeholder="Max Tries"
            fieldSize="small"
          />
        </div>
        <div class="flex-title">
          <span class="title">Answer</span>
          <span class="line"></span>
        </div>
        <div class="mb-2 mt-2" style="display: flex; align-items: center">
          <nb-checkbox
            (checkedChange)="allowSpelling($event)"
            [checked]="data.spelling"
          >
            Enable Enhanced Spelling Recognition.
          </nb-checkbox>
          <nb-icon
            class="ms-2"
            style="color: #929bb3; width: 15px; margin-bottom: 2px"
            nbTooltip="Voice recognition recognizes the input as a spelling format. Ex: N E W Y O R K."
            nbTooltipPlacement="bottom"
            nbTooltipStatus="basic"
            nbTooltipTrigger="hover"
            icon="question-mark-circle-outline"
          >
          </nb-icon>
        </div>
        <div class="mb-3 mt-2" *ngIf="data.spelling === true">
          <label
            for="spelling"
            class="form-label label d-flex"
            style="align-items: center"
          >
            <span>Start From Position</span>
            <nb-icon
              class="ms-1"
              style="color: #929bb3; width: 15px"
              nbTooltip="Start enhanced spelling recognition at position X of the main prompt."
              nbTooltipPlacement="bottom"
              nbTooltipStatus="basic"
              nbTooltipTrigger="hover"
              icon="question-mark-circle-outline"
            >
            </nb-icon
          ></label>
          <input
            id="spelling"
            [(ngModel)]="data.spellingPosition"
            type="number"
            min="0"
            nbInput
            fullWidth
            placeholder="Start From Position"
            fieldSize="small"
          />
        </div>
        <div class="flex-title">
          <span class="title">Log</span>
          <span class="line"></span>
        </div>
        <div class="mb-3 mt-3" style="display: flex; align-items: center">
          <nb-checkbox (checkedChange)="allowLog($event)" [checked]="data.log">
            Write Log For Input
          </nb-checkbox>
          <nb-icon
            class="ms-2"
            style="color: #929bb3; width: 15px; margin-bottom: 2px"
            nbTooltip="Logging of main prompts and user response information."
            nbTooltipPlacement="bottom"
            nbTooltipStatus="basic"
            nbTooltipTrigger="hover"
            icon="question-mark-circle-outline"
          >
          </nb-icon>
        </div>
      </nb-step>
      <nb-step [label]="labelThree">
        <ng-template #labelThree>Grammar</ng-template>
        <ngx-multiple-grammar
          #multipleGrammar
          [grammarArray]="grammarArray"
          [type]="data.typeGrammar"
          [type]="data.typeGrammar"
          [isLoadingGrammar]="isLoadingGrammar"
        ></ngx-multiple-grammar>
        <div class="row">
          <div class="flex-title">
            <span class="title">Assign Variable</span>
            <span class="line"></span>
          </div>
          <div class="mb-2">
            <label class="form-label label d-flex" style="align-items: center">
              <span>User Response</span>
              <nb-icon
                class="ms-1"
                style="color: #929bb3; width: 15px"
                nbTooltip="Assign the user response to the variable."
                nbTooltipPlacement="bottom"
                nbTooltipStatus="basic"
                nbTooltipTrigger="hover"
                icon="question-mark-circle-outline"
              >
              </nb-icon
            ></label>
            <ngx-input-value-static
              placeHolder="Select User Response"
              selectAttribute="true"
              [propertyInput]="data.userResponse"
              (propertyEmit)="getUserResponse($event)"
            >
            </ngx-input-value-static>
          </div>
        </div>
        <div class="row">
          <div class="mb-2">
            <div class="form-label d-flex" style="align-items: center">
              <label class="label required"> Intent </label>
              <nb-icon
                class="ms-1"
                style="color: #929bb3; width: 15px"
                nbTooltip="Assign the intent of the user to the variable."
                nbTooltipPlacement="bottom"
                nbTooltipStatus="basic"
                nbTooltipTrigger="hover"
                icon="question-mark-circle-outline"
              >
              </nb-icon>
            </div>

            <ngx-input-value-static
              placeHolder="Select Intent"
              selectAttribute="true"
              [propertyInput]="data.attribute"
              (propertyEmit)="getAttribute($event)"
              [errorMessage]="errorMessage"
            >
            </ngx-input-value-static>
          </div>
        </div>
        <div class="row">
          <div class="mb-2">
            <label class="form-label label d-flex" style="align-items: center">
              <span>Entity</span>
              <nb-icon
                class="ms-1"
                style="color: #929bb3; width: 15px"
                nbTooltip="Assign the entity of the the user to the variable."
                nbTooltipPlacement="bottom"
                nbTooltipStatus="basic"
                nbTooltipTrigger="hover"
                icon="question-mark-circle-outline"
              >
              </nb-icon
            ></label>
            <ngx-input-value-static
              placeHolder="Select Entity"
              selectAttribute="true"
              [propertyInput]="data.entityAttribute"
              (propertyEmit)="getEntityAttribute($event)"
            >
            </ngx-input-value-static>
          </div>
        </div>
      </nb-step>
    </nb-stepper>
  </div>
  <footer-action
    (handleCancelAction)="handleCancelAction()"
    (handleSaveAction)="handleSaveAction()"
  ></footer-action>
</div>
