import {
  AfterViewInit,
  Component,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { MxCell, MxGraph } from "../../../../@core/model/mxgraph";
import { ChatGPT } from "../../../../@core/model/chatgpt";
import {
  NbDialogService,
  NbGlobalPhysicalPosition,
  NbPopoverDirective,
  NbToastrService,
  NbWindowRef,
} from "@nebular/theme";
import { DataService } from "../../../../@core/utils/data.service";
import { DialogAttributeComponent } from "../../other/dialog-attribute/dialog-attribute.component";
import { CreateGPTDatasetComponent } from "./create-gpt-dataset/create-gpt-dataset.component";
import {
  AudioClient,
  ChatGPTClient,
  GPTDataDto,
  GrammarClient,
} from "../../../../System-api";
import { Observable, Subject, Subscription, map, of } from "rxjs";
import { SimpleAudioPlayerModalComponent } from "../../../../shared/simple-audio-player-modal/simple-audio-player-modal.component";
import { SimpleAudioPlayerComponent } from "../../../../shared/simple-audio-player/simple-audio-player.component";
import { CreateGrammarComponent } from "../get-input/create-grammar/create-grammar.component";
import { ConfirmDialogComponent } from "../../../../shared/confirm-dialog/confirm-dialog.component";
import { SignalRService } from "../../../../@core/utils/signalR.service";
import { TextToSpeechComponent } from "../../../../shared/text-to-speech/text-to-speech.component";
import { UntypedFormGroup } from "@angular/forms";
import {
  RxFormBuilder,
  RxwebValidators,
} from "@rxweb/reactive-form-validators";
import { noWhitespaceValidator } from "../../../../@core/utils/helpers";
import { GraphHandlerService } from "../../../../@core/utils/graph.service";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { UpdateDatasetComponent } from "../../../../portal/customer-role/studio/datasets/update/update-dataset.component";

@Component({
  selector: "ngx-chatgpt",
  templateUrl: "./chatgpt.component.html",
  styleUrls: ["./chatgpt.component.scss"],
})
export class ChatGPTComponent implements OnInit, OnDestroy {
  @ViewChild(NbPopoverDirective) popover: NbPopoverDirective;
  @ViewChild("item") accordion;
  @ViewChild("audioPlayer", {
    static: true,
  })
  audioPlayer: SimpleAudioPlayerComponent;
  @ViewChild("audioNotMatchPlayer", {
    static: true,
  })
  audioNotMatchPlayer: SimpleAudioPlayerComponent;
  @ViewChild("audioNoInputPlayer", {
    static: true,
  })
  audioNoInputPlayer: SimpleAudioPlayerComponent;
  @ViewChild("textToSpeech", {
    static: true,
  })
  textToSpeech: TextToSpeechComponent;
  @ViewChild("textToSpeechNotMatch", {
    static: true,
  })
  textToSpeechNotMatch: TextToSpeechComponent;
  @ViewChild("textToSpeechNoInput", {
    static: true,
  })
  textToSpeechNoInput: TextToSpeechComponent;
  @ViewChild("autoInput") input;
  listCondition;
  cell: MxCell;
  graph: MxGraph;
  data: ChatGPT = new ChatGPT();
  listDataGPT: GPTDataDto[];
  dataTTS: any = [];
  dataTTSCB: any = [];
  dataTTSNotMatch: any = [];
  dataTTSNoInput: any = [];
  options: object[] = [
    { id: 1, name: "Audio From Library" },
    { id: 2, name: "Text To Speech or Chat Text" },
  ];
  selectedSubscription: Subscription;
  grammarsData: any = [];
  intentSelected: any[] = [];
  eventsSubject: Subject<void> = new Subject<void>();
  eventsSubjectNotMatch: Subject<void> = new Subject<void>();
  eventsSubjectNoInput: Subject<void> = new Subject<void>();
  attributes: string[] = this.dataService.ivrAttributeArray;
  filteredOptions$: Observable<string[]>;
  gptStatusChangedSubscription: Subscription;
  grammarStatusChangedSubscription: Subscription;
  isLoading = false;
  cancelAction = false;
  formGroup: UntypedFormGroup;
  searchText: any = "";
  grammarArray = [];
  grammarDataFilter = [];
  isLoadingGrammar = true;
  icon = "plus-outline";
  timeout = null;
  constructor(
    private dialogService: NbDialogService,
    protected windowRef: NbWindowRef,
    public dataService: DataService,
    private chatGPTClient: ChatGPTClient,
    private grammarClient: GrammarClient,
    private toastrService: NbToastrService,
    private signalRService: SignalRService,
    private audioClient: AudioClient,
    private formBuilder: RxFormBuilder,
    private graphService: GraphHandlerService
  ) {
    this.createForm();
  }
  createForm() {
    this.formGroup = this.formBuilder.group({
      QADataset: ["", [RxwebValidators.required(), noWhitespaceValidator]],
    });
  }
  deleteGrammarInList(i) {
    this.grammarArray.splice(i, 1);
  }
  handleSaveAction() {
    this.windowRef.close();
  }
  handleCancelAction() {
    this.cancelAction = true;
    this.windowRef.close(true);
  }
  ngOnDestroy(): void {
    this.grammarStatusChangedSubscription.unsubscribe();
    this.gptStatusChangedSubscription.unsubscribe();
  }
  handleSentiment($event, type) {
    switch (type) {
      case "main":
        this.data.sentimentMain = $event;
        break;
      case "noInput":
        this.data.sentimentNoInput = $event;
        break;
      case "notMatch":
        this.data.sentimentNotMatch = $event;
        break;
      default:
        break;
    }
  }
  getGPTData() {
    this.isLoading = true;
    this.listDataGPT = [];
    this.chatGPTClient.getAll().subscribe((rs) => {
      this.listDataGPT = [...rs.gptDataList];
      this.isLoading = false;
    });
  }
  private filterGPT(value: string): any[] {
    const filterValue = value?.toLowerCase();
    return this.listDataGPT?.filter((optionValue) =>
      optionValue.name.toLowerCase().includes(filterValue)
    );
  }
  getFilteredOptionsGPT(value: string): Observable<any[]> {
    return of(value).pipe(map((filterString) => this.filterGPT(filterString)));
  }
  private filterGrammar(value: string): string[] {
    const filterValue = value?.toLowerCase();
    return this.grammarsData?.filter((optionValue) =>
      optionValue.name.toLowerCase().includes(filterValue)
    );
  }
  getFilteredOptionsGrammar(value: string): Observable<any[]> {
    return of(value).pipe(
      map((filterString) => this.filterGrammar(filterString))
    );
  }
  ngOnInit() {
    this.gptStatusChangedSubscription =
      this.signalRService.statusGPTChanged.subscribe((rs) => {
        if (rs) {
          this.getGPTData();
          this.notify(rs);
        }
      });
    this.grammarStatusChangedSubscription =
      this.signalRService.statusGrammarChanged.subscribe((rs) => {
        if (rs) {
          this.getCustomGrammars();
          this.notify(rs);
        }
      });

    this.getGPTData();
    this.filteredOptions$ = of(this.attributes);
    this.handleSubscribeAudio();
    this.handleSubscribeTTS();

    for (let [key, value] of Object.entries(this.data)) {
      this.data[key] =
        this.cell.getAttribute(key) != "undefined"
          ? this.cell.getAttribute(key)
          : "";
    }
    this.handleSetTypeTTS();
    this.handleShowButtonAudio();
    if (
      this.data.audioId ||
      this.data.audioNotMatchId ||
      this.data.audioNoInputId
    ) {
      this.audioClient.get().subscribe((audioVm) => {
        this.audioPlayer.listAudioInAction = audioVm.audios;

        this.audioNotMatchPlayer.listAudioInAction = audioVm.audios;
        this.audioNoInputPlayer.listAudioInAction = audioVm.audios;
        this.audioPlayer.playSelectedAudio(this.data.audioId);

        this.audioNotMatchPlayer.playSelectedAudio(this.data.audioNotMatchId);
        this.audioNoInputPlayer.playSelectedAudio(this.data.audioNoInputId);
      });
    }
    if (this.data.option) {
      this.handleAudio(Number(this.data.option));
    }
    if (this.data.enableCheckpoint) {
      this.data.enableCheckpoint =
        this.data.enableCheckpoint.toString() === "true";
    } else {
      this.data.enableCheckpoint = false;
    }
    if (this.data.customTrain) {
      this.data.customTrain = this.data.customTrain.toString() === "true";
    } else {
      this.data.customTrain = false;
    }

    if (!this.data.noInputTimeout || this.data.noInputTimeout?.trim() == "") {
      this.data.noInputTimeout = "5";
    }
    if (!this.data.minLength || this.data.minLength?.trim() == "") {
      this.data.minLength = "1";
    }
    if (!this.data.maxLength && this.data.maxLength?.trim() != "") {
      this.data.maxLength = "20";
    }
    if (this.data.log) {
      this.data.log = this.data.log.toString() === "true";
    } else {
      this.data.log = false;
    }
    if (this.data.idGrammar) {
      this.data.idGrammar =
        Number(this.data.idGrammar) == 0 || isNaN(this.data.idGrammar)
          ? null
          : Number(this.data.idGrammar);
    } else {
      this.data.idGrammar = null;
    }

    if (!this.data.idDataset) {
      this.data.idDataset = null;
    }

    if (!this.data.keepContext) {
      this.data.keepContext = false;
    }

    if (this.data.intentQA) {
      let intentQA = this.data.intentQA.split(",").filter(Boolean);
      if (intentQA.length > 0) {
        intentQA.forEach((element) => {
          let obj = { display: element, value: element };
          this.intentSelected.push(obj);
        });
      }
    }
    if (this.data.grammarArray) {
      this.grammarArray = JSON.parse(this.data.grammarArray);
    }
    if (this.data.text) {
      this.textToSpeech.selectCurrentTTS(this.data.text);
    }
    if (this.data.textChatBot) {
      this.textToSpeech.selectCurrentTTSBot(this.data.textChatBot);
    }
    if (this.data.textNotMatch) {
      this.textToSpeechNotMatch.selectCurrentTTS(this.data.textNotMatch);
    } else {
      this.textToSpeechNotMatch.checkNull = true;
    }
    if (this.data.textNoInput) {
      this.textToSpeechNoInput.selectCurrentTTS(this.data.textNoInput);
    } else {
      this.textToSpeechNoInput.checkNull = true;
    }
    this.textToSpeech.isChatBot = true;
    this.getCustomGrammars();

    this.windowRef.onClose.subscribe(() => this.submit());
  }
  allowLog($event) {
    this.data.log = $event;
  }
  allowKeepContext($event) {
    this.data.keepContext = $event;
  }
  handleAudio(value: number) {
    this.data.option = value;
  }
  handleDataIntentQA() {
    let intentQA = [];
    if (this.intentSelected.length > 0) {
      this.intentSelected.forEach((element) => {
        intentQA.push(element.display);
      });
    }
    this.data.intentQA = intentQA.toString();
  }
  submit() {
    if (!this.cancelAction && !this.isLoading) {
      this.handleDataIntentQA();
      const data = this.listDataGPT.find(
        (item) => item.id == this.data.idDataset
      );
      this.data.directory = data?.extenData || "";
      this.data.datasetName = data?.name || "";
      this.data.type = data?.type || "";
      this.data.idDataset = data?.id || "";
      if (
        this.data.timeout == "" ||
        !this.data.timeout ||
        parseFloat(this.data.timeout) < 0 ||
        !Number.isInteger(parseFloat(this.data.timeout))
      ) {
        this.data.timeout = "10";
      }
      if (
        this.data.repeat == "" ||
        !this.data.repeat ||
        parseFloat(this.data.repeat) < 0 ||
        !Number.isInteger(parseFloat(this.data.repeat))
      ) {
        this.data.repeat = "2";
      }
      if (
        this.data.minLength == "" ||
        !this.data.minLength ||
        parseFloat(this.data.minLength) < 0 ||
        !Number.isInteger(parseFloat(this.data.minLength))
      ) {
        this.data.minLength = "1";
      }
      if (
        this.data.noInputTimeout == "" ||
        !this.data.noInputTimeout ||
        parseFloat(this.data.noInputTimeout) < 0 ||
        !Number.isInteger(parseFloat(this.data.noInputTimeout))
      ) {
        this.data.noInputTimeout = "5";
      }
      if (
        parseFloat(this.data.maxLength) < 0 ||
        !Number.isInteger(parseFloat(this.data.maxLength))
      ) {
        this.data.maxLength = "";
      }
      this.data.grammarArray = JSON.stringify(this.grammarArray);
      for (let [key, value] of Object.entries(this.data)) {
        if (typeof this.data[key] == "string") {
          value = value?.trim();
        }
        this.cell.setAttribute(key, value || "");
      }

      const chatgptCell = this.graph.getModel().getCell(this.cell.getId());
      var fieldsRequired = [];
      if (this.data.customTrain) {
        fieldsRequired = [this.data.idDataset];
      }
      const check = this.graphService.checkIsRequired(
        this.graph,
        fieldsRequired,
        chatgptCell
      );
      this.cell.setAttribute("checkFields", check?.toString());
    }
  }
  clear() {
    this.searchText = "";
    this.filterData(null);
  }
  filterData(event) {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      let val = "";
      if (event != null) {
        val = event.target.value.toLowerCase();
      }
      this.grammarsData = this.grammarDataFilter.filter((x) =>
        x.name.toLowerCase().includes(val)
      );
    }, 200);
  }
  addToObject(item) {
    let dataExist = this.grammarArray.find((grammar) => {
      return grammar.id == item.id;
    });
    if (!dataExist) {
      let dataSubmit = {
        id: item.id,
        name: item.name,
        type: item.type,
      };
      this.grammarArray.push(dataSubmit);
    }
  }
  closeModal() {
    this.icon = "plus-outline";
    this.popover?.hide();
  }
  getCustomGrammars() {
    this.isLoading = true;
    this.grammarsData = [];
    this.grammarDataFilter = [];
    this.grammarClient.getByType("Custom").subscribe((result) => {
      if (result != null) {
        result.grammarDtos.forEach((value) => {
          this.grammarsData = [
            ...this.grammarsData,
            {
              id: value.id,
              name: value.name,
              type: "Custom",
              status: value.status,
            },
          ];
          this.grammarDataFilter = this.grammarsData;
        });

        if (this.isLoadingGrammar == true)
          if (this.data.idGrammar) {
            this.grammarArray.push({
              id: this.data.idGrammar,
              name: this.data.grammarName,
              type: "Custom",
            });
            this.data.idGrammar = 0;
            this.data.grammarName = "";
            this.isLoadingGrammar = false;
          } else {
            if (this.grammarArray.length > 0) {
              for (let index = 0; index < this.grammarArray.length; index++) {
                let checkExist = this.grammarsData.find(
                  (x) => this.grammarArray[index].id == x.id
                );
                if (checkExist) {
                  this.grammarArray[index].name = checkExist.name;
                } else {
                  this.grammarArray.splice(index, 1);
                  index = index - 1;
                }
              }
              this.isLoadingGrammar = false;
            } else {
              this.isLoadingGrammar = false;
            }
          }
      }
      this.isLoading = false;
    });
  }

  addChatGPTDataset() {
    this.dialogService
      .open(UpdateDatasetComponent, {
        autoFocus: false,
        context: { isAction: true, title: "Create Dataset" },
      })
      .onClose.subscribe((rs) => {
        if (rs?.rs == true) {
          this.addNewDataGPTToList(rs.name);
        }
      });
  }
  addNewDataGPTToList(name) {
    this.chatGPTClient.getAll().subscribe((rs) => {
      this.listDataGPT = [...rs.gptDataList];
      this.data.datasetName = name;
      this.data.idDataset = this.listDataGPT?.find((x) => x.name == name).id;
    });
  }
  updateCustomGrammar(e: any, grammar: any) {
    e.stopPropagation();

    this.grammarClient.get(grammar.id).subscribe((rs) => {
      if (rs) {
        const updateGrammar = this.dialogService.open(CreateGrammarComponent, {
          autoFocus: false,
          context: {
            title: `Grammar`,
            isUpdate: true,
            updateGrammar: rs,
            engine: rs.engine,
            engineType: rs.engineType,
            typeGrammar: "Custom",
            isGPTAction: true,
          },
        });
        updateGrammar.componentRef.instance.onAdd.subscribe((rs) => {
          if (rs) {
            updateGrammar.close();
            this.getCustomGrammars();
            this.updateDataObject(grammar, rs, false);
          }
        });
      }
    });
  }

  removeDigitGrammar(e: any, grammar: any) {
    e.stopPropagation();
    this.dialogService
      .open(ConfirmDialogComponent, {
        autoFocus: false,
        context: {
          question: "Grammar '" + grammar.name + "' will be deleted. Sure?",
          textYes: "Delete",
          textNo: "Cancel",
          statusYes: "danger",
          statusNo: "basic",
        },
      })
      .onClose.subscribe((isConfirm) => {
        if (isConfirm) {
          this.isLoading = true;
          this.grammarClient.delete(grammar.id).subscribe((rs) => {
            this.showToast(rs);
            if (rs) {
              this.isLoading = false;
              this.getCustomGrammars();
              this.updateDataObject(grammar, null, true);
            }
          });
        }
      });
  }
  updateDataObject(oldData, newData, idDelete = false) {
    let updateData = this.grammarArray.findIndex((item) => {
      return item.id == oldData.id;
    });
    if (updateData >= 0) {
      if (idDelete) {
        this.grammarArray.splice(updateData, 1);
      } else {
        Object.keys(this.grammarArray[updateData]).forEach((key) => {
          if (newData.hasOwnProperty(key)) {
            this.grammarArray[updateData][key] = newData[key];
          }
        });
      }
    }
  }
  showToast(result) {
    if (result) {
      this.toastrService.show("Delete Grammar successfully", `Notification`, {
        position: NbGlobalPhysicalPosition.BOTTOM_LEFT,
        status: "success",
      });
    } else {
      this.toastrService.show("Delete Grammar unsuccessfully", `Notification`, {
        position: NbGlobalPhysicalPosition.BOTTOM_LEFT,
        status: "danger",
      });
    }
  }
  notify(result) {
    if (result) {
      this.toastrService.show("Train successfully", `Notification`, {
        position: NbGlobalPhysicalPosition.BOTTOM_LEFT,
        status: "success",
      });
    } else {
      this.toastrService.show("Train unsuccessfully", `Notification`, {
        position: NbGlobalPhysicalPosition.BOTTOM_LEFT,
        status: "danger",
      });
    }
  }

  addGrammar() {
    const createGrammar = this.dialogService.open(CreateGrammarComponent, {
      autoFocus: false,
      context: {
        title: `Grammar`,
        typeGrammar: "Custom",
        engine: false,
        isGPTAction: true,
      },
    });
    createGrammar.componentRef.instance.onAdd.subscribe((rs) => {
      if (rs) {
        createGrammar.close();
        this.getCustomGrammars();
      }
    });
  }
  handleMaxValueNumberOutput(data) {
    const inputValue = data?.target?.value?.trim();
    if (inputValue && !isNaN(inputValue)) {
      if (Number(inputValue) > 4000) {
        this.data.numberOutput = 4000;
      }
    } else {
      this.data.numberOutput = 0;
    }
  }

  handleMaxValueTemperature(data) {
    const inputValue = data?.target?.value?.trim();
    if (inputValue && !isNaN(inputValue)) {
      if (Number(inputValue) > 2) {
        this.data.temperature = 2;
      }
    } else {
      this.data.temperature = 0;
    }
  }

  addAttribute() {
    this.dialogService
      .open(DialogAttributeComponent, { autoFocus: false })
      .onClose.subscribe((rs) => {
        if (rs) {
          this.data.attribute = rs;
        }
      });
  }
  addAttributeUser() {
    this.dialogService
      .open(DialogAttributeComponent, { autoFocus: false })
      .onClose.subscribe((rs) => {
        if (rs) {
          this.data.userResponse = rs;
        }
      });
  }
  addAttributeSentiment() {
    this.dialogService
      .open(DialogAttributeComponent, { autoFocus: false })
      .onClose.subscribe((rs) => {
        if (rs) {
          this.data.sentiment = rs;
        }
      });
  }
  showLog() {
    if (this.icon == "plus-outline") {
      if (this.grammarsData.length <= 0) {
        // this.getGrammarData();
      }
      this.icon = "close-outline";
      this.popover?.show();
    } else {
      this.icon = "plus-outline";
      this.popover?.hide();
    }
  }
  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.grammarArray, event.previousIndex, event.currentIndex);
  }
  updateCurrentData(e: any, id: any) {
    e.stopPropagation();
    let dataUpdate = this.listDataGPT.find((x) => x.id == id);
    if (dataUpdate) {
      this.updateData(e, dataUpdate);
    }
  }
  updateCurrentDataGrammar(e: any, id: any) {
    e.stopPropagation();
    let dataUpdate = this.grammarsData.find((x) => x.id == id);
    if (dataUpdate) {
      this.updateCustomGrammar(e, dataUpdate);
    }
  }

  updateData(e: any, dataset: any) {
    e.stopPropagation();
    this.chatGPTClient.get(dataset.id).subscribe((rs) => {
      if (rs) {
        this.dialogService
          .open(UpdateDatasetComponent, {
            autoFocus: false,
            context: {
              data: rs,
              isAction: true,
              title: "Update Dataset",
            },
          })
          .onClose.subscribe((rs) => {
            this.getGPTData();
          });
      }
    });
  }

  removeData(e: any, dataset: any) {
    e.stopPropagation();
    this.dialogService
      .open(ConfirmDialogComponent, {
        autoFocus: false,
        context: {
          question: "QA dataset '" + dataset.name + "' will be deleted. Sure?",
          textYes: "Delete",
          textNo: "Cancel",
          statusYes: "danger",
          statusNo: "basic",
        },
      })
      .onClose.subscribe((isConfirm) => {
        if (isConfirm) {
          this.isLoading = true;
          this.chatGPTClient.delete(Number(dataset.id)).subscribe((rs) => {
            this.showToast(rs);
            if (rs == true) {
              this.isLoading = false;
              this.getGPTData();
            }
          });
        }
      });
  }

  showModify() {
    let createAudio = this.dialogService.open(SimpleAudioPlayerModalComponent, {
      autoFocus: false,
      context: {
        showAddButton: true,
        audioListId: this.data.audioId.split(","),
      },
    });
    createAudio.componentRef.instance.onAdd.subscribe((rs) => {
      createAudio.close();
      this.eventsSubject.next(rs);
    });
  }

  showModifyNotMatch() {
    let createAudio = this.dialogService.open(SimpleAudioPlayerModalComponent, {
      autoFocus: false,
      context: {
        showAddButton: true,
        audioListId: this.data.audioNotMatchId.split(","),
      },
    });
    createAudio.componentRef.instance.onAdd.subscribe((rs) => {
      createAudio.close();
      this.eventsSubjectNotMatch.next(rs);
    });
  }
  showModifyNoInput() {
    let createAudio = this.dialogService.open(SimpleAudioPlayerModalComponent, {
      autoFocus: false,
      context: {
        showAddButton: true,
        audioListId: this.data.audioNoInputId.split(","),
      },
    });
    createAudio.componentRef.instance.onAdd.subscribe((rs) => {
      createAudio.close();
      this.eventsSubjectNoInput.next(rs);
    });
  }

  handleShowButtonAudio() {
    this.audioPlayer.showAddButton = true;
    this.audioPlayer.isCallFlowAction = true;
    this.audioNotMatchPlayer.showAddButton = true;
    this.audioNotMatchPlayer.isCallFlowAction = true;
    this.audioNoInputPlayer.showAddButton = true;
    this.audioNoInputPlayer.isCallFlowAction = true;
  }
  handleSetTypeTTS() {
    this.textToSpeechNoInput.isNoInput = true;
    this.textToSpeechNoInput.type = "no-input";
    this.textToSpeechNotMatch.isNotMatch = true;
    this.textToSpeechNotMatch.type = "not-match";
  }

  handleSubscribeAudio() {
    this.selectedSubscription = this.audioPlayer.selectedAudio.subscribe(
      (audio) => {
        let audioPath = [];
        let audioId = [];
        if (audio) {
          audio.forEach((obj) => {
            audioPath.push(obj.pathAsterisk);
            audioId.push(obj.id);
          });
        }
        this.data.audioId = audio !== undefined ? audioId.toString() : "";
        this.data.audioPath = audio !== undefined ? audioPath.toString() : "";
      }
    );
    this.selectedSubscription =
      this.audioNotMatchPlayer.selectedAudio.subscribe((audio) => {
        let audioPath = [];
        let audioId = [];
        if (audio) {
          audio.forEach((obj) => {
            audioPath.push(obj.pathAsterisk);
            audioId.push(obj.id);
          });
        }
        this.data.audioNotMatchId =
          audio !== undefined ? audioId.toString() : "";
        this.data.audioNotMatchPath =
          audio !== undefined ? audioPath.toString() : "";
      });
    this.selectedSubscription = this.audioNoInputPlayer.selectedAudio.subscribe(
      (audio) => {
        let audioPath = [];
        let audioId = [];
        if (audio) {
          audio.forEach((obj) => {
            audioPath.push(obj.pathAsterisk);
            audioId.push(obj.id);
          });
        }
        this.data.audioNoInputId =
          audio !== undefined ? audioId.toString() : "";
        this.data.audioNoInputPath =
          audio !== undefined ? audioPath.toString() : "";
      }
    );
  }

  handleSubscribeTTS() {
    this.selectedSubscription = this.textToSpeech.selectedTTS.subscribe(
      (tts) => {
        this.data.text = JSON.stringify(tts);
      }
    );
    this.selectedSubscription = this.textToSpeech.selectedTTSBot.subscribe(
      (tts) => {
        this.data.textChatBot = JSON.stringify(tts);
      }
    );
    this.selectedSubscription = this.textToSpeechNotMatch.selectedTTS.subscribe(
      (tts) => {
        this.data.textNotMatch = JSON.stringify(tts);
      }
    );
    this.selectedSubscription = this.textToSpeechNoInput.selectedTTS.subscribe(
      (tts) => {
        this.data.textNoInput = JSON.stringify(tts);
      }
    );
  }
  private filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.attributes.filter((optionValue) =>
      optionValue.toLowerCase().includes(filterValue)
    );
  }

  getFilteredOptions(value: string): Observable<string[]> {
    return of(value).pipe(map((filterString) => this.filter(filterString)));
  }

  onChange() {
    this.filteredOptions$ = this.getFilteredOptions(
      this.input.nativeElement.value
    );
  }

  setTemperature(duration: number) {
    this.data.temperature = duration;
  }

  getTemperature(): number {
    return (this.data.temperature * 100) / 2;
  }

  setNumberOutput(duration: number) {
    this.data.numberOutput = duration;
  }

  getNumberOutput(): number {
    return (this.data.numberOutput * 100) / 4000;
  }

  onSelectionChange($event) {
    this.filteredOptions$ = this.getFilteredOptions($event);
  }
  getUserResponse(event) {
    this.data.userResponse = event;
  }
  getAttribute(event) {
    this.data.attribute = event;
  }
  getSentiment(event) {
    this.data.sentiment = event;
  }
}
