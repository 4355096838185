import { TraceLogComponent } from "./../actions/action/trace-log/trace-log.component";
import * as callFlowAction from "./callflows-action.json";
import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  ViewChild,
  TemplateRef,
  AfterViewInit,
  Output,
  EventEmitter,
} from "@angular/core";
import {
  NbDialogService,
  NbWindowState,
  NbToastrService,
  NbGlobalPhysicalPosition,
  NbWindowControlButtonsConfig,
  NbIconLibraries,
  NbPopoverDirective,
} from "@nebular/theme";
import { NbWindowService } from "@nebular/theme";

import {
  QueueDto,
  CallFlowDto,
  NumberVm,
  TrunkVm,
  UserActionClient,
  CreateUserActionCommand,
  ConfigurationClient,
} from "../../System-api";
import {
  MxGraph,
  MxUndoManager,
  MxHierarchicalLayout,
  MxConnectionConstraint,
  MxPoint,
  MXUTILITIES,
  MxCell,
  MxCodec,
  MxKeyHandler,
  MxRubberband,
  MxEditor,
  MxOutline,
  MxGeometry,
  MxGraphView,
  mxCellHighlight,
} from "../../@core/model/mxgraph";
import { Subscription } from "rxjs";
import { GraphHandlerService } from "../../@core/utils/graph.service";
import { EventBusService } from "../../@core/utils/eventbus.service";
import { DataService } from "../../@core/utils/data.service";
import { EventData } from "../../shared/model/eventdata";
import { CheckAttributeComponent } from "../actions/action/check-attribute/check-attribute.component";
import { CheckTimeComponent } from "../actions/action/check-time/check-time.component";
import { GetInputComponent } from "../actions/action/get-input/get-input.component";
import { OutreachActionComponent } from "../actions/action/outreach-action/outreach-action.component";
import { GoToQueueComponent } from "../actions/action/go-to-queue/go-to-queue.component";
import { HttpRequestComponent } from "../actions/action/http-request/http-request.component";
import { PlayAudioComponent } from "../actions/action/playaudio/callflows-playaudio";
import { RedirectCallflowComponent } from "../actions/action/redirect-callflow/redirect-callflow.component";
import { SayNumberComponent } from "../actions/action/say-number/say-number.component";
import { SetAttributeComponent } from "../actions/action/set-attribute/set-attribute.component";
import { SetLanguageComponent } from "../actions/action/set-language/set-language.component";
import { TransferComponent } from "../actions/action/transfer/transfer.component";
import { VoiceMailComponent } from "../actions/action/voice-mail/voice-mail.component";
import { IvrAttributeComponent } from "../actions/other/ivr-attribute/ivr-attribute.component";
import { IncommingCallObject } from "../../@core/model/incomming-call-object";
import { ResponseDefineComponent } from "../actions/other/response-define/response-define.component";
import { ConditionDefineComponent } from "../actions/other/condition-define/condition-define.component";
import { CustomFunctionComponent } from "../actions/action/custom-function/custom-function.component";
import { FailureCaseDefinationComponent } from "../actions/other/failure-case-defination/failure-case-defination.component";
import { WaitActionComponent } from "../actions/action/wait-action/wait-action.component";
import { ConfirmDialogComponent } from "../../shared/confirm-dialog/confirm-dialog.component";
import { DataSetActionComponent } from "../actions/action/dataset-action/dataset-action.component";
import { ChatGPTComponent } from "../actions/action/chatgpt/chatgpt.component";
import { DialComponent } from "../actions/action/dial/dial.component";
import { DialCaseDefinationComponent } from "../actions/other/dial-case-defination/dial-case-defination.component";
import { DialCaseContextComponent } from "../actions/other/dial-case-context/dial-case-context.component";
import { CustomEventComponent } from "../actions/action/custom-event/custom-event.component";
import { TranslateActionComponent } from "./../actions/action/translate-action/translate-action.component";
import { AnswerDetectComponent } from "../actions/action/answer-detect/answer-detect.component";
import { AnswerDetectStatusComponent } from "../actions/other/answer-detect-status/answer-detect-status.component";
import { FormsComponent } from "../actions/action/forms/formscomponent";
import { IntentRecognitionComponent } from "../actions/action/intent-recognition/intent-recognition.component";
import { SendMailActionComponent } from "../actions/action/send-mail/send-mail.component";
import { KeyboardShortcutsComponent } from "../../shared/keyboard-shortcuts/keyboard-shortcuts.component";
import { CMSActionComponent } from "../actions/action/cms-action/cms-action.component";
import { LLMComponent } from "../actions/action/llm/llm.component";
@Component({
  selector: "callflows-graph",
  templateUrl: "./callflows-graph.component.html",
  styleUrls: ["./callflows-graph.component.scss"],
})
export class CallFlowsGraphComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  @ViewChild("ActionHeaderTemplate") actionHeaderTemplate: TemplateRef<any>;
  @ViewChild("actionNameCallFlow") actionNameCallFlow: TemplateRef<any>;
  @ViewChild("attributeBox") attributeBox: TemplateRef<any>;
  @ViewChild("actionInput") actionInput: any;
  @ViewChild(NbPopoverDirective) popover: NbPopoverDirective;
  @Output() openRevision: EventEmitter<any> = new EventEmitter<any>();
  actionName: string = "";
  defaultActionName: string = "";
  currentActionName: string = "";
  isEditActionName: boolean = false;
  isShowActionName: boolean = false;
  isShowVariable: boolean = false;
  searchText: string = "";
  selectedItem: any;
  actionList: any;
  callFlowActionDes: any;
  queues: QueueDto[] = [];
  callflows: CallFlowDto[] = [];
  phoneNumbers: NumberVm[] = [];
  trunksNumber: TrunkVm[] = [];
  callSetting: any = { ...this.dataService.CallSetting };
  languageDefault;
  @Input() isReadOnly: any = false;
  @Input() isAllowDesign: any = false;
  graph: MxGraph;
  @Input() data: CallFlowDto;
  windowRef: any;
  windowSubscription: Subscription;
  undoMng: MxUndoManager;
  editor: MxEditor;
  currentAction: MxCell;
  currentHighlight: mxCellHighlight;
  firstChange: boolean = false;
  @ViewChild("ivrAttribute", {
    static: true,
  })
  ivrAttribute: IvrAttributeComponent;
  mouseCurrentX: any;
  mouseCurrentY: any;
  cell: any;
  errorActionName = false;
  connectEvent: boolean;
  maxZoomIn: number = 4;
  maxZoomOut: number = 0.6;
  showMap = false;
  showCallBtn: boolean = true;
  link = "";
  constructor(
    private dialogService: NbDialogService,
    private windowService: NbWindowService,
    private graphHandler: GraphHandlerService,
    private eventBusService: EventBusService,
    public dataService: DataService,
    private userActionClient: UserActionClient,
    private toastrService: NbToastrService,
    private nbIconLib: NbIconLibraries,
    public configurationClient: ConfigurationClient
  ) {
    this.customEvaIcon();
  }
  openChat() {
    this.dataService.openChatContent = !this.dataService.openChatContent;
    this.focusInput();
  }
  ngAfterViewInit(): void {
    this.popover.show();
    this.popover.hide();
  }
  clickAction(event) {
    if (this.currentAction) {
      this.currentHighlight.hide();
    }

    this.selectedItem = event;
    const scale = this.graph.getView().getScale();
    const newX = 600 / scale;
    const newY = 300 / scale;
    const dx = newX - this.selectedItem.geometry.x;
    const dy = newY - this.selectedItem.geometry.y;
    this.graph.getView().setTranslate(dx, dy);

    var h1 = new mxCellHighlight(this.graph, "#00ff00", 3);
    h1.highlight(this.graph.view.getState(event));
    this.currentHighlight = h1;
    this.currentAction = event;
    // this.graph.getSelectionModel().setCell(event);
  }
  viewMore(event) {
    if (event.link != "") {
      window.open(event.link, event.name);
    }
  }
  filterAction() {
    let arrayAction = [];
    this.graph.model.root.children[0].children.map((item) => {
      if (item.value != undefined && item.value.attributes) {
        if (
          item.value.attributes["name"].nodeValue
            ?.toLowerCase()
            .includes(this.searchText.toLowerCase())
        ) {
          arrayAction.push(item);
        }
      }
    });
    this.actionList = arrayAction;
  }
  getActionList() {
    let arrayAction = [];
    this.graph.model.root.children[0].children.map((item) => {
      if (item.value != undefined && item.value.attributes) {
        arrayAction.push(item);
      }
    });
    this.actionList = arrayAction;
  }
  focusInput() {
    if (this.dataService.openChatContent == true) {
      let primasBot = document.getElementById(
        "primas-bot-portal-design"
      ) as any;
      if (primasBot) {
        primasBot.contentWindow.postMessage(
          {
            type: "FOCUS_INPUT",
            value: {},
          },
          "*"
        );
      }
    }
  }
  getSupportFlow() {
    this.configurationClient.getSupportFlow().subscribe((rs) => {
      if (rs != "") {
        this.link = rs;
        setTimeout(() => {
          this.dataService.openChatContent = false;
          this.dataService.openChatButton = true;
        }, 2000);
      }
    });
  }
  ngOnInit() {
    this.dataService.openChatButton = false;
    this.dataService.openChatContent = false;
    this.getSetting();
    this.handleGraph();
    this.eventBusService.emit(new EventData("getMxGraph", this.graph));
    this.eventBusService.on("DeletedActionEvent", (isDeleted: boolean) => {
      this.destroyPopupMenu();
    });
    this.getActionList();
    const bounds = this.graph.getGraphBounds();
    if (bounds.x < 0 || bounds.y < 0) {
      this.graph.view.setTranslate(520, 90);
    }
    this.getSupportFlow();
  }

  getSetting() {
    this.callSetting = { ...this.dataService.CallSetting };
    this.languageDefault = JSON.parse(this.callSetting.defaultLanguage);
  }

  getGraph() {
    return this.graph;
  }

  ngOnDestroy() {
    this.dataService.CallFlow = null;
    this.graphHandler.firstChange = false;
    this.graph.popupMenuHandler.hideMenu();
  }

  callControlEvent(isOpen) {
    if (isOpen) {
      document.getElementById("call-popup").style.display = "block";
      this.showCallBtn = false;
    } else {
      document.getElementById("call-popup").style.display = "none";
      this.showCallBtn = true;
    }
  }

  handleGraph() {
    var container = document.getElementById("graphContainer");
    var outline = document.getElementById("outline");
    MXUTILITIES.mxEvent.disableContextMenu(container);
    this.graph = new MxGraph(container);
    try {
      this.graph.setCellsMovable(true);
      this.graph.setCellsResizable(true);
      this.graph.setAutoSizeCells(false);
      new MxRubberband(this.graph);
      this.graph.setPanning(true);
      this.graph.setEdgeLabelsMovable(false);
      this.graph.setAllowDanglingEdges(false);
      this.graph.setConnectable(true);
      this.graph.setCellsSelectable(true);
      this.graph.setTooltips(true);
      this.graph.setCellsEditable(true);
      this.graph.centerZoom = false;
      this.graph.graphHandler.guidesEnabled = true;
      this.graph.allowAutoPanning = true;
      new MxOutline(this.graph, outline);
      this.graph.isCellEditable = function (cell) {
        if (cell?.style.includes("shape=note")) {
          return true;
        }
        return false;
      };

      this.graph.isCellResizable = function (cell) {
        if (cell?.style.includes("shape=note")) {
          return true;
        }
        return false;
      };

      // Removes the folding icon and disables any folding
      this.graph.isCellFoldable = function (cell) {
        return false;
      };

      // Removes the folding icon and disables any folding
      this.graph.isCellSelectable = function (cell) {
        if (cell?.getAttribute("applicationId") == "PortRef") {
          return false;
        }
        return true;
      };
      // set layout
      new MxHierarchicalLayout(this.graph).execute(
        this.graph.getDefaultParent()
      );
      // set port to connect
      this.graph.getAllConnectionConstraints = function (terminal) {
        if (
          terminal != null &&
          this.model.isVertex(terminal.cell) &&
          !terminal.cell?.style.includes("shape=note")
        ) {
          var arrayTerminal = [];

          if (
            terminal.cell.getAttribute("applicationId") == "IncommingCall" ||
            terminal.cell.getAttribute("applicationId") == "HangUp" ||
            terminal.cell.getAttribute("applicationId") == "ErrorHandler" ||
            terminal.cell.getAttribute("applicationId") == "CustomEvent"
          ) {
            return [new MxConnectionConstraint(new MxPoint(0.5, 1), true)];
          }

          if (
            terminal.cell.getAttribute("transition") == undefined &&
            terminal.cell.getAttribute("applicationId").split("-")[0] != "Dial"
          ) {
            arrayTerminal.push(
              new MxConnectionConstraint(new MxPoint(0.5, 1), true)
            );
          }

          arrayTerminal.push(
            new MxConnectionConstraint(new MxPoint(1, 0.5), true),
            new MxConnectionConstraint(new MxPoint(0, 0.5), true),
            new MxConnectionConstraint(new MxPoint(0.5, 0), true)
          );

          return arrayTerminal;
        }

        return null;
      };

      //set tooltip with name attribute
      this.graph.getTooltipForCell = function (cell) {
        return cell.getAttribute("name");
      };

      // Set some stylesheet options for the visual appearance of vertices
      var style = this.graph.getStylesheet().getDefaultVertexStyle();

      style[MXUTILITIES.mxConstants.STYLE_SHAPE] = "label";
      style[MXUTILITIES.mxConstants.STYLE_VERTICAL_ALIGN] =
        MXUTILITIES.mxConstants.ALIGN_MIDDLE;
      style[MXUTILITIES.mxConstants.STYLE_ALIGN] =
        MXUTILITIES.mxConstants.ALIGN_LEFT;
      style[MXUTILITIES.mxConstants.STYLE_SPACING_LEFT] = 32;
      style[MXUTILITIES.mxConstants.STYLE_SPACING_TOP] = 5;
      style[MXUTILITIES.mxConstants.STYLE_STROKECOLOR] = "#FFFFFF";
      style[MXUTILITIES.mxConstants.STYLE_STROKEWIDTH] = "2";
      style[MXUTILITIES.mxConstants.STYLE_FILLCOLOR] = "#FFFFFF";
      style[MXUTILITIES.mxConstants.STYLE_FONTCOLOR] = "#354DE8";
      style[MXUTILITIES.mxConstants.STYLE_FONTFAMILY] = "Verdana";
      style[MXUTILITIES.mxConstants.STYLE_FONTSIZE] = "9";
      style[MXUTILITIES.mxConstants.STYLE_FONTSTYLE] = "1";
      style[MXUTILITIES.mxConstants.STYLE_SHADOW] = "0";
      style[MXUTILITIES.mxConstants.STYLE_ROUNDED] = "1";
      style[MXUTILITIES.mxConstants.STYLE_IMAGE] = "/assets/images/calling.png";
      style[MXUTILITIES.mxConstants.STYLE_IMAGE_WIDTH] = "25";
      style[MXUTILITIES.mxConstants.STYLE_IMAGE_HEIGHT] = "25";
      style[MXUTILITIES.mxConstants.STYLE_OVERFLOW] = "hidden";
      MXUTILITIES.mxConstants.VERTEX_SELECTION_COLOR = "#354DE8";
      MXUTILITIES.mxConstants.VERTEX_SELECTION_DASHED = false;
      MXUTILITIES.mxConstants.GUIDE_COLOR = "#0088cf";

      // Sets the default style for edges
      style = this.graph.getStylesheet().getDefaultEdgeStyle();
      style[MXUTILITIES.mxConstants.STYLE_ROUNDED] = true;
      style[MXUTILITIES.mxConstants.STYLE_STROKEWIDTH] = 1;
      style[MXUTILITIES.mxConstants.STYLE_EXIT_X] = 0.5; // center
      style[MXUTILITIES.mxConstants.STYLE_EXIT_Y] = 1.0; // bottom
      style[MXUTILITIES.mxConstants.STYLE_EXIT_PERIMETER] = 0; // disabled
      style[MXUTILITIES.mxConstants.STYLE_ENTRY_X] = 0.5; // center
      style[MXUTILITIES.mxConstants.STYLE_ENTRY_Y] = 0; // top
      style[MXUTILITIES.mxConstants.STYLE_ENTRY_PERIMETER] = 0; // disabled
      style[MXUTILITIES.mxConstants.STYLE_FONTSIZE] = "10";
      MXUTILITIES.mxConstants.EDGE_SELECTION_COLOR = "#354DE8";

      // Disable the following for straight lines
      style[MXUTILITIES.mxConstants.STYLE_EDGE] =
        MXUTILITIES.mxEdgeStyle.OrthConnector;

      //key press handler
      this.keyPressHandler();

      // Fix for wrong preferred size
      var oldGetPreferredSizeForCell = this.graph.getPreferredSizeForCell;
      this.graph.getPreferredSizeForCell = function (cell) {
        var result = oldGetPreferredSizeForCell.apply(this, arguments);

        if (result != null) {
          result.width = Math.max(120, result.width - 40);
        }

        return result;
      };

      this.graph.addListener(MXUTILITIES.mxEvent.CLICK, (sender, event) => {
        var pt = this.graph.getPointForEvent(event.getProperty("event"));

        this.mouseCurrentX = pt.x;
        this.mouseCurrentY = pt.y;
        if (this.windowRef) {
          this.windowRef.close();
          this.windowSubscription.unsubscribe();
        }

        this.graphHandler.closeMenuAction.emit(true);
        //To detect first change after mxgraph loaded flow design
        if (this.graphHandler.firstChange == false) {
          this.eventBusService.emit(
            new EventData("MxGraphChanges", this.graph)
          );
          this.graphHandler.firstChange = true;
        }

        var cell = event.getProperty("cell"); // cell may be null
        if (event?.properties?.event?.button == 2) {
          this.graph.popupMenuHandler.factoryMethod = (menu, cell, evt) => {
            if (!cell) {
              menu.addItem("Undo", null, () => {
                this.undoMng.undo();
              });
              menu.addItem("Redo", null, () => {
                this.undoMng.redo();
              });
              menu.addSeparator();
              menu.addItem("Paste", null, async () => {
                this.pasteCells();
              });

              menu.addItem("Copy callflow", null, async () => {
                var enc = new MxCodec(MXUTILITIES.mxUtils.createXmlDocument());
                var node = enc.encode(this.graph.getModel());
                var xml = MXUTILITIES.mxUtils.getXml(node);
                xml = xml.replace(new RegExp("<st ", "g"), "<MxPoint ");
                await navigator.clipboard.writeText(xml);
              });
              menu.addSeparator();
              menu.addItem("Copy image", null, () => {
                let svg = this.graphHandler.handleRevisionImage(this.graph);
                const bounds = this.graph.getGraphBounds();
                const width = bounds.x + bounds.width + 4;
                const height = bounds.y + bounds.height + 4;
                this.svgString2Image(svg, width, height, "png", (pngData) => {
                  const data = this.convertBase64ToBlob(pngData);
                  try {
                    navigator.clipboard.write([
                      new ClipboardItem({
                        "image/png": data,
                      }),
                    ]);
                  } catch (error) {
                    console.error(error);
                  }
                });
              });
              menu.addItem("Save as image", null, () => {
                let svg = this.graphHandler.handleRevisionImage(this.graph);
                const bounds = this.graph.getGraphBounds();
                const width = bounds.x + bounds.width + 4;
                const height = bounds.y + bounds.height + 4;
                this.svgString2Image(svg, width, height, "jpg", (jpgData) => {
                  let file = this.dataURLtoFile(jpgData);
                  this.downloadData(
                    `${this.dataService.CallFlow.name}.jpg`,
                    file
                  );
                });
              });
              menu.addSeparator();
              menu.addItem("Select All", null, async () => {
                this.graph.selectAll();
                let cells = new Array();
                cells = this.graph.getSelectionCells();
                cells.shift();
                cells.shift();
                cells.shift();
                this.graph.setSelectionCells(cells);
              });
              menu.addItem("Add Note", null, async () => {
                this.graphHandler.addNote(
                  this.graph,
                  this.mouseCurrentX,
                  this.mouseCurrentY
                );
              });
            } else if (
              cell?.vertex &&
              cell?.id != "treeRoot" &&
              cell?.id != "eventRoot" &&
              cell?.id != "errorRoot" &&
              cell.getValue().nodeName != "PortRef" &&
              !cell.style.includes("shape=note")
            ) {
              var enable = false;
              var cells = new Array();
              cells = this.graph.getSelectionCells();
              if (cells.length > 0) {
                if (
                  cells[0].getId() != "treeRoot" &&
                  cells[0].getId() != "eventRoot" &&
                  cells[0].getId() != "errorRoot" &&
                  cell.getValue().nodeName != "PortRef" &&
                  !cell.style.includes("shape=note")
                ) {
                  enable = true;
                }
              }
              menu.addItem("Delete", null, () => {
                if (enable == true) {
                  this.graph.removeCells(cells);
                }
              });
              menu.addSeparator();
              menu.addItem("Cut", null, async () => {
                this.copyCells(cells, enable);
                this.graph.removeCells(cells);
              });
              menu.addItem("Copy", null, async () => {
                this.copyCells(cells, enable);
              });
              menu.addItem("Duplicate", null, async () => {
                if ((enable = true)) {
                  MXUTILITIES.mxClipboard.copy(this.graph, cells);
                  MXUTILITIES.mxClipboard.paste(this.graph);
                }
                this.copyCells(cells, enable);
              });
              menu.addItem("Edit", null, () => {
                this.openWindow(cell, NbWindowState.MAXIMIZED);
              });
              menu.addSeparator();
              menu.addItem("Add to User Action", null, () => {
                this.saveAction(cell);
              });
            } else {
              return;
            }
          };
        }
        if (event?.properties?.event?.button == 0) {
          if (cell != null && !this.connectEvent) {
            // var warningImage = new MxImage("/assets/images/warning.gif", 20, 20);
            // this.graph.setCellWarning(cell, "This is a warning message.",warningImage);
            this.openWindow(cell, NbWindowState.MAXIMIZED);
          }
        }

        this.connectEvent = false;
        // Disables any default behaviour for the double click
        event.consume();
      });

      let mouseDownTime;
      let isDragging = false;
      document.addEventListener(
        "mousedown",
        function (event) {
          this.graph.popupMenuHandler.hideMenu();
          if (this.currentAction) {
            this.currentHighlight.hide();
          }
          mouseDownTime = new Date().getTime();
          isDragging = true;
          // Start a timer to check if the user is clicking or dragging
          setTimeout(() => {
            if (isDragging) {
            } else {
              this.graph.clearSelection();
            }
          }, 200);
        }.bind(this)
      );
      document.addEventListener(
        "mouseup",
        function (event) {
          isDragging = false;
          const mouseUpTime = new Date().getTime();

          // Check if the mouse was released within a certain time after it was clicked
          if (mouseUpTime - mouseDownTime < 200) {
            this.graph.clearSelection();
          }
        }.bind(this)
      );

      this.graph.addListener(
        MXUTILITIES.mxEvent.CELLS_RESIZED,
        (sender, event) => {
          if (event.getProperty("cells").length > 0) {
            var cell = event.getProperty("cells")[0];
            if (!cell?.style.includes("shape=note")) {
              var geo = cell.getGeometry();
              geo.height = "50";

              if (event.properties.bounds[0].width < 120) {
                geo.width = "120";
              }

              if (event.properties.bounds[0].width > 250) {
                geo.width = "250";
              }
              cell.setGeometry(geo);
            }
          }
        }
      );

      this.graph.addListener(
        MXUTILITIES.mxEvent.DOUBLE_CLICK,
        (sender, evt) => {
          var cell = evt.getProperty("cell");
          if (cell != null) {
            if (cell.edge == 1) {
              var source = sender.getModel().getTerminal(cell, true);
              var target = sender.getModel().getTerminal(cell, false);
              if (
                source.parent.getAttribute("applicationId").split("-")[0] ==
                  "CheckAttribute" ||
                source.parent.getAttribute("applicationId").split("-")[0] ==
                  "IntentRecognition" ||
                source.parent.getAttribute("applicationId").split("-")[0] ==
                  "LLM" ||
                source.parent.getAttribute("applicationId").split("-")[0] ==
                  "GetInput" ||
                source.parent.getAttribute("applicationId").split("-")[0] ==
                  "ChatGPT" ||
                source.getAttribute("applicationId") == "CustomEvent"
              ) {
                let value = cell.getValue();
                if (
                  source.getAttribute("case") == "Success" ||
                  source.getAttribute("applicationId") == "CustomEvent"
                ) {
                  this.dialogService
                    .open(ConditionDefineComponent, {
                      autoFocus: false,
                      context: {
                        type: source.parent
                          .getAttribute("applicationId")
                          .split("-")[0],
                        isUpdate: true,
                        updateData: value,
                      },
                    })
                    .onClose.subscribe((data) => {
                      cell.setValue(data.method + ":" + data.data);
                      this.graph.refresh(cell);
                    });
                } else {
                  if (
                    source.parent.getAttribute("applicationId").split("-")[0] ==
                      "GetInput" ||
                    source.parent.getAttribute("applicationId").split("-")[0] ==
                      "ChatGPT"
                  ) {
                    if (source.getEdgeCount() == 3) {
                      return;
                    } else {
                      this.dialogService
                        .open(FailureCaseDefinationComponent, {
                          autoFocus: false,
                          context: {
                            item: source.edges,
                            isUpdate: true,
                            updateData: value,
                          },
                        })
                        .onClose.subscribe((data) => {
                          if (data.method == "") {
                            sender.removeCells([cell]);
                          } else {
                            cell.setValue(data.method);
                            this.graph.refresh(cell);
                          }
                        });
                    }
                  }
                }
              } else if (
                source.parent.getAttribute("applicationId").split("-")[0] ==
                "AnswerDetect"
              ) {
                let value = cell.getValue();
                if (source.getAttribute("case") == "Success") {
                  this.dialogService
                    .open(AnswerDetectStatusComponent, {
                      autoFocus: false,
                      context: {
                        item: source.edges,
                        isUpdate: true,
                        updateData: value,
                      },
                    })
                    .onClose.subscribe((data) => {
                      cell.setValue(data.status);
                      this.graph.refresh(cell);
                    });
                }
              } else if (
                source.parent.getAttribute("applicationId").split("-")[0] ==
                "Dial"
              ) {
                let value = cell.getValue();
                if (source.getAttribute("case") == "Option") {
                  this.dialogService
                    .open(DialCaseContextComponent, {
                      autoFocus: false,
                      context: {
                        item: source.edges,
                        isUpdate: true,
                        updateData: value,
                      },
                    })
                    .onClose.subscribe((data) => {
                      if (data.method == "") {
                        sender.removeCells([cell]);
                      } else {
                        cell.setValue(data.method);
                        this.graph.refresh(cell);
                      }
                    });
                } else {
                  if (source.getEdgeCount() == 5) {
                    return;
                  } else {
                    this.dialogService
                      .open(DialCaseDefinationComponent, {
                        autoFocus: false,
                        context: {
                          item: source.edges,
                          isUpdate: true,
                          updateData: value,
                        },
                      })
                      .onClose.subscribe((data) => {
                        if (data.method == "") {
                          sender.removeCells([cell]);
                        } else {
                          cell.setValue(data.method);
                          this.graph.refresh(cell);
                        }
                      });
                  }
                }
              }
            }
          }
        }
      );

      //Event handler when edge is reconnected
      this.graph.addListener(
        MXUTILITIES.mxEvent.CONNECT_CELL,
        (sender, evt) => {
          var graph = sender;
          var edge = evt.properties.edge;
          var target = graph.getModel().getTerminal(edge, false);
          if (
            target.getAttribute("applicationId") == "HangUp" ||
            target.getAttribute("applicationId") == "IncommingCall" ||
            target.getAttribute("applicationId") == "ErrorHandler" ||
            target.getAttribute("applicationId") == "CustomEvent"
          ) {
            graph.getModel().setTerminal(edge, evt.properties.previous, false);
          }
        }
      );

      //Event handler when edge connect with vertex
      this.graph.connectionHandler.addListener(
        MXUTILITIES.mxEvent.CONNECT,
        (sender, evt) => {
          var graph = sender.graph;
          var edge = evt.getProperty("cell");
          var source = sender.graph.getModel().getTerminal(edge, true);
          var target = sender.graph.getModel().getTerminal(edge, false);
          var edgeColor;
          this.connectEvent = true;
          if (source.getAttribute("applicationId") == "HangupAction") {
            if (source.getEdgeCount() > 0) {
              graph.removeCells([edge]);
              return;
            }
          }
          if (
            target.getAttribute("applicationId") == "PortRef" ||
            source.parent == target ||
            target.getAttribute("applicationId") == "IncommingCall" ||
            target.getAttribute("applicationId") == "HangUp" ||
            target.getAttribute("applicationId") == "ErrorHandler" ||
            target.getAttribute("applicationId") == "CustomEvent" ||
            target.style.includes("shape=note") ||
            source.style.includes("shape=note")
          ) {
            graph.removeCells([edge]);
            return;
          }
          if (source.getAttribute("applicationId") == "PortRef") {
            if (source.getAttribute("case") == "Success") {
              edgeColor = "#00D68F";
              if (source.parent.getAttribute("multipleCase") == undefined) {
                if (source.getEdgeCount() > 1) {
                  graph.removeCells([edge]);
                  return;
                } else {
                  edge.setValue(source.getAttribute("case"));
                }
              } else {
                if (
                  source.parent.getAttribute("applicationId").split("-")[0] ==
                    "CheckAttribute" ||
                  source.parent.getAttribute("applicationId").split("-")[0] ==
                    "IntentRecognition" ||
                  source.parent.getAttribute("applicationId").split("-")[0] ==
                    "LLM" ||
                  source.parent.getAttribute("applicationId").split("-")[0] ==
                    "GetInput" ||
                  source.parent.getAttribute("applicationId").split("-")[0] ==
                    "ChatGPT"
                ) {
                  this.dialogService
                    .open(ConditionDefineComponent, {
                      autoFocus: false,
                      context: {
                        type: source.parent
                          .getAttribute("applicationId")
                          .split("-")[0],
                      },
                    })
                    .onClose.subscribe((data) => {
                      if (data.data == "") {
                        graph.removeCells([edge]);
                      } else {
                        edge.setValue(data.method + ":" + data.data);
                        this.graph.refresh(edge);
                      }
                    });
                } else if (
                  source.parent.getAttribute("applicationId").split("-")[0] ==
                  "Dial"
                ) {
                  if (source.getEdgeCount() > 5) {
                    graph.removeCells([edge]);
                    return;
                  } else {
                    this.dialogService
                      .open(DialCaseDefinationComponent, {
                        autoFocus: false,
                        context: { item: source.edges },
                      })
                      .onClose.subscribe((data) => {
                        if (data.method == "") {
                          graph.removeCells([edge]);
                        } else {
                          edge.setValue(data.method);
                          this.graph.refresh(edge);
                        }
                      });
                  }
                } else if (
                  source.parent.getAttribute("applicationId").split("-")[0] ==
                  "AnswerDetect"
                ) {
                  if (source.getEdgeCount() > 3) {
                    graph.removeCells([edge]);
                    return;
                  } else {
                    this.dialogService
                      .open(AnswerDetectStatusComponent, {
                        autoFocus: false,
                        context: { item: source.edges },
                      })
                      .onClose.subscribe((data) => {
                        if (data.status == "") {
                          graph.removeCells([edge]);
                        } else {
                          edge.setValue(data.status);
                          this.graph.refresh(edge);
                        }
                      });
                  }
                }
              }
            } else if (source.getAttribute("case") == "Option") {
              edgeColor = "#354DE8";
              if (
                source.parent.getAttribute("multipleOptionCase") == undefined
              ) {
                if (source.getEdgeCount() > 1) {
                  graph.removeCells([edge]);
                  return;
                } else {
                  edge.setValue(source.getAttribute("case"));
                }
              } else {
                if (
                  source.parent.getAttribute("applicationId").split("-")[0] ==
                  "Dial"
                ) {
                  if (source.getEdgeCount() > 1) {
                    graph.removeCells([edge]);
                    return;
                  } else {
                    this.dialogService
                      .open(DialCaseContextComponent, {
                        autoFocus: false,
                        context: { item: source.edges },
                      })
                      .onClose.subscribe((data) => {
                        if (data.method == "") {
                          graph.removeCells([edge]);
                        } else {
                          edge.setValue(data.method);
                          this.graph.refresh(edge);
                        }
                      });
                  }
                }
              }
            } else {
              edgeColor = "#FD7E14";
              if (
                source.parent.getAttribute("multipleOtherCase") == undefined
              ) {
                if (source.getEdgeCount() > 1) {
                  graph.removeCells([edge]);
                  return;
                } else {
                  edge.setValue("Other");
                }
              } else {
                if (
                  source.parent.getAttribute("applicationId").split("-")[0] ==
                    "GetInput" ||
                  source.parent.getAttribute("applicationId").split("-")[0] ==
                    "ChatGPT"
                ) {
                  if (source.getEdgeCount() > 3) {
                    graph.removeCells([edge]);
                    return;
                  }
                  if (source.getEdgeCount() > 1) {
                    this.dialogService
                      .open(FailureCaseDefinationComponent, {
                        autoFocus: false,
                        context: { item: source.edges },
                      })
                      .onClose.subscribe((data) => {
                        if (data.method == "") {
                          graph.removeCells([edge]);
                        } else {
                          edge.setValue(data.method);
                          this.graph.refresh(edge);
                        }
                      });
                  } else {
                    this.dialogService
                      .open(FailureCaseDefinationComponent, {
                        autoFocus: false,
                      })
                      .onClose.subscribe((data) => {
                        if (data.method == "") {
                          graph.removeCells([edge]);
                        } else {
                          edge.setValue(data.method);
                          this.graph.refresh(edge);
                        }
                      });
                  }
                } else {
                  this.dialogService
                    .open(ResponseDefineComponent, { autoFocus: false })
                    .onClose.subscribe((data) => {
                      if (data.data == "") {
                        graph.removeCells([edge]);
                      } else {
                        edge.setValue(data.method + ":" + data.data);
                        this.graph.refresh(edge);
                      }
                    });
                }
              }
            }
            edge.setStyle(`strokeColor=${edgeColor};`);
            edge.geometry.offset = new MxPoint(
              edge.geometry.getCenterX(),
              edge.geometry.getCenterY() + 15
            );
            edge.geometry.relative = true;
            this.graph.refresh(edge);
          } else if (source.getAttribute("applicationId") == "CustomEvent") {
            this.dialogService
              .open(ConditionDefineComponent, { autoFocus: false })
              .onClose.subscribe((data) => {
                if (data.data == "") {
                  graph.removeCells([edge]);
                } else {
                  edge.setValue(data.method + ":" + data.data);
                  this.graph.refresh(edge);
                }
              });
          } else {
            if (
              source.edges.filter((edge) => edge.source == source).length > 1 ||
              source.children?.filter((child) => child.getAttribute("case"))
                .length > 0
            ) {
              graph.removeCells([edge]);
            }
          }
        }
      );

      this.graph.isHtmlLabel = function (cell) {
        return !this.isSwimlane(cell);
      };

      // Dynamically adds text to the label as we zoom in
      // (without affecting the preferred size for new cells)
      this.graph.cellRenderer.getLabelValue = (state) => {
        if (!this.graph.getModel().isEdge(state.cell)) {
          let displayName = state.cell.getAttribute("name", state.cell.value);
          //let displayName = state.cell.value.name;
          return displayName;
        }
        return state.cell.value;
      };

      // Gets the default parent for inserting new cells. This
      // is normally the first child of the root (ie. layer 0).
      var parent = this.graph.getDefaultParent();

      // Adds the root vertex of the tree
      this.graph.getModel().beginUpdate();
      try {
        if (this.data?.data === undefined) {
          var w = this.graph.container.offsetWidth;

          var doc = MXUTILITIES.mxUtils.createXmlDocument();
          var applicationValue = doc.createElement("IncommingCall");
          applicationValue.setAttribute("applicationId", "IncommingCall");
          applicationValue.setAttribute("name", "Incoming Call");

          var v1 = this.graph.insertVertex(
            parent,
            "treeRoot",
            applicationValue,
            50,
            20,
            120,
            50,
            "image=/assets/images/start_50px.png"
          );

          //add hangup
          var applicationHangUp = doc.createElement("HangUp");
          applicationHangUp.setAttribute("applicationId", "HangUp");
          applicationHangUp.setAttribute("name", "Event Hangup");

          this.graph.container.offsetWidth;
          this.graph.insertVertex(
            parent,
            "eventRoot",
            applicationHangUp,
            v1.getGeometry().x + 800,
            20,
            120,
            50,
            "image=/assets/images/hangup_50px.png"
          );

          //add error handle
          var applicationError = doc.createElement("ErrorHandler");
          applicationError.setAttribute("applicationId", "ErrorHandler");
          applicationError.setAttribute("name", "Error Handler");

          this.graph.container.offsetWidth;
          this.graph.insertVertex(
            parent,
            "errorRoot",
            applicationError,
            v1.getGeometry().x + 1000,
            20,
            120,
            50,
            "image=/assets/images/cancel_50px.png"
          );
        } else {
          this.graphHandler.parseFromXmlToGraph(this.graph, this.data.data);
        }
      } finally {
        // Updates the display
        this.graph.getModel().endUpdate();
      }
    } finally {
      this.graph.getModel().endUpdate();
    }

    //set undo action
    this.undoMng = new MxUndoManager(30);
    var listener = MXUTILITIES.mxUtils.bind(this, function (sender, evt) {
      if (evt.properties.edit.changes[0].child?.getId() == "PortRef") {
        return;
      }
      this.undoMng.undoableEditHappened(evt.properties.edit);
    });
    this.graph.getModel().addListener(MXUTILITIES.mxEvent.UNDO, listener);
    this.graph.getView().addListener(MXUTILITIES.mxEvent.UNDO, listener);

    //set zoom in/ zoom out
    MXUTILITIES.mxEvent.addMouseWheelListener((evt, up) => {
      if (
        (this.graph.getView().getScale() > this.maxZoomIn && up) ||
        (this.graph.getView().getScale() < this.maxZoomOut && !up)
      ) {
        return;
      }

      if (MXUTILITIES.mxEvent.isConsumed(evt)) {
        return;
      }

      let gridEnabled = this.graph.gridEnabled;

      // disable snapping
      this.graph.gridEnabled = false;

      let p1 = this.graph.getPointForEvent(evt, false);

      if (up) {
        this.graph.zoomIn();
      } else {
        this.graph.zoomOut();
      }

      let p2 = this.graph.getPointForEvent(evt, false);
      let deltaX = p2.x - p1.x;
      let deltaY = p2.y - p1.y;
      let view = this.graph.view;

      view.setTranslate(view.translate.x + deltaX, view.translate.y + deltaY);

      this.graph.gridEnabled = gridEnabled;

      MXUTILITIES.mxEvent.consume(evt);
    }, container);
  }

  toolboxExcute(action: string): void {
    switch (action) {
      case "redo":
        this.undoMng.redo();
        break;
      case "undo":
        this.undoMng.undo();
        break;
      case "zoomIn":
        if (this.graph.getView().getScale() < this.maxZoomIn) {
          this.graph.zoomIn();
        }
        break;
      case "zoomOut":
        if (this.graph.getView().getScale() > this.maxZoomOut) {
          this.graph.zoomOut();
        }
        break;
      case "enLarge":
        this.graph.zoomActual();
        break;
      case "navigate":
        this.showMap = !this.showMap;
        break;
      case "fitToWidth":
        this.graph.fit();
        break;
      case "listVariable":
        if (!this.isShowVariable) {
          this.isShowActionName = false;
          this.isShowVariable = true;
        }
        this.getActionList();
        break;
      case "keyboard":
        this.openShortcuts();
        break;
      case "search":
        if (!this.isShowActionName) {
          this.isShowActionName = true;
          this.isShowVariable = false;
          this.popover.hide();
        } else {
          this.isShowActionName = false;
          this.popover.show();
        }
        this.searchText = "";
        this.getActionList();
        break;
    }
  }

  importFlowHandler(data) {
    var model = this.graph.getModel();
    model.beginUpdate();
    model.clear();
    model.endUpdate();
    this.graphHandler.parseFromXmlToGraph(this.graph, data);
  }

  getXml() {
    var enc = new MxCodec(MXUTILITIES.mxUtils.createXmlDocument());
    var node = enc.encode(this.graph.getModel());
    var xml = MXUTILITIES.mxUtils.getXml(node);
    xml = xml.replace(new RegExp("<st ", "g"), "<MxPoint ");
    return xml;
  }
  getFlow() {
    let cellTree = this.graph.getModel().getCell("treeRoot");
    let eventTree = this.graph.getModel().getCell("eventRoot");
    let errorRoot = undefined;
    if (this.graph.getModel().getCell("errorRoot") != undefined) {
      errorRoot = this.graph.getModel().getCell("errorRoot");
    }

    // Filter cells with 'applicationId' equal to 'CustomEvent'
    let customEventList = Object.values(this.graph.getModel().cells).filter(
      (cell: MxCell) => cell.getAttribute("applicationId") === "CustomEvent"
    );

    let completedFlow = {
      Action: {},
    };
    let flow = <IncommingCallObject>this.getActionFlow(cellTree, []);
    flow.EventRoot = <any>this.getActionFlow(eventTree, []);

    if (errorRoot != undefined) {
      flow.ErrorRoot = <any>this.getActionFlow(errorRoot, []);
    }

    var customList = [];
    customEventList.forEach((element: MxCell) => {
      customList.push(<any>this.getActionFlow(element, []));
    });
    flow.CustomEvent = customList;

    completedFlow.Action = {};
    completedFlow.Action[flow.Id] = flow;
    let completedFlowJson = JSON.stringify(completedFlow);
    return completedFlowJson;
  }
  getAllAudioIdUsed() {
    var listId = [];
    this.graph.getModel().cells[1].children.forEach((cell) => {
      var id = cell.getAttribute("audioId", "");
      var idInput = cell.getAttribute("audioNoInputId", "");
      var idNotMatch = cell.getAttribute("audioNotMatchId", "");
      var idRepeat = cell.getAttribute("audioIdRepeat", "");
      var idConfirm = cell.getAttribute("audioConfirmId", "");
      if (id && id != "undefined" && id != "null") {
        const idList = id.split(",");
        idList.forEach((audio) => {
          if (!listId.includes(Number(audio))) {
            listId.push(Number(audio));
          }
        });
      }
      if (idInput && idInput != "undefined" && idInput != "null") {
        const idList = idInput.split(",");
        idList.forEach((audio) => {
          if (!listId.includes(Number(audio))) {
            listId.push(Number(audio));
          }
        });
      }
      if (idNotMatch && idNotMatch != "undefined" && idNotMatch != "null") {
        const idList = idNotMatch.split(",");
        idList.forEach((audio) => {
          if (!listId.includes(Number(audio))) {
            listId.push(Number(audio));
          }
        });
      }
      if (idRepeat && idRepeat != "undefined" && idRepeat != "null") {
        const idList = idRepeat.split(",");
        idList.forEach((audio) => {
          if (!listId.includes(Number(audio))) {
            listId.push(Number(audio));
          }
        });
      }
      if (idConfirm && idConfirm != "undefined" && idConfirm != "null") {
        const idList = idConfirm.split(",");
        idList.forEach((audio) => {
          if (!listId.includes(Number(audio))) {
            listId.push(Number(audio));
          }
        });
      }
    });
    return listId;
  }
  getAllSubflowUsed() {
    var listSubflow = [];
    this.graph.getModel().cells[1].children.forEach((cell) => {
      var name = cell.getAttribute("applicationId", "");
      if (name == "RedirectCallflow") {
        const id = cell.getAttribute("callFlowId", "");
        if (!listSubflow.includes(Number(id)) && !isNaN(id)) {
          listSubflow.push(Number(id));
        }
      }
      if (name == "Outreach") {
        const id = cell.getAttribute("callflowId", "");
        if (!listSubflow.includes(Number(id)) && !isNaN(id)) {
          listSubflow.push(Number(id));
        }
      }
    });
    return listSubflow;
  }
  getAllReportUsed() {
    var listSubflow = [];
    this.graph.getModel().cells[1].children.forEach((cell) => {
      var name = cell.getAttribute("applicationId", "");
      if (name == "DataSet") {
        const id = cell.getAttribute("datasetId", "");
        if (!listSubflow.includes(Number(id)) && !isNaN(id)) {
          listSubflow.push(Number(id));
        }
      }
    });
    return listSubflow;
  }
  getAllCMSUsed() {
    var listSubflow = [];
    this.graph.getModel().cells[1].children.forEach((cell) => {
      var name = cell.getAttribute("applicationId", "");
      if (name == "CMS") {
        const id = cell.getAttribute("cmsId", "");
        if (!listSubflow.includes(Number(id)) && !isNaN(id)) {
          listSubflow.push(Number(id));
        }
      }
    });
    return listSubflow;
  }
  getAllFunctionUsed() {
    var listFunction = [];
    this.graph.getModel().cells[1].children.forEach((cell) => {
      var name = cell.getAttribute("applicationId", "");
      if (name.split("-")[0] == "GetInput") {
        var typeGrammar = cell.getAttribute("typeGrammar", "");
        if (typeGrammar == "Function") {
          const id = cell.getAttribute("functionId", "");
          if (!listFunction.includes(id) && !isNaN(id) && id) {
            listFunction.push(id);
          }
        }
        let grammarArray = cell.getAttribute("grammarArray", "");
        if (grammarArray) {
          grammarArray = JSON.parse(grammarArray);
          grammarArray.forEach((grammar) => {
            if (
              !listFunction.includes(grammar.id) &&
              !isNaN(grammar.id) &&
              grammar.id &&
              grammar.type == "Function"
            ) {
              listFunction.push(grammar.id);
            }
          });
        }
      }
      if (name == "CustomFunction") {
        const id = cell.getAttribute("functionId", "");
        if (!listFunction.includes(id) && !isNaN(id) && id) {
          listFunction.push(id);
        }
      }
    });
    return listFunction;
  }
  getAllGrammarUsed() {
    var listGrammar = [];
    this.graph.getModel().cells[1].children.forEach((cell) => {
      var name = cell.getAttribute("applicationId", "");
      if (name.split("-")[0] == "ChatGPT") {
        const grammarName = cell.getAttribute("grammarName", "");
        if (!listGrammar.includes(grammarName)) {
          listGrammar.push(grammarName);
        }
        let grammarArray = cell.getAttribute("grammarArray", "");
        if (grammarArray) {
          grammarArray = JSON.parse(grammarArray);
          grammarArray.forEach((grammar) => {
            if (!listGrammar.includes(grammar.name)) {
              listGrammar.push(grammar.name);
            }
          });
        }
      }
      if (name.split("-")[0] == "GetInput") {
        var typeGrammar = cell.getAttribute("typeGrammar", "");
        if (
          typeGrammar != "Function" &&
          typeGrammar != "Any" &&
          typeGrammar != "BuiltIn"
        ) {
          const grammarName = cell.getAttribute("grammarName", "");
          if (grammarName && !listGrammar.includes(grammarName)) {
            listGrammar.push(grammarName);
          }
        }
        let grammarArray = cell.getAttribute("grammarArray", "");
        if (grammarArray) {
          grammarArray = JSON.parse(grammarArray);
          grammarArray.forEach((grammar) => {
            if (
              (!listGrammar.includes(grammar.name) &&
                grammar.type == "Custom") ||
              grammar.type == "Digit" ||
              grammar.type == "PhraseList"
            ) {
              listGrammar.push(grammar.name);
            }
          });
        }
      }
    });
    return listGrammar;
  }
  getAllChatGPTUsed() {
    var listDirectory = [];
    this.graph.getModel().cells[1].children.forEach((cell) => {
      var name = cell.getAttribute("applicationId", "");
      if (name.split("-")[0] == "ChatGPT") {
        var directory = cell.getAttribute("idDataset", "");
        if (!listDirectory.includes(Number(directory)) && !isNaN(directory)) {
          listDirectory.push(directory);
        }
      }
    });
    return listDirectory;
  }

  getAllEmailTemplateUsed() {
    var listEmailTemplate = [];
    this.graph.getModel().cells[1].children.forEach((cell) => {
      var name = cell.getAttribute("applicationId", "");
      if (name.includes("SendMail")) {
        const emailId = parseInt(cell.getAttribute("template", ""));
        if (!listEmailTemplate.includes(emailId) && !isNaN(emailId)) {
          listEmailTemplate.push(emailId);
        }
      }
    });
    return listEmailTemplate;
  }

  getActionFlow(actionCell: MxCell, mainFlow: MxCell[]) {
    mainFlow.push(actionCell);
    let edges = actionCell.edges;

    let action = {};

    if (
      mainFlow.filter((cell) => cell.getId() == actionCell.getId()).length > 1
    ) {
      action["Id"] = "SubAction";
      action["Key"] = actionCell.getId();
      return action;
    }
    var attributes = actionCell.getValue().attributes;
    let applicationId = attributes.applicationId.value;
    let transition = attributes.transition?.value;
    let multipleTransition = attributes.multipleTransition?.value;

    for (let [key, value] of Object.entries(attributes)) {
      let valueObj = value as any;
      let propertyName = valueObj.nodeName.replace(/([A-Z])/g, " $1");
      propertyName =
        propertyName.charAt(0).toUpperCase() + propertyName.slice(1);
      propertyName = propertyName.replace(/\s/g, "");

      action[propertyName] = valueObj.value;
    }
    if (applicationId.includes("-")) {
      applicationId = applicationId.split("-")[0];
    }
    action["Id"] = applicationId;
    action["Key"] = actionCell.getId();
    if (transition || multipleTransition) {
      if (actionCell.getAttribute("multipleCase") != undefined) {
        action["Cases"] = [];
      } else {
        action["Cases"] = {};
      }

      if (actionCell.getAttribute("multipleOtherCase") != undefined) {
        action["OtherCases"] = [];
      } else {
        action["OtherCases"] = {};
      }
      if (actionCell.getAttribute("multipleOptionCase") != undefined) {
        action["OptionCases"] = [];
      } else {
        action["OptionCases"] = {};
      }

      for (let child of actionCell.children) {
        if (child.edges != undefined) {
          if (child.getAttribute("case") == "Fail" && child.edges.length > 0) {
            for (let edge of child.edges) {
              let nextAction: any = this.getActionFlow(edge.target, mainFlow);
              let caseFlow = {
                CaseOption: edge.value,
                Action: {},
              };
              if (nextAction != null) {
                caseFlow.Action[nextAction.Id] = nextAction;
                if (Array.isArray(action["OtherCases"])) {
                  action["OtherCases"].push(caseFlow);
                } else {
                  action["OtherCases"] = caseFlow;
                }
              }
            }
          } else if (
            child.getAttribute("case") == "Option" &&
            child.edges.length > 0
          ) {
            for (let edge of child.edges) {
              let nextAction: any;
              nextAction = this.getActionFlow(edge.target, mainFlow);
              if (nextAction != null) {
                let caseFlow = {
                  CaseOption: edge.value,
                  Action: {},
                };
                caseFlow.Action[nextAction.Id] = nextAction;

                if (Array.isArray(action["OptionCases"])) {
                  action["OptionCases"].push(caseFlow);
                } else {
                  action["OptionCases"] = caseFlow;
                }
              }
            }
          } else {
            for (let edge of child.edges) {
              let nextAction: any;
              nextAction = this.getActionFlow(edge.target, mainFlow);
              if (nextAction != null) {
                let caseFlow = {
                  CaseOption: edge.value,
                  Action: {},
                };
                caseFlow.Action[nextAction.Id] = nextAction;

                if (Array.isArray(action["Cases"])) {
                  action["Cases"].push(caseFlow);
                } else {
                  action["Cases"] = caseFlow;
                }
              }
            }
          }
        }
      }
    } else if (applicationId == "CustomEvent") {
      action["Cases"] = [];
      if (actionCell.edges?.length > 0) {
        for (let edge of actionCell.edges) {
          let nextAction: any;
          nextAction = this.getActionFlow(edge.target, mainFlow);
          if (nextAction != null) {
            let caseFlow = {
              CaseOption: edge.value,
              Action: {},
            };
            caseFlow.Action[nextAction.Id] = nextAction;

            if (Array.isArray(action["Cases"])) {
              action["Cases"].push(caseFlow);
            } else {
              action["Cases"] = caseFlow;
            }
          }
        }
      }
    } else {
      if (edges != null) {
        let edge = edges.find((edge) => edge.source == actionCell);
        if (edge != undefined) {
          let nextAction: any = this.getActionFlow(edge.target, mainFlow);
          if (nextAction != null) {
            action["NextAction"] = {};
            action["NextAction"][nextAction.Id] = nextAction;
          }
        }
      }
    }
    return action;
  }
  keyPressHandler() {
    var keyHandler = new MxKeyHandler(this.graph);
    keyHandler.keyDown = MXUTILITIES.mxUtils.bind(this, function (evt) {
      var ctrl = evt.ctrlKey ? true : false;
      var enableKey = false;
      var cells = new Array();
      cells = this.graph.getSelectionCells();
      if (cells.length > 0) {
        if (
          cells[0].getId() != "treeRoot" &&
          cells[0].getId() != "eventRoot" &&
          cells[0].getId() != "errorRoot"
        ) {
          enableKey = true;
        }
      }
      if (evt.target.tagName != "BODY") {
        return;
      }
      if (ctrl) {
        if (evt.key == "z") {
          this.undoMng.undo();
        } else if (evt.key == "a") {
          evt.preventDefault();
          this.graph.selectAll();
          let cells = new Array();
          cells = this.graph.getSelectionCells();

          cells.shift();
          cells.shift();
          cells.shift();
          this.graph.setSelectionCells(cells);
        } else if (evt.key == "y") {
          this.undoMng.redo();
        } else if (evt.key == "v") {
          this.pasteCells();
        } else if (evt.key == "c" && enableKey) {
          this.copyCells(cells, enableKey);
        } else if (evt.key == "x" && enableKey) {
          this.copyCells(cells, enableKey);
          this.graph.removeCells(cells);
        } else if (evt.key == "k") {
          this.toolboxExcute("search");
        }
      } else if (evt.key == "Delete" && enableKey) {
        MXUTILITIES.mxClipboard.removeCells(this.graph, cells);
      } else if (
        evt.key == "ArrowLeft" ||
        evt.key == "ArrowUp" ||
        evt.key == "ArrowRight" ||
        evt.key == "ArrowDown"
      ) {
        cells.forEach((element) => {
          var geo = element.getGeometry();
          switch (evt.key) {
            case "ArrowLeft":
              geo.x -= 10;
              break;
            case "ArrowUp":
              geo.y -= 10;
              break;
            case "ArrowRight":
              geo.x += 10;
              break;
            case "ArrowDown":
              geo.y += 10;
              break;
            default:
              return;
          }
          element.setGeometry(geo);
          this.refreshGraphAction(element);
        });
      } else if (evt.key == "=" || evt.key == "+") {
        this.toolboxExcute("zoomIn");
      } else if (evt.key == "-") {
        this.toolboxExcute("zoomOut");
      } else if (evt.key == "m") {
        this.toolboxExcute("navigate");
      }
    });
  }

  refreshGraphAction(cell: MxCell) {
    var model = this.graph.getModel();
    model.beginUpdate();

    this.graph.refresh(cell);
    let edges = this.graph.getEdges(cell);
    for (let i = 0; i < edges.length; i++) {
      this.graph.refresh(edges[i]);
    }

    let childs = this.graph.getChildCells(cell);
    for (let i = 0; i < childs.length; i++) {
      let child = childs[i];
      this.graph.refresh(child);

      let childEdges = this.graph.getEdges(child);
      for (let j = 0; j < childEdges.length; j++) {
        this.graph.refresh(childEdges[j]);
      }
    }

    model.endUpdate();
  }

  saveAction(cell) {
    let applicationId = cell.getAttribute("applicationId", "");
    if (applicationId.includes("-")) {
      applicationId = applicationId.split("-")[0];
    }
    const name = cell.getAttribute("name", "");
    const iconImage = cell.style.split("=");
    const data = [cell.value][0].getAttributeNames().reduce((acc, name) => {
      return { ...acc, [name]: [cell.value][0].getAttribute(name) };
    }, {});
    const userAction = new CreateUserActionCommand();
    userAction.name = name.trim();
    userAction.iconImage = iconImage[1].substring(0, iconImage[1].length - 1);
    userAction.data = JSON.stringify(data);
    userAction.applicationId = applicationId;
    this.userActionClient.getAll().subscribe((rs) => {
      let listUserAction = [];
      if (rs) {
        rs.userActions.map((flow) => {
          if (userAction.name === flow.name.trim()) {
            const obj = {
              id: flow.applicationId + "-" + flow.id,
              name: flow.name,
              description: "",
              iconImage: flow.iconImage,
              data: JSON.parse(flow.data),
            };
            listUserAction.push(obj);
          }
        });
        if (listUserAction.length > 0) {
          this.dialogService
            .open(ConfirmDialogComponent, {
              autoFocus: false,
              context: {
                question:
                  "User Action already exists! Do you want to overwrite it?",
                textYes: "Yes",
                textNo: "No",
                statusYes: "primary",
                statusNo: "basic",
              },
            })
            .onClose.subscribe((isConfirm) => {
              if (isConfirm == true) {
                userAction.id = Number(listUserAction[0].id.split("-")[1]);
                this.handleSaveAction(userAction);
              }
            });
        } else {
          this.handleSaveAction(userAction);
        }
      }
    });
  }
  handleSaveAction(userAction: CreateUserActionCommand) {
    this.userActionClient.create(userAction).subscribe((rs) => {
      if (rs != null) {
        this.graphHandler.reloadMenu.emit(rs);
        this.toastrService.show(
          "Add to User Action successfully",
          `Notification`,
          {
            position: NbGlobalPhysicalPosition.BOTTOM_LEFT,
            status: "success",
          }
        );
      } else {
        this.toastrService.show(
          "Add to User Action unsuccessfully",
          `Notification`,
          {
            position: NbGlobalPhysicalPosition.BOTTOM_LEFT,
            status: "danger",
          }
        );
      }
    });
  }
  titleTemplate() {
    // Implement your custom logic to generate the title dynamically.
    return "Dynamic Window Title";
  }
  openWindow(cell: MxCell, displayMode: NbWindowState) {
    this.popover?.hide();
    if (this.windowRef) {
      this.windowRef.close();
      this.windowSubscription.unsubscribe();
    }

    let applicationId = cell.getAttribute("applicationId", "");
    let name = cell.getAttribute("name", "");
    (callFlowAction as any).default.map((action) => {
      if (applicationId.includes(action.id)) {
        this.callFlowActionDes = action;
      }
    });
    this.defaultActionName = applicationId;
    this.actionName = name;
    const buttonsConfig: NbWindowControlButtonsConfig = {
      minimize: false,
      maximize: false,
      fullScreen: false,
      close: false,
    };

    if (applicationId.includes("PlayAudio")) {
      this.windowRef = this.windowService.open(PlayAudioComponent, {
        title: name,
        titleTemplate: this.actionHeaderTemplate,
        initialState: displayMode,
        closeOnBackdropClick: false,
        closeOnEsc: false,
        buttons: buttonsConfig,
        context: {
          cell: cell,
          graph: this.graph,
        },
        windowClass: "action-css",
      });
    } else if (applicationId.includes("CheckAttribute")) {
      this.windowRef = this.windowService.open(CheckAttributeComponent, {
        title: name,
        titleTemplate: this.actionHeaderTemplate,
        initialState: displayMode,
        closeOnBackdropClick: false,
        closeOnEsc: false,
        buttons: buttonsConfig,
        context: {
          cell: cell,
          graph: this.graph,
        },
        windowClass: "action-css",
      });
    } else if (applicationId.includes("ChatGPT")) {
      this.windowRef = this.windowService.open(ChatGPTComponent, {
        title: name,
        titleTemplate: this.actionHeaderTemplate,
        initialState: displayMode,
        closeOnBackdropClick: false,
        closeOnEsc: false,
        buttons: buttonsConfig,
        context: {
          cell: cell,
          graph: this.graph,
        },
        windowClass: "action-css",
      });
    } else if (applicationId.includes("GoToQueue")) {
      this.windowRef = this.windowService.open(GoToQueueComponent, {
        title: name,
        titleTemplate: this.actionHeaderTemplate,
        initialState: displayMode,
        closeOnBackdropClick: false,
        closeOnEsc: false,
        buttons: buttonsConfig,
        context: {
          cell: cell,
          graph: this.graph,
          queues: this.queues,
        },
        windowClass: "action-css",
      });
    } else if (applicationId.includes("HttpRequest")) {
      this.windowRef = this.windowService.open(HttpRequestComponent, {
        title: name,
        titleTemplate: this.actionHeaderTemplate,
        initialState: displayMode,
        closeOnBackdropClick: false,
        closeOnEsc: false,
        buttons: buttonsConfig,
        context: {
          cell: cell,
          graph: this.graph,
        },
        windowClass: "action-css",
      });
    } else if (applicationId.includes("CMS")) {
      this.windowRef = this.windowService.open(CMSActionComponent, {
        title: name,
        titleTemplate: this.actionHeaderTemplate,
        initialState: displayMode,
        closeOnBackdropClick: false,
        closeOnEsc: false,
        buttons: buttonsConfig,
        context: {
          cell: cell,
          graph: this.graph,
        },
        windowClass: "action-css",
      });
    } else if (applicationId.includes("DataSet")) {
      this.windowRef = this.windowService.open(DataSetActionComponent, {
        title: name,
        titleTemplate: this.actionHeaderTemplate,
        initialState: displayMode,
        closeOnBackdropClick: false,
        closeOnEsc: false,
        buttons: buttonsConfig,
        context: {
          cell: cell,
          graph: this.graph,
        },
        windowClass: "action-css",
      });
    } else if (applicationId.includes("CustomEvent")) {
      this.windowRef = this.windowService.open(CustomEventComponent, {
        title: name,
        titleTemplate: this.actionHeaderTemplate,
        initialState: displayMode,
        closeOnBackdropClick: false,
        closeOnEsc: false,
        buttons: buttonsConfig,
        context: {
          cell: cell,
          graph: this.graph,
        },
        windowClass: "action-css",
      });
    } else if (applicationId.includes("SetAttribute")) {
      this.windowRef = this.windowService.open(SetAttributeComponent, {
        title: name,
        titleTemplate: this.actionHeaderTemplate,
        initialState: displayMode,
        closeOnBackdropClick: false,
        closeOnEsc: false,
        buttons: buttonsConfig,
        context: {
          cell: cell,
          graph: this.graph,
        },
        windowClass: "action-css",
      });
    } else if (applicationId.includes("SendMail")) {
      this.windowRef = this.windowService.open(SendMailActionComponent, {
        title: name,
        titleTemplate: this.actionHeaderTemplate,
        initialState: displayMode,
        closeOnBackdropClick: false,
        closeOnEsc: false,
        buttons: buttonsConfig,
        context: {
          cell: cell,
          graph: this.graph,
        },
        windowClass: "action-css",
      });
    } else if (applicationId.includes("SetLanguage")) {
      this.windowRef = this.windowService.open(SetLanguageComponent, {
        title: name,
        titleTemplate: this.actionHeaderTemplate,
        initialState: displayMode,
        closeOnBackdropClick: false,
        closeOnEsc: false,
        buttons: buttonsConfig,
        context: {
          callSetting: this.dataService.CallSetting,
          cell: cell,
          graph: this.graph,
        },
        windowClass: "action-css",
      });
    } else if (applicationId.includes("TraceLog")) {
      this.windowRef = this.windowService.open(TraceLogComponent, {
        title: name,
        titleTemplate: this.actionHeaderTemplate,
        initialState: displayMode,
        closeOnBackdropClick: false,
        closeOnEsc: false,
        buttons: buttonsConfig,
        context: {
          callSetting: this.callSetting,
          cell: cell,
          graph: this.graph,
        },
        windowClass: "action-css",
      });
    } else if (applicationId.includes("RedirectCallflow")) {
      this.windowRef = this.windowService.open(RedirectCallflowComponent, {
        title: name,
        titleTemplate: this.actionHeaderTemplate,
        initialState: displayMode,
        closeOnBackdropClick: false,
        closeOnEsc: false,
        buttons: buttonsConfig,
        context: {
          cell: cell,
          callflows: this.callflows,
          graph: this.graph,
        },
        windowClass: "action-css",
      });
    } else if (applicationId.includes("LLM")) {
      this.windowRef = this.windowService.open(LLMComponent, {
        title: name,
        titleTemplate: this.actionHeaderTemplate,
        initialState: displayMode,
        closeOnBackdropClick: false,
        closeOnEsc: false,
        buttons: buttonsConfig,
        context: {
          cell: cell,
          callflows: this.callflows,
          graph: this.graph,
        },
        windowClass: "action-css",
      });
    } else if (applicationId.includes("IntentRecognition")) {
      this.windowRef = this.windowService.open(IntentRecognitionComponent, {
        title: name,
        titleTemplate: this.actionHeaderTemplate,
        initialState: displayMode,
        closeOnBackdropClick: false,
        closeOnEsc: false,
        buttons: buttonsConfig,
        context: {
          cell: cell,
          callflows: this.callflows,
          graph: this.graph,
        },
        windowClass: "action-css",
      });
    } else if (applicationId.includes("WaitAction")) {
      this.windowRef = this.windowService.open(WaitActionComponent, {
        title: name,
        titleTemplate: this.actionHeaderTemplate,
        initialState: displayMode,
        closeOnBackdropClick: false,
        closeOnEsc: false,
        buttons: buttonsConfig,
        context: {
          cell: cell,
          graph: this.graph,
        },
        windowClass: "action-css",
      });
    } else if (applicationId.includes("GetInput")) {
      this.windowRef = this.windowService.open(GetInputComponent, {
        title: name,
        titleTemplate: this.actionHeaderTemplate,
        initialState: displayMode,
        closeOnBackdropClick: false,
        closeOnEsc: false,
        buttons: buttonsConfig,
        context: {
          cell: cell,
          graph: this.graph,
        },
        windowClass: "action-css",
      });
    } else if (applicationId.includes("VoiceMail")) {
      this.windowRef = this.windowService.open(VoiceMailComponent, {
        title: name,
        titleTemplate: this.actionHeaderTemplate,
        initialState: displayMode,
        closeOnBackdropClick: false,
        closeOnEsc: false,
        buttons: buttonsConfig,
        context: {
          cell: cell,
          graph: this.graph,
        },
        windowClass: "action-css",
      });
    } else if (applicationId.includes("SayAttribute")) {
      this.windowRef = this.windowService.open(SayNumberComponent, {
        title: name,
        titleTemplate: this.actionHeaderTemplate,
        initialState: displayMode,
        closeOnBackdropClick: false,
        closeOnEsc: false,
        buttons: buttonsConfig,
        context: {
          cell: cell,
          graph: this.graph,
        },
        windowClass: "action-css",
      });
    } else if (applicationId.includes("Transfer")) {
      this.windowRef = this.windowService.open(TransferComponent, {
        title: name,
        titleTemplate: this.actionHeaderTemplate,
        initialState: displayMode,
        closeOnBackdropClick: false,
        closeOnEsc: false,
        buttons: buttonsConfig,
        context: {
          cell: cell,
          graph: this.graph,
        },
        windowClass: "action-css",
      });
    } else if (applicationId.includes("Dial")) {
      this.windowRef = this.windowService.open(DialComponent, {
        title: name,
        titleTemplate: this.actionHeaderTemplate,
        initialState: displayMode,
        closeOnBackdropClick: false,
        closeOnEsc: false,
        buttons: buttonsConfig,
        context: {
          cell: cell,
          graph: this.graph,
          trunksNumber: this.trunksNumber,
        },
        windowClass: "action-css",
      });
    } else if (applicationId.includes("CheckTime")) {
      this.windowRef = this.windowService.open(CheckTimeComponent, {
        title: name,
        titleTemplate: this.actionHeaderTemplate,
        initialState: displayMode,
        closeOnBackdropClick: false,
        closeOnEsc: false,
        buttons: buttonsConfig,
        context: {
          cell: cell,
          graph: this.graph,
        },
        windowClass: "action-css",
      });
    } else if (applicationId.includes("Outreach")) {
      this.windowRef = this.windowService.open(OutreachActionComponent, {
        title: name,
        titleTemplate: this.actionHeaderTemplate,
        initialState: displayMode,
        closeOnBackdropClick: false,
        closeOnEsc: false,

        buttons: buttonsConfig,
        context: {
          cell: cell,
          graph: this.graph,
          callSetting: this.dataService.CallSetting,
        },
        windowClass: "action-css",
      });
    } else if (applicationId.includes("CustomFunction")) {
      this.windowRef = this.windowService.open(CustomFunctionComponent, {
        title: "Custom Function",
        titleTemplate: this.actionHeaderTemplate,
        initialState: displayMode,
        closeOnBackdropClick: false,
        closeOnEsc: false,
        buttons: buttonsConfig,
        context: {
          cell: cell,
          graph: this.graph,
        },
        windowClass: "action-css",
      });
      this.windowSubscription = this.windowRef.onClose.subscribe(() => {
        this.graph.refresh(cell);
      });
    } else if (applicationId.includes("Translate")) {
      this.windowRef = this.windowService.open(TranslateActionComponent, {
        title: name,
        titleTemplate: this.actionHeaderTemplate,
        initialState: displayMode,
        closeOnBackdropClick: false,
        closeOnEsc: false,

        buttons: buttonsConfig,
        context: {
          cell: cell,
          graph: this.graph,
          // callSetting: this.dataService.CallSetting,
        },
        windowClass: "action-css",
      });
    } else if (applicationId.includes("AnswerDetect")) {
      this.windowRef = this.windowService.open(AnswerDetectComponent, {
        title: name,
        titleTemplate: this.actionHeaderTemplate,
        initialState: displayMode,
        closeOnBackdropClick: false,
        closeOnEsc: false,

        buttons: buttonsConfig,
        context: {
          cell: cell,
          graph: this.graph,
        },
        windowClass: "action-css",
      });
    } else if (applicationId.includes("Forms")) {
      this.windowRef = this.windowService.open(FormsComponent, {
        title: name,
        titleTemplate: this.actionHeaderTemplate,
        initialState: displayMode,
        closeOnBackdropClick: false,
        closeOnEsc: false,
        buttons: buttonsConfig,
        context: {
          cell: cell,
          graph: this.graph,
        },
        windowClass: "action-css",
      });
    } else {
      return;
    }

    this.windowSubscription = this.windowRef.onClose.subscribe((rs) => {
      if (rs != true) {
        if (this.actionName && !this.isEditActionName) {
          cell.setAttribute("name", this.actionName);
        }
        this.actionName = "";
        this.isEditActionName = false;
        this.graph.updateCellSize(cell, true);
        this.graph.refresh(cell);
      }
    });
  }

  closeActionWindow() {
    if (this.windowRef != undefined) {
      this.windowRef.close();
    }
  }

  dataURLtoFile(dataurl) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], "", { type: mime });
  }

  copyCells(cells, enable) {
    var cellsChild = [];
    for (let i = 0; i < cells.length; i++) {
      if (
        cells[i].getAttribute("transition") == "true" ||
        cells[i].getAttribute("multipleTransition") == "true"
      ) {
        cellsChild.push(...cells[i].children);
      }
    }
    cells.push(...cellsChild);

    if (enable == true) {
      var enc = new MxCodec(MXUTILITIES.mxUtils.createXmlDocument());
      var node = enc.encode(cells);
      var xml = MXUTILITIES.mxUtils.getXml(node);
      xml = xml.replace(new RegExp("<st ", "g"), "<MxPoint ");
      navigator.clipboard.writeText(xml);
    }
  }
  pasteCells() {
    navigator.clipboard.readText().then((xml) => {
      if (xml.includes("<root>")) {
        this.graph.getModel().clear();
        this.graphHandler.parseFromXmlToGraph(this.graph, xml);
      } else if (xml.includes("<Array>")) {
        var doc = MXUTILITIES.mxUtils.parseXml(xml);
        var codec = new MxCodec(doc);
        var elt = doc.documentElement.firstChild;
        var cells = [];
        while (elt != null) {
          cells.push(codec.decodeCell(elt));
          elt = elt.nextSibling;
        }

        cells.forEach((cell) => {
          let addOverlay = cell.getId() != "treeRoot";
          if (addOverlay != false) {
            addOverlay =
              cell.getId() != "eventRoot" && cell.getId() != "errorRoot";
          }
          if (
            cell.getAttribute("applicationId") == undefined ||
            cell.getAttribute("applicationId") == "PortRef"
          ) {
            addOverlay = false;
          }

          this.graphHandler.addOverlays(this.graph, cell, addOverlay);
        });

        //move to new position for all cells
        var dx = this.mouseCurrentX - cells[0].geometry.x;
        var dy = this.mouseCurrentY - cells[0].geometry.y;

        for (var i = 0; i < cells.length; i++) {
          if (
            cells[i]?.style.includes("shape=note") ||
            (cells[i].getAttribute("applicationId") != undefined &&
              cells[i].getAttribute("applicationId") != "PortRef")
          ) {
            cells[i].geometry.x += dx;
            cells[i].geometry.y += dy;
          }
        }

        MXUTILITIES.mxClipboard.copy(this.graph, cells);
        MXUTILITIES.mxClipboard.paste(this.graph);
      }
    });
  }

  downloadData(filenameForDownload: string, data: any) {
    var textUrl = URL.createObjectURL(data);
    var element = document.createElement("a");
    element.setAttribute("href", textUrl);
    element.setAttribute("download", filenameForDownload);
    element.style.display = "none";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }
  svgString2Image(svgString, width, height, format, callback) {
    // set default for format parameter
    format = format ? format : "png";
    // SVG data URL from SVG string
    var svgData =
      "data:image/svg+xml;base64," +
      btoa(unescape(encodeURIComponent(svgString)));
    // create canvas in memory(not in DOM)
    var canvas = document.createElement("canvas");
    // get canvas context for drawing on canvas
    var context = canvas.getContext("2d");
    // set canvas size
    canvas.width = width;
    canvas.height = height;
    // create image in memory(not in DOM)
    var image = new Image();
    // later when image loads run this
    image.onload = function () {
      // async (happens later)
      // clear canvas
      context.clearRect(0, 0, width, height);
      context.fillStyle = "#edf1f7";
      context.fillRect(0, 0, canvas.width, canvas.height);
      // draw image with SVG data to canvas
      context.drawImage(image, 0, 0, width, height);
      // snapshot canvas as png
      var pngData = canvas.toDataURL("image/" + format);
      // pass png data URL to callback
      callback(pngData);
    }; // end async
    // start loading SVG data into in memory image
    image.src = svgData;
  }
  private convertBase64ToBlob(base64Image: string) {
    // Split into two parts
    const parts = base64Image.split(";base64,");

    // Hold the content type
    const imageType = parts[0].split(":")[1];

    // Decode Base64 string
    const decodedData = window.atob(parts[1]);

    // Create UNIT8ARRAY of size same as row data length
    const uInt8Array = new Uint8Array(decodedData.length);

    // Insert all character code into uInt8Array
    for (let i = 0; i < decodedData.length; ++i) {
      uInt8Array[i] = decodedData.charCodeAt(i);
    }

    // Return BLOB image after conversion
    return new Blob([uInt8Array], { type: imageType });
  }
  openShortcuts() {
    this.dialogService.open(KeyboardShortcutsComponent, { autoFocus: false });
  }
  editActionName(isEdit: boolean) {
    this.isEditActionName = isEdit;
    this.errorActionName = false;
    this.currentActionName = this.actionName;
    setTimeout(() => {
      this.actionInput.nativeElement.focus();
    }, 100);
  }
  closeActionName() {
    if (!this.windowRef.componentInstance.isLoading) {
      this.windowRef.close();
    }
  }

  handleInputBlur() {
    if (!this.errorActionName) {
      this.isEditActionName = false;
      this.actionName = this.actionName?.trim();
      if (this.actionName?.trim() == "") {
        this.actionName = this.currentActionName;
      }
    }
  }
  checkActionName(e) {
    if (e.length > 35) {
      this.errorActionName = true;
    } else {
      this.errorActionName = false;
    }
  }

  destroyPopupMenu() {
    if (this.windowRef) {
      this.windowRef.close();
    }
    this.graph.popupMenuHandler.hideMenu();
  }

  customEvaIcon() {
    this.nbIconLib
      .getPack("eva")
      .icons.set(
        "redo",
        "<img src='/assets/images/redo_30px.png' width='20px'/>"
      );
    this.nbIconLib
      .getPack("eva")
      .icons.set(
        "keyboard",
        "<img src='/assets/images/keyboard_30px.png' width='23px'/>"
      );
    this.nbIconLib
      .getPack("eva")
      .icons.set(
        "undo",
        "<img src='/assets/images/undo_30px.png' width='20px'/>"
      );
    this.nbIconLib
      .getPack("eva")
      .icons.set(
        "zoomIn",
        "<img src='/assets/images/zoom_in_30px.png' width='20px'/>"
      );
    this.nbIconLib
      .getPack("eva")
      .icons.set(
        "navigate",
        "<img src='/assets/images/navigation_30px.png' width='20px'/>"
      );
    this.nbIconLib
      .getPack("eva")
      .icons.set(
        "zoomOut",
        "<img src='/assets/images/zoom_out_30px.png' width='20px'/>"
      );
    this.nbIconLib
      .getPack("eva")
      .icons.set(
        "enLarge",
        "<img src='/assets/images/enlarge_30px.png' width='20px'/>"
      );
    this.nbIconLib
      .getPack("eva")
      .icons.set(
        "fitToWidth",
        "<img src='/assets/images/fit_to_width_30px.png' width='20px'/>"
      );
    this.nbIconLib
      .getPack("eva")
      .icons.set(
        "listVariable",
        "<img src='/assets/images/list_30px.png' width='20px'/>"
      );
    this.nbIconLib
      .getPack("eva")
      .icons.set(
        "exitVariable",
        "<img src='/assets/images/close_30px.png' width='20px'/>"
      );
    this.nbIconLib
      .getPack("eva")
      .icons.set(
        "search",
        "<img src='/assets/images/search_30px.png' width='20px'/>"
      );
  }
}
