<div [formGroup]="makeCallForm">
  <nb-card style="max-width: 550px" [ngClass]="{ 'is-preview': isPreview }">
    <nb-card-header *ngIf="isPreview == false">
      <div class="d-flex justify-content-between">
        Make A Test Call
        <button
          (click)="dismiss()"
          type="button"
          class="btn-close"
          aria-label="Close"
        ></button>
      </div>
    </nb-card-header>
    <nb-card-body>
      <div class="mb-2">
        <label class="form-label label required">Outgoing Number</label>
        <nb-select
          *ngIf="this.selectedOutGoingNumber != null"
          fullWidth
          formControlName="outGoingNumber"
          placeholder="Select Outgoing Number"
          [status]="
            makeCallForm.controls['outGoingNumber'].invalid &&
            (makeCallForm.controls['outGoingNumber'].dirty ||
              makeCallForm.controls['outGoingNumber'].touched)
              ? 'danger'
              : 'basic'
          "
          [(ngModel)]="selectedOutGoingNumber"
        >
          <!-- <nb-option value="100">Context</nb-option> -->
          <nb-option value="101">Local SIP</nb-option>
          <nb-option
            *ngFor="let phoneNumber of phoneNumbers"
            value="{{ phoneNumber.number }}"
          >
            {{ phoneNumber.number }}
          </nb-option>
        </nb-select>
        <ng-container
          *ngIf="
            makeCallForm.controls['outGoingNumber'].invalid &&
            (makeCallForm.controls['outGoingNumber'].dirty ||
              makeCallForm.controls['outGoingNumber'].touched)
          "
        >
          <p
            class="caption status-danger"
            *ngIf="
              makeCallForm.controls['outGoingNumber'].hasError('required') ||
              makeCallForm.controls['outGoingNumber'].hasError('whitespace')
            "
          >
            Outgoing Number is required.
          </p>
        </ng-container>
      </div>
      <div class="mb-2" *ngIf="selectedOutGoingNumber != '100'">
        <label for="callPhoneNumber" class="form-label label required"
          >My Phone Number</label
        >
        <input
          id="callPhoneNumber"
          type="text"
          [(ngModel)]="callPhoneNumber"
          nbInput
          [status]="
            makeCallForm.controls['number'].invalid &&
            (makeCallForm.controls['number'].dirty ||
              makeCallForm.controls['number'].touched)
              ? 'danger'
              : 'basic'
          "
          formControlName="number"
          fullWidth
          (keyup.enter)="makeCall()"
          placeholder="My Phone Number"
        />
        <ng-container
          *ngIf="
            makeCallForm.controls['number'].invalid &&
            (makeCallForm.controls['number'].dirty ||
              makeCallForm.controls['number'].touched)
          "
        >
          <p
            class="caption status-danger"
            *ngIf="
              makeCallForm.controls['number'].hasError('required') ||
              makeCallForm.controls['number'].hasError('whitespace')
            "
          >
            My Phone Number is required.
          </p>
          <p
            class="caption status-danger"
            *ngIf="
              makeCallForm.controls['number'].hasError('digit') &&
              !makeCallForm.controls['number'].hasError('maxLength')
            "
          >
            My Phone Number must be numeric.
          </p>
          <p
            class="caption status-danger"
            *ngIf="makeCallForm.controls['number'].hasError('maxLength')"
          >
            Maximum length is 15 characters.
          </p>
        </ng-container>
      </div>
      <button
        class="float-end mt-2"
        nbButton
        size="small"
        [nbSpinner]="loadingButton"
        nbSpinnerSize="small"
        nbSpinnerStatus="info"
        [disabled]="
          ((!callPhoneNumber ||
            !selectedOutGoingNumber ||
            !makeCallForm.valid) &&
            selectedOutGoingNumber != '100') ||
          loadingButton
        "
        type="button"
        (click)="makeCall()"
        status="primary"
      >
        Make Call
      </button>
    </nb-card-body>
  </nb-card>
</div>
