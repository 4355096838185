import { Component, OnInit } from "@angular/core";
import { NbDialogRef } from "@nebular/theme";
import { ResponseDefine } from "../../../../@core/model/response-define";

@Component({
  selector: "ngx-dial-case-defination",
  templateUrl: "./dial-case-defination.component.html",
  styleUrls: ["./dial-case-defination.component.scss"],
})
export class DialCaseDefinationComponent implements OnInit {
  data: ResponseDefine = new ResponseDefine();
  listFail: any;
  item: any;
  isUpdate: boolean = false;
  updateData = null;
  constructor(private ref: NbDialogRef<DialCaseDefinationComponent>) {}

  ngOnInit(): void {
    this.listFail = [
      "Unavailable",
      "Congestion",
      "No answer",
      "Busy",
      "Answer",
    ];

    if (Array.isArray(this.item)) {
      for (var i = 0; i < this.item.length; i++) {
        if (this.item[i].value) {
          if (this.isUpdate && this.item[i].value == this.updateData) {
            this.data.method = this.updateData;
          } else {
            var index = this.listFail.indexOf(this.item[i].value);
            if (index > -1) {
              this.listFail.splice(index, 1);
            }
          }
        }
      }
    }

    if (this.listFail.length == 1) {
      this.data.method = this.listFail[0];
      this.dismiss();
    }
  }

  ngOnDestroy(): void {
    this.ref.close(this.data);
  }

  dismiss() {
    this.ref.close(this.data);
  }
}
