import { IApplicationData } from "./common";
export class VoiceMail implements IApplicationData {
  constructor() {
    this.name = "Record";
    this.maxDuration = "";
    this.audioId = "";
    this.audioPath = "";
    this.text = "";
    this.option = 1;
    this.attribute = "";
    this.sentiment = "";
    this.speechtotext = true;
    this.isRequired = true;
    this.checkFields = false;
    this.pathAttribute = "";
    this.timeout = "5";
  }
  name: string;
  maxDuration: string;
  audioPath: string;
  audioId: string;
  text: string;
  option: number;
  attribute: string;
  timeout: string;
  sentiment: string;
  speechtotext: boolean;
  isRequired: boolean;
  checkFields: boolean;
  pathAttribute: string;
}
