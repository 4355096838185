<form>
  <div class="form-group mb-3">
    <label for="extension" class="label">Extension</label>
    <input
      id="extension"
      nbInput
      [(ngModel)]="userName"
      fullWidth
      fieldSize="small"
      placeholder="Extension"
    />
  </div>
  <div class="form-group mb-3">
    <label for="password" class="label">Password</label>
    <input
      id="password"
      [(ngModel)]="passWord"
      type="password"
      nbInput
      fullWidth
      fieldSize="small"
      placeholder="Password"
    />
  </div>
  <button
    type="submit"
    [disabled]="!userName || !passWord"
    (click)="submit()"
    class="mb-3"
    nbButton
    size="small"
    status="primary"
  >
    Login
  </button>
</form>
