import { IApplicationData } from "./common";
export class HttpRequestData implements IApplicationData {
  constructor() {
    this.url = "";
    this.method = "GET";
    this.params = "";
    this.bodyRaw = "";
    this.body = "";
    this.statusCode = "";
    this.headers = "";
    this.headerResponse = "";
    this.name = "Http Request";
    this.attribute = "";
    this.response = "";
    this.timeout = "30";
    this.retry = "0";
    this.typeRaw = "json";
    this.option = 1;
    this.transition = true;
    this.log = false;
    this.selectedOptionLog = "[]";
    this.isRequired = true;
    this.checkFields = false;
  }
  name: string;
  method: string;
  url: string;
  headers: string;
  body: string;
  statusCode: string;
  headerResponse: string;
  response: string;
  attribute: string;
  timeout: string;
  retry: string;
  params: string;
  bodyRaw: string;
  transition: boolean;
  option: number;
  typeRaw: string;
  log: boolean;
  selectedOptionLog: string;
  isRequired: boolean;
  checkFields: boolean;
}
