<nb-card style="width: 400px !important">
  <nb-card-header>
    <div class="d-flex justify-content-between">
      <span>Dial Status Condition</span>
      <button
        (click)="dismiss()"
        type="button"
        class="btn-close"
        aria-label="Close"
      ></button>
    </div>
  </nb-card-header>
  <nb-card-body>
    <div class="row m-2">
      <div class="col-12">
        <div class="mb-2">
          <label class="form-label label">Dial Status</label>
          <nb-select
            fullWidth
            placeholder="Select Dial Status"
            [(ngModel)]="data.method"
          >
            <nb-option *ngFor="let item of listFail" [value]="item">{{
              item
            }}</nb-option>
          </nb-select>
        </div>
      </div>
    </div>
    <button
      class="float-end"
      (click)="dismiss()"
      nbButton
      size="small"
      status="primary"
    >
      Confirm
    </button>
  </nb-card-body>
</nb-card>
