import { IApplicationData } from "./common";
export class PlayAudioData implements IApplicationData {
  constructor() {
    this.audioId = "";
    this.audioName = "";
    this.name = "Play Prompt";
    this.audioPath = "";
    this.text = "";
    this.sentiment = "normal-speak";
    this.textChatBot = "";
    this.option = 1;
    this.allowBreak = false;
    this.log = false;
  }
  audioId: string;
  audioName: string;
  name: string;
  audioPath: string;
  text: string;
  textChatBot: string;
  sentiment: string;
  option: number;
  allowBreak: boolean;
  log: boolean;
}
