import { Component, OnInit } from "@angular/core";
import { NbDialogService, NbWindowRef } from "@nebular/theme";
import { DataService } from "../../../../@core/utils/data.service";
import { GraphHandlerService } from "../../../../@core/utils/graph.service";
import { MxCell, MxGraph } from "../../../../@core/model/mxgraph";
import { SendMail } from "../../../../@core/model/send-mail";
import { EmailTemplateClient } from "../../../../System-api";
import { UntypedFormGroup } from "@angular/forms";
import {
  RxFormBuilder,
  RxwebValidators,
} from "@rxweb/reactive-form-validators";
import { noWhitespaceValidator } from "../../../../@core/utils/helpers";

@Component({
  selector: "ngx-send-mail",
  templateUrl: "./send-mail.component.html",
  styleUrls: ["./send-mail.component.scss"],
})
export class SendMailActionComponent implements OnInit {
  cell: MxCell;
  graph: MxGraph;
  data: SendMail = new SendMail();
  cancelAction = false;
  attributes: any = this.dataService.ivrAttribute;
  mentionConfig = {
    items: this.attributes,
    triggerChar: "{",
    labelKey: "value",
    disableSort: true,
    mentionSelect: (item) => {
      return "{" + item.value + "}";
    },
  };
  optionContent: object[] = [
    { id: 1, name: "Template" },
    { id: 2, name: "Text" },
  ];
  templates = [];
  isLoading = false;
  formGroup: UntypedFormGroup;
  currentTextEmail = "";
  constructor(
    private dialogService: NbDialogService,
    protected windowRef: NbWindowRef,
    public dataService: DataService,
    private graphService: GraphHandlerService,
    private emailTemplateClient: EmailTemplateClient,
    private formBuilder: RxFormBuilder
  ) {
    this.createForm();
  }

  getTemplateAttribute($event) {
    this.data.templateVariable = $event;
  }
  createForm() {
    this.formGroup = this.formBuilder.group({
      emailAddress: ["", [RxwebValidators.required(), noWhitespaceValidator]],
      emailSubject: ["", [RxwebValidators.required(), noWhitespaceValidator]],
      emailName: ["", [RxwebValidators.required(), noWhitespaceValidator]],
    });
  }
  ngOnInit() {
    for (let [key, value] of Object.entries(this.data)) {
      this.data[key] = this.cell.getAttribute(key);
    }
    if (this.data.template) {
      this.data.template =
        Number(this.data.template) == 0 || isNaN(this.data.template)
          ? null
          : Number(this.data.template);
    } else {
      this.data.template = null;
    }
    if (this.data.emailRecipients && this.data.emailRecipients != "") {
      this.data.emailRecipients = this.handleConvertDataString(
        this.data.emailRecipients
      );
    }
    this.handleTemplate(Number(this.data.optionTemplate));
    this.getEmailTemplate();
    this.windowRef.onClose.subscribe(() => this.submit());
  }
  handleConvertDataString(data) {
    if (data) {
      return data.split(",");
    }
    return data;
  }
  setTemplate() {}
  addTemplate() {
    window.open(`portal/email/template/update`, "_blank");
  }
  getEmailTemplate() {
    this.isLoading = true;
    this.emailTemplateClient.getAll(0, 1000, "id", true, null).subscribe(
      (rs) => {
        this.isLoading = false;
        this.templates = rs.emailTemplateDtos;
        if (this.data.template) {
          this.data.templateName = (rs.emailTemplateDtos.find(e => e.id == this.data.template)).name;
        }
      },
      (error) => {
        this.isLoading = false;
      }
    );
  }
  viewData(id) {
    window.open(`portal/email/template/update/${id}`, "_blank");
  }
  handleSaveAction() {
    this.windowRef.close();
  }
  handleTemplate(value: number) {
    this.data.optionTemplate = value;
  }
  onTextChange(e) {
    this.currentTextEmail = e;
  }
  updateEmailRecipients() {
    if (this.currentTextEmail?.trim() != "") {
      let tempArray = this.data.emailRecipients as any;
      if (!tempArray) {
        tempArray = [] as any;
      }
      let dataExist = tempArray.find((x: any) => x == this.currentTextEmail);
      if (!dataExist) {
        tempArray.push(this.currentTextEmail);
      }
      this.data.emailRecipients = tempArray;
    }
  }
  changeTemplate(event) {
    this.data.templateName = event?.name;
  }
  submit() {
    if (!this.cancelAction) {
      this.updateEmailRecipients();
      for (let [key, value] of Object.entries(this.data)) {
        if (typeof this.data[key] == "string") {
          value = value?.trim();
        }
        this.cell.setAttribute(key, value || "");
      }
      const checkAttributeCell = this.graph
        .getModel()
        .getCell(this.cell.getId());
      const fieldsRequired = [
        this.data.emailAddress?.trim(),
        this.data.emailSubject?.trim(),
        this.data.emailName?.trim(),
      ];
      const check = this.graphService.checkIsRequired(
        this.graph,
        fieldsRequired,
        checkAttributeCell
      );
      this.cell.setAttribute("checkFields", check?.toString());
    }
  }
  handleCancelAction() {
    this.cancelAction = true;
    this.windowRef.close(true);
  }
}
