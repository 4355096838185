<nb-layout
  (window:unload)="doUnload()"
  *ngIf="loadAction == true"
  [nbSpinner]="isLoading"
  nbSpinnerStatus="primary"
>
  <nb-layout-header
    *ngIf="isAllowDesign == true"
    fixed
    [ngStyle]="{ 'pointer-events': isAllowDesign == false ? 'none' : 'auto' }"
  >
    <div
      class="w-100 d-flex flex-row align-items-center justify-content-between"
    >
      <div class="float-start d-flex" style="align-items: center">
        <button
          style="margin-right: 10px"
          size="small"
          status="basic"
          nbButton
          ghost
          (click)="back()"
        >
          <nb-icon icon="arrow-back"></nb-icon>
        </button>
        <form
          [formGroup]="callFlowNameForm"
          novalidate
          class="d-flex"
          style="align-items: center"
        >
          <div>
            <input
              (ngModelChange)="checkCallFLowName($event)"
              type="text"
              nbInput
              #inputName
              formControlName="name"
              [attr.disabled]="isNameLoading == false ? null : isNameLoading"
              [(ngModel)]="data.name"
              [status]="
                callFlowNameForm.controls['name'].invalid &&
                (callFlowNameForm.controls['name'].dirty ||
                  callFlowNameForm.controls['name'].touched)
                  ? 'danger'
                  : 'basic'
              "
              fullWidth
              [nbSpinner]="isNameLoading"
              nbSpinnerSize="small"
              nbSpinnerStatus="info"
              [nbTooltipDisabled]="callFlowNameForm.valid"
              [nbTooltip]="
                callFlowNameForm.controls['name'].hasError('required') ||
                callFlowNameForm.controls['name'].hasError('whitespace')
                  ? 'Flow Name is required'
                  : callFlowNameForm.controls['name']?.errors?.isExist
                  ? 'Flow Name already exist.'
                  : callFlowNameForm.controls['name']?.errors?.maxlength &&
                    !callFlowNameForm.controls['name'].hasError('required') &&
                    !callFlowNameForm.controls['name'].hasError('whitespace')
                  ? 'Maximum length is 50 characters.'
                  : callFlowNameForm.controls['name']?.errors?.regex &&
                    !callFlowNameForm.controls['name']?.errors?.maxlength &&
                    !callFlowNameForm.controls['name'].hasError('required') &&
                    !callFlowNameForm.controls['name'].hasError('whitespace')
                  ? errorRegex
                  : ''
              "
              nbTooltipPlacement="bottom"
              nbTooltipStatus="danger"
              id="input-name"
              placeholder="Flow Name"
              fieldSize="small"
              class="callflow-name"
              [attr.disabled]="isReadOnly"
            />
          </div>
          <button
            *ngIf="isReadOnly != true"
            id="save-change"
            nbButton
            status="basic"
            class="ms-2 fixed-btn primary"
            [disabled]="
              isExist ||
              callFlowNameForm.invalid ||
              isNameLoading ||
              isSaveLoading ||
              isPublishLoading ||
              isReadOnly == true
            "
            (click)="save()"
            size="small"
            [nbSpinner]="isSaveLoading"
            nbSpinnerSize="tiny"
            nbSpinnerStatus="info"
          >
            <nb-icon icon="save-outline" class="icon-save"></nb-icon>
            Save
          </button>
          <button
            *ngIf="isReadOnly != true"
            id="publish"
            [disabled]="
              !data.id ||
              isExist ||
              callFlowNameForm.invalid ||
              isNameLoading ||
              isSaveLoading ||
              isPublishLoading ||
              isReadOnly == true
            "
            nbButton
            status="basic"
            class="ms-2 fixed-btn primary"
            (click)="publish()"
            size="small"
            [nbSpinner]="isPublishLoading"
            nbSpinnerSize="tiny"
            nbSpinnerStatus="info"
          >
            <nb-icon icon="star-outline" class="icon-publish"></nb-icon>
            Publish
          </button>
          <button
            *ngIf="isReadOnly == true"
            nbButton
            status="basic"
            class="ms-2 fixed-btn primary"
            size="small"
            nbSpinnerSize="tiny"
            nbSpinnerStatus="info"
            style="pointer-events: none; cursor: pointer"
          >
            <nb-icon icon="eye-outline" class="icon-publish"></nb-icon>
            Read Only
          </button>
        </form>
        <div
          class="d-flex align-items-center text-secondary ms-3 text-size save-status"
        >
          {{ savedStatus }}
        </div>
      </div>
      <div
        class="float-end action-row"
        style="display: flex; flex-wrap: nowrap"
      >
        <button
          (click)="openWindow()"
          nbButton
          status="basic"
          class="ms-2 secondary revistion-text"
          size="small"
          title="Revision History"
        >
          Revision History
        </button>
        <button
          (click)="openWindow()"
          nbButton
          status="basic"
          class="ms-2 secondary revistion-icon"
          size="small"
          title="Revision History"
        >
          <nb-icon icon="clock-outline"></nb-icon>
        </button>
        <button
          title="Make A Test Call"
          (click)="makeATestCall()"
          nbButton
          status="basic"
          class="ms-2 secondary"
          size="small"
        >
          <nb-icon icon="phone-call-outline"></nb-icon>
        </button>
        <button
          title="Make A Test Chat"
          (click)="makeATestChat()"
          nbButton
          status="basic"
          class="ms-2 secondary"
          size="small"
          [ngClass]="{ 'button-active': showChatBtn == false }"
        >
          <nb-icon icon="message-square-outline"></nb-icon>
        </button>
        <input
          [(ngModel)]="fileFlow"
          accept="application/zip"
          #fileInput
          (click)="fileInput.value = null"
          type="file"
          (change)="importCheckFlow(fileInput.files)"
          style="display: none"
        />
        <button
          title="Import Flow"
          (click)="fileInput.click()"
          nbButton
          status="basic"
          class="ms-2 secondary"
          size="small"
        >
          <nb-icon icon="log-in-outline"></nb-icon>
        </button>
        <button
          title="Export Flow"
          (contextmenu)="exportJson()"
          (click)="exportFlow()"
          nbButton
          status="basic"
          class="ms-2 secondary"
          size="small"
        >
          <nb-icon icon="log-out-outline"></nb-icon>
        </button>
        <button
          title="Log"
          (click)="logModal()"
          nbButton
          status="basic"
          class="ms-2 secondary"
          size="small"
        >
          <nb-icon icon="file-text-outline"></nb-icon>
        </button>
        <button
          title="Help Center"
          (click)="helpCenter()"
          nbButton
          status="basic"
          class="ms-2 secondary"
          size="small"
        >
          <nb-icon icon="question-mark-circle-outline"></nb-icon>
        </button>
        <button
          title="Setting"
          (click)="settingModal()"
          nbButton
          status="basic"
          class="ms-2 secondary"
          size="small"
        >
          <nb-icon icon="settings-2-outline"></nb-icon>
        </button>
      </div>
    </div>
  </nb-layout-header>

  <nb-layout-column *ngIf="isDesignShow">
    <callflows-graph
      #callflowGraph
      [(data)]="data"
      [isReadOnly]="isReadOnly"
      [isAllowDesign]="isAllowDesign"
    ></callflows-graph>
    <ngx-chat
      *ngIf="showChatBtn == false"
      style="display: inline-grid"
      #chatComponent
    ></ngx-chat>
    <menu-app #menuAction [appItems]="appItems"></menu-app>
  </nb-layout-column>
</nb-layout>
