<nb-card style="margin-bottom: 0; min-width: 420px">
  <nb-card-header>Notifications</nb-card-header>
  <nb-card-body>
    {{ question }}
  </nb-card-body>
  <nb-card-footer>
    <div class="float-end">
      <button
        (click)="onSubmit()"
        type="submit"
        class="me-2"
        size="small"
        status="{{ statusYes }}"
        nbButton
      >
        {{ textYes }}
      </button>
      <button (click)="dismiss()" status="{{ statusNo }}" nbButton size="small">
        {{ textNo }}
      </button>
    </div>
  </nb-card-footer>
</nb-card>
