import {
  Component,
  EventEmitter,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import {
  NbDialogRef,
  NbGlobalPhysicalPosition,
  NbToastrService,
} from "@nebular/theme";
import { DataService } from "../../../../@core/utils/data.service";
import {
  AssignCallFlowCommand,
  CallSettingClient,
  NumberClient,
} from "../../../../System-api";
import { UntypedFormGroup } from "@angular/forms";
import { RxFormBuilder } from "@rxweb/reactive-form-validators";
import { SettingConfigureComponent } from "./setting-configuage/setting-configure.component";
@Component({
  selector: "setting-call-flows",
  templateUrl: "setting-callflows.component.html",
  styleUrls: ["./setting-callflows.component.scss"],
})
export class SettingCallFlowsComponent implements OnDestroy, OnInit {
  @ViewChild("configure", {
    static: true,
  })
  configure: SettingConfigureComponent;
  onAdd = new EventEmitter();
  callSettingForm: UntypedFormGroup;
  languageDefault;
  callSetting;
  phoneNumberList;
  botContactList;
  isLoading: boolean = false;
  isSaveLoading: boolean = false;
  updateVariable: boolean = false;
  updateVariableName: string = "";
  updateVariableValue: string = "";
  updateCurrentVariableName: string = "";
  isReadOnly = false;

  @HostListener('window:popstate', ['$event'])
    onPopState(event) {
      this.dismiss();
  }
  constructor(
    private dataService: DataService,
    private callSettingClient: CallSettingClient,
    private numberClient: NumberClient,
    private formBuilder: RxFormBuilder,
    private toastrService: NbToastrService,
    protected ref: NbDialogRef<SettingCallFlowsComponent>
  ) {
    this.numberClient
      .getContactConfig(
        this.dataService.CallFlow.id == undefined
          ? 0
          : this.dataService.CallFlow.id
      )
      .subscribe((item) => {
        this.phoneNumberList = item.phoneNumbers;
        this.botContactList = item.botContact;
      });
    this.createForm();
  }
  routerVariable() {
    window.open("https://docs.primas.net/display/CAL/Call+Setting");
  }
  ngOnInit(): void {
    if (this.updateVariable) {
      this.configure.name = this.updateVariableName;
      this.configure.value = this.updateVariableValue;
      this.configure.currentName = this.updateCurrentVariableName;
    }
  }

  ngOnDestroy(): void {}

  createForm() {
    this.callSettingForm = this.formBuilder.group({
      enableRecording: [""],
      detectLanguage: [""],
      allowLogInfo: [""],
      languageSetting: [""],
      speechToTextService: [""],
      translateService: [""],
      botName: [""],
      botLogo: [""],
      botGreetingMessages: [""],
    });
  }

  onSubmit() {
    if (this.isReadOnly != true) {
      for (let prop in this.callSetting) {
        if (typeof this.callSetting[prop] === "string") {
          this.callSetting[prop] = this.callSetting[prop].trim();
        }
      }
      this.isLoading = true;
      this.isSaveLoading = true;
      if (this.dataService.CallFlow.id) {
        this.callSettingClient.update(this.callSetting).subscribe({
          next: (rs) => {
            this.isSaveLoading = false;
            if (rs) {
              this.dataService.CallSetting = this.callSetting;
              this.saveNumberFlow(
                this.dataService.CallFlow.id,
                this.callSetting.selectedPhoneNumbers
              );
              this.onAdd.emit({ callSetting: this.callSetting, result: rs });
            } else {
              this.isLoading = false;
              this.isSaveLoading = false;
            }
          },
          error: () => {
            this.isLoading = false;
            this.isSaveLoading = false;
          },
        });
      } else {
        this.isLoading = false;
        this.isSaveLoading = false;
        this.dataService.CallSetting = this.callSetting;
        this.showToast(true);
      }
      this.dataService.ivrAttribute = this.configure.configureValue;
    }
  }

  saveNumberFlow(callflowId: number, numbers: string) {
    this.numberClient
      .assignCallFlow(
        new AssignCallFlowCommand({
          callFlowId: callflowId,
          phoneNumbers: numbers,
        })
      )
      .subscribe({
        next: (rs) => {
          this.isLoading = false;
          this.isSaveLoading = false;
        },
        error: () => {
          this.isLoading = false;
          this.isSaveLoading = false;
        },
      });
  }

  showToast(result) {
    if (result) {
      this.toastrService.show("Save setting successfully", `Notification`, {
        position: NbGlobalPhysicalPosition.BOTTOM_LEFT,
        status: "success",
      });
    } else {
      this.toastrService.show("Save setting unsuccessfully", `Notification`, {
        position: NbGlobalPhysicalPosition.BOTTOM_LEFT,
        status: "danger",
      });
    }
  }

  dismiss() {
    this.ref.close(false);
  }
}
