import { NbDialogService } from "@nebular/theme";
import { DataService } from "./../../../../../@core/utils/data.service";
import { Component, OnInit } from "@angular/core";
import { ConfirmDialogComponent } from "../../../../../shared/confirm-dialog/confirm-dialog.component";
import { RxFormBuilder } from "@rxweb/reactive-form-validators";
import { ValidInput } from "../../../../../@core/utils/helpers";
import { UntypedFormGroup } from "@angular/forms";

@Component({
  selector: "setting-configure",
  templateUrl: "./setting-configure.component.html",
  styleUrls: ["./setting-configure.component.scss"],
})
export class SettingConfigureComponent implements OnInit {
  name: string = "";
  value: string = "";
  configureValue = [];
  currentName = "";
  invalidName = false;
  actionType = "Add";
  configurationForm: UntypedFormGroup;
  constructor(
    private dataService: DataService,
    private dialogService: NbDialogService,
    private formBuilder: RxFormBuilder
  ) {}

  ngOnInit() {
    this.createForm();
    this.configureValue = [...this.dataService?.ivrAttribute];
  }
  createForm() {
    this.configurationForm = this.formBuilder.group({
      name: ["", ValidInput.variable],
      value: [""],
    });
  }
  handleEdit(data, e) {
    this.invalidName = false;
    this.currentName = data.value;
    this.actionType = "Update";
    this.name = data.value;
    this.value = data.content;
  }
  remove(data, index) {
    this.dialogService
      .open(ConfirmDialogComponent, {
        autoFocus: false,
        context: {
          question:
            "Are you sure you want to delete '" +
            this.configureValue[index].value +
            "' ?",
          textYes: "Delete",
          textNo: "Cancel",
          statusYes: "danger",
          statusNo: "basic",
        },
      })
      .onClose.subscribe((isConfirm) => {
        if (isConfirm) {
          if (data.default == 2) {
            if (index != 0) {
              this.configureValue.splice(index, 1);
            } else {
              this.configureValue.shift();
            }
          }
        }
      });
  }
  handleWhiteSpace(type, event) {
    if (type == "name") {
      if (event?.trim()) {
        this.checkInvalidName(event);
      } else {
        this.name = "";
      }
    }
    if (!this.name.trim() && !this.value.trim()) {
      this.currentName = "";
      this.actionType = "Add";
      this.invalidName = false;
    }
    if (type == "value") {
      const textArea = document.getElementById("text-value-attribute");
      if (textArea) {
        if (event?.trim()) {
          textArea.style.height = `${textArea.scrollHeight}px`;
        } else {
          textArea.style.height = "32.4px";
        }
      }
    }
  }
  checkInvalidName($event) {
    // const data = this.configureValue.find((x) => {
    //   return (
    //     x.value?.toLowerCase() == $event?.toLowerCase().trim() &&
    //     $event?.toLowerCase().trim() != this.currentName.toLowerCase().trim()
    //   );
    // });
    // if (data) {
    //   this.invalidName = true;
    // } else {
    //   this.invalidName = false;
    // }

    if (this.configurationForm.valid) {
      const data = this.configureValue.find((x) => {
        return x.value == $event && $event != this.currentName;
      });
      if (data) {
        this.configurationForm.controls["name"].setErrors({});
        this.configurationForm.controls["name"].setErrors({
          duplicateName: true,
        });
      }
    }
  }
  addVariable() {
    const data = this.configureValue.find((x) => {
      return (
        x.value?.toLowerCase().trim() ==
          this.currentName?.toLowerCase().trim() && x.default == 2
      );
    });
    if (data) {
      const index = this.configureValue.findIndex(
        (x) => x.value?.trim() == this.currentName?.trim()
      );

      this.configureValue[index].value = this.name?.trim();
      this.configureValue[index].content = this.value?.trim();
      this.actionType = "Add";
    } else {
      let variable = {
        default: 2,
        value: this.name?.trim(),
        content: this.value?.trim(),
      };
      this.configureValue.push(variable);
    }
    this.name = "";
    this.value = "";
    this.currentName = "";
    this.configurationForm.controls["name"].reset();
    this.configurationForm.controls["name"].markAsUntouched();
    this.configurationForm.controls["name"].setErrors(null);
    const textArea = document.getElementById("text-value-attribute");
    if (textArea) {
      textArea.style.height = "32.4px";
    }
  }
}
