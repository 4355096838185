import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DurationPipe } from "./duration.pipe";
import { PhonePipe } from "./phone.pipe";
import { BotPipe } from "./botconact.pipe";
import { URLPipe } from "./url-iframe.pipe";

@NgModule({
  declarations: [DurationPipe, PhonePipe, BotPipe, URLPipe],
  imports: [CommonModule],
  exports: [DurationPipe, PhonePipe, BotPipe, URLPipe],
})
export class MainPipe {}
