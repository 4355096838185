import { Component } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';


@Component({
  selector: 'nb-alert-dialog',
  templateUrl: './alert-dialog.component.html'
})
export class AlertDialogComponent {
  title: string
  question: any;
  textYes: string;
  textNo: string;
  statusYes: string;
  statusNo: string;
  constructor(protected ref: NbDialogRef<AlertDialogComponent>) { }
  dismiss() {
    this.ref.close(false);
  }
  onSubmit() {
    this.ref.close(true);
  }
}
