<nb-card
  style="min-width: 600px; max-width: 600px; max-height: 70vh"
  status="basic"
>
  <nb-card-header class="infor-header">
    <div class="d-flex justify-content-between">
      Script ChatBox
      <button
        (click)="dismiss(true)"
        type="button"
        class="btn-close"
        aria-label="Close"
      ></button>
    </div>
  </nb-card-header>
  <nb-card-body>
    <div class="container-item">
      <div class="item-infor-col" style="width: 100%">
        <div class="label-script">
          <label class="form-label label" style="margin-bottom: 0"
            >jQuery</label
          >
          <button
            matTooltip="Copy"
            matTooltipPosition="left"
            nbButton
            status="basic"
            ghost
            size="small"
            class="copy-button"
            (click)="copyJquery()"
          >
            <nb-icon icon="copy"></nb-icon>
          </button>
        </div>
        <textarea
          style="min-height: 100px; max-height: 100px"
          nbInput
          fullWidth
          [(ngModel)]="jquery"
        ></textarea>
      </div>
      <div class="item-infor-col" style="width: 100%">
        <div class="label-script">
          <label class="form-label label" style="margin-bottom: 0"
            >Script</label
          >
          <button
            matTooltip="Copy"
            matTooltipPosition="left"
            nbButton
            status="basic"
            ghost
            size="small"
            class="copy-button"
            (click)="copy()"
          >
            <nb-icon icon="copy"></nb-icon>
          </button>
        </div>
        <textarea
          style="min-height: 250px; max-height: 400px"
          nbInput
          fullWidth
          [(ngModel)]="script"
        ></textarea>
      </div>
    </div>
  </nb-card-body>
  <nb-card-footer
    style="
      display: flex;
      align-items: center;
      justify-content: end;
      gap: 0.5rem;
    "
  >
    <button
      type="button"
      nbButton
      status="basic"
      size="small"
      (click)="dismiss(true)"
    >
      Cancel
    </button>
    <button
      type="button"
      nbButton
      status="primary"
      size="small"
      (click)="dismiss(false)"
    >
      Done
    </button></nb-card-footer
  >
</nb-card>
