import { DataService } from "./../../../../@core/utils/data.service";
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
} from "@angular/core";
import { NbDialogRef } from "@nebular/theme";
import {
  NumberVm,
  SettingsClient,
  GetSettingValuesCompanyQuery,
  NumberClient,
  CheckNumber,
} from "../../../../System-api";
import { FormControl, UntypedFormGroup } from "@angular/forms";
import {
  RxFormBuilder,
  RxwebValidators,
} from "@rxweb/reactive-form-validators";
import { noWhitespaceValidator } from "../../../../@core/utils/helpers";

@Component({
  selector: "choose-test-number",
  templateUrl: "choose-test-number.component.html",
  styleUrls: ["./choose-test-number.component.scss"],
})
export class ChooseTestNumberComponent implements OnInit, OnDestroy {
  @Input() isPreview: boolean = false;
  @Input() loadingButton: boolean = false;
  @Output() run = new EventEmitter<any>();
  phoneNumbers: any[];
  selectedOutGoingNumber: string;
  callPhoneNumber: string;
  makeCallForm: UntypedFormGroup;
  constructor(
    @Optional() private ref: NbDialogRef<any>,
    private settingClient: SettingsClient,
    private phoneNumberClient: NumberClient,
    private dataService: DataService,
    private formBuilder: RxFormBuilder
  ) {
    this.makeCallForm = this.formBuilder.group({
      outGoingNumber: new FormControl("", [RxwebValidators.required()]),
      number: new FormControl("", [
        RxwebValidators.required(),
        RxwebValidators.maxLength({
          value: 15,
        }),
        RxwebValidators.digit(),
      ]),
    });
  }
  ngOnDestroy(): void {
    this.dataService.selectedOutGoingNumber = this.selectedOutGoingNumber;
    this.dataService.callPhoneNumber = this.callPhoneNumber;
  }
  getPhoneNumbers() {
    this.phoneNumberClient.getNumbersByCompany().subscribe((item) => {
      this.phoneNumbers = item.phoneNumbers;
      if (
        !this.dataService.selectedOutGoingNumber &&
        !this.dataService.callPhoneNumber
      ) {
        let settingKeys = new GetSettingValuesCompanyQuery({
          keys: ["OutgoingTestNumber", "TestNumber"],
          companyId: 0,
        });
        this.settingClient.getValues(settingKeys).subscribe((data) => {
          let outGoingSetting = data
            .filter((common) => common.key === "OutgoingTestNumber")
            .pop();
          if (outGoingSetting != undefined) {
            this.selectedOutGoingNumber = outGoingSetting.value;
          } else {
            this.selectedOutGoingNumber = "";
          }
          let testCallNumberSetting = data
            .filter((common) => common.key === "TestNumber")
            .pop();
          if (testCallNumberSetting != undefined) {
            this.callPhoneNumber = testCallNumberSetting.value;
          } else {
            this.callPhoneNumber = "";
          }
        });
      } else {
        this.selectedOutGoingNumber = this.dataService.selectedOutGoingNumber;
        this.callPhoneNumber = this.dataService.callPhoneNumber;
      }
    });
  }
  ngOnInit() {
    this.getPhoneNumbers();
  }
  dismiss() {
    this.ref.close(false);
  }
  makeCall() {
    if (
      (this.callPhoneNumber && this.selectedOutGoingNumber) ||
      this.selectedOutGoingNumber == "100"
    ) {
      if (this.isPreview == false) {
        this.ref.close({
          outGoingNumber: this.selectedOutGoingNumber,
          callPhoneNumber: this.callPhoneNumber,
        });
      } else {
        this.run.emit({
          outGoingNumber: this.selectedOutGoingNumber,
          callPhoneNumber: this.callPhoneNumber,
        });
      }
    }
  }
  onSelectedChange(event) {
    this.selectedOutGoingNumber = event;
  }
}
