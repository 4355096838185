<div class="action-config-wrapper" style="max-width: 500px !important">
  <div class="config-action">
    <div class="row">
      <div class="mb-2 col-12 flex-col">
        <label class="form-label label required">Method</label>
        <nb-select
          placeholder="Select Method"
          size="small"
          [(ngModel)]="data.method"
          fullWidth
          class="method-option"
        >
          <nb-option style="padding-left: 10px !important" value="GET"
            >GET</nb-option
          >
          <nb-option style="padding-left: 10px !important" value="CREATE"
            >CREATE</nb-option
          >
          <nb-option style="padding-left: 10px !important" value="UPDATE"
            >UPDATE</nb-option
          >
          <nb-option style="padding-left: 10px !important" value="DELETE"
            >DELETE</nb-option
          >
        </nb-select>
      </div>
      <div class="mb-2 col-12 flex-col">
        <label class="form-label label required">Data Storages</label>
        <form [formGroup]="formGroup">
          <div class="d-flex custom-gap">
            <div class="select-style">
              <ng-select
                formControlName="cms"
                [nbSpinner]="isLoading"
                nbSpinnerSize="medium"
                nbSpinnerStatus="info"
                [items]="cmsList"
                bindLabel="name"
                bindValue="id"
                [(ngModel)]="data.cmsId"
                (change)="setCMS($event)"
                placeholder="Select Data Storages"
                [ngClass]="{
                  isInvalid:
                    formGroup.controls['cms'].invalid &&
                    (formGroup.controls['cms'].dirty ||
                      formGroup.controls['cms'].touched)
                }"
              >
                <ng-template
                  ng-option-tmp
                  let-item="item"
                  let-index="index"
                  let-search="searchTerm"
                >
                  <div class="function-item">
                    <span>{{ item.name }}</span>
                    <div class="function-item-action">
                      <nb-icon
                        icon="edit-outline"
                        style="font-size: 15px"
                        (click)="handleEdit(item.id)"
                      ></nb-icon>
                    </div>
                  </div>
                </ng-template>
              </ng-select>
              <button
                nbButton
                [disabled]="!data.cmsId"
                status="primary"
                size="small"
                (click)="handleEdit(data.cmsId)"
              >
                <nb-icon icon="edit-outline"></nb-icon>
              </button>
            </div>
            <button
              nbButton
              status="primary"
              size="small"
              (click)="createNew()"
            >
              <nb-icon icon="plus-outline"></nb-icon>
            </button>
          </div>
          <ng-container
            *ngIf="
              formGroup.controls['cms'].invalid &&
              (formGroup.controls['cms'].dirty ||
                formGroup.controls['cms'].touched)
            "
          >
            <p
              class="caption status-danger mt-1"
              style="margin-bottom: 0"
              *ngIf="
                formGroup.controls['cms'].hasError('required') ||
                formGroup.controls['cms'].hasError('whitespace')
              "
            >
              Data Storages is required.
            </p>
          </ng-container>
        </form>
      </div>

      <div *ngIf="data.cmsId">
        <div *ngIf="data.method != 'CREATE'">
          <div class="flex-title">
            <span class="title">Filters</span>
            <span class="line"></span>
          </div>
          <div
            *ngIf="conditionsArray.length > 0"
            style="font-size: 13px; margin-top: -0.5rem"
          >
            <span>Items that match </span>
            <span
              [matMenuTriggerFor]="menu"
              class="text-link"
              style="text-decoration: underline"
              >{{ data.conditionsType }}</span
            >
            <span> conditions below.</span>
          </div>
          <div
            [nbPopover]="templateRef"
            nbPopoverTrigger="noop"
            nbPopoverPlacement="left"
          >
            <div class="mt-3" *ngIf="conditionsArray.length > 0">
              <nb-card
                style="height: auto; margin-bottom: 1.25rem !important"
                status="basic"
              >
                <nb-list
                  class="example-list"
                  cdkDropList
                  (cdkDropListDropped)="dropOptions($event)"
                >
                  <nb-list-item
                    [ngClass]="{ 'border-active': current == i }"
                    *ngFor="let condition of conditionsArray; let i = index"
                    class="example-box"
                    cdkDrag
                  >
                    <div class="grammar-name-wrapper">
                      <span class="condition-item"
                        >{{ condition.cmsEntity.fieldName }}
                        <span style="font-weight: 600">{{
                          condition.conditions
                        }}</span>
                        {{ condition.value }}</span
                      >
                    </div>
                    <div class="action-wrapper">
                      <button
                        ghost
                        class="tbl-action"
                        nbButton
                        size="small"
                        (click)="showConditions(true, i, condition)"
                      >
                        <nb-icon icon="settings-2-outline"></nb-icon>
                      </button>
                      <button
                        ghost
                        class="tbl-action"
                        nbButton
                        size="small"
                        (click)="deleteInList(i)"
                      >
                        <nb-icon icon="trash-2-outline"></nb-icon>
                      </button>
                      <div
                        class="move-icon"
                        style="display: flex; align-items: center"
                      >
                        <nb-icon
                          style="margin-right: -14px"
                          status="basic"
                          icon="more-vertical-outline"
                        ></nb-icon>
                        <nb-icon
                          status="basic"
                          icon="more-vertical-outline"
                        ></nb-icon>
                      </div>
                    </div>
                  </nb-list-item>
                </nb-list>
              </nb-card>
            </div>
          </div>

          <div class="add-grammar mb-2">
            <button
              [nbPopover]="templateRef"
              nbPopoverTrigger="noop"
              nbPopoverPlacement="left"
              (click)="showConditions()"
              shape="round"
              type="button"
              size="small"
              status="primary"
              nbButton
            >
              <nb-icon [icon]="icon"></nb-icon>
            </button>
            <span style="font-size: 13.5px">More filter</span>
          </div>
        </div>
        <div *ngIf="data.method == 'CREATE'">
          <div class="flex-title" *ngIf="dataCMS && dataCMS.length > 0">
            <span class="title">New Item Data</span>
            <span class="line"></span>
          </div>
          <div class="col-12">
            <div class="mb-2 field-wrapper" *ngFor="let entity of dataCMS">
              <label for="" class="form-label label">{{
                entity.fieldName
              }}</label>

              <textarea
                fullWidth
                nbInput
                fieldSize="small"
                [(ngModel)]="entity.value"
                [placeholder]="entity.fieldName"
                [mention]="attributes"
                [mentionConfig]="mentionConfig"
                style="height: 32px; min-height: 32px; max-height: 200px"
              ></textarea>
            </div>
          </div>
        </div>
        <div *ngIf="data.method == 'UPDATE'">
          <div class="flex-title">
            <span class="title">Update Fields Data</span>
            <span class="line"></span>
          </div>
          <div
            class="row mb-2"
            style="display: flex; align-items: end"
            *ngFor="let updateData of updateArray; let i = index"
          >
            <div class="col-4">
              <div class="mb-2 field-wrapper">
                <label for="" class="form-label label">Field</label>
                <nb-select
                  placeholder="Select Field"
                  size="small"
                  fullWidth
                  [(ngModel)]="updateData.uniqueName"
                  class="method-option"
                >
                  <nb-option
                    style="padding-left: 10px !important"
                    *ngFor="let item of dataCMS"
                    [value]="item.uniqueName"
                    >{{ item.fieldName }}</nb-option
                  >
                </nb-select>
              </div>
            </div>
            <div class="col-6">
              <div class="mb-2 field-wrapper">
                <label for="" class="form-label label">Value</label>
                <input
                  fullWidth
                  [(ngModel)]="updateData.value"
                  nbInput
                  fieldSize="small"
                  placeholder="Value"
                  [mention]="attributes"
                  [mentionConfig]="mentionConfig"
                />
              </div>
            </div>
            <div class="col-2 mb-2">
              <button
                type="button"
                size="small"
                nbButton
                (click)="removeItem(i)"
              >
                <nb-icon icon="trash-2-outline"></nb-icon>
              </button>
            </div>
          </div>
          <div class="add-grammar mb-2">
            <button
              (click)="moreUpdate()"
              shape="round"
              type="button"
              size="small"
              status="primary"
              nbButton
            >
              <nb-icon icon="plus-outline"></nb-icon>
            </button>
            <span style="font-size: 13.5px">More fields</span>
          </div>
        </div>
        <div *ngIf="data.method == 'GET'">
          <div class="flex-title">
            <span class="title">Arrange</span>
            <span class="line"></span>
          </div>
          <div class="mb-2 col-12 flex-col">
            <label class="form-label label d-flex" style="align-items: center"
              ><span>Query Limit</span>
              <nb-icon
                class="ms-1"
                style="width: 15px"
                nbTooltip="The limit number of items returned. No limit will be applied if the input is 0 or empty."
                nbTooltipPlacement="end"
                nbTooltipStatus="basic"
                icon="question-mark-circle-outline"
              >
              </nb-icon
            ></label>
            <input
              id="queryLimit"
              [(ngModel)]="data.queryLimit"
              (input)="handleOnlyIntegerNumber($event)"
              type="number"
              min="0"
              nbInput
              fullWidth
              placeholder="Query Limit"
              fieldSize="small"
            />
          </div>
          <div class="mb-2 col-12 flex-col">
            <label class="form-label label required">Sort By</label>
            <nb-select
              placeholder="Select Sort By"
              size="small"
              fullWidth
              [(ngModel)]="data.sortBy"
              class="method-option"
            >
              <nb-option style="padding-left: 10px !important" value="created"
                >Created Date</nb-option
              >
              <nb-option
                style="padding-left: 10px !important"
                *ngFor="let item of dataCMS"
                [value]="item.uniqueName"
                >{{ item.fieldName }}</nb-option
              >
            </nb-select>
          </div>
          <div class="mb-2 col-12 flex-col">
            <label class="form-label label required">Order By</label>
            <nb-select
              placeholder="Select Order By"
              size="small"
              fullWidth
              [(ngModel)]="data.orderBy"
              class="method-option"
            >
              <nb-option style="padding-left: 10px !important" value="ascending"
                >Ascending (A - Z)</nb-option
              >
              <nb-option
                style="padding-left: 10px !important"
                value="descending"
                >Descending (Z - A)</nb-option
              >
            </nb-select>
          </div>
        </div>

        <div class="mb-2 col-12 flex-col" *ngIf="data.method == 'GET'">
          <div class="flex-title" style="margin-bottom: 1.25rem">
            <span class="title">Assign Variable</span>
            <span class="line"></span>
          </div>
          <div mb-2 col-12 flex-col>
            <label class="form-label label">Result</label>
            <ngx-input-value-static
              selectAttribute="true"
              [propertyInput]="data.attribute"
              placeHolder="Select Result"
              (propertyEmit)="getAttribute($event)"
            >
            </ngx-input-value-static>
          </div>
        </div>
      </div>
    </div>
  </div>
  <footer-action
    (handleCancelAction)="handleCancelAction()"
    (handleSaveAction)="handleSaveAction()"
  ></footer-action>
</div>
<ng-template #templateRef>
  <nb-card class="example-box-2"
    ><nb-card-header
      style="
        padding: 15px;
        display: flex;
        align-items: center;
        gap: 0.5rem;
        justify-content: space-between;
        padding-left: 1.5rem;
      "
    >
      <span *ngIf="isUpdate == false">New Filter</span>
      <span *ngIf="isUpdate == true">Update Filter</span>
      <button nbButton size="small" ghost type="button" (click)="closeModal()">
        <nb-icon icon="close"></nb-icon>
      </button>
    </nb-card-header>
    <nb-card-body class="grammar-add">
      <div class="mb-2 col-12 flex-col">
        <label class="form-label label required">Field Name</label>
        <nb-select
          placeholder="Select Field Name"
          size="small"
          fullWidth
          class="method-option"
          [(ngModel)]="conditionTemp.cmsEntity"
        >
          <nb-option
            *ngFor="let item of dataCMS"
            style="padding-left: 10px !important"
            [value]="item.id"
            >{{ item.fieldName }}</nb-option
          >
        </nb-select>
      </div>
      <div class="mb-2 col-12 flex-col">
        <label class="form-label label required">Conditions</label>
        <nb-select
          placeholder="Select Conditions"
          size="small"
          fullWidth
          class="method-option"
          [(ngModel)]="conditionTemp.conditions"
        >
          <nb-option
            *ngFor="let condition of conditions"
            style="padding-left: 10px !important"
            [value]="condition.value"
            >{{ condition.name }}</nb-option
          >
        </nb-select>
      </div>
      <div
        class="mb-2 col-12 flex-col"
        *ngIf="conditionTemp.conditions != 'null or empty'"
        style="position: relative"
      >
        <label class="form-label label required">Value</label>
        <input
          fullWidth
          nbInput
          fieldSize="small"
          placeholder="Value"
          [(ngModel)]="conditionTemp.value"
          [mention]="attributes"
          [mentionConfig]="mentionConfig"
          (keyup)="mentionListOpen($event)"
        />
      </div>
    </nb-card-body>
    <nb-card-footer>
      <div style="display: flex; justify-content: end">
        <button
          type="button"
          *ngIf="isUpdate == false"
          status="primary"
          class="ms-2"
          size="small"
          nbButton
          (click)="addToObject()"
          [disabled]="
            !conditionTemp.cmsEntity ||
            !conditionTemp.conditions ||
            (!conditionTemp.value &&
              conditionTemp.conditions != 'null or empty') ||
            (!conditionTemp.value?.trim() &&
              conditionTemp.conditions != 'null or empty')
          "
        >
          Add
        </button>
        <button
          type="button"
          *ngIf="isUpdate == true"
          status="primary"
          class="ms-2"
          size="small"
          nbButton
          (click)="updateToObject()"
          [disabled]="
            !conditionTemp.cmsEntity ||
            !conditionTemp.conditions ||
            !conditionTemp.value ||
            !conditionTemp.value?.trim()
          "
        >
          Update
        </button>
      </div>
    </nb-card-footer>
  </nb-card>
</ng-template>
<mat-menu #menu="matMenu">
  <div style="padding: 10px 5px; font-size: 13px; gap: 0.2rem">
    <div
      (click)="updateConditionType('all')"
      style="padding: 5px; cursor: pointer"
      class="hover-item"
    >
      all
    </div>
    <div
      (click)="updateConditionType('any')"
      style="padding: 5px; cursor: pointer"
      class="hover-item"
    >
      any
    </div>
  </div>
</mat-menu>
