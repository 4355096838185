import { NgModule } from "@angular/core";
import {
  NbLayoutModule,
  NbSidebarModule,
  NbActionsModule,
  NbCheckboxModule,
  NbContextMenuModule,
  NbTabsetModule,
  NbUserModule,
  NbTreeGridModule,
  NbSelectModule,
  NbTooltipModule,
  NbAutocompleteModule,
} from "@nebular/theme";
import { SharedModule } from "../../../shared/shared.module";
import { AudioRoutingModule } from "./audio-routing.module";
import { AudioComponent } from "./audio.component";
import { IndexAudioComponent } from "./index/index-audio.component";
import { CreateAudioComponent } from "./create/create-audio.component";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
@NgModule({
  imports: [
    SharedModule,
    NbLayoutModule,
    NbSidebarModule,
    NbActionsModule,
    NbCheckboxModule,
    NbContextMenuModule,
    NbTabsetModule,
    NbTreeGridModule,
    NbSelectModule,
    NbUserModule,
    NbTooltipModule,
    AudioRoutingModule,
    NbAutocompleteModule,
    MatAutocompleteModule,
  ],
  declarations: [IndexAudioComponent, AudioComponent, CreateAudioComponent],
})
export class AudioModule {}
