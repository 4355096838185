<div [formGroup]="parentForm">
  <div class="flex-col">
    <div>
      <div class="flex-title">
        <div class="label-title-wrapper">
          <span class="title">Contact</span>
          <nb-icon
            class="hint-icon"
            nbTooltipTrigger="hover"
            nbTooltip="Assign phone numbers and bot contact to your flow."
            nbTooltipPlacement="right"
            nbTooltipStatus="basic"
            icon="question-mark-circle-outline"
          ></nb-icon>
        </div>
        <span class="line"></span>
      </div>
      <div class="box-setting">
        <div class="form-group row">
          <div class="col-md-6">
            <label
              class="label col-sm-3 col-form-label"
              style="padding-top: 0; width: 100%"
              >Phone Number</label
            >
            <div class="col-sm-10">
              <nb-select
                (selectedChange)="phoneChangeHandle($event)"
                [(selected)]="selectedPhoneNumbers"
                fullWidth
                multiple
                size="small"
                placeholder="Select Phone Number"
              >
                <nb-option
                  *ngIf="phoneNumberList"
                  [ngClass]="{ 'hidden-css': phoneNumberList?.length > 0 }"
                  [disabled]="true"
                  >None</nb-option
                >
                <nb-option
                  *ngFor="let option of phoneNumberList"
                  [value]="option.id"
                  [disabled]="!option.active"
                  >{{ option.number | phone }}</nb-option
                >
              </nb-select>
            </div>
          </div>
          <div class="col-md-6">
            <label class="label col-sm-3 col-form-label" style="padding-top: 0"
              >Chatbot</label
            >
            <div class="col-sm-10">
              <nb-select
                (selectedChange)="botContactChangeHandle($event)"
                [(selected)]="selectedBotContact"
                fullWidth
                size="small"
                multiple
                placeholder="Select Chatbot"
              >
                <nb-option
                  *ngIf="botContactList"
                  [ngClass]="{ 'hidden-css': botContactList?.length > 0 }"
                  [disabled]="true"
                  >None</nb-option
                >
                <nb-option
                  *ngFor="let option of botContactList"
                  [value]="option.id"
                  >{{ option.provider + "-" + option.contactId }}</nb-option
                >
              </nb-select>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <div class="flex-title">
        <div class="label-title-wrapper">
          <span class="title">Recording</span>
          <nb-icon
            class="hint-icon"
            nbTooltipTrigger="hover"
            nbTooltip="Allow you to record all the calls in your flow."
            nbTooltipPlacement="right"
            nbTooltipStatus="basic"
            icon="question-mark-circle-outline"
          ></nb-icon>
        </div>
        <span class="line"></span>
      </div>
      <div class="box-setting">
        <nb-checkbox
          formControlName="enableRecording"
          [(ngModel)]="callSetting.enableRecording"
          (checkedChange)="handleEnableRecording($event)"
          >Allow Call Recording</nb-checkbox
        >
      </div>
    </div>
    <div>
      <div class="flex-title">
        <div class="label-title-wrapper">
          <span class="title">Detect Language</span>
          <nb-icon
            class="hint-icon"
            nbTooltipTrigger="hover"
            nbTooltip="Detect and change the language of your flow by country calling codes."
            nbTooltipPlacement="right"
            nbTooltipStatus="basic"
            icon="question-mark-circle-outline"
          ></nb-icon>
        </div>
        <span class="line"></span>
      </div>
      <div class="box-setting">
        <nb-toggle
          formControlName="detectLanguage"
          [(ngModel)]="callSetting.detectLanguage"
          labelPosition="start"
          >Enable</nb-toggle
        >
      </div>
    </div>
    <div>
      <div class="flex-title">
        <div class="label-title-wrapper">
          <span class="title">Allow TraceLog Info</span>
          <nb-icon
            class="hint-icon"
            nbTooltipTrigger="hover"
            nbTooltip="You can review the info log and variables of all the calls with your flow."
            nbTooltipPlacement="right"
            nbTooltipStatus="basic"
            icon="question-mark-circle-outline"
          ></nb-icon>
        </div>
        <span class="line"></span>
      </div>
      <div class="box-setting">
        <nb-toggle
          formControlName="allowLogInfo"
          [(ngModel)]="callSetting.allowLogInfo"
          labelPosition="start"
          >Enable</nb-toggle
        >
      </div>
    </div>
    <div></div>
  </div>
</div>
