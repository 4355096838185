<div style="user-select: none" class="menu-wrapper">
  <div *ngIf="!showName">
    <button
      nbButton
      fullWidth
      class="button-action"
      status="primary"
      ghost
      id="scroll-up"
      (click)="scrollUp()"
    >
      <nb-icon icon="arrow-ios-upward-outline"></nb-icon>
    </button>
  </div>

  <div style="background-color: white; margin-bottom: 10px">
    <div *ngIf="showName">
      <div style="margin: 5px 0px 5px 0px">
        <input
          style="width: 95%; margin: 4px"
          type="text"
          nbInput
          (keyup)="filterAction($event)"
          placeholder="Search Action"
          fieldSize="small"
        />
      </div>
    </div>
    <div
      class="menu-action-system"
      id="system-action"
      [ngClass]="{ 'menu-scroll': showName }"
    >
      <nb-list
        [matTooltip]="showName ? '' : c.name"
        matTooltipPosition="after"
        *ngFor="let c of appItems.system"
        [ngClass]="{ 'hidden-action-list': !currentDataSystem.includes(c) }"
        [ngClass]="{ 'show-name-item': showName }"
      >
        <nb-list-item
          id="{{ 'item-' + c.id }}"
          [ngClass]="{ 'hidden-action': !currentDataSystem.includes(c) }"
        >
          <nb-user
            size="tiny"
            shape="rectangle"
            [picture]="c.iconImage"
            [name]="showName ? c.name : ''"
            [ngClass]="{ 'show-name': showName }"
          ></nb-user>
        </nb-list-item>
      </nb-list>
      <div class="btn-explan">
        <button
          nbButton
          fullWidth
          class="button-action-2"
          status="primary"
          ghost
          (click)="toggleMenu()"
        >
          <nb-icon
            [icon]="showName ? 'arrow-ios-back' : 'arrow-ios-forward'"
          ></nb-icon>
        </button>
      </div>
    </div>
  </div>

  <div class="menu-action">
    <nb-list
      style="margin-bottom: 0"
      [matTooltip]="showName ? '' : 'User Action'"
      matTooltipPosition="after"
      nbPopoverTrigger="noop"
      [nbPopover]="userAction"
      nbPopoverPlacement="right"
      (click)="show()"
    >
      <nb-list-item [ngClass]="{ 'more-active': popover?.isShown }">
        <nb-user
          size="tiny"
          shape="rectangle"
          picture="/assets/images/more_50px.png"
          [name]="showName ? 'User Action' : ''"
          [ngClass]="{ 'show-name': showName }"
        ></nb-user>
      </nb-list-item>
    </nb-list>
  </div>
  <div *ngIf="!showName">
    <button
      nbButton
      fullWidth
      class="button-action"
      id="scroll-down"
      status="primary"
      ghost
      (click)="scrollDown()"
    >
      <nb-icon icon="arrow-ios-downward-outline"></nb-icon>
    </button>
  </div>
  <ng-template #userAction>
    <div>
      <div class="user-action-title">
        <span>User Action</span>
      </div>
      <div style="padding: 0.5rem; border-bottom: 1px solid #f2f3f5">
        <input
          type="text"
          nbInput
          fullWidth
          (keyup)="filterUserAction($event)"
          placeholder="Search User Action"
          fieldSize="small"
        />
      </div>
      <div class="menu-action user-action-modal" style="padding: 0">
        <nb-list
          *ngFor="let c of appItems.user | filter : term"
          style="margin-bottom: 0; cursor: pointer"
        >
          <nb-list-item
            #userAction
            id="{{ 'item-' + c.id }}"
            [ngClass]="{
              'hidden-action':
                !currentDataUser.includes(c) || isEditActionName == c.id,
              'user-action-active': openRemove == false
            }"
            class="user-action"
            style="padding-bottom: 0.5rem; padding-top: 0.5rem"
          >
            <div style="display: contents" *ngIf="isEditActionName != c.id">
              <nb-user
                style="word-break: break-word"
                size="tiny"
                shape="rectangle"
                [picture]="c.iconImage"
                [name]="c.name"
              ></nb-user>

              <div style="text-align: right; display: flex; margin-left: 5px">
                <nb-icon
                  (click)="editActionName(c)"
                  icon="edit-outline"
                  style="
                    margin-right: 5px;
                    color: #3367d6 !important;
                    width: 1rem !important;
                    cursor: pointer;
                  "
                ></nb-icon>
                <nb-icon
                  (click)="removeAction(c.id)"
                  style="
                    margin-right: 5px;
                    color: #3367d6 !important;
                    width: 1rem !important;
                    cursor: pointer;
                  "
                  icon="close-outline"
                ></nb-icon>
              </div>
            </div>
          </nb-list-item>
          <nb-list-item
            class="user-action-form"
            [ngClass]="{
              'hidden-action': isEditActionName != c.id
            }"
          >
            <form [formGroup]="userActionForm" novalidate>
              <div
                [nbTooltipDisabled]="userActionForm.valid"
                [nbTooltip]="
                  userActionForm.controls['name']?.errors?.isExist
                    ? 'User Action Name already exist.'
                    : userActionForm.controls['name']?.errors?.maxLength
                    ? 'Maximum length is 35 characters.'
                    : false
                "
                nbTooltipPlacement="bottom"
                nbTooltipStatus="danger"
              >
                <input
                  id="{{ 'input-' + c.id }}"
                  type="text"
                  nbInput
                  fieldSize="small"
                  fullWidth
                  placeholder="Action Name"
                  (keyup.enter)="handleInputBlur($event)"
                  (blur)="handleInputBlur($event)"
                  formControlName="name"
                  [ngModel]="currentName"
                  (ngModelChange)="checkUserActionName($event)"
                  [status]="
                    userActionForm.controls['name'].invalid &&
                    (userActionForm.controls['name'].dirty ||
                      callFlowNameForm.controls['name'].touched)
                      ? 'danger'
                      : 'basic'
                  "
                />
              </div>
            </form>
          </nb-list-item>
        </nb-list>
      </div>
    </div>
  </ng-template>
</div>
