<div class="action-config-wrapper" style="max-width: 550px">
  <div class="config-action">
    <div class="mb-2" style="display: flex; align-items: center; gap: 0.5rem">
      <div class="col-md-9" style="flex: 1">
        <label class="form-label label required">Model</label>
        <nb-select
          placeholder="Select Model"
          size="small"
          fullWidth
          [(ngModel)]="data.model"
          class="method-option"
        >
          <nb-option value="gpt">GPT 3.5</nb-option>
          <nb-option value="gemini">Gemini 1.0 Pro</nb-option>
        </nb-select>
      </div>
      <div style="margin-top: 0.5rem; width: 6.5rem">
        <label class="form-label label d-flex mb-0" style="align-items: center"
          ><span>Temperature</span>
          <nb-icon
            class="ms-1"
            style="color: #929bb3; width: 15px"
            nbTooltip="What sampling temperature to use, between 0 and 2. Higher values like 0.8 will make the output more random, while lower values like 0.2 will make it more focused and deterministic."
            nbTooltipPlacement="bottom"
            nbTooltipStatus="basic"
            icon="question-mark-circle-outline"
          >
          </nb-icon
        ></label>
        <div class="d-flex">
          <textarea
            type="text"
            nbInput
            maxlength="3"
            fullWidth
            fieldSize="small"
            [(ngModel)]="data.temperature"
            (input)="handleMaxValueTemperature($event)"
            style="height: 32.4px; resize: none"
            onlyDoubleNumber
          ></textarea>
        </div>
      </div>
    </div>
    <div class="mb-2" style="display: flex; gap: 0.5rem">
      <form [formGroup]="formGroup" style="flex: 1">
        <div class="form-label d-flex" style="align-items: center">
          <label class="label required"> Input </label>
          <nb-icon
            class="ms-1"
            style="color: #929bb3; width: 15px"
            nbTooltip="Information you want to be added to the prompt."
            nbTooltipPlacement="bottom"
            nbTooltipStatus="basic"
            nbTooltipTrigger="hover"
            icon="question-mark-circle-outline"
          >
          </nb-icon>
        </div>
        <textarea
          formControlName="text"
          type="text"
          id="input-text"
          nbInput
          fullWidth
          [(ngModel)]="data.text"
          (ngModelChange)="onChange($event)"
          placeholder="Input"
          fieldSize="small"
          [mention]="attributes"
          style="height: 32.4px; max-height: 150px; min-height: 32.4px"
          [mentionConfig]="mentionConfig"
          [status]="
            formGroup.controls['text'].invalid &&
            (formGroup.controls['text'].dirty ||
              formGroup.controls['text'].touched)
              ? 'danger'
              : 'basic'
          "
        ></textarea>
        <ng-container
          *ngIf="
            formGroup.controls['text'].invalid &&
            (formGroup.controls['text'].dirty ||
              formGroup.controls['text'].touched)
          "
        >
          <p
            class="caption status-danger"
            style="margin-bottom: 0"
            *ngIf="
              formGroup.controls['text'].hasError('required') ||
              formGroup.controls['text'].hasError('whitespace')
            "
          >
            Input is required.
          </p>
        </ng-container>
      </form>
      <div style="margin-top: 1.7rem">
        <button
          [nbSpinner]="isLoading"
          [disabled]="isLoading"
          nbSpinnerStatus="primary"
          nbButton
          status="primary"
          [disabled]="!data.text || data.text?.trim() == '' || isLoading"
          size="small"
          title="Check Output"
          (click)="makeTest()"
        >
          Check
        </button>
      </div>
    </div>

    <div class="mb-2">
      <label class="form-label label d-flex" style="align-items: center">
        <span>Prompt</span>
        <nb-icon
          class="ms-1"
          style="color: #929bb3; width: 15px"
          nbTooltip="Type the prompt words of the LLM model to get the corresponding answer. Example: Today is {current_day}, if the user's input has a date, please return only the date in 'MM/DD/YYYY' format for response and no text, otherwise NoResponse is returned."
          nbTooltipPlacement="bottom"
          nbTooltipStatus="basic"
          nbTooltipTrigger="hover"
          icon="question-mark-circle-outline"
        >
        </nb-icon
      ></label>
      <textarea
        type="text"
        id="input-text-prompt"
        nbInput
        fullWidth
        [(ngModel)]="data.prompt"
        (ngModelChange)="onChangePrompt($event)"
        placeholder="Prompt"
        fieldSize="small"
        [mention]="attributes"
        style="
          height: 150px;
          max-height: 300px;
          min-height: 150px;
          padding-top: 0.5rem;
        "
        [mentionConfig]="mentionConfig"
      ></textarea>
    </div>
    <div class="mb-2" *ngIf="outputReview">
      <label class="form-label label d-flex" style="align-items: center">
        Output Preview
      </label>
      <textarea
        type="text"
        nbInput
        [(ngModel)]="outputReview"
        fullWidth
        placeholder="Output Preview"
        fieldSize="small"
        style="
          height: 75px;
          min-height: 75px;
          max-height: 250px;
          padding-top: 0.5rem;
        "
        readOnly
      ></textarea>
    </div>
    <div class="row">
      <div class="mb-2">
        <div class="form-label" style="display: flex; align-items: center">
          <label class="label d-flex required">Output</label>
          <nb-icon
            class="ms-1"
            style="color: #929bb3; width: 15px"
            nbTooltip="Assign the output to the variable."
            nbTooltipPlacement="bottom"
            nbTooltipStatus="basic"
            nbTooltipTrigger="hover"
            icon="question-mark-circle-outline"
          >
          </nb-icon>
        </div>

        <ngx-input-value-static
          placeHolder="Select Output"
          selectAttribute="true"
          [propertyInput]="data.attribute"
          (propertyEmit)="getAttribute($event)"
          [errorMessage]="errorMessage"
        >
        </ngx-input-value-static>
      </div>
    </div>
  </div>
  <footer-action
    (handleCancelAction)="handleCancelAction()"
    (handleSaveAction)="handleSaveAction()"
  ></footer-action>
</div>
