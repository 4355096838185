import { AfterViewInit, Component, OnInit, ViewChild } from "@angular/core";
import { AnswerDetect } from "../../../../@core/model/answer-detect";
import { MxCell } from "../../../../@core/model/mxgraph";
import {
  NbDialogService,
  NbPopoverDirective,
  NbWindowRef,
} from "@nebular/theme";
import { SimpleAudioPlayerComponent } from "../../../../shared/simple-audio-player/simple-audio-player.component";
import { Subject, Subscription } from "rxjs";
import { SimpleAudioPlayerModalComponent } from "../../../../shared/simple-audio-player-modal/simple-audio-player-modal.component";
import { TextToSpeechComponent } from "../../../../shared/text-to-speech/text-to-speech.component";
import { AudioClient } from "../../../../System-api";
import { Forms } from "../../../../@core/model/forms";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { DataService } from "../../../../@core/utils/data.service";

@Component({
  selector: "ngx-form-action",
  templateUrl: "./forms.component.html",
  styleUrls: ["./forms.component.scss"],
})
export class FormsComponent implements OnInit, AfterViewInit {
  @ViewChild(NbPopoverDirective) popover: NbPopoverDirective;
  cell: MxCell;
  data: Forms = new Forms();
  cancelAction = false;
  options: object[] = [
    { id: 1, name: "Custom" },
    { id: 2, name: "URL" },
  ];
  icon = "plus-outline";
  questionArray = [
    { name: "Short Answer", icon: "edit-2-outline", type: "short" },
    { name: "Long Answer", icon: "menu-2-outline", type: "long" },
    {
      name: "Multiple Choice",
      icon: "radio-button-on",
      type: "radio",
    },
    { name: "Checkbox", icon: "checkmark-square-2-outline", type: "checkbox" },
    {
      name: "Drop-down Menu",
      icon: "arrow-down",
      type: "select",
    },
  ];
  questions = [];
  isEditQuestion: boolean = false;
  isAddQuestion: boolean = false;
  current = -1;
  listNation = [];
  languagesSetting: any=[];
  dataConvert: any=[];
  constructor(
    protected windowRef: NbWindowRef,
    private dialogService: NbDialogService,
    private audioClient: AudioClient,
    private dataService: DataService
  ) {}
  handleOnlyIntegerNumber(event) {
    if (event.target?.value != null) {
      var number = event.target.value.toString();
      if (number.match("^[0-9]*$")) {
        this.questions[this.current][event.target.attributes.id.value] = number;
      }
    }
  }
  addToObject(question) {
    var newQuestion = {
      name: question.name,
      label: "No label",
      icon: question.icon,
      type: question.type,
      placeholder: "",
      answerVariable: "",
      intentVariable: "",
      inputType: "text",
      minLength: 0,
      maxLength: 99999,
      validationErrorMessage: "Please enter a valid value",
      grammar: [],
      menuOption: [],
      answerOption: [{ name: "Option 1", value: "1" }],
      isRequired: false,
    };
    this.questions.push(newQuestion);
  }
  removeMenuOption(z) {
    if (z != 0) {
      this.questions[this.current].menuOption.splice(z, 1);
    } else {
      this.questions[this.current].menuOption.shift();
    }
  }
  getAnswerAction() {
    this.listNation = JSON.parse(this.dataService.CallSetting.languageSetting);
    this.questions.forEach((item, index) => {
      if (item.menuOption && item.menuOption.length > 0) {
        this.listNation.forEach((obj) => {
          for (let i = 0; i < item.menuOption.length; i++) {
            let check = 0;
            for (let j = 0; j < item.menuOption[i].value.length; j++) {
              const languageSetting = obj.language.split("-");
              const languageTTS =
                item.menuOption[i].value[j].language.split("-");
              if (languageSetting[0] == languageTTS[0]) {
                item.menuOption[i].value[j].name = obj.name;
                item.menuOption[i].value[j].language = obj.language;
                check = 1;
              }
            }
            if (check == 0) {
              item.menuOption[i].value.push({
                name: obj.name,
                language: obj.language,
                value: "",
              });
            }
          }
        });
        for (let k = 0; k < item.menuOption.length; k++) {
          for (let z = 0; z < item.menuOption[k].value.length; z++) {
            let check = 0;
            this.listNation.forEach((element) => {
              const languageSetting =
                item.menuOption[k].value[z].language.split("-");
              const languageTTS = element.language.split("-");
              if (languageSetting[0] == languageTTS[0]) {
                check = 1;
              }
            });
            if (check == 0) {
              if (z != 0) {
                item.menuOption[k].value.splice(z, 1);
                z--;
              } else {
                item.menuOption[k].value.shift();
                z--;
              }
            }
          }
        }
        this.questions[index].menuOption = item.menuOption;
      }
    });
  }

  removeOption(i) {
    if (this.questions[this.current].answerOption.length > 1) {
      this.questions[this.current].answerOption.splice(i, 1);
    }
  }
  dropOptions(event: CdkDragDrop<any[]>) {
    moveItemInArray(
      this.questions[this.current].answerOption,
      event.previousIndex,
      event.currentIndex
    );
  }
  dropMenuOptions(event: CdkDragDrop<any[]>) {
    moveItemInArray(
      this.questions[this.current].menuOption,
      event.previousIndex,
      event.currentIndex
    );
  }
  addNewOption() {
    this.questions[this.current].answerOption.push({
      name: "Option " + (this.questions[this.current].answerOption.length + 1),
      value: "" + (this.questions[this.current].answerOption.length + 1),
    });
  }
  getAttribute(event) {
    this.questions[this.current].answerVariable = event;
  }
  getAttributeIntent(event) {
    this.questions[this.current].intentVariable = event;
  }
  editQuestion(question) {
    for (let key in this.questions[question]) {
      if (typeof this.questions[question][key] === "string") {
        this.questions[question][key] = this.questions[question][key].trim();
      }
    }
    this.filterEmptyOption(question);
    if (question === this.current) {
      this.popover?.hide();
      this.current = -1;
    } else {
      this.popover?.hide();
      this.current = question;
      this.popover?.show();
    }
  }
  deleteInList(i) {
    this.questions.splice(i, 1);
    if (i > 0) {
      this.current = i - 1;
    } else {
      this.current = 0;
    }
  }
  ngAfterViewInit(): void {}
  showModal() {
    const overlayPane = document.querySelector(".cdk-overlay-container");
    if (overlayPane) {
      overlayPane.classList.remove("custom-form-question");
    }
    this.popover?.hide();
    this.current = -1;
  }
  closeModal() {
    this.filterEmptyOption(this.current);
    const overlayPane = document.querySelector(".cdk-overlay-container");
    if (overlayPane) {
      overlayPane.classList.remove("custom-form-question");
    }
    this.popover?.hide();
    this.current = -1;
  }
  openTemplateRef2() {
    const idTemplate = document.getElementById("box-questions");
    if (idTemplate) {
      const overlay = idTemplate.closest(".cdk-overlay-pane");
      overlay.classList.add("custom-form-question");
    }
  }
  filterEmptyOption(index) {
    if (
      this.questions[index].name == "Drop-down Menu" ||
      this.questions[index].name == "Checkbox" ||
      this.questions[index].name == "Multiple Choice"
    ) {
      let filterQuestions = this.questions[index].answerOption;
      this.questions[index].answerOption.forEach((q) => {
        if (q.name == "" || q.value == "") {
          filterQuestions = filterQuestions.filter((element) => element != q);
        }
      });
      if (filterQuestions.length == 0) {
        filterQuestions = [{ name: "Option 1", value: "1" }];
      }
      this.questions[index].answerOption = filterQuestions;
    }
  }
  addNewAnswer() {
    let newLanguage = { name: "", value: [] };
    this.listNation.forEach((nation) => {
      newLanguage.value.push({
        name: nation.name,
        language: nation.language,
        value: "",
      });
    });

    if (!this.questions[this.current].menuOption) {
      this.questions[this.current].menuOption = [];
    }
    this.questions[this.current].menuOption.push(newLanguage);
  }
  ngOnInit() {
    for (let [key, value] of Object.entries(this.data)) {
      this.data[key] = this.cell.getAttribute(key);
    }
    this.questions = JSON.parse(this.data.questions);
    this.getAnswerAction();
    this.handleType(Number(this.data.option));
    if (this.data.markRequiredFields) {
      this.data.markRequiredFields =
        this.data.markRequiredFields?.toString() === "true";
    } else {
      this.data.markRequiredFields = false;
    }
    if (this.data.skip) {
      this.data.skip = this.data.skip?.toString() === "true";
    } else {
      this.data.skip = false;
    }
    this.languagesSetting = JSON.parse(this.dataService.CallSetting.languageSetting);
    this.convertFormatField(false);
    this.windowRef.onClose.subscribe(() => this.submit());
  }
  allowMarkRequiredFields($event) {
    this.data.markRequiredFields = $event;
  }
  allowSkip($event) {
    this.data.skip = $event;
  }

  handleType(value: number) {
    this.data.option = value;
  }
  handleAnswerOption(answer) {
    try {
      let data = answer;
      if (data && data?.length > 0) {
        let finalData = data.filter((x) => x.name?.trim() != "");
        if (finalData.length > 0) {
          const trimmedData = finalData.map((item) => {
            return {
              ...item,
              name: item.name.trim(),
            };
          });
          trimmedData.forEach((item, index) => {
            item.value.forEach((value, indexValue) => {
              trimmedData[index].value[indexValue].value =
                trimmedData[index].value[indexValue].value.trim();
            });
          });
          return trimmedData;
        } else {
          return answer;
        }
      } else {
        return answer;
      }
    } catch (error) {
      return answer;
    }
  }
  onNameChange(event, index: number) {
    this.questions[this.current].answerOption[index].name =
      event.target.value.trim();
  }
  onValueChange(event, index: number) {
    this.questions[this.current].answerOption[index].value =
      event.target.value.trim();
  }
  checkDefaultLanguageUser(data) {
    let check = false;
    let defaultLanguage = (this.languagesSetting.find(lg => lg.defaultUser == true)).name;
    if (data.name === defaultLanguage) {
      check = true;
    }
    return check;
  }
  handleChange(event, i, field) {
    if (field == 'title') {
      this.dataConvert[i].textTitle = event;
    } else {
      this.dataConvert[i].textDescription = event;
    }
  }
  convertFormatField(isSubmit) {
    let title = [];
    let description = [];
    try {
      title = JSON.parse(this.data.title);
      description = JSON.parse(this.data.description);
      if (!isSubmit) { 
        this.languagesSetting.forEach(lg => {
          const index = title.findIndex(t => lg.name === t.name);
          const textTitle = index !== -1 ? title[index].text : '';
          const textDescription = index !== -1 ? description[index].text : '';
          this.dataConvert.push({ name: lg.name, language: lg.language, defaultUser: lg.defaultUser, textTitle, textDescription });
        });
      } else {
        title = [];
        description = [];
        this.dataConvert.forEach(l => {
          title.push({name: l.name, language: l.language, defaultUser: l.defaultUser, text: l.textTitle.trim()});
          description.push({name: l.name, language: l.language, defaultUser: l.defaultUser, text: l.textDescription.trim()});
        })
      }
    } catch(e){
      this.languagesSetting.forEach(lg => {
        this.dataConvert.push({name: lg.name, language: lg.language, defaultUser: lg.defaultUser, textTitle: this.data.title, textDescription: this.data.description});
      })
      this.dataConvert.forEach(l => {
        title.push({name: l.name, language: l.language, defaultUser: l.defaultUser, text: l.textTitle});
        description.push({name: l.name, language: l.language, defaultUser: l.defaultUser, text: l.textDescription});
      })
    }
    this.data.title = JSON.stringify(title).toString();
    this.data.description = JSON.stringify(description).toString();
  }
  submit() {
    this.convertFormatField(true);
    this.questions.forEach((item, index) => {
      if (this.questions[index]?.menuOption) {
        this.questions[index].menuOption = this.handleAnswerOption(
          this.questions[index].menuOption
        );
      }
    });
    this.data.questions = JSON.stringify(this.questions);
    if (!this.cancelAction) {
      for (let [key, value] of Object.entries(this.data)) {
        if (typeof this.data[key] == "string") {
          value = value?.trim();
        }
        this.cell.setAttribute(key, value);
      }
    }
  }
  handleSaveAction() {
    this.windowRef.close();
  }
  handleCancelAction() {
    this.cancelAction = true;
    this.windowRef.close(true);
  }
  drop(event: CdkDragDrop<any[]>) {
    this.popover?.hide();
    this.current = -1;
    moveItemInArray(this.questions, event.previousIndex, event.currentIndex);
  }
}
