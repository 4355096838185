import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  OnDestroy,
  OnInit,
  Optional,
  ViewChild,
} from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import {
  CheckNameExistQuery,
  CreateCustomFunctionCommand,
  CustomFunctionClient,
  CustomFunctionDto,
  ExportFunctionCommand,
  MakeTestCustomFunctionCommand,
  UpdateCustomFunctionCommand,
} from "../../../../../System-api";
import {
  RxFormBuilder,
  RxwebValidators,
} from "@rxweb/reactive-form-validators";
import {
  ValidInput,
  noWhitespaceValidator,
} from "../../../../../@core/utils/helpers";
import { DataService } from "../../../../../@core/utils/data.service";
import {
  NbDialogRef,
  NbDialogService,
  NbGlobalPhysicalPosition,
  NbToastrService,
  NbWindowRef,
  NbWindowService,
} from "@nebular/theme";
import { ActivatedRoute, Router } from "@angular/router";
import { TestFunctionComponent } from "../test-function/test-function.component";
import { EventBusService } from "../../../../../@core/utils/eventbus.service";
import { EventData } from "../../../../../shared/model/eventdata";
import * as ace from "ace-builds";
import "ace-builds/src-noconflict/ext-language_tools";
import { ResizeEvent } from "angular-resizable-element";
import { TestParamComponent } from "../advanced-custom-function/test-param/test-param.component";
@Component({
  selector: "update-function",
  templateUrl: "./update-function.component.html",
  styleUrls: ["./update-function.component.scss"],
})
export class UpdateFunctionComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  @ViewChild("editor") private editor: ElementRef<HTMLElement>;
  @ViewChild("inputName")
  inputName: ElementRef<any>;
  functionForm: UntypedFormGroup;
  customFunction: CustomFunctionDto = new CustomFunctionDto();
  updateFunction: any;
  isCreate: boolean = true;
  windowRef: NbWindowRef;
  isAction: boolean = false;
  isLoading: boolean = false;
  isCodeLoading: boolean = false;
  isExist: boolean = false;
  timeout: any = null;
  isFunctionLoading: boolean = false;
  isSaveLoading: boolean = false;
  currentFunctionName: string = "";
  expand: boolean = false;
  errorForm: boolean = false;
  isLoadingExport: boolean = false;
  onAdd = new EventEmitter();
  runFunctionResponse: any = {};
  isLoadingRun: boolean = false;
  isOpenTerminal: boolean = false;
  languages = [
    {
      key: "DEFAULT",
      language: "PHP AGI",
      code: `<?php\n/**
    * You can use $pagiClient variable to code PAGI like:
    * $pagiClient->sayNumber(123);
    * Warning: Please don't use print or echo.
    * You can use variable $attribute to set optional values like:
    * $attribute['key'] = $value;
    * You can use variable use tracelog function like:
    * Helper::InsertTraceLog($log, $logLevel);
    * $logLevel is info(default), debug or error.
    */\n/* Code here */\n`,
    },
    {
      key: "PHP",
      language: "PHP",
      code: `<?php\n/**
    * Warning: Please don't use print or echo.
    * You can use variable $attribute to set optional values like:
    * $attribute['key'] = $value;
    * You can use variable use tracelog function like:
    * Helper::InsertTraceLog($log, $logLevel);
    * $logLevel is info(default), debug or error.
    * You can reuse another custom function like:
    * CustomFunctions::anotherFunction(param1, param2, ...);
    */\n/* Code here */\n`,
    },
    {
      key: "JS",
      language: "JAVASCRIPT",
      code: `/**
    * You can use Helper to set or get optional values like:
    * Helper.setAttribute(key, value);
    * Helper.getAttribute(key);
    * You can use variable use tracelog function like:
    * Helper.insertLog(log);
    * You can reuse another custom function like:
    * CustomFunctions.anotherFunction(param1, param2, ...);
    */
    \n/* Code here */\n`,
    },
  ];
  style: object = {};
  constructor(
    private formBuilder: RxFormBuilder,
    private dataService: DataService,
    private customFunctionClient: CustomFunctionClient,
    private toastrService: NbToastrService,
    private router: Router,
    private windowService: NbWindowService,
    private eventBusService: EventBusService,
    private activatedRoute: ActivatedRoute,
    private dialogService: NbDialogService,
    @Optional() private ref: NbDialogRef<UpdateFunctionComponent>
  ) {
    this.isCodeLoading = true;
    this.createForm();
    this.eventBusService.on("clickTestFunction", (isTrue: boolean) => {
      if (isTrue) {
        this.close();
      }
    });
  }
  closeTerminal() {
    this.isOpenTerminal = false;
    this.runFunctionResponse = {};
  }
  ngAfterViewInit() {
    ace.config.set(
      "basePath",
      "https://unpkg.com/ace-builds@1.4.12/src-noconflict"
    );

    const aceEditor = ace.edit(this.editor.nativeElement);
    aceEditor.getSession().setValue(this.getCode("DEFAULT"));
    aceEditor.setTheme("ace/theme/xcode");
    aceEditor.session.setMode("ace/mode/php");
    aceEditor.getSession().setUseWorker(true);
    aceEditor.setOptions({
      wrap: true,
      highlightActiveLine: true,
      enableBasicAutocompletion: true,
      enableSnippets: true,
      enableLiveAutocompletion: true,
    });
    aceEditor.on("change", () => {
      this.customFunction.code = aceEditor.getValue();
      this.functionForm.controls["code"].setValue(aceEditor.getValue());
      this.setCodeData(this.customFunction.language, aceEditor.getValue());
    });
    aceEditor.commands.addCommand({
      name: "save",
      bindKey: { win: "Ctrl-S", mac: "Cmd-S" },
      exec: (editor) => {
        if (this.functionForm.valid) {
          this.onSubmit();
        } else {
          this.toastrService.show(
            "Save function unsuccessfully",
            `Notification`,
            {
              position: NbGlobalPhysicalPosition.BOTTOM_LEFT,
              status: "danger",
            }
          );
        }
      },
    });
  }
  setCodeData(key, code) {
    if (!key) {
      key = "DEFAULT";
    }
    const index = this.languages.findIndex((item) => item.key === key);
    if (index >= 0) {
      this.languages[index].code = code;
    }
  }
  fullScreen() {
    this.expand = !this.expand;
    const functionName = document.querySelector(
      ".function-name"
    ) as HTMLElement;
    const functionCode = document.querySelector(
      ".function-code"
    ) as HTMLElement;
    functionName.classList.toggle("hidden");
    functionCode.classList.toggle("col-md-12");
  }
  ngOnDestroy(): void {
    this.dataService.CustomFunction = null;
  }

  ngOnInit(): void {
    this.customFunction.code = this.getCode("DEFAULT");
    this.customFunction.language = this.getLanguage("DEFAULT");
    this.functionForm.controls["code"].setValue(this.customFunction.code);
    this.getFunctionDto();
  }

  close() {
    this.windowRef.close();
  }

  createForm() {
    this.functionForm = this.formBuilder.group({
      name: [
        "",
        [
          RxwebValidators.required(),
          noWhitespaceValidator,
          RxwebValidators.maxLength({
            value: 50,
            message: "Maximum length is 50 characters.",
          }),
          RxwebValidators.alphaNumeric({
            message: "Function Name must contain only letters, numbers.",
          }),
        ],
      ],
      language: [""],
      code: ["", ValidInput.required],
    });
  }

  getFunctionDto() {
    this.activatedRoute.params.subscribe((params) => {
      if (params["id"]) {
        this.isCreate = false;
        this.updateFunction = new UpdateCustomFunctionCommand();
        this.customFunctionClient.get(params["id"]).subscribe(
          (item) => {
            if (item) {
              this.dataService.CustomFunction = item;
              this.customFunction = item;
              //this.functionForm.controls["name"].disable();
              if (this.customFunction && this.customFunction.name) {
                this.currentFunctionName = this.customFunction?.name;
              }
              if (this.customFunction && !this.customFunction.language) {
                this.customFunction.language = this.getLanguage("DEFAULT");
              }
              const aceEditor = ace.edit(this.editor.nativeElement);
              if (
                this.customFunction.language == this.getLanguage("PHP") ||
                this.customFunction.language == this.getLanguage("DEFAULT")
              ) {
                aceEditor.session.setMode("ace/mode/php");
              } else if (
                this.customFunction.language == this.getLanguage("JS")
              ) {
                aceEditor.session.setMode("ace/mode/javascript");
              }
              aceEditor.session.setValue(this.customFunction.code);
              this.setCodeData(
                this.customFunction.language,
                this.customFunction.code
              );
            } else {
              this.router.navigate([`portal/studio/functions/update`]);
            }
            this.isCodeLoading = false;
          },
          (error) => {
            this.isCodeLoading = false;
            this.router.navigate([`portal/studio/functions/update`]);
          }
        );
      } else if (this.dataService.CustomFunction != null) {
        this.updateFunction = new UpdateCustomFunctionCommand();
        this.customFunctionClient
          .get(this.dataService.CustomFunction.id)
          .subscribe(
            (item) => {
              this.isCodeLoading = false;
              this.customFunction = item;
              if (this.customFunction && this.customFunction.name) {
                this.currentFunctionName = this.customFunction?.name;
              }
              if (this.customFunction && !this.customFunction.language) {
                this.customFunction.language = this.getLanguage("DEFAULT");
              }
              const aceEditor = ace.edit(this.editor.nativeElement);
              if (
                this.customFunction.language == this.getLanguage("PHP") ||
                this.customFunction.language == this.getLanguage("DEFAULT")
              ) {
                aceEditor.session.setMode("ace/mode/php");
              } else if (
                this.customFunction.language == this.getLanguage("JS")
              ) {
                aceEditor.session.setMode("ace/mode/javascript");
              }
              aceEditor.session.setValue(this.customFunction.code);
              //this.functionForm.controls["name"].disable();
            },
            (error) => {
              this.isCodeLoading = false;
            }
          );
        this.isCreate = false;
      } else {
        this.isCodeLoading = false;
        this.updateFunction = new CreateCustomFunctionCommand();
        this.isCreate = true;
      }
    });
  }

  isEmpty(obj) {
    for (var prop in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, prop)) {
        return false;
      }
    }

    return true;
  }

  isEmptyObject(value) {
    if (value == null) {
      // null or undefined
      return false;
    }

    if (typeof value !== "object") {
      // boolean, number, string, function, etc.
      return false;
    }

    const proto = Object.getPrototypeOf(value);

    // consider `Object.create(null)`, commonly used as a safe map
    // before `Map` support, an empty object as well as `{}`
    if (proto !== null && proto !== Object.prototype) {
      return false;
    }

    return this.isEmpty(value);
  }

  selectLanguage(value) {
    if (value == "PHP") {
      this.customFunction.code = this.getCode("PHP");
      this.customFunction.language = this.getLanguage("PHP");
      const aceEditor = ace.edit(this.editor?.nativeElement);
      aceEditor.session.setValue(this.customFunction.code);
      aceEditor.session.setMode("ace/mode/php");
    } else if (value == "JS") {
      this.customFunction.code = this.getCode("JS");
      this.customFunction.language = this.getLanguage("JS");
      const aceEditor = ace.edit(this.editor.nativeElement);
      aceEditor.session.setValue(this.customFunction.code);
      aceEditor.session.setMode("ace/mode/javascript");
    } else if (value == "DEFAULT") {
      this.customFunction.code = this.getCode("DEFAULT");
      this.customFunction.language = this.getLanguage("DEFAULT");
      const aceEditor = ace.edit(this.editor?.nativeElement);
      aceEditor.session.setValue(this.customFunction.code);
      aceEditor.session.setMode("ace/mode/php");
    }
  }

  getCode(key: string): string {
    const language = this.languages.find((item) => item.key === key);
    return language ? language.code : "";
  }

  getLanguage(key: string): string {
    const language = this.languages.find((item) => item.key === key);
    return language ? language.key : "";
  }

  checkCodeChange(value) {
    this.isLoading = false;
  }

  checkFunctionName(value) {
    if (this.functionForm.controls["name"].valid) {
      const functionNamePattern = /^[a-zA-Z][a-zA-Z0-9]{0,49}$/;
      const valid = functionNamePattern.test(value);
      if (!valid) {
        this.functionForm.controls["name"].setErrors({
          functionInvalid: "Function Name is invalid.",
        });
        return;
      }
    }
  }

  handleFocus() {
    setTimeout(() => {
      this.inputName?.nativeElement?.focus();
    }, 0);
  }

  getParam($event) {
    this.customFunction.param = $event;
  }

  getError($event) {
    this.errorForm = $event;
  }

  back() {
    this.router.navigate([`portal/studio/functions`]);
  }

  dismiss() {
    if (this.isAction == true) {
      this.onAdd.emit(true);
    }
    this.ref.close();
  }

  setValidationErrors(errorData) {
    var errorData = JSON.parse(errorData);
    if (errorData) {
      for (const [key, value] of Object.entries(errorData)) {
        const fieldName = key.toLowerCase(); // Ensure case matches form control names
        const errorMessage = value[0];
        if (this.functionForm.get(fieldName)) {
          const control = this.functionForm.get(fieldName);
          control.setErrors({ serverError: errorMessage });
          control.markAsDirty();
        }
      }
    }
  }

  onSubmit() {
    if (this.functionForm.valid) {
      this.eventBusService.emit(new EventData("clickSubmitFunction", true));
      this.updateFunction.name = this.customFunction.name;
      this.updateFunction.code = this.customFunction.code;
      if (this.customFunction.language == this.getLanguage("DEFAULT")) {
        this.updateFunction.language = null;
      } else {
        this.updateFunction.language = this.customFunction.language;
      }
      this.updateFunction.param = this.customFunction.param;
    }
    if (this.updateFunction instanceof UpdateCustomFunctionCommand) {
      this.updateFunction.id = this.dataService.CustomFunction.id;
      this.isLoading = true;
      this.isSaveLoading = true;
      this.customFunctionClient.update(this.updateFunction).subscribe({
        next: (rs) => {
          var saveFunctionResult = JSON.parse(rs);
          rs = saveFunctionResult?.result;
          this.isLoading = false;
          this.isSaveLoading = false;
          this.showToast(rs);
        },
        error: (error) => {
          if (error.status == 422) {
            this.setValidationErrors(error.response);
          }
          this.showToast(false);
          this.isLoading = false;
          this.isSaveLoading = false;
        },
      });
    } else {
      this.isLoading = true;
      this.isSaveLoading = true;
      this.customFunctionClient.create(this.updateFunction).subscribe({
        next: (func) => {
          this.isLoading = false;
          this.isSaveLoading = false;
          this.showToast(func != null ? true : false);
          if (this.isAction == true) {
            this.onAdd.emit(func);
          } else {
            if (func != null) {
              this.router.navigate([
                "/portal/studio/functions/update/" + func.id,
              ]);
            }
          }
        },
        error: (error) => {
          if (error.status == 422) {
            this.setValidationErrors(error.response);
          }
          this.showToast(false);
          this.isLoading = false;
          this.isSaveLoading = false;
        },
      });
    }
  }

  exportFunction() {
    this.isLoadingExport = true;
    this.customFunctionClient
      .export(
        new ExportFunctionCommand({
          functionId: this.customFunction.id,
        })
      )
      .subscribe(
        (rs) => {
          this.isLoadingExport = false;
          let file = this.dataURLtoFile("data:application/zip;base64," + rs);
          this.downloadData(this.customFunction.name + ".zip", file);
        },
        (error) => {
          this.isLoadingExport = false;
        }
      );
  }

  dataURLtoFile(dataurl) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], "", { type: mime });
  }

  downloadData(filenameForDownload: string, data: any) {
    var textUrl = URL.createObjectURL(data);
    var element = document.createElement("a");
    element.setAttribute("href", textUrl);
    element.setAttribute("download", filenameForDownload);
    element.style.display = "none";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }

  runFunction(testData) {
    this.isLoadingRun = true;
    this.customFunctionClient.run(testData).subscribe({
      next: (result) => {
        this.isLoadingRun = false;
        if (result) {
          this.runFunctionResponse = JSON.parse(result);
          this.runFunctionResponse.result = this.runFunctionResponse?.result;
          this.dialogService.open(TestFunctionComponent, {
            autoFocus: false,
            context: { runFunctionResponse: this.runFunctionResponse },
          });
        } else {
          this.toastrService.show(
            "Run function unsuccessfully",
            `Notification`,
            {
              position: NbGlobalPhysicalPosition.BOTTOM_LEFT,
              status: "danger",
            }
          );
        }
      },
      error: () => {
        this.toastrService.show("Run function unsuccessfully", `Notification`, {
          position: NbGlobalPhysicalPosition.BOTTOM_LEFT,
          status: "danger",
        });
        this.isLoadingRun = false;
      },
    });
  }
  run() {
    var testData = new MakeTestCustomFunctionCommand();
    testData.functionId = this.customFunction.id;
    testData.attributes = {};
    testData.params = {};
    if (this.customFunction.param && this.customFunction.param != "") {
      this.dialogService
        .open(TestParamComponent, {
          autoFocus: false,
          context: {
            params: this.customFunction.param,
          },
        })
        .onClose.subscribe((data) => {
          if (data) {
            testData.params = data;
            this.runFunction(testData);
          }
        });
    } else {
      this.runFunction(testData);
    }
  }

  showToast(result) {
    if (result) {
      this.toastrService.show(
        "Save custom function successfully",
        `Notification`,
        {
          position: NbGlobalPhysicalPosition.BOTTOM_LEFT,
          status: "success",
        }
      );
    } else {
      this.toastrService.show(
        "Save custom function unsuccessfully",
        `Notification`,
        {
          position: NbGlobalPhysicalPosition.BOTTOM_LEFT,
          status: "danger",
        }
      );
    }
  }
}
