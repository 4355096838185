<audio
  (playing)="audioPlaying()"
  (ended)="audioEnded()"
  #testAudio
  [src]="dataAudio ? 'data:audio/mp3;base64,' + dataAudio : ''"
  autoplay
>
  <source />
</audio>
<div [formGroup]="parentForm">
  <div class="flex-col">
    <div>
      <div class="flex-title">
        <div class="label-title-wrapper">
          <span class="title">Language Setting</span>
          <nb-icon
            class="hint-icon"
            nbTooltipTrigger="hover"
            nbTooltip="Choose the languages you want to make available for your flow."
            nbTooltipPlacement="right"
            nbTooltipStatus="basic"
            icon="question-mark-circle-outline"
          ></nb-icon>
        </div>
        <span class="line"></span>
      </div>
      <form
        [formGroup]="callSettingLanguageForm"
        novalidate
        (submit)="addNewLanguageSetting()"
        class="box-setting add-language"
      >
        <div style="width: 22rem">
          <label for="input-service" class="form-label label required"
            >Service</label
          >
          <input
            id="input-service"
            type="text"
            nbInput
            fullWidth
            formControlName="nationService"
            [matAutocomplete]="autoService"
            fieldSize="small"
            placeholder="Select Service"
            (ngModelChange)="onChangeService($event)"
          />
          <mat-autocomplete
            #autoService="matAutocomplete"
            [displayWith]="displayValue"
          >
            <mat-option
              style="background-color: white; border: 1px solid #edf1f7"
              *ngFor="let service of filteredService | async"
              [value]="service"
            >
              {{ service.name }}
            </mat-option>
          </mat-autocomplete>
        </div>
        <div style="width: 22rem">
          <label for="input-language" class="form-label label required"
            >Language</label
          >
          <input
            id="input-language"
            type="text"
            nbInput
            fullWidth
            fieldSize="small"
            formControlName="nationLanguage"
            [matAutocomplete]="autoLanguage"
            placeholder="Select Language"
            [attr.disabled]="isLoadingLanguage"
            (ngModelChange)="onChangeLanguage($event)"
          />
          <mat-autocomplete
            #autoLanguage="matAutocomplete"
            [displayWith]="displayValue"
          >
            <mat-option
              style="background-color: white; border: 1px solid #edf1f7"
              *ngFor="let language of filteredLanguage | async"
              [value]="language"
            >
              {{ language.name }}
            </mat-option>
          </mat-autocomplete>
        </div>
        <div style="width: 22rem">
          <label class="form-label label required" for="input-voice"
            >Voice</label
          >
          <input
            id="input-voice"
            type="text"
            nbInput
            fullWidth
            formControlName="nationVoice"
            [matAutocomplete]="autoVoiceId"
            fieldSize="small"
            placeholder="Select Voice"
            [attr.disabled]="isLoadingVoice"
            (ngModelChange)="onChangeVoice($event)"
          />
          <mat-autocomplete
            #autoVoiceId="matAutocomplete"
            [displayWith]="displayValue"
          >
            <mat-option
              style="background-color: white; border: 1px solid #edf1f7"
              *ngFor="let voice of filteredVoice | async"
              [value]="voice"
            >
              {{ voice.name }}
            </mat-option>
          </mat-autocomplete>
        </div>
        <div>
          <button
            [disabled]="!callSettingLanguageForm.valid"
            type="submit"
            size="small"
            nbButton
            status="primary"
          >
            Add
          </button>
        </div>
      </form>
      <nb-list style="max-height: 250px; min-height: 40px">
        <nb-list-item
          *ngFor="let languageSetting of listLanguageSetting; let i = index"
        >
          <div class="nation-item">
            <div class="nation-item-name" style="width: 30%">
              <span style="font-size: 13px">{{ languageSetting.name }}</span>
              <span
                class="tag-item-language"
                style="
                  margin-left: 0.2rem;
                  background-color: #3367d6;
                  color: white;
                "
                *ngIf="languageSetting.defaultUser"
                >default</span
              >
            </div>
            <div class="tag-content" style="width: 50%; font-size: 13px">
              <span
                >{{ languageSetting.service == "0" ? "Primas | " : ""
                }}{{ languageSetting.service == "1" ? "Soundhound | " : ""
                }}{{ languageSetting.service == "4" ? "Azure | " : ""
                }}{{ languageSetting.service == "2" ? "AmazonPolly | " : ""
                }}{{ languageSetting.service == "3" ? "Google | " : ""
                }}{{ languageSetting.voiceId }}
                {{
                  languageSetting.gender ? "| " + languageSetting.gender : ""
                }}</span
              >
            </div>
            <div class="tag-action" style="width: 20%">
              <nb-icon
                [icon]="
                  languageSetting.defaultUser == true
                    ? 'checkmark-circle-2'
                    : 'checkmark-circle-2-outline'
                "
                [title]="
                  languageSetting.defaultUser == true
                    ? 'Default language'
                    : 'Set as default language'
                "
                (click)="setDefaultLanguage(i)"
                style="font-size: 18px; color: #3367d6; cursor: pointer"
              ></nb-icon>
              <nb-icon
                icon="edit-outline"
                (click)="handleEditLanguage(languageSetting)"
                style="font-size: 18px; color: #3367d6; cursor: pointer"
              ></nb-icon>
              <nb-icon
                icon="trash-2-outline"
                (click)="removeLanguage(i, languageSetting.name)"
                *ngIf="
                  !(
                    languageSetting.defaultUser ||
                    languageSetting.language.includes('en')
                  )
                "
                style="font-size: 18px; color: #ff4332; cursor: pointer"
              ></nb-icon>
            </div>
          </div>
        </nb-list-item>
      </nb-list>
    </div>
  </div>
  <div class="flex-col" style="margin-top: 1.5rem">
    <div>
      <div class="flex-title">
        <span class="title">Voice Testing</span>
        <span class="line"></span>
      </div>
      <form>
        <div class="box-setting" style="flex-wrap: nowrap; align-items: start">
          <div style="width: 22rem; margin-bottom: 0.5rem">
            <label for="languageTextt" class="form-label label">Language</label>
            <input
              id="languageTextt"
              [formControl]="languageText"
              type="text"
              nbInput
              fullWidth
              fieldSize="small"
              placeholder="Select Language"
              [nbAutocomplete]="autoNgModel"
            />
            <nb-autocomplete #autoNgModel>
              <nb-option
                *ngFor="let option of filteredLanguageTest$ | async"
                [value]="option.name"
                [ngClass]="{
                  'bg-active': option.name == languageText?.value
                }"
              >
                {{ option.name }}
              </nb-option>
            </nb-autocomplete>
          </div>
          <div style="width: 47.5rem; margin-bottom: 0.5rem">
            <label for="textPreview" class="form-label label"
              >Test Prompt</label
            >
            <textarea
              id="textPreview"
              [(ngModel)]="textPreview"
              type="text"
              nbInput
              fieldSize="small"
              id="text-preview"
              style="
                height: 32.4px;
                max-height: 150px;
                min-height: 32.4px;
                margin-bottom: -4px;
                resize: none;
              "
              fullWidth
              (ngModelChange)="handleAutoHeight($event)"
              placeholder="Test Prompt"
            ></textarea>
          </div>
        </div>
        <div class="voice-list-wrapper" style="padding-bottom: 1.4rem">
          <div
            style="
              width: 50%;
              padding-right: 0.5rem;
              border-right: 1px solid #f0f3f8;
            "
          >
            <label for="input-voice-list" class="form-label label"
              >Voice List</label
            >
            <nb-accordion>
              <nb-accordion-item
                [ngClass]="{ 'hidden-voice-list': service.voice.length <= 0 }"
                *ngFor="let service of listVoiceTest; let i = index"
              >
                <nb-accordion-item-header
                  [ngClass]="{ 'hidden-voice-list': service.voice.length <= 0 }"
                  style="display: flex; align-items: center; gap: 0.25rem"
                  ><span>{{ service.name }} </span>
                  <span *ngIf="service.voice.length <= 0">
                    (Not Support)</span
                  ></nb-accordion-item-header
                >
                <nb-accordion-item-body>
                  <nb-list>
                    <nb-list-item
                      *ngFor="let voice of service.voice; let j = index"
                    >
                      <div
                        class="nation-item"
                        style="
                          padding-right: 0.26rem;
                          justify-content: space-between;
                          gap: 0.5rem;
                        "
                      >
                        <div class="nation-item-name">
                          <button
                            style="margin-right: 0.5rem"
                            nbButton
                            size="tiny"
                            (click)="convertTTS(i, j)"
                            ghost
                          >
                            <nb-icon
                              [icon]="
                                i == currentService &&
                                j == currentVoice &&
                                isPlaying
                                  ? 'stop-circle'
                                  : 'play-circle'
                              "
                              status="primary"
                            ></nb-icon>
                          </button>

                          <span style="font-size: 12px">{{
                            voice.voiceId
                          }}</span>
                          <span style="font-size: 12px">
                            {{
                              voice.gender ? " (" + voice.gender + ")" : ""
                            }}</span
                          >
                        </div>
                        <div class="tag-action">
                          <button
                            type="button"
                            nbButton
                            size="tiny"
                            status="primary"
                            (click)="handleAddToListCompare(i, j)"
                            style="font-size: 8px; padding: 3px 6px"
                          >
                            Compare
                          </button>
                          <button
                            type="button"
                            nbButton
                            size="tiny"
                            status="primary"
                            outline
                            (click)="handleAddToList(i, j)"
                            style="font-size: 8px; padding: 3px 6px"
                          >
                            Use
                          </button>
                          <button
                            type="button"
                            nbButton
                            size="tiny"
                            status="primary"
                            ghost
                            (click)="downloadTTS(i, j, null)"
                          >
                            <nb-icon icon="download-outline"></nb-icon>
                          </button>
                        </div>
                      </div>
                    </nb-list-item>
                  </nb-list>
                </nb-accordion-item-body>
              </nb-accordion-item>
            </nb-accordion>
          </div>
          <div
            style="
              width: 50%;
              padding-left: 0.5rem;
              border-left: 1px solid #f0f3f8;
            "
          >
            <label for="input-service" class="form-label label"
              >Compare List</label
            >
            <nb-list>
              <nb-list-item
                class="compare-list"
                *ngFor="let languageSetting of listVoiceCompare; let i = index"
              >
                <div
                  class="nation-item"
                  style="
                    padding-right: 0.26rem;
                    justify-content: space-between;
                    gap: 0.5rem;
                  "
                >
                  <div class="nation-item-name">
                    <button
                      nbButton
                      size="tiny"
                      (click)="convertTTSCompare(i)"
                      ghost
                      style="margin-right: 0.5rem"
                    >
                      <nb-icon
                        [icon]="
                          i == currentVoiceCompare && isPlaying
                            ? 'stop-circle'
                            : 'play-circle'
                        "
                        status="primary"
                      ></nb-icon>
                    </button>
                    <span style="font-size: 13px">
                      {{ languageSetting.name }} |
                      {{ languageSetting.service == "0" ? "Primas | " : ""
                      }}{{
                        languageSetting.service == "1" ? "Soundhound | " : ""
                      }}{{ languageSetting.service == "4" ? "Azure | " : ""
                      }}{{
                        languageSetting.service == "2" ? "AmazonPolly | " : ""
                      }}{{ languageSetting.service == "3" ? "Google | " : "" }}
                      {{ languageSetting.voiceId }}
                      {{
                        languageSetting.gender
                          ? " - " + languageSetting.gender
                          : ""
                      }}</span
                    >
                  </div>
                  <div class="tag-action">
                    <button
                      type="button"
                      nbButton
                      size="tiny"
                      status="danger"
                      outline
                      (click)="deleteCompareVoice(i)"
                      style="font-size: 8px; padding: 3px 6px"
                    >
                      Delete
                    </button>
                    <button
                      type="button"
                      nbButton
                      size="tiny"
                      status="primary"
                      outline
                      (click)="handleAddCompareToList(i)"
                      style="font-size: 8px; padding: 3px 6px"
                    >
                      Use
                    </button>

                    <button
                      type="button"
                      nbButton
                      size="tiny"
                      status="primary"
                      ghost
                      (click)="downloadTTS(i, null, 'compare')"
                    >
                      <nb-icon icon="download-outline"></nb-icon>
                    </button>
                  </div>
                </div>
              </nb-list-item>
            </nb-list>
          </div>
        </div>
      </form>
      <!-- <nb-tabset>
        <nb-tab
          [tabTitle]="service.name"
          *ngFor="let service of listService"
          (changeTab)="yourFunc($event)"
        >
          <form>
            <div>
              <div style="width: 13rem; margin-bottom: 0.5rem">
                <label for="input-service" class="form-label label"
                  >Language</label
                >
                <input
                  type="text"
                  nbInput
                  fullWidth
                  fieldSize="small"
                  placeholder="Select a language"
                />
              </div>
            </div>
            <div>
              <label for="input-service" class="form-label label">Voice</label>
              <nb-list>
                <nb-list-item
                  *ngFor="
                    let languageSetting of listLanguageSetting;
                    let i = index
                  "
                >
                  <div class="nation-item">
                    <div class="nation-item-name" style="width: 80%">
                      <nb-icon
                        [icon]="
                          i == currentText && isPlaying
                            ? 'stop-circle-outline'
                            : 'play-circle-outline'
                        "
                        style="
                          font-size: 18px;
                          color: #3367d6;
                          cursor: pointer;
                          margin-right: 0.5rem;
                        "
                        (click)="convertTTS(i)"
                      ></nb-icon>
                      <span style="font-size: 13px">{{
                        languageSetting.voiceId
                      }}</span>
                    </div>
                    <div class="tag-action" style="width: 20%">
                      <nb-icon
                        icon="plus-circle-outline"
                        matTooltip="Add To Language Setting"
                        matTooltipPosition="left"
                        style="font-size: 18px; color: #3367d6; cursor: pointer"
                      ></nb-icon>
                    </div>
                  </div>
                </nb-list-item>
              </nb-list>
            </div>
          </form>
        </nb-tab>
      </nb-tabset> -->
      <!-- <form class="voice-test">
        <div style="margin-bottom: 1rem; margin-top: 1rem">
          <label class="form-label label" for="input-voice">Text</label>
          <textarea
            [(ngModel)]="textPreview"
            type="text"
            nbInput
            fieldSize="small"
            id="text-preview"
            style="
              height: 32.4px;
              max-height: 150px;
              min-height: 32.4px;
              margin-bottom: -4px;
              resize: none;
            "
            fullWidth
            (ngModelChange)="handleAutoHeight($event)"
            placeholder="Text"
          ></textarea>
        </div>
        <nb-list>
          <nb-list-item
            *ngFor="let languageSetting of listLanguageReview; let i = index"
          >
            <div class="nation-item">
              <div class="nation-item-name" style="width: 30%">
                <nb-icon
                  [icon]="
                    i == currentText && isPlaying
                      ? 'stop-circle-outline'
                      : 'play-circle-outline'
                  "
                  style="
                    font-size: 18px;
                    color: #3367d6;
                    cursor: pointer;
                    margin-right: 0.5rem;
                  "
                  (click)="convertTTS(i)"
                ></nb-icon>
                <span style="font-size: 13px">{{ languageSetting.name }}</span>
              </div>
              <div class="tag-content" style="width: 50%; font-size: 13px">
                <span
                  >{{ languageSetting.service == "0" ? "Primas | " : ""
                  }}{{ languageSetting.service == "1" ? "Soundhound | " : ""
                  }}{{ languageSetting.service == "4" ? "Azure | " : ""
                  }}{{ languageSetting.service == "2" ? "AmazonPolly | " : ""
                  }}{{ languageSetting.service == "3" ? "Google | " : ""
                  }}{{ languageSetting.voiceId }} |
                  {{ languageSetting.gender }}</span
                >
              </div>
              <div class="tag-action" style="width: 20%">
                <nb-icon
                  icon="arrow-upward-outline"
                  matTooltip="Add To Language Setting"
                  style="font-size: 18px; color: #3367d6; cursor: pointer"
                ></nb-icon>
                <nb-icon
                  icon="edit-outline"
                  style="font-size: 18px; color: #3367d6; cursor: pointer"
                ></nb-icon>
                <nb-icon
                  icon="trash-2-outline"
                  style="font-size: 18px; color: #ff4332; cursor: pointer"
                ></nb-icon>
              </div>
            </div>
          </nb-list-item>
        </nb-list>
      </form> -->
    </div>
  </div>
</div>
