import { Component, OnDestroy, OnInit } from "@angular/core";
import { NbDialogRef } from "@nebular/theme";
import { ResponseDefine } from "../../../../@core/model/response-define";
import { DataService } from "../../../../@core/utils/data.service";

@Component({
  selector: "ngx-condition-define",
  templateUrl: "./condition-define.component.html",
  styleUrls: ["./condition-define.component.scss"],
})
export class ConditionDefineComponent implements OnInit, OnDestroy {
  data: ResponseDefine = new ResponseDefine();
  listCondition;
  type = "";
  title = "Case Condition";
  isUpdate: boolean = false;
  updateData = null;
  attributes: any = this.dataService.ivrAttribute;
  ivrFunction: any = this.dataService.ivrFunction;
  mentionConfig = {
    items: this.attributes,
    triggerChar: "{",
    labelKey: "value",
    disableSort: true,
    mentionSelect: (item) => {
      return "{" + item.value + "}";
    },
  };
  constructor(
    private ref: NbDialogRef<ConditionDefineComponent>,
    private dataService: DataService
  ) {}
  ngOnDestroy(): void {
    this.ref.close(this.data);
  }

  ngOnInit() {
    if (
      this.type == "GetInput" ||
      this.type == "ChatGPT" ||
      this.type == "IntentRecognition"
    ) {
      this.title = "Check Intent";
    }
    if (this.type == "LLM") {
      this.title = "Check Output";
    }
    this.data.method = "Equal";
    this.listCondition = [
      "Equal",
      "Not equal",
      "Is less than",
      "Is less than or equal",
      "Is greater than",
      "Is greater than or equal",
      "Starts with",
      "Ends with",
      "Contains",
      "Empty",
      "Exist",
    ];
    if (this.isUpdate && this.updateData) {
      const firstColonIndex = this.updateData.indexOf(":");
      const firstPart = this.updateData.substring(0, firstColonIndex);
      const secondPart = this.updateData.substring(firstColonIndex + 1);
      this.data.method = firstPart;
      this.data.data = secondPart;
    }
  }

  drop($event) {
    this.data.data += "{" + $event.dataTransfer.getData("text").trim() + "}";
    $event.preventDefault();
  }

  dismiss() {
    this.ref.close(this.data);
  }
}
