<nb-card
  class="import-wrapper"
  [nbSpinner]="isLoading"
  nbSpinnerSize="giant"
  nbSpinnerStatus="primary"
>
  <nb-card-header>
    <div class="d-flex justify-content-between">
      Import FLow
      <button
        type="button"
        class="btn-close"
        (click)="dismiss()"
        aria-label="Close"
      ></button>
    </div>
  </nb-card-header>
  <nb-card-body style="padding: 0.5rem; overflow: hidden">
    <div class="tree-folder">
      <mat-tree #tree [dataSource]="dataSource" [treeControl]="treeControl">
        <!-- This is the tree node template for leaf nodes -->
        <mat-tree-node
          *matTreeNodeDef="let node"
          matTreeNodePadding
          matTreeNodePaddingIndent="10"
          (click)="handleShowFile(node)"
        >
          <button mat-icon-button disabled></button>
          <div class="folder-style">
            <nb-icon style="color: #3367d6" icon="file"></nb-icon>
            <span>{{ node.name }}</span>
            <nb-icon
              *ngIf="
                node.name.includes('.') &&
                node.isExist &&
                node.isOverride == false
              "
              [matTooltip]="node.name + ' existed'"
              matTooltipPosition="right"
              style="color: red; width: 1rem; height: 1rem"
              icon="alert-circle"
            ></nb-icon>
            <nb-icon
              *ngIf="
                node.name == 'attribute.txt' &&
                conflictValue.length > 0 &&
                !conflictOverrideAll
              "
              [matTooltip]="node.name + ' conflict'"
              matTooltipPosition="right"
              style="color: red; width: 1rem; height: 1rem"
              icon="alert-circle"
            ></nb-icon>
          </div>
        </mat-tree-node>
        <!-- This is the tree node template for expandable nodes -->
        <mat-tree-node
          *matTreeNodeDef="let node; when: hasChild"
          matTreeNodePadding
          matTreeNodePaddingIndent="10"
          (click)="handleShowFile(node)"
        >
          <button
            mat-icon-button
            matTreeNodeToggle
            [attr.aria-label]="'Toggle ' + node.name"
          >
            <nb-icon
              *ngIf="treeControl.isExpanded(node)"
              icon="arrow-ios-downward-outline"
            ></nb-icon>
            <nb-icon
              *ngIf="!treeControl.isExpanded(node)"
              icon="arrow-ios-forward-outline"
            ></nb-icon>
          </button>
          <div class="folder-style">
            <nb-icon style="color: #3367d6" icon="folder"></nb-icon>
            <span [matTooltip]="node.name" matTooltipPosition="right">{{
              node.name
            }}</span>
          </div>
        </mat-tree-node>
      </mat-tree>
    </div>
    <div class="file-area">
      <div
        class="file-row-css"
        [ngClass]="{
          'hidden-file':
            dataImportFilter.length == 1 &&
            dataImportFilter[0].name == 'attribute.txt' &&
            conflictValue.length > 0
        }"
        *ngFor="let item of dataImportFilter; let i = index"
      >
        <div class="file-css">
          <nb-icon
            style="color: #3367d6; width: 5rem; height: 5rem"
            icon="file"
          ></nb-icon>
          <div class="file-name">
            <span *ngIf="!item.edit">{{ item.name }}</span>
            <span
              style="color: red; font-size: 12px"
              *ngIf="item.isExist && !item.edit && !item.isOverride"
            >
              Data in {{ item.name }} is existed. It will be saved with a new
              name</span
            >
          </div>
        </div>
        <div *ngIf="item.isExist" style="margin-right: 1rem; user-select: none">
          <nb-checkbox
            [(ngModel)]="item.isOverride"
            (checkedChange)="handleOverride($event, i)"
            status="basic"
            >Override</nb-checkbox
          >
        </div>
      </div>
      <nb-card
        *ngIf="
          dataImportFilter.length == 1 &&
          dataImportFilter[0].name == 'attribute.txt' &&
          conflictValue.length > 0
        "
        style="margin-bottom: 0; width: 100%"
      >
        <nb-card-header>Conflict Variable</nb-card-header>
        <nb-card-body>
          <div class="conflict-space">
            <div
              class="conflict-item"
              style="font-weight: bold; font-size: 13px"
            >
              <span style="width: 16%">Name</span>
              <span style="width: 35%">Current Data</span>
              <span style="width: 35%">Import Data</span>
              <nb-checkbox style="opacity: 0; width: 14%" status="basic"
                >Override</nb-checkbox
              >
            </div>
            <div
              class="conflict-item"
              style="font-size: 13px"
              *ngFor="let value of conflictValue; let j = index"
            >
              <span style="width: 16%">{{ value.old.value }}</span>
              <span style="width: 35%">{{ value.old.content }}</span>
              <span style="width: 35%">{{ value.new.content }}</span>
              <nb-checkbox
                style="width: 14%"
                [(ngModel)]="value.isOverride"
                (checkedChange)="handleOverrideAttribute($event, j)"
                status="basic"
                >Override</nb-checkbox
              >
            </div>
          </div>
        </nb-card-body>
      </nb-card>
    </div>
  </nb-card-body>
  <nb-card-footer>
    <div class="d-flex" style="width: 100%; justify-content: space-between">
      <nb-checkbox
        [(ngModel)]="isOverrideAll"
        (checkedChange)="handleOverrideAll($event)"
        status="basic"
        >Override All</nb-checkbox
      >
      <button
        nbButton
        size="small"
        [disabled]="isLoading"
        (click)="onSubmit()"
        status="primary"
      >
        Import
      </button>
    </div>
  </nb-card-footer>
</nb-card>
