<form
  [formGroup]="datasetForm"
  novalidate
  (submit)="onSubmit()"
  style="width: 600px; min-height: 0"
>
  <nb-card>
    <nb-card-header>
      <div class="d-flex justify-content-between">
        Dataset
        <button type="button" nbButton ghost (click)="dismiss()">
          <nb-icon icon="close-outline"></nb-icon>
        </button>
      </div>
    </nb-card-header>
    <nb-card-body>
      <div class="dataset-wrap">
        <div class="row">
          <div class="col-md-6 mb-3">
            <label for="input-name" class="form-label label required"
              >Name</label
            >
            <div
              [nbSpinner]="isNameLoading"
              nbSpinnerSize="medium"
              nbSpinnerStatus="info"
            >
              <input
                #inputName
                type="text"
                formControlName="name"
                nbInput
                [(ngModel)]="command.name"
                (ngModelChange)="checkDatasetName($event)"
                [attr.disabled]="isNameLoading == false ? null : isNameLoading"
                fullWidth
                [status]="
                  datasetForm.controls['name'].invalid &&
                  (datasetForm.controls['name'].dirty ||
                    datasetForm.controls['name'].touched)
                    ? 'danger'
                    : 'basic'
                "
                id="input-name"
                fieldSize="small"
                placeholder="Name"
              />
            </div>
            <ng-container
              *ngIf="
                datasetForm.controls['name'].invalid &&
                (datasetForm.controls['name'].dirty ||
                  datasetForm.controls['name'].touched)
              "
            >
              <p
                class="caption status-danger"
                style="margin-bottom: 0"
                *ngIf="
                  datasetForm.controls['name'].hasError('required') ||
                  datasetForm.controls['name'].hasError('whitespace')
                "
              >
                Name is required.
              </p>
              <p
                class="caption status-danger"
                style="margin-bottom: 0"
                *ngIf="datasetForm.controls['name'].errors.isExist"
              >
                Name already exist.
              </p>
              <p
                class="caption status-danger"
                style="margin-bottom: 0"
                *ngIf="
                  datasetForm.controls['name'].errors.maxlength &&
                  !datasetForm.controls['name'].hasError('required') &&
                  !datasetForm.controls['name'].hasError('whitespace')
                "
              >
                Maximum length is 50 characters.
              </p>
              <p
                class="caption status-danger"
                style="margin-bottom: 0"
                *ngIf="
                  datasetForm.controls['name'].errors.regex &&
                  !datasetForm.controls['name'].errors.maxlength &&
                  !datasetForm.controls['name'].hasError('required') &&
                  !datasetForm.controls['name'].hasError('whitespace')
                "
              >
                Name cannot contain any of the following characters: \ / : *? "
                < > |
              </p>
              <p
                class="caption status-danger"
                *ngIf="datasetForm.controls['name'].errors.serverError"
              >
                {{ datasetForm.controls["name"].errors.serverError }}
              </p>
            </ng-container>
          </div>
          <div class="col-md-6 mb-3">
            <label for="charttype" class="form-label label">Chart Type</label>
            <nb-select
              fullWidth
              placeholder="Select Chart Type"
              [(ngModel)]="command.chartType"
              formControlName="chartType"
              size="small"
            >
              <nb-option value="Bar">Bar</nb-option>
              <nb-option value="Pie">Pie</nb-option>
            </nb-select>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 mb-3">
            <label for="labels" class="form-label label">Labels</label>
            <tag-input
              id="labels"
              formControlName="labels"
              style="width: 100%"
              [(ngModel)]="labels"
              secondaryPlaceholder=""
              addOnBlur="true"
              placeholder=""
            >
            </tag-input>
          </div>
        </div>
        <div class="row">
          <div
            class="col-md-12 mb-3"
            style="display: flex; flex-direction: column"
          >
            <label for="labels" class="form-label label"
              >Only Get Last Result</label
            >
            <nb-checkbox
              [checked]="command.lastResult"
              (checkedChange)="toggle($event)"
              >Enable</nb-checkbox
            >
          </div>
        </div>
      </div>
    </nb-card-body>
    <nb-card-footer>
      <div class="d-flex justify-content-end">
        <button
          nbButton
          size="small"
          [disabled]="
            !datasetForm.valid || isNameLoading || isExist || isLoading
          "
          status="primary"
        >
          <nb-icon icon="save-outline"></nb-icon>Save
        </button>
      </div>
    </nb-card-footer>
  </nb-card>
</form>
