import { filter } from "rxjs";
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { TextToSpeech } from "../../@core/model/text-to-speech";
import { DataService } from "../../@core/utils/data.service";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import {
  AudioClient,
  ConvertAudioCommand,
  CreateAudioLanguageCommand,
} from "../../System-api";
import { ppid } from "process";

@Component({
  selector: "text-to-speech",
  templateUrl: "./text-to-speech.component.html",
  styleUrls: ["./text-to-speech.component.scss"],
})
export class TextToSpeechComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild("testAudio", { static: true })
  public testAudio: ElementRef;
  listNation;
  dataText = [];
  dataTextBot = [];
  data: TextToSpeech = new TextToSpeech();
  currentTTS = [];
  currentTTSBot = [];
  isNoInput: boolean = false;
  isNotMatch: boolean = false;
  previousLength = 0;
  isChatBot: boolean = false;
  checkNull: boolean = false;
  isPlaying: boolean;
  audioBefore: CreateAudioLanguageCommand;
  dataAudio;
  attributes: any = this.dataService.ivrAttribute;
  ivrFunction: any = this.dataService.ivrFunction;
  selectedTTS: EventEmitter<any> = new EventEmitter<any>();
  selectedTTSBot: EventEmitter<any> = new EventEmitter<any>();
  newPrompt = [];
  newPromptBot = [];
  currentText: string = "";
  mentionConfig = {
    mentions: [
      {
        items: this.attributes,
        triggerChar: "{",
        labelKey: "value",
        disableSort: true,
        mentionSelect: (item) => {
          return "{" + item.value + "}";
        },
      },
      {
        items: this.ivrFunction,
        triggerChar: ">",
        labelKey: "value",
        disableSort: true,
        mentionSelect: (item) => {
          return ">" + item.value;
        },
      },
    ],
  };
  type = "main";
  isMainPromptAndCollect = false;
  constructor(
    private dataService: DataService,
    private audioClient: AudioClient
  ) {}
  ngAfterViewInit(): void {}

  trackByFn(index, item) {
    return index;
  }
  trackByFnBot(index, item) {
    return index;
  }
  ngOnDestroy(): void {
    this.newPrompt.forEach((text, index) => {
      if (text.trim()) {
        this.dataText[index].text.push(text.trim());
      }
    });
    if (this.isChatBot) {
      this.newPromptBot.forEach((text, index) => {
        if (text.trim()) {
          this.dataTextBot[index].textChatBot.push(text.trim());
        }
      });
    }
    this.handleCheckEmptyData();
  }
  handleCheckEmptyData() {
    this.dataText.forEach((item, index) => {
      this.dataText[index].text = this.dataText[index].text?.filter(
        (x) => x?.trim() != ""
      );
    });
    this.selectedTTS.emit(this.dataText);
    if (this.isChatBot) {
      this.dataTextBot.forEach((item, index) => {
        this.dataTextBot[index].textChatBot = this.dataTextBot[
          index
        ].textChatBot?.filter((x) => x?.trim() != "");
      });
      this.selectedTTSBot.emit(this.dataTextBot);
    }
  }
  ngOnInit() {
    this.audioBefore = new CreateAudioLanguageCommand();
    this.getNationSetting();
    if (this.checkNull) {
      if (this.isNoInput) {
        this.setDefaultNoInput();
      }
      if (this.isNotMatch) {
        this.setDefaultNotMatch();
      }
    }
  }
  setDefaultNoInput() {
    for (let i = 0; i < this.dataText.length; i++) {
      const language = this.dataText[i].language.split("-");
      if (this.dataText[i].text == "" && language[0] == "en") {
        this.dataText[i].text = [
          "Sorry, I didn't hear you ",
          "I'm sorry, I'm having a hard time hearing you.",
        ];
      }
    }
    this.selectedTTS.emit(this.dataText);
  }
  setDefaultNotMatch() {
    for (let i = 0; i < this.dataText.length; i++) {
      const language = this.dataText[i].language.split("-");
      if (this.dataText[i].text == "" && language[0] == "en") {
        this.dataText[i].text = [
          "Sorry, I didn't understand that",
          "I'm sorry, I didn't understand what you said.",
        ];
      }
    }
    this.selectedTTS.emit(this.dataText);
  }
  convertTTS(i, j, type) {
    const data = type == "bot" ? this.dataTextBot[i] : this.dataText[i];
    this.currentText = type == "bot" ? data.textChatBot[j] : data.text[j];
    const audio = this.listNation.find((obj) => obj.name == data.name);
    if (this.isPlaying) {
      this.isPlaying = false;
      this.testAudio.nativeElement.pause();
      this.testAudio.nativeElement.currentTime = 0;
      return;
    }
    if (
      this.audioBefore.content == this.currentText &&
      this.audioBefore.service == audio.service &&
      this.audioBefore.language == audio.language &&
      this.audioBefore.voiceId == audio.voiceId
    )
      this.testAudio.nativeElement.play();
    else {
      this.audioBefore.content = this.currentText;
      this.audioBefore.service = audio.service;
      this.audioBefore.language = audio.language;
      this.audioBefore.voiceId = audio.voiceId;

      this.audioClient
        .convert(
          new ConvertAudioCommand({
            content: this.currentText,
            service: audio.service,
            language: audio.language,
            voiceId: audio.voiceId,
          })
        )
        .subscribe((result) => {
          this.dataAudio = result;
          this.isPlaying = true;
        });
    }
  }
  getNationSetting() {
    this.listNation = JSON.parse(this.dataService.CallSetting.languageSetting);
    if (this.currentTTS.length > 0 || this.currentTTSBot.length > 0) {
      this.dataText = this.currentTTS;
      if (this.isChatBot) {
        this.dataTextBot = this.currentTTSBot;
      }
    }

    this.handleBindingData(this.dataText, "phone");
    this.newPrompt = new Array(this.dataText.length).fill("");
    if (this.isChatBot) {
      this.handleBindingData(this.dataTextBot, "bot");
      this.newPromptBot = new Array(this.dataTextBot.length).fill("");
    }
  }
  handleBindingData(data: any, type: string) {
    this.listNation.forEach((obj) => {
      let check = 0;
      for (let i = 0; i < data.length; i++) {
        const languageSetting = obj.language.split("-");
        const languageTTS = data[i].language.split("-");
        if (languageSetting[0] == languageTTS[0]) {
          data[i].name = obj.name;
          data[i].language = obj.language;
          data[i].defaultUser = obj.defaultUser;
          check = 1;
        }
      }
      if (check == 0) {
        let objText = null;
        if (type == "bot") {
          objText = { text: [], textChatBot: [] };
        } else {
          objText = { text: [] };
        }
        obj = { ...obj, ...objText };
        delete obj["service"];
        delete obj["voiceId"];
        delete obj["gender"];
        data.push(obj);
      }
    });
    for (let j = 0; j < data.length; j++) {
      let check = 0;
      this.listNation.forEach((item) => {
        const languageSetting = data[j].language.split("-");
        const languageTTS = item.language.split("-");
        if (languageSetting[0] == languageTTS[0]) {
          check = 1;
        }
      });
      if (check == 0) {
        if (j != 0) {
          data.splice(j, 1);
          j--;
        } else {
          data.shift();
          j--;
        }
      }
    }
  }
  selectCurrentTTS(text) {
    try {
      this.currentTTS = JSON.parse(text);
      this.currentTTS.forEach((item, index) => {
        if (typeof item.text == "string") {
          const textData = item.text.split("\u2022").filter(Boolean);
          this.currentTTS[index].text = textData;
        }
      });
    } catch (e) {
      this.currentTTS = [];
    }
  }
  selectCurrentTTSBot(text) {
    try {
      this.currentTTSBot = JSON.parse(text);
      this.currentTTSBot.forEach((item, index) => {
        if (typeof item.text == "string") {
          this.currentTTSBot[index].text = [item.text];
        }
        if (typeof item.textChatBot == "string") {
          this.currentTTSBot[index].textChatBot = [item.textChatBot];
        }
      });
    } catch (e) {
      this.currentTTSBot = [];
    }
  }
  onChangeContentText(i, j) {
    const textArea = document.getElementById(
      this.type + "-text-item-" + i + "-" + j
    );
    if (textArea) {
      textArea.style.height = "auto";
      textArea.style.height = `${textArea.scrollHeight}px`;
    }
  }
  resetContentText(i, j) {
    const textArea = document.getElementById(
      this.type + "-text-item-" + i + "-" + j
    );
    if (textArea) {
      textArea.style.height = "auto";
    }
  }
  onChangeContentTextBot(i, j) {
    const textArea = document.getElementById(
      this.type + "-text-item-bot-" + i + "-" + j
    );
    if (textArea) {
      textArea.style.height = "auto";
      textArea.style.height = `${textArea.scrollHeight}px`;
    }
  }
  resetContentTextBot(i, j) {
    const textArea = document.getElementById(
      this.type + "-text-item-bot-" + i + "-" + j
    );
    if (textArea) {
      textArea.style.height = "auto";
    }
  }
  onChangeContent(i) {
    const textArea = document.getElementById(
      this.type + "-input-prompt-text-" + i
    );
    if (textArea) {
      textArea.style.height = "auto";
      textArea.style.height = `${textArea.scrollHeight + 2}px`;
    }
  }
  onChangeContentBot(i) {
    const textArea = document.getElementById(
      this.type + "-input-prompt-text-bot-" + i
    );
    if (textArea) {
      textArea.style.height = "auto";
      textArea.style.height = `${textArea.scrollHeight}px`;
    }
  }
  checkDefaultLanguageUser(data) {
    let check = false;
    if (data.defaultUser === true) {
      check = true;
    }
    return check;
  }
  handleAddNewPrompt(index) {
    if (this.newPrompt[index].trim()) {
      this.dataText[index].text.push(this.newPrompt[index].trim());
      this.newPrompt[index] = "";
      this.selectedTTS.emit(this.dataText);
      setTimeout(() => {
        this.onChangeContentText(index, this.dataText[index].text.length - 1);
        this.onChangeContent(index);
      });
      const textArea = document.getElementById(
        this.type + "-input-prompt-text-" + index
      );
      textArea.focus();
    }
  }
  handleAddNewPromptBot(index) {
    if (this.newPromptBot[index].trim()) {
      this.dataTextBot[index].textChatBot.push(this.newPromptBot[index].trim());
      this.newPromptBot[index] = "";
      this.selectedTTSBot.emit(this.dataTextBot);
      setTimeout(() => {
        this.onChangeContentTextBot(
          index,
          this.dataTextBot[index].textChatBot.length - 1
        );
        this.onChangeContentBot(index);
      });
      const textArea = document.getElementById(
        this.type + "-input-prompt-text-bot-" + index
      );
      textArea.focus();
    }
  }
  handleEdit(i, j) {
    this.onChangeContentText(i, j);
  }

  handleEditBot(i, j) {
    this.onChangeContentTextBot(i, j);
  }

  drop(event: CdkDragDrop<string[]>, index) {
    moveItemInArray(
      this.dataText[index].text,
      event.previousIndex,
      event.currentIndex
    );
    this.resetContentText(index, event.previousIndex);
    this.resetContentText(index, event.currentIndex);
    this.selectedTTS.emit(this.dataText);
  }
  dropBot(event: CdkDragDrop<string[]>, index) {
    moveItemInArray(
      this.dataTextBot[index].textChatBot,
      event.previousIndex,
      event.currentIndex
    );
    this.resetContentTextBot(index, event.previousIndex);
    this.resetContentTextBot(index, event.currentIndex);
    this.selectedTTSBot.emit(this.dataTextBot);
  }
  handleRemovePrompt(index, i) {
    if (i != 0) {
      this.dataText[index].text.splice(i, 1);
      this.selectedTTS.emit(this.dataText);
    } else {
      this.dataText[index].text.shift();
      this.selectedTTS.emit(this.dataText);
    }
  }
  handleRemovePromptBot(index, i) {
    if (i != 0) {
      this.dataTextBot[index].textChatBot.splice(i, 1);
      this.selectedTTSBot.emit(this.dataTextBot);
    } else {
      this.dataTextBot[index].textChatBot.shift();
      this.selectedTTSBot.emit(this.dataTextBot);
    }
  }
  audioPlaying() {
    this.isPlaying = true;
  }
  audioEnded() {
    this.isPlaying = false;
  }
}
