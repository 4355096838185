<div class="header-table">
  <div class="column-name">
    <nb-icon
      (click)="clear()"
      title="Remove filter"
      *ngIf="(search && search.trim() != '') || searchDate != null"
      icon="funnel-outline"
    ></nb-icon>

    <span [matMenuTriggerFor]="menu" (menuClosed)="submit()">{{
      column.name
    }}</span>
  </div>

  <nb-icon
    class="button-action"
    icon="more-vertical-outline"
    [matMenuTriggerFor]="menu"
    (menuClosed)="submit()"
    #menuTrigger
  ></nb-icon>
</div>
<mat-menu #menu="matMenu">
  <div
    style="padding: 1rem 1.5rem; display: flex; flex-direction: column"
    (click)="$event.stopPropagation()"
  >
    <mat-form-field>
      <mat-label class="label">Choose type</mat-label>
      <mat-select
        style="font-size: 14px; font-weight: 500"
        [(ngModel)]="selectedItem"
        (click)="($event.preventDefault)"
      >
        <mat-option
          class="custome-mat-option"
          style="font-size: 0.75rem; font-weight: 500; height: 2.5em"
          *ngFor="let item of filter"
          [value]="item"
          >{{ item }}</mat-option
        >
      </mat-select>
    </mat-form-field>
    <input
      *ngIf="type == 'string'"
      type="text"
      status="primary"
      autofocus
      (keyup.enter)="enter()"
      placeholder="Filter..."
      [(ngModel)]="search"
      nbInput
      fieldSize="tiny"
      style="margin-top: -0.25rem"
    />
    <input
      *ngIf="type == 'date'"
      [nbDatepicker]="datepicker"
      status="primary"
      placeholder="Filter..."
      [(ngModel)]="searchDate"
      nbInput
      fieldSize="tiny"
      style="margin-top: -0.25rem"
    />
    <nb-datepicker #datepicker format="MM/dd/yyyy"></nb-datepicker>
  </div>
</mat-menu>
