import {
  TestTranslateCommand,
  TranslateClient,
} from "./../../../../../System-api";
import { Component, Input, OnInit } from "@angular/core";
import { FormControl, UntypedFormGroup } from "@angular/forms";
import { ServiceTranslate } from "../../../../../System-api";
import { Observable, map, of, startWith } from "rxjs";
import {
  languageCode,
  languageCodeSource,
} from "../setting-language/language-code";
import { NbGlobalPhysicalPosition, NbToastrService } from "@nebular/theme";

@Component({
  selector: "setting-translate",
  templateUrl: "./setting-translate.component.html",
  styleUrls: ["./setting-translate.component.scss"],
})
export class SettingTranslateComponent implements OnInit {
  @Input() parentForm: UntypedFormGroup;
  @Input() callSetting;
  languageText: FormControl;
  languageSource: FormControl;
  data = [
    { name: "Google", value: ServiceTranslate.Google },
    { name: "Amazon", value: ServiceTranslate.Amazon },
    { name: "Azure", value: ServiceTranslate.Azure },
  ];
  filteredLanguageTest$: Observable<any[]>;
  filteredLanguageSource$: Observable<any[]>;
  listLanguageTest = languageCode;
  listLanguageSource = languageCodeSource;
  textPreview: FormControl;
  compareList = [];
  selectedService = ["Google", "Amazon", "Azure"];
  selectedLanguage = null;
  selectedLanguageSource = null;
  isLoading = false;
  constructor(
    private translateClient: TranslateClient,
    private toastrService: NbToastrService
  ) {}
  swapLanguage() {
    const tempValue = this.languageText.value;
    this.languageText.setValue(this.languageSource.value);
    this.languageSource.setValue(tempValue);
  }
  ngOnInit() {
    this.languageText = new FormControl();
    this.textPreview = new FormControl();
    this.languageSource = new FormControl();
    this.filteredLanguageTest$ = of(this.listLanguageTest);
    this.filteredLanguageTest$ = this.languageText.valueChanges.pipe(
      startWith(""),
      map((filterString) => this.filter(filterString))
    );
    this.filteredLanguageSource$ = of(this.listLanguageSource);
    this.filteredLanguageSource$ = this.languageSource.valueChanges.pipe(
      startWith(""),
      map((filterString) => this.filterSource(filterString))
    );
  }
  translate() {
    let dataRequest = new TestTranslateCommand();
    this.selectedService.forEach((language) => {
      let data = this.data.find((x) => x.name == language);
      if (data) {
        switch (language) {
          case "Google":
            dataRequest.google = true;
            break;
          case "Amazon":
            dataRequest.amazon = true;
            break;
          case "Azure":
            dataRequest.azure = true;
            break;
          default:
            break;
        }
      }
    });
    dataRequest.text = this.textPreview.value;
    dataRequest.targetLanguage = this.selectedLanguage?.split("-")[0];
    dataRequest.sourceLanguage = this.selectedLanguageSource?.split("-")[0];
    try {
      this.isLoading = true;
      this.translateClient.translateTest(dataRequest).subscribe((rs: any) => {
        this.isLoading = false;
        this.compareList = [];
        this.compareList = rs.translations;
        this.selectedService.forEach((item) => {
          let data = this.compareList?.find((x) => item == x.service);
          if (!data) {
            this.compareList.push({
              result: { text: "", duration: -1 },
              service: item,
            });
          }
        });
      });
    } catch (error) {
      this.isLoading = false;
      this.compareList = [];
      this.toastrService.show(error?.message, `Notification`, {
        position: NbGlobalPhysicalPosition.BOTTOM_LEFT,
        status: "danger",
      });
    }
  }
  private filter(value: string): any[] {
    const language = this.listLanguageTest.find((x) => x.name == value);
    if (language) {
      this.selectedLanguage =
        language.code.azure || language.code.google || language.code.amazon;
    } else {
      this.selectedLanguage = null;
    }
    const filterValue = value.toLowerCase();
    return this.listLanguageTest.filter((optionValue) =>
      optionValue.name?.toLowerCase().includes(filterValue)
    );
  }
  private filterSource(value: string): any[] {
    const language = this.listLanguageSource.find((x) => x.name == value);
    if (language) {
      this.selectedLanguageSource =
        language.code.azure || language.code.google || language.code.amazon;
    } else {
      this.selectedLanguageSource = null;
    }
    const filterValue = value.toLowerCase();
    return this.listLanguageSource.filter((optionValue) =>
      optionValue.name?.toLowerCase().includes(filterValue)
    );
  }
  handleSelect(event) {
    this.callSetting.translateService = event;
  }
  handleAutoHeight(event) {
    const textArea = document.getElementById("text-preview-translate");
    const textArea2 = document.getElementById("text-preview-translate-2");
    if (textArea) {
      if (event?.trim()) {
        textArea.style.height = `${textArea.scrollHeight + 2}px`;
      } else {
        textArea.style.height = "163px";
      }
    }
    if (textArea2) {
      if (event?.trim()) {
        textArea2.style.height = `${textArea.scrollHeight + 2}px`;
      } else {
        textArea2.style.height = "163px";
      }
    }
  }
}
