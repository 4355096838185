import { DataService } from "./../../../../@core/utils/data.service";
import { languageCode } from "./../../other/setting-callflows/setting-language/language-code";
import { Component, HostListener, OnInit, ViewChild } from "@angular/core";
import { MxCell, MxGraph } from "../../../../@core/model/mxgraph";
import { TranslateAction } from "../../../../@core/model/translate-action";
import { NbDialogService, NbWindowRef } from "@nebular/theme";
import {
  FormControl,
  FormGroup,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { Observable, map, of, startWith } from "rxjs";
import { DialogAttributeComponent } from "../../other/dialog-attribute/dialog-attribute.component";
import {
  RxFormBuilder,
  RxwebValidators,
} from "@rxweb/reactive-form-validators";
import { noWhitespaceValidator } from "../../../../@core/utils/helpers";
import { GraphHandlerService } from "../../../../@core/utils/graph.service";

@Component({
  selector: "translate-action",
  templateUrl: "./translate-action.component.html",
  styleUrls: ["./translate-action.component.scss"],
})
export class TranslateActionComponent implements OnInit {
  cell: MxCell;
  graph: MxGraph;
  listLanguageText = languageCode;
  filteredSourceLanguageText$: Observable<any[]>;
  filteredTargetLanguageText$: Observable<any[]>;
  sourceLanguageText: FormControl;
  targetLanguageText: FormControl;
  isLoading = false;
  selectedLanguage = null;
  data: TranslateAction = new TranslateAction();
  attributes: any = this.dataService.ivrAttribute;
  cancelAction = false;
  ivrFunction: any = this.dataService.ivrFunction;
  mentionConfig = {
    items: this.attributes,
    triggerChar: "{",
    labelKey: "value",
    disableSort: true,
    mentionSelect: (item) => {
      return "{" + item.value + "}";
    },
  };
  formGroup: UntypedFormGroup;
  constructor(
    protected windowRef: NbWindowRef,
    private dialogService: NbDialogService,
    public dataService: DataService,
    private formBuilder: RxFormBuilder,
    private graphService: GraphHandlerService
  ) {
    this.createForm();
  }
  createForm() {
    this.formGroup = this.formBuilder.group({
      textPrompt: ["", [RxwebValidators.required(), noWhitespaceValidator]],
    });
  }
  handleSaveAction() {
    this.windowRef.close();
  }
  handleCancelAction() {
    this.cancelAction = true;
    this.windowRef.close(true);
  }
  ngOnInit(): void {
    this.sourceLanguageText = new FormControl("", [
      RxwebValidators.required(),
      noWhitespaceValidator,
    ]);
    this.targetLanguageText = new FormControl();

    this.filteredSourceLanguageText$ = of(this.listLanguageText);
    this.filteredTargetLanguageText$ = of(this.listLanguageText);
    this.filteredSourceLanguageText$ =
      this.sourceLanguageText.valueChanges.pipe(
        startWith(""),
        map((filterString) => this.filter(filterString))
      );
    this.filteredTargetLanguageText$ =
      this.targetLanguageText.valueChanges.pipe(
        startWith(""),
        map((filterString) => this.filter(filterString))
      );
    for (let [key, value] of Object.entries(this.data)) {
      this.data[key] = this.cell.getAttribute(key);
    }
    this.windowRef.onClose.subscribe(() => this.submit());
  }
  submit() {
    if (!this.cancelAction) {
      for (let [key, value] of Object.entries(this.data)) {
        if (typeof this.data[key] == "string") {
          value = value?.trim();
        }
        this.cell.setAttribute(key, value || "");
      }
      this.windowRef.close();
      const translateCell = this.graph.getModel().getCell(this.cell.getId());
      const fieldsRequired = [
        this.data.sourceLanguage,
        this.data.targetLanguage,
        this.data.text.trim(),
      ];
      const check = this.graphService.checkIsRequired(
        this.graph,
        fieldsRequired,
        translateCell
      );
      this.cell.setAttribute("checkFields", check?.toString());
    }
  }
  onChangeContent() {
    const textArea = document.getElementById("content-translate");
    if (textArea) {
      textArea.style.height = `${textArea.scrollHeight}px`;
    }
  }
  drop($event) {
    this.data.text += "{" + $event.dataTransfer.getData("text").trim() + "}";
    $event.preventDefault();
  }
  addAttribute() {
    this.dialogService
      .open(DialogAttributeComponent, { autoFocus: false })
      .onClose.subscribe((rs) => {
        if (rs) {
          this.data.attribute = rs;
        }
      });
  }
  setTargetLanguage(event) {
    if (this.selectedLanguage != null) {
      this.data.codeTargetLanguage = this.selectedLanguage.split("-")[0];
      this.data.targetLanguage = event;
      this.data.targetLanguageType = false;
    } else {
      const language = this.attributes.find((x) => event == `{${x.value}}`);
      if (language) {
        this.data.codeTargetLanguage = language.content;
        this.data.targetLanguage = language.value;
        this.data.targetLanguageType = true;
      }
    }
  }
  setSourceLanguage(event) {
    if (event.trim() === "") {
      this.sourceLanguageText.setErrors({ required: true });
      this.sourceLanguageText.setErrors({ whitespace: true });
    } else {
      this.sourceLanguageText.setErrors(null);
    }
    if (this.selectedLanguage != null) {
      this.data.codeSourceLanguage = this.selectedLanguage.split("-")[0];
      this.data.sourceLanguage = event;
      this.data.sourceLanguageType = false;
    } else {
      const language = this.attributes.find((x) => event == `{${x.value}}`);
      if (language) {
        this.data.codeSourceLanguage = language.content;
        this.data.sourceLanguage = language.value;
        this.data.sourceLanguageType = true;
      }
    }
  }
  onBlur(language) {
    if (language.trim() === "") {
      this.targetLanguageText.setErrors({ required: true });
      this.targetLanguageText.setErrors({ whitespace: true });
    } else {
      this.targetLanguageText.setErrors(null);
    }
  }
  private filter(value: string): any[] {
    const language = this.listLanguageText.find((x) => x.name == value);
    if (language) {
      this.selectedLanguage =
        language.code.azure || language.code.google || language.code.amazon;
    } else {
      this.selectedLanguage = null;
    }
    const filterValue = value.toLowerCase();
    return this.listLanguageText.filter((optionValue) =>
      optionValue.name?.toLowerCase().includes(filterValue)
    );
  }
  getAttribute(event) {
    this.data.attribute = event;
  }
}
