import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { MatMenuTrigger } from "@angular/material/menu";
import { NbSelectComponent } from "@nebular/theme";
import { FilterData } from "../../System-api";

@Component({
  selector: "header-table",
  templateUrl: "./header-table.component.html",
  styleUrls: ["./header-table.component.scss"],
})
export class HeaderTableComponent implements OnInit {
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
  @ViewChild(NbSelectComponent) select: NbSelectComponent;
  @Input() column: any;
  @Input() type: any = "string";
  @Output() handleFilter = new EventEmitter<any>();
  filter = [
    "Contains",
    "Not Contains",
    "Equals",
    "Not Equals",
    "Starts With",
    "Ends With",
  ];
  selectedItem = "Contains";
  search = "";
  searchDate = null;
  constructor() {}
  clear() {
    this.search = null;
    this.searchDate = null;
    this.submit();
  }
  submit(): void {
    this.search = this.search?.trim();
    let data = new FilterData();
    data.prop = this.column.prop;
    if (this.type == "string") {
      data.value = this.search;
    }
    if (this.type == "date") {
      if (this.searchDate != null) {
        let dataTime = this.searchDate.toLocaleDateString("en-US", {
          month: "2-digit",
          day: "2-digit",
          year: "numeric",
        });
        data.value = dataTime;
      } else {
        data.value = null;
      }
    }
    data.filter = this.selectedItem;
    data.type = this.type;
    this.handleFilter.emit(data);
  }
  enter() {
    this.trigger.closeMenu();
  }

  ngOnInit() {
    if (this.type == "date") {
      this.filter = [
        "Before",
        "Before Or Equals",
        "Equals",
        "Not Equals",
        "After Or Equals",
        "After",
      ];
      this.selectedItem = "Equals";
    }
  }
}
