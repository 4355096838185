import {
  CallFlowDto,
  AgentDto,
  AgentInQueueDto,
  CustomerVm,
  QueueInformation,
  CallSettingDto,
  CustomFunctionDto,
  DraftDto,
  NumberVm,
  AgentInfoDto,
} from "../../System-api";
export class DataService {
  public CallFlow: CallFlowDto;
  public CallSetting: CallSettingDto;
  public AgentInfo: AgentInfoDto;
  public Agent: AgentDto;
  public QueueInformation: QueueInformation;
  public AgentInQueue: AgentInQueueDto[];
  public Customer: CustomerVm;
  public PhoneNumber: NumberVm;
  public ivrAttribute: any[];
  public ivrAttributeArray: any[];
  public NewUser: boolean;
  public IsPrevious: boolean;

  //softphone
  public callStatus: CallStatus;
  public stateCall: string;
  //
  
  public CustomFunction: CustomFunctionDto;
  public Draft: DraftDto;
  public HEIGHT_SCALE_TABLE = 0.66;
  public Tag = "all";
  public CurrentPageCallFlow = 0;
  public CompanyId = null;
  public CurrentPageCustomFunction = 0;
  public CurrentPageDataset = 0;
  public CurrentPageCustom = 0;
  public CurrentPageAgent = 0;
  public CurrentPageQueue = 0;
  public CurrentPagePhoneNumber = 0;
  public userAction = [];
  public selectedOutGoingNumber: string;
  public callPhoneNumber: string;
  public userName: string;
  public isReadOnly: boolean;
  public ivrFunction: any[];
  public Dataset: any;
  public cms: number;
  public openChatButton: boolean;
  public openChatContent: boolean;
}

export enum CallStatus {
  NoCall = 0,
  Calling = 1,
  Ringging = 2,
  Incomming = 3,
  InCall = 4,
}
