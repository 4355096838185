  
import { Pipe, PipeTransform } from '@angular/core';
@Pipe({ name: 'duration' })
export class DurationPipe implements PipeTransform {
  transform(value:number) {
      var minutes = parseInt((value/60).toString(), 10);
      var seconds = value%60;

      if(minutes > 0 )
      {
        return minutes+' minutes'+(seconds ? ' and '+seconds+' seconds' : '');
      }
      else{
        return seconds+' seconds';
      }
    }
}