<div class="action-config-wrapper" style="max-width: 550px">
  <div class="config-action">
    <nb-stepper orientation="horizontal" selectedIndex="0">
      <nb-step [label]="labelOne">
        <ng-template #labelOne>Say Somethings</ng-template>
        <nb-radio-group
          [(ngModel)]="data.option"
          class="choice-option"
          (ngModelChange)="handleAudio($event)"
        >
          <nb-radio *ngFor="let option of options" [value]="option.id">{{
            option.name
          }}</nb-radio>
        </nb-radio-group>
        <div
          class="form-group"
          style="padding: 1rem 0; width: 100%; display: inline-grid"
        >
          <text-to-speech #textToSpeech></text-to-speech>
          <nb-card class="select-audio" [ngClass]="{ show: data.option == 1 }">
            <nb-card-header class="audio-list-style">
              <span>Audio List</span>
              <nb-icon
                class="ms-2"
                style="color: #929bb3; width: 15px"
                nbTooltip="Select the audio you want to play. The audio list will play in order and continuously. If no audio is selected, the system will use text to speech."
                nbTooltipPlacement="bottom"
                nbTooltipStatus="basic"
                nbTooltipTrigger="hover"
                icon="question-mark-circle-outline"
              >
              </nb-icon
            ></nb-card-header>
            <nb-card-body>
              <simple-audio-player
                #audioPlayer
                [events]="eventsSubject.asObservable()"
              ></simple-audio-player>
              <button
                nbButton
                size="small"
                status="primary"
                (click)="showModify()"
              >
                <nb-icon icon="plus-circle-outline"></nb-icon> Add
              </button>
            </nb-card-body>
          </nb-card>
        </div>

        <div
          class="mb-3"
          style="display: flex; flex-direction: column; margin-top: 0.5rem"
        >
          <label for="name" class="form-label label">Choose Videobot</label>
          <sentiment-select
            placeHolder="Choose Videobot"
            (valueSelected)="handleSentiment($event)"
            [value]="data.sentiment"
            defaultValue="normal-speak"
          ></sentiment-select>
        </div>
      </nb-step>
      <nb-step [label]="labelTwo">
        <ng-template #labelTwo>Select Queue</ng-template>
        <div class="form-label d-flex" style="align-items: center">
          <label class="label required"> Queue </label>
          <nb-icon
            class="ms-1"
            style="color: #929bb3; width: 15px"
            nbTooltip="Specify the queue that the user will be transferred to."
            nbTooltipPlacement="bottom"
            nbTooltipStatus="basic"
            nbTooltipTrigger="hover"
            icon="question-mark-circle-outline"
          >
          </nb-icon>
        </div>

        <form [formGroup]="formGroup">
          <div class="d-flex custom-gap">
            <ng-select
              formControlName="queue"
              [nbSpinner]="isLoading"
              nbSpinnerSize="medium"
              nbSpinnerStatus="info"
              [items]="queues"
              bindLabel="name"
              bindValue="id"
              fullWidth
              size="small"
              placeholder="Select Queue"
              [(ngModel)]="selectedQueue"
              (change)="queueSelectedChange($event)"
              [ngClass]="{
                isInvalid:
                  formGroup.controls['queue'].invalid &&
                  (formGroup.controls['queue'].dirty ||
                    formGroup.controls['queue'].touched)
              }"
            >
              <ng-template
                ng-option-tmp
                let-item="item"
                let-index="index"
                let-search="searchTerm"
              >
                <div class="function-item">
                  <span>{{ item.name }}</span>
                </div>
                <div class="function-item-action">
                  <nb-icon
                    icon="edit-outline"
                    style="font-size: 15px"
                    (click)="handleEdit(item.id)"
                  ></nb-icon>
                </div>
              </ng-template>
            </ng-select>
            <button
              type="button"
              size="small"
              status="primary"
              nbButton
              (click)="createQueue()"
            >
              <nb-icon icon="plus-outline"></nb-icon>
            </button>
          </div>
          <ng-container
            *ngIf="
              formGroup.controls['queue'].invalid &&
              (formGroup.controls['queue'].dirty ||
                formGroup.controls['queue'].touched)
            "
          >
            <p
              class="caption status-danger"
              style="margin-bottom: 0"
              *ngIf="
                formGroup.controls['queue'].hasError('required') ||
                formGroup.controls['queue'].hasError('whitespace')
              "
            >
              Queue is required.
            </p>
          </ng-container>
        </form>
      </nb-step>
      <!-- <nb-step [label]="labelThree">
              <ng-template #labelThree>Setting</ng-template>
          </nb-step> -->
    </nb-stepper>
  </div>
  <footer-action
    (handleCancelAction)="handleCancelAction()"
    (handleSaveAction)="handleSaveAction()"
  ></footer-action>
</div>
