import {
  Component,
  Input,
  OnInit,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter,
} from "@angular/core";
import { DatatableComponent } from "@swimlane/ngx-datatable";
import { DataService } from "../../../../../@core/utils/data.service";
import {
  FilterData,
  GetListCallLog,
  Page,
  TraceLogClient,
} from "../../../../../System-api";
import { Clipboard } from "@angular/cdk/clipboard";
import {
  NbGlobalPhysicalPosition,
  NbPopoverDirective,
  NbToastrService,
} from "@nebular/theme";

@Component({
  selector: "log-list",
  templateUrl: "log-list.component.html",
  styleUrls: ["./log-list.component.scss"],
})
export class LogListComponent implements OnInit {
  @ViewChild(NbPopoverDirective) popover: NbPopoverDirective;
  @ViewChild(DatatableComponent) table: DatatableComponent;
  @Input() isModal = true;
  @Input() data: any[] = [];
  @Output() dataLogExport = new EventEmitter<any[]>();
  @Output() disabled = new EventEmitter<boolean>();
  @Output() updateLoading = new EventEmitter<any>();
  logContent = "";
  logContentString = "";
  loading = false;
  callId: any;
  columns = [];
  filteredData = [];
  fromNumber: any;
  toNumber: any;
  page: Page = new Page();
  rows = [];
  timeout: any = null;
  searchText: string = null;
  fromNumberSearch: string = null;
  callFlowId = this.dataService?.CallFlow?.id || null;
  showMoreContent = true;
  copyTitle = "Copy to clipboard";
  filterObject: FilterData[] = [];
  isLoadingExport = false;
  constructor(
    private dataService: DataService,
    private tracelogClient: TraceLogClient,
    private clipboard: Clipboard,
    private toastrService: NbToastrService
  ) {
    this.columns = [
      { prop: "id", name: "ID" },
      { prop: "fromNumber", name: "From" },
      { prop: "toNumber", name: "To" },
      { prop: "logContent", name: "Log Content" },
      { prop: "dateCreate", name: "Created" },
    ];
    this.page.pageNumber = 0;
    this.page.size = Math.floor((window.innerHeight * 0.55) / Number(34));
    this.page.sortBy = "id";
  }
  filterHeader(data: FilterData) {
    let indexExist = this.filterObject.findIndex((x) => x.prop == data.prop);
    if (indexExist >= 0) {
      if (
        this.filterObject[indexExist]?.value != data.value ||
        this.filterObject[indexExist]?.filter != data.filter
      ) {
        this.filterObject[indexExist] = data;
        this.setPage({ offset: 0 });
      }
    } else {
      this.filterObject.push(data);
      if (data.value) {
        this.setPage({ offset: 0 });
      }
    }
  }
  setPage($event) {
    this.page.pageNumber = $event.offset;
    this.loading = true;
    let dataRequest = new GetListCallLog();
    dataRequest.page = this.page;
    dataRequest.callId = this.callId;
    dataRequest.searchText = this.searchText;
    dataRequest.fromNumber = this.fromNumberSearch;
    dataRequest.id = this.callFlowId;
    dataRequest.filterData = this.filterObject;
    this.tracelogClient.getAllInTable(dataRequest).subscribe(
      (pagedData) => {
        this.loading = false;
        this.rows = pagedData?.callLog || [];
        this.page = pagedData?.page;
        if (this.rows?.length > 0) {
          this.disabled.emit(false);
        } else {
          this.disabled.emit(true);
        }
      },
      (error) => {
        this.loading = false;
      }
    );
  }
  onSort(event) {
    const currentPage = this.page.pageNumber + 1;
    this.page.sortAsc = event.sorts[0].dir == "asc" ? true : false;
    this.page.sortBy = event.sorts[0].prop;
    this.setPage({ offset: this.page.pageNumber });
    this.table.onFooterPage({ page: currentPage });
  }
  ngOnInit() {}
  showLog(data) {
    this.copyTitle = "Copy to clipboard";
    this.logContentString = data;
    this.logContent = JSON.parse(data);
  }
  closeModal() {
    this.popover.hide();
  }
  copyAttribute() {
    this.clipboard.copy(this.logContentString);
    this.copyTitle = "Copied to clipboard";
  }
  searchFromNumber(event) {
    this.fromNumberSearch = event?.target?.value?.toLowerCase();
  }
  searchDataSearch(event) {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.searchText = event?.target?.value?.toLowerCase();
      this.setPage({ offset: 0 });
    }, 300);
  }
  exportLog() {
    this.updateLoading.emit(true);
    this.isLoadingExport = true;
    this.tracelogClient
      .getAll(
        this.callFlowId,
        0,
        1000,
        this.page.sortBy,
        this.page.sortAsc,
        this.callId,
        this.searchText,
        this.fromNumberSearch
      )
      .subscribe(
        (pagedData) => {
          this.updateLoading.emit(false);
          this.isLoadingExport = false;
          this.dataLogExport.emit(pagedData?.callLog.reverse());
        },
        (error) => {
          this.updateLoading.emit(false);
          this.isLoadingExport = false;
        }
      );
  }
  ngAfterViewInit(): void {}
}
